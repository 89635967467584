import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

export interface MessagePayload {
  text: string;
  data: any;
}
export const GENERAL_MESSAGE_KEY = 'GENERAL_MESSAGE';

@Injectable()
export class MessageService {
  private subject = new Subject<MessagePayload>();

  send(message: string, data: any = null) {
    this.subject.next({ text: message, data: data });
  }

  read(): Observable<MessagePayload> {
    return this.subject.asObservable();
  }
}
