import { NgModule } from '@angular/core';
import { FormatPhoneNumberPipe } from 'app/shared/pipes/format-phone-number.pipe';

@NgModule({
  declarations: [
    FormatPhoneNumberPipe,
  ],
  exports: [
    FormatPhoneNumberPipe
  ]
})
export class SharedModule {}
