import { Injectable } from '@angular/core';
import { jwtDecode } from 'jwt-decode';

export const TOKEN_KEY = 'trevelyan_token';
export const USER_KEY = 'trevelyan_user';
const ACTIVE_FILTERS_KEY = 'peopleSearchGridFilters';
export const PRIVATE_LOCAL_STORAGE_ITEMS = [TOKEN_KEY, USER_KEY, ACTIVE_FILTERS_KEY];

export interface PermissionObject {
  hasPermission: boolean;
  permissionId: number;
}

export interface User {
  id: number;
  firstname: string;
  surname: string;
  email: string;
}

@Injectable()
export class AuthService {
  private user: User;

  getToken(): string {
    return localStorage.getItem(TOKEN_KEY);
  }

  setToken(token: string): void {
    localStorage.setItem(TOKEN_KEY, token);
  }

  getUser(): User {
    return this.user || <User>JSON.parse(localStorage.getItem(USER_KEY));
  }

  setUser(user: User): void {
    this.user = user;
    localStorage.setItem(USER_KEY, JSON.stringify(this.user));
  }

  getTokenExpirationDate(token: string): Date {
    const decoded = jwtDecode(token) as any;

    if (decoded.exp === undefined) {
      return null;
    }

    const date = new Date(0);
    date.setUTCSeconds(decoded.exp);
    return date;
  }

  isTokenExpired(): boolean {
    const token = this.getToken();
    if (!token) {
      return true;
    } else {
      const date = this.getTokenExpirationDate(token);
      if (date === undefined) {
        return false;
      } else {
        return !(date.valueOf() > new Date().valueOf());
      }
    }
  }

  deleteAuthCookie() {
    const now = new Date();
    const expiryDate = new Date();
    expiryDate.setFullYear(now.getFullYear() - 10);
    document.cookie = 'auth_token=;expires=' + expiryDate + ';';
  }

  clear() {
    this.user = undefined;
    this.clearLocalStorage();
    this.deleteAuthCookie();
  }

  hasPermission(permission: string): boolean {
    const token = this.getToken();
    if (!token) {
      return false;
    }
    const decoded = jwtDecode(token);
    return decoded[permission];
  }

  getPermissionObject(permission: string): PermissionObject {
    const token = this.getToken();
    if (!token) {
      return { hasPermission: false, permissionId: null };
    }
    const decoded = jwtDecode(token);
    return decoded[permission];
  }

  private clearLocalStorage() {
    // Clearing the USER_OVERRIDE_KEY is not included here as that prevents staff not logged in to the GP from
    // impersonating growers on their first try, they would need to log in then click the link again.
    PRIVATE_LOCAL_STORAGE_ITEMS.forEach(key => localStorage.removeItem(key));
  }
}
