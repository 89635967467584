<div class="breadcrumbs-panel">
  <app-breadcrumbs></app-breadcrumbs>
  <app-help-button class="help-button" [helpKey]="helpKey"></app-help-button>
</div>
<div class="col-group">
  <div class="col-left">
      <div class="input-panel">
        <label for="orchard-select">Orchard</label>
        <kendo-dropdownlist id="orchard-select"
                            [data]="orchardOptions"
                            textField="grower_number"
                            valueField="variety_grow_methods"
                            [value]="selectedOrchard"
                            (valueChange)="onOrchardSelect($event)">
          <ng-template kendoDropDownListValueTemplate let-dataItem>
            <div class="item-text"
                 [class.reduced-width]="orchardOptions?.length > 1">
              {{ dataItem?.grower_number | appendIfExists:' - ' }}{{ dataItem?.orchard_name }}
            </div>
            <div *ngIf="orchardOptions?.length > 1"
                 class="additional-items">
              {{ orchardOptions.length - 1 }} more
            </div>
          </ng-template>
          <ng-template kendoDropDownListItemTemplate let-dataItem>
              {{ dataItem.grower_number }} - {{ dataItem.orchard_name }}
          </ng-template>
        </kendo-dropdownlist>
        <div class="sub-text">Select orchard here.</div>
      </div>

      <div class="input-panel">
        <label for="variety-select">Variety</label>
        <kendo-dropdownlist id="variety-select"
                            [disabled]="isVarietyDisabled"
                            [data]="varietyOptions"
                            textField="variety_name"
                            valueField="variety_id"
                            [value]="selectedVariety"
                            (valueChange)="onVarietySelect($event)">
          <ng-template kendoDropDownListValueTemplate let-dataItem>
            <div class="item-text">{{ dataItem?.variety_name }}</div>
            <div *ngIf="varietyOptions.length > 1" class="additional-items">{{ varietyOptions.length - 1 }} more</div>
          </ng-template>
        </kendo-dropdownlist>
        <div class="sub-text">Choose your variety.</div>
      </div>

      <div class="input-panel">
        <label for="grow-method-select">Grow Method</label>
        <kendo-dropdownlist id="grow-method-select"
                            [disabled]="isGrowMethodDisabled"
                            [data]="growMethodOptions"
                            textField="grow_method_name"
                            valueField="grow_method_name"
                            [value]="selectedGrowMethod"
                            (valueChange)="onGrowMethodSelect($event)">
          <ng-template kendoDropDownListValueTemplate let-dataItem>
            <div class="item-text">{{ dataItem?.grow_method_name }}</div>
            <div *ngIf="growMethodOptions.length > 1" class="additional-items">{{ growMethodOptions.length - 1 }} more</div>
          </ng-template>
        </kendo-dropdownlist>
        <div class="sub-text">Choose your growing method.</div>
      </div>
  </div>
  <div class="col col-right">
    <div class="input-panel" [class.error]="!validatableFields.chargeableRejectsBeforeThinningPercent.isValid">
      <label for="chargeable-reject-before">
        Chargeable Rejects Before Thinning
        <span class="unit">(%)</span>
      </label>
      <input kendoTextBox id="chargeable-reject-before" [(ngModel)]="chargeableRejectsBeforeThinningPercent" (input)="calculate()">
      <div class="additional-info">{{ chargeableRejectsBeforeThinningValue | currency }}/tray</div>
    </div>
    <div class="input-panel" [class.error]="!validatableFields.chargeableRejectsAfterThinningPercent.isValid">
      <label for="chargeable-reject-after">
        Chargeable Rejects After Thinning
        <span class="unit">(%)</span>
      </label>
      <input kendoTextBox id="chargeable-reject-after" [(ngModel)]="chargeableRejectsAfterThinningPercent" (input)="calculate()">
      <div class="additional-info">{{ chargeableRejectsAfterThinningValue | currency }}/tray</div>
    </div>
    <div class="input-panel" [class.error]="!validatableFields.traysPerHa.isValid">
      <label for="trays-per-ha">Trays/Ha</label>
      <input kendoTextBox id="trays-per-ha" [(ngModel)]="traysPerHa" (input)="calculate()">
    </div>

    <div class="group">
      <div class="group-label">
        Savings
        <span class="unit">($/Ha)</span>
      </div>
      <div class="panel">
        <div class="label">Surcharge</div>
        <div class="value">{{ savings.surcharge | currency }}</div>
      </div>
      <div class="panel">
        <div class="label">Picking</div>
        <div class="value">{{ savings.picking | currency }}</div>
      </div>
      <div class="panel">
        <div class="label">Cartage</div>
        <div class="value">{{ savings.cartage | currency }}</div>
      </div>
      <div class="panel space-top">
        <div class="label">Total</div>
        <div class="value bold">{{ savings.total | currency }}</div>
      </div>
    </div>

    <div class="input-panel" [class.error]="!validatableFields.costOfThinning.isValid">
      <label for="cost-of-thinning">
        Cost of Thinning
        <span class="unit">($/Ha)</span>
      </label>
      <input kendoTextBox id="cost-of-thinning" [(ngModel)]="costOfThinning" (input)="calculate()">
    </div>
    <div class="panel spotlight">
      <div class="label">Marginal Benefit</div>
      <div class="value bold">{{ marginalBenefit | currency }}<span class="unit">/Ha</span></div>
    </div>
  </div>
  <div class="col-help" [innerHTML]="calculatorDescription?.content"></div>
</div>

<loading-indicator [class.visible]="isLoading"
                   [radius]="30"
                   [thickness]="4"
                   message=""
                   [isLight]="true"
                   externalMessage="Loading orchard data..."
                   [isAbsolute]="true">
</loading-indicator>
