import { Injectable } from '@angular/core';
import { Observable, forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpService } from 'app/shared/services/http.service';
import * as moment from 'moment';

interface WeatherForecast {
  fcst_valid_local: string,
  dow: string,
  date: moment.Moment
}

interface WeatherForecastDailyPart {
  icon_code: number,
  narrative: string
}

export interface WeatherForecastDaily extends WeatherForecast {
  day?: WeatherForecastDailyPart,
  night: WeatherForecastDailyPart,
  icon_code: number,
  narrative: string
  max_temp: number,
  min_temp: number
}

export interface WeatherForecastHourly extends WeatherForecast {
  temp: number,
  icon_code: number,
  pop: number,  // chance of rain
  wspd: number,  // wind speed (km/hr)
  wdir_cardinal: string,  // cardinal wind direction
}


@Injectable()
export class OrchardWeatherService extends Observable<{ daily: WeatherForecastDaily[], hourly: WeatherForecastHourly[] }> {
  private ENDPOINT = 'orchards/weather_forecast/{latitude}/{longitude}/';
  private MAX_DAILY_FORECASTS = 3;

  constructor(
    private http: HttpService
  ) {
    super(null);
  }

  public fetch(latitude: number, longitude: number): Observable<{ daily: WeatherForecastDaily[], hourly: WeatherForecastHourly[] }> {
    const url = this.ENDPOINT.replace('{latitude}', latitude.toString()).replace('{longitude}', longitude.toString());
    return forkJoin({
      daily: this.http.get(`${ url }daily/`, {}, false),
      hourly: this.http.get(`${ url }hourly/`, {}, false)
    }).pipe(
      map((data) => {
        const dailyForecasts = (data['daily']['forecasts'] as WeatherForecastDaily[]).slice(0, this.MAX_DAILY_FORECASTS);
        for (let i=0; i < dailyForecasts.length; i++) {
          dailyForecasts[i].date = moment(dailyForecasts[i].fcst_valid_local);
          let period = 'day' in dailyForecasts[i] ? dailyForecasts[i]['day'] : dailyForecasts[i]['night'];
          dailyForecasts[i].narrative = period.narrative;
          dailyForecasts[i].icon_code = period.icon_code;
        }

        const hourlyForecasts = data['hourly']['forecasts'] as WeatherForecastHourly[];
        for (let i=0; i < hourlyForecasts.length; i++) {
          hourlyForecasts[i].date = moment(hourlyForecasts[i].fcst_valid_local);
        }

        return { daily: dailyForecasts, hourly: hourlyForecasts };
      })
    );
  }
}
