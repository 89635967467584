import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

export type NotificationType = 'info' | 'error' | 'success';
export type NotificationTheme = 'light' | 'dark';
export type NotificationLocation = 'top-left' | 'top-center' | 'top-right' | 'bottom-left' | 'bottom-center' | 'bottom-right' | 'center';
export const DEFAULT_NOTIFICATION_THEME = 'dark';
export const DEFAULT_NOTIFICATION_TYPE = 'info';
export const DEFAULT_NOTIFICATION_LOCATION = 'top-center';

export interface NotificationOptions {
  title?: string;
  type?: NotificationType;
  icon?: string;
  duration?: number | null;
  theme?: NotificationTheme;
  location?: NotificationLocation;
}

export interface NotificationState {
  visible: boolean;
  message?: string;
  options?: NotificationOptions;
}

@Injectable()
export class NotificationService {
  onChange = new Subject<NotificationState>();

  constructor() {}

  showNotification(message: string, options: NotificationOptions) {
    this.onChange.next({ visible: true, message, options });
  }

  hideNotification() {
    this.onChange.next({ visible: false });
  }
}
