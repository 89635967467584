import { FormGroup } from '@angular/forms';

export class MetadataFormGroup<TMetadata> extends FormGroup {
  private _metadata: TMetadata;
  get metadata(): TMetadata {
    return this._metadata;
  }
  set metadata(value: TMetadata) {
    this._metadata = value;
  }
}
