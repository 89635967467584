<app-widget [updatedAt]="updatedAt"
            [isLoading]="isLoading"
            [hasNoData]="!tabs?.length"
            loadingMessage="Loading sample information..."
            noDataMessage="No sample information available."
            [dropdownItems]="availableSeasons"
            [selectedDropdownItem]="selectedSeason"
            [onDropdownItemChange]="selectSeason.bind(this)"
            dropdownHandle="avocadoSampleInformationWidgetSeasonDropdown"
            [secondDropdownItems]="availableGrowerNumbers"
            [selectedSecondDropdownItem]="selectedGrowerNumber"
            [onSecondDropdownItemChange]="selectGrowerNumber.bind(this)"
            secondDropdownItemLabel="PPIN"
            secondDropdownHandle="avocadoSampleInformationWidgetOrchardDropdown"
            [thirdDropdownItems]="availableMaturityAreas"
            [selectedThirdDropdownItem]="selectedMaturityArea"
            [onThirdDropdownItemChange]="selectMaturityArea.bind(this)"
            thirdDropdownItemLabel="MA"
            thirdDropdownHandle="avocadoSampleInformationWidgetMaDropdown"
            [headerButtons]="headerButtons">
  <kendo-tabstrip *ngIf="tabs?.length" class="tabstrip"
                  (tabSelect)="onTabSelect($event)">
    <kendo-tabstrip-tab *ngFor="let tab of tabs"
                        [title]="tab.title"
                        [selected]="tab.selected">
        <ng-template kendoTabContent>
          <app-simple-grid [columns]="gridColumns"
                           [data]="gridDataFiltered"
                           [selectedRowIndexes]="[0]"
                           rowSelectType="single"
                           [rowSelectCallback]="onGridRowSelect.bind(this)"
                           [hasColumnSelect]="true">
          </app-simple-grid>
        </ng-template>
    </kendo-tabstrip-tab>
  </kendo-tabstrip>
  <div *ngIf="areDetailsVisible && selectedRow" class="details">
  </div>
</app-widget>
