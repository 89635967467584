<div #chartFrame class="chart-frame">
  <div *ngIf="axisSettings?.y" class="axis y">
    <div class="label">
      {{ axisSettings.y.label }}
      <span *ngIf="axisSettings.y.unit" class="unit" [innerHTML]="axisSettings.y.unit"></span>
    </div>
  </div>

  <div #chartContainer
       class="chart-container"
       (mouseleave)="onMouseLeave()">
      <svg class="chart"
           [class.hidden]="hideSvg"
           [class.has-x-grid]="showXGrid"
           [class.has-y-grid]="showYGrid"
           [attr.width]="width"
           [attr.height]="height">
        <g #chart class="chart-content"
           [attr.transform]="'translate(' + margin.left + ', ' +  margin.top + ')'"></g>
      </svg>

      <app-tooltip *ngIf="hasTooltip && !resize.resizeInProgress"
                   class="tooltip dark"
                   [title]="tooltip.title"
                   top="0"
                   [left]="tooltip.left"
                   [visible]="tooltip.isVisible"
                   [display]="tooltip.display"
                   [width]="tooltip.width"
                   [hasArrow]="false"
                   [hasLine]="true"
                   [hasFooter]="tooltipConfig?.showFooter"
                   direction="east"
                   [lineHeight]="height - margin.bottom"
                   [limits]="{ left: margin.left, top: 0, right: margin.right, bottom: 0 }">
        <span class="content" [innerHTML]="tooltip.content"></span>
        <span class="footer" [innerHTML]="tooltip.footer"></span>
      </app-tooltip>
  </div>
</div>

<div *ngIf="axisSettings?.x"
     class="axis x center">
  {{ axisSettings.x.label }}
  <span *ngIf="axisSettings.x.unit" class="unit" [innerHTML]="axisSettings.x.unit"></span>
</div>

<div #chartLegend
     class="legend-panel"
     [class.hidden]="hideLegend">
  <div *ngIf="showLegend" [class]="'legend ' + legendLocation">
    <div *ngFor="let legendItem of legendItems"
        class="legend-item"
        (mouseenter)="onLegendItemMouseOver(legendItem)"
        (mouseleave)="onLegendItemMouseOut()">
      <div [class]="'indicator ' + legendItem.cssClass"></div>
      <div class="label">{{ legendItem.label }}</div>
    </div>
  </div>
</div>

<div *ngIf="hasNoDataMessage"
     class="no-data-message"
     [class.visible]="hasNoData">
  <i class="fas fa-exclamation-triangle"></i>
  <div class="message">{{ noDataMessage }}</div>
  <div class="sub-message">{{ noDataSubMessage }}</div>
</div>
