import { TutorialStep, AttachDirection } from 'app/shared/services/tutorial.service';

class AvocadoProductionWidgetStep extends TutorialStep {
  constructor(step) {
    super(step);
    this.id = 'avocado-production-widget';
    this.title = 'Avocado Production Widget';
    this.arrow = true;
    this.scrollTo = true;
  }
}

export function getAvocadoProductionWidgetSteps(tutorialService): AvocadoProductionWidgetStep[] {
  return [
    new AvocadoProductionWidgetStep ({
      beforeShowPromise: () => {
        return new Promise<void>((resolve) => {
          tutorialService.clickElement('avocado-production');
          resolve();
        });
      },
      attachTo: {
        element: '.col:first-child > app-tabbed-content > .content',
        on: AttachDirection.top
      },
      scrollTo: true,
      text: 'The Production Widget provides a high-level summary of harvest and packing progress for your orchard(s). Data is updated once packing is reconciled each day.'
    }),
    new AvocadoProductionWidgetStep ({
      attachTo: {
        element: 'grow-avocado-production-widget kendo-tabstrip > ul li:first-child',
        on: AttachDirection.bottom
      },
      text: 'Use these tabs to switch between available Varieties and Grow Methods.'
    }),
    new AvocadoProductionWidgetStep ({
      attachTo: {
        element: 'grow-avocado-production-widget app-simple-grid .row-toggle',
        on: AttachDirection.right
      },
      text: 'Use the arrows to show or hide given orchard\'s MAs and Packruns.'
    }),
    new AvocadoProductionWidgetStep ({
      attachTo: {
        element: 'grow-avocado-production-widget .chart',
        on: AttachDirection.top
      },
      text: 'This chart shows the number of tipped bins over the number of estimated bins.'
    }),
    new AvocadoProductionWidgetStep ({
      attachTo: {
        element: 'grow-avocado-production-widget .comparison-panel',
        on: AttachDirection.top
      },
      text: 'Here you can see how you compare against orchards in the same region and against Trevelyan’s averages. Green values indicate an improvement over the averages, while red values indicate a decline.'
    }),
    new AvocadoProductionWidgetStep ({
      beforeShowPromise: () => {
        return new Promise<void>((resolve) => {
          tutorialService.clickElement('avocado-production');
          resolve();
        });
      },
      attachTo: {
        element: 'grow-avocado-production-widget header dropdown',
        on: AttachDirection.bottom
      },
      text: 'Use the Season selector to switch between available seasons.'
    })
  ];
}
