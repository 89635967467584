<div class="footer"
     [class.selected]="isFooterSelected"
     (mouseover)="expandFooter()"
     (mouseout)="collapseFooter()">
  <div class="footer-content">
    <div class="column logo-column">
      <div class="row logo"></div>
      <div class="copyright full-height-only">
        &copy; {{ currentYear }} Trevelyan’s Pack & Cool Ltd
      </div>
    </div>
    <div class="column full-height-only">
      <div class="street">
        310 No 1 Rd.
      </div>
      <div class="city">
        Te Puke
      </div>
      <div class="postal-code">
        3182
      </div>
      <div class="country">
        New Zealand
      </div>
    </div>
    <div class="column full-height-only">
      <div class="row">
        <a href="mailto:info@trevelyan.co.nz">info&#64;trevelyan.co.nz</a>
      </div>
      <div class="row">
        <a href="tel:07-573-0085">07-573-0085</a>
      </div>
    </div>
    <div class="column full-height-only">
      <div class="row">
        <a href="#terms-of-use" (click)="showTermsOfUse()">Terms of Use</a>
      </div>
      <div class="row">
        <a href="#privacy-policy" (click)="showPrivacyPolicy()">Privacy Policy</a>
      </div>
    </div>
    <div class="column right-column help-and-feedback-column">
      <div class="row right-footer-row">
        <div *ngIf="displayHelpToggle" class="help-toggle-panel">
          Help
          <app-toggle class="help-toggle small inline"
                      [stateValue]="helpService.getHelp()"
                      (stateChange)="toggleHelp()"
                      tabstop="1"></app-toggle>
        </div>
        <button *ngIf="displayTutorialButton" class="button small rounded footer-button" (click)="showTutorial()">Tutorial</button>
        <button *ngIf="displayFeedbackButton" class="button small rounded footer-button" (click)="showFeedbackForm()">Feedback</button>
      </div>
    </div>
  </div>
</div>
