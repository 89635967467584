import { Component, OnInit, HostListener, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'rank',
  templateUrl: './rank.component.html',
  styleUrls: ['./rank.component.scss']
})
export class RankComponent implements OnInit {
  highlightValue = 0;
  currentValue = 0;
  numberOfItems = 5;
  rankItems: number[];
  legend = {
    badLabel: 'Unhappy',
    goodLabel: 'Happy'
  }
  @Output() currentChange = new EventEmitter();

  constructor() {
    this.rankItems = Array(this.numberOfItems).fill(0).map((_, i) => i)
  }

  ngOnInit() {
  }

  @Input()
  get current() {
    return this.currentValue;
  }

  set current(currentValue) {
    this.highlightValue = currentValue;
    this.currentValue = currentValue;
    this.currentChange.emit(this.currentValue);
  }

  @HostListener('mouseleave') onMouseLeave() {
    this.highlightValue = this.currentValue;
  }

  select(value) {
    this.current = value;
  }

  setHighlightIndex(value) {
    this.highlightValue = value;
  }
}
