import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { Product, UserProductsService } from 'app/shared/services/user-products.service';

@Injectable()
export class ProductInterceptor implements HttpInterceptor {
  constructor(
    private userProductsService: UserProductsService
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (request.params.has('product_id')) {
      return next.handle(request);
    }

    const product: Product = this.userProductsService.getSelectedProduct();
    if (request.headers.has('Authorization') && product?.product_id) {
      request = request.clone({
        setParams: {
          product_id: product.product_id.toString()
        }
      });
    }
    return next.handle(request);
  }
}

