import { Component, HostBinding, Input, ViewContainerRef } from '@angular/core';
import { StateService } from 'app/shared/services/state.service';

export interface ColorItem {
  background: string;
  foreground?: string;
}

 // Trev. versions of: white -> green -> yellow -> orange -> red
export const DEFAULT_COLORS: ColorItem[] = [
  { background: 'ffffff' },
  { background: 'ffd32f' },
  { background: 'f27930' },
  { background: 'c5242b', foreground: '#ffffff' }
];
export const FIRST_COLOR_STEPS = 50;
export const LAST_COLOR_STEPS = 5;

@Component({
  selector: 'app-heatmap-item',
  templateUrl: './heatmap-item.component.html',
  styleUrls: ['./heatmap-item.component.scss']
})
export class HeatmapItemComponent {
  @HostBinding('class') heatMapStepCssClass: string;

  private _percent: number = null;
  @Input()
  get percent(): number {
    return this._percent;
  }
  set percent(value: number) {
    if (!this.expandedColors.length) {
      this.expandColors();
    }
    if (value !== this._percent) {
      this._percent = value;
      this.setColor();
    }
  }

  private _steps = 100;
  @Input()
  get steps(): number {
    return this._steps;
  }
  set steps(value: number) {
    if (value) {
      this._steps = value;
      this.expandColors(true);
      this.setColor();
    }
  }

  private _colors = DEFAULT_COLORS;
  @Input()
  get colors(): ColorItem[] {
    return this._colors;
  }
  set colors(value: ColorItem[]) {
    if (value !== this._colors) {
      this._colors = value;
      this.expandColors(true);
      this.setColor();
    }
  }
  private expandedColors: ColorItem[] = [];

  constructor(
    private viewContainerRef: ViewContainerRef,
    private stateService: StateService
  ) {}

  private setColor() {
    const maxColorIndex = this.expandedColors.length - 1;
    const colorIndex = Math.floor(this.percent * maxColorIndex);
    const colorItem = this.expandedColors[colorIndex];
    if (colorItem) {
      this.viewContainerRef.element.nativeElement.style.backgroundColor = colorItem.background;
      this.viewContainerRef.element.nativeElement.style.color = colorItem.foreground;
    }
  }

  private interpolateColor(from: string, to: string, percent: number): string {
    const fromRgb = this.scaledHexToRgb(from, (1 - percent));
    const toColor = this.scaledHexToRgb(to, percent);
    const interpolatedColor = [0, 1, 2].map(i => Math.min(Math.round(fromRgb[i] + toColor[i]), 255));
    return `rgb(${ interpolatedColor.join(',') })`;
  }

  private scaledHexToRgb(hex: string, percent: number): number[] {
    return this.hex2Rgb(hex).map((hexOctet) => parseInt(hexOctet, 16) * percent);
  }

  private hex2Rgb(hex: string): string[] {
    return hex.match(/.{1,2}/g);
  }

  private nthRoot(value: number, root: number): number {
    return Math.pow(value, 1 / root);
  }

  private stepsBetweenColors(color: number, scale: number, totalColors: number): number {
    const root_result = this.nthRoot(LAST_COLOR_STEPS / FIRST_COLOR_STEPS, totalColors - 1);
    return Math.round(scale * FIRST_COLOR_STEPS * Math.pow(root_result, color));
  }

  private getScale(): number {
    const totalColors = this.colors.length;
    let generatedSteps = 0;
    for (let i = 0; i < totalColors - 1; i++) {
        generatedSteps += this.stepsBetweenColors(i, 1, totalColors);
    }
    return this.steps / generatedSteps;
  }

  private expandColors(force = false) {
    if (!force && this.stateService.data['heatmapColors']) {
      this.expandedColors = this.stateService.data['heatmapColors'];
    } else {
      const totalColors = this.colors.length;
      const scale = this.getScale();

      for (let i = 0; i < totalColors - 1; i++) {
          const stepsBetweenColors = this.stepsBetweenColors(i, scale, totalColors);
          const color = this.colors[i];
          const nextColor = this.colors[i + 1];

          for (let step = 0; step < stepsBetweenColors; step++) {
            const foreground = nextColor.foreground || '#000000';
            const background = this.interpolateColor(color.background, nextColor.background, step / stepsBetweenColors);
            this.expandedColors.push({ background, foreground });
          }
      }

      this.stateService.data['heatmapColors'] = this.expandedColors;
    }
  }
}
