import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { AbstractControl, ControlContainer, FormControl, FormGroup, FormGroupDirective } from '@angular/forms';

interface RequestedChange {
  value: string;
  requested_at: Date;
  requested_by_name: string;
}

export interface ChangeableField<TValue> {
  value: TValue;
  requested_change: RequestedChange;
}

interface ChangeRequestDescriptor {
  name: string;
  object_id: number;
  old_value: string;
  new_value: string;
}

interface DisplayedFields<TValue> {
  [p: string]: ChangeableField<TValue>;
}

export class ChangeRequestFormControl extends FormControl {
  show = false;
  // Property used from the form group for fetching the object id from the form data
  objectIdKey: string;

  private key: string;
  private objectId: number;
  private oldValue: string;

  constructor(objectIdKey: string, validators?: any) {
    super('', validators);
    this.objectIdKey = objectIdKey;
  }

  toggle() {
    this.show = !this.show;
  }

  private getInputValue(): string {
    const value = super.getRawValue().toString().trim();
    // Masked input fields return the mask value when empty, which causes them to get submitted as a change request
    if (value.match(/^[ -]+$/)) {
      return '';
    }
    return value;
  }

  hasChanged() {
    const newValue = this.getInputValue();
    return this.touched && this.dirty && newValue && newValue !== this.oldValue;
  }

  getRawValue(): ChangeRequestDescriptor {
    return {
      name: this.key,
      object_id: this.objectId,
      old_value: this.oldValue,
      new_value: this.getInputValue()
    };
  }

  setupControl(name: string, objectId: number, field: ChangeableField<any>) {
    this.key = name;
    this.objectId = objectId;
    this.oldValue = field.requested_change?.value || field.value;
  }
}

export class ChangeRequestFormGroup extends FormGroup {
  declare controls: { [key: string]: ChangeRequestFormControl };

  patchValue(formData: { [key: string]: any }, options?: { onlySelf?: boolean; emitEvent?: boolean }) {
    if (formData == null) { return; }

    (Object.keys(formData) as Array<keyof any>).forEach((name: string) => {
      const control = this.controls[name] as ChangeRequestFormControl;
      if (control) {
        const objectId = formData[control.objectIdKey];
        if (!objectId) {
          throw new Error(`Missing object id for control ${ name }`);
        }

        control.setupControl(name, objectId, formData[name]);
      }
    });
    this.updateValueAndValidity(options);
  }

  getRawValue(): ChangeRequestDescriptor[] {
    return Object.values(this.controls).reduce((result, control) => {
      if (control.hasChanged()) {
        result.push(control.getRawValue());
      }
      return result;
    }, []);
  }
}

@Component({
  selector: 'changeable-field',
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }],
  styleUrls: ['../agreement-form.component.scss'],
  template: `
    <div class="row">
      <div class="label">{{ label }}</div>
      <div class="control">
        <button [class]="'button' + (showForm ? ' fg-danger' : ' fg-ok')"
                [disabled]="!isEnabled"
                type="button"
                (click)="onControlClick()">
          <i [class]="'fas' + (showForm ? ' fa-times' : ' fa-pen-to-square')"></i>
        </button>
      </div>
      <div class="value">
        <div [class.previous-value]="latestRequestedChange || showForm">
          <ng-content select="[dataValue]"></ng-content>

          <div *ngFor="let dataField of _displayedFields | keyvalue: originalOrder">
            {{ dataField.value?.value }}
          </div>
        </div>

        <div *ngIf="latestRequestedChange"
             class="requested-change"
             [class.previous-value]="showForm">
          <div *ngFor="let dataField of _displayedFields | keyvalue: originalOrder; let length = count">
            {{ dataField.value?.value && !dataField.value?.requested_change?.value
            ? (length > 1 ? '' : '*deleted*')
            : (dataField.value?.requested_change?.value || dataField.value?.value || '') }}
          </div>
          <div *ngIf="latestRequestedChange.requested_by_name" class="metadata" >
            {{ latestRequestedChange.requested_by_name }},
            {{ latestRequestedChange.requested_at | date: 'dd/MM/yy, h:mm a' }}
          </div>
        </div>

        <div *ngIf="showForm" #inputs class="inputs">
          <ng-content></ng-content>
        </div>
      </div>
    </div>
  `,
  host: { class: 'changeable-field' }
})
export class ChangeableFieldComponent<TValue> {
  @ViewChild('inputs', { static: false }) inputs: ElementRef;

  protected isEnabled = true;
  @Input()
  set enableControl(isEnabled: boolean) {
    this.isEnabled = isEnabled;
    if (!isEnabled) {
      this.closeControl();
    }
  }
  @Input() label: string;
  @Input()
  set data(value: {}) {
    if (!value || !Object.keys(value).length) {
      return;
    }
    this._displayedFields = this.getCleanDisplayedFields<TValue>(value);
    this.latestRequestedChange = this.getLatestRequestedChange();
  }
  _displayedFields: { [p: string]: ChangeableField<TValue> } = {};

  @Input()
  set dataKeys(value: string | string[]) {
    this._dataKeys = Array.isArray(value) ? value : [value];
  }
  private _dataKeys: string[];

  @Input()
  set controls(value: AbstractControl | AbstractControl[]) {
    this._controls = Array.isArray(value) ? value : [value];
  }
  private _controls: AbstractControl[];

  latestRequestedChange: RequestedChange = null;
  showForm = false;

  // Used by the keyvalue pipe in the template to preserve the order of the fields
  originalOrder = (a, b): number => 0;

  constructor() {}

  onControlClick() {
    if (!this.isEnabled) {
      return;
    }

    this.showForm = !this.showForm;
    if (!this.showForm) {
      this.closeControl();
    } else {
      setTimeout(this.initForm.bind(this));
    }
  }

  closeControl() {
    this.showForm = false;
    this._controls.forEach(item => {
      item.reset();
    });
  }

  private initForm() {
    // Set focus to the first input
    const firstInput = this.inputs?.nativeElement.querySelector('.k-input-inner');
    if (firstInput.nodeName !== 'INPUT') {
      return;
    }

    firstInput.setSelectionRange(0, 0); // Moves the cursor to beginning of a text input.
    firstInput.focus();

    // Set a placeholder on each input
    const inputs = this.inputs?.nativeElement.querySelectorAll('.k-input');
    (inputs || []).forEach(input => {
      const formControlName = input.getAttribute('formcontrolname');
      const inputElement = input.querySelector('.k-input-inner');
      inputElement.placeholder = this.getPlaceholder(formControlName);
    });
  }

  private getPlaceholder(formControlName: string): string {
    const dataField = this._displayedFields[formControlName];
    return (dataField?.requested_change.value || dataField?.value || '') as string;
  }

  private getLatestRequestedChange(): RequestedChange {
    return Object.values(this._displayedFields)
      .filter(field => !!field.requested_change?.requested_at)
      .sort((a, b) => {
        return (new Date(a.requested_change.requested_at) as any) - (new Date(b.requested_change.requested_at) as any);
    })[0]?.requested_change;
  }

  private getCleanDisplayedFields<T>(value: {}): DisplayedFields<T> {
    return this._dataKeys.reduce((obj, key) => {
      return Object.assign(obj, {
        [key]: value[key] as ChangeableField<T>
      });
    }, {});
  }
}
