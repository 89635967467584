import { Component, OnInit } from '@angular/core';
import { AgreementsService } from 'app/agreements/agreements.service';
import { finalize } from 'rxjs';
import { StateService } from 'app/shared/services/state.service';
import { AgreementListItem } from 'app/agreements/agreements.service';
import { SettingsService } from 'app/shared/services/settings.service';
import { NotificationService } from 'app/notification/notification.service';
import { TutorialService } from 'app/shared/services/tutorial.service';

@Component({
  selector: 'agreements-list',
  templateUrl: './agreements-list.component.html',
  styleUrls: ['./agreements-list.component.scss']
})
export class AgreementsListComponent implements OnInit {
  outstandingAgreements: AgreementListItem[] = [];
  completedAgreements: AgreementListItem[] = [];
  dateFormat = SettingsService.PUBLIC_DATE_TIME_FORMAT;

  constructor(
    private notificationService: NotificationService,
    private agreementsService: AgreementsService,
    private stateService: StateService,
    private tutorialService: TutorialService
  ) { }

  ngOnInit() {
    this.getAgreements();
    this.tutorialService.hideTutorialFooterButton();
  }

  private getAgreements() {
    this.stateService.loading = true;
    this.agreementsService.readAll()
      .pipe(finalize(() => this.stateService.loading = false))
      .subscribe((data) => {
        this.outstandingAgreements = data.filter(agreement => !agreement.signed_at);
        this.completedAgreements = data.filter(agreement => agreement.signed_at);
      });
  }
}
