<app-widget [updatedAt]="updatedAt"
            [isLoading]="isLoading"
            [hasNoData]="!tabs?.length"
            loadingMessage="Loading sample information..."
            noDataMessage="No sample information available."
            [dropdownItems]="availableSeasons"
            [selectedDropdownItem]="selectedSeason"
            [onDropdownItemChange]="selectSeason.bind(this)"
            dropdownHandle="sampleInformationWidgetSeasonDropdown"
            [secondDropdownItems]="availableGrowerNumbers"
            [selectedSecondDropdownItem]="selectedGrowerNumber"
            [onSecondDropdownItemChange]="selectGrowerNumber.bind(this)"
            secondDropdownItemLabel="KPIN"
            secondDropdownHandle="sampleInformationWidgetOrchardDropdown"
            [thirdDropdownItems]="availableMaturityAreas"
            [selectedThirdDropdownItem]="selectedMaturityArea"
            [onThirdDropdownItemChange]="selectMaturityArea.bind(this)"
            thirdDropdownItemLabel="MA"
            thirdDropdownHandle="sampleInformationWidgetMaDropdown"
            [headerButtons]="headerButtons">
  <kendo-tabstrip *ngIf="tabs?.length" class="tabstrip"
                  (tabSelect)="onTabSelect($event)">
    <kendo-tabstrip-tab *ngFor="let tab of tabs"
                        [title]="tab.title"
                        [selected]="tab.selected">
        <ng-template kendoTabContent>
          <app-simple-grid [columns]="gridColumns"
                           [data]="gridDataFiltered"
                           [selectedRowIndexes]="[0]"
                           rowSelectType="single"
                           [rowSelectCallback]="onGridRowSelect.bind(this)"
                           [hasColumnSelect]="true">
          </app-simple-grid>
        </ng-template>
    </kendo-tabstrip-tab>
  </kendo-tabstrip>

  <div *ngIf="selectedRow" class="details" [class.visible]="areDetailsVisible">
    <div class="status-panel">
      <div class="value"
        [class.cleared]="selectedRow.sample_status === 'Cleared'"
        [class.failed]="selectedRow.sample_status === 'Failed'">
        {{ selectedRow.sample_status || emptyPlaceholder }}
      </div>
      <div class="comments">{{ selectedRow.comments }}</div>
    </div>
    <div class="additional-data-panel">
      <div class="column dark">
        <table>
          <tr>
            <th class="label">Sample Number</th>
            <td class="value" [class.empty]="!selectedRow.sample_number">{{ selectedRow.sample_number || emptyPlaceholder }}</td>
          </tr>
          <tr>
            <th class="label">Booked Date</th>
            <td class="value" [class.empty]="!selectedRow.booked_date">{{ selectedRow.booked_date || emptyPlaceholder }}</td>
          </tr>
          <tr>
            <th class="label">Weight Average</th>
            <td class="value" [class.empty]="!selectedRow.fresh_weight_average">{{ selectedRow.fresh_weight_average || emptyPlaceholder }}</td>
          </tr>
          <tr *ngIf="showBlackSeedData">
            <th class="label">% Black Seeds</th>
            <td class="value" [class.empty]="!selectedRow.percentage_black_seeds">{{ selectedRow.percentage_black_seeds || emptyPlaceholder }}</td>
          </tr>
          <tr>
            <th class="label-column label" colspan="2">Dry Matter</th>
          </tr>
          <tr>
            <th class="label">Average</th>
            <td class="value" [class.empty]="!selectedRow.dry_matter_average">{{ selectedRow.dry_matter_average || emptyPlaceholder }}</td>
          </tr>
          <tr>
            <th class="label">Threshold</th>
            <td class="value" [class.empty]="!selectedRow.dry_matter_threshold">{{ selectedRow.dry_matter_threshold || emptyPlaceholder }}</td>
          </tr>
        </table>
      </div>
      <div class="column">
        <table>
          <tr>
            <th class="label-column label" colspan="2">Brix</th>
          </tr>
          <tr>
            <th class="label">Lower Fractile</th>
            <td class="value" [class.empty]="!selectedRow.brix_fractile_lower">{{ selectedRow.brix_fractile_lower || emptyPlaceholder }}</td>
          </tr>
          <tr>
            <th class="label">Average</th>
            <td class="value" [class.empty]="!selectedRow.brix_average">{{ selectedRow.brix_average || emptyPlaceholder }}</td>
          </tr>
          <tr>
            <th class="label">Upper Fractile</th>
            <td class="value" [class.empty]="!selectedRow.brix_fractile_upper">{{ selectedRow.brix_fractile_upper || emptyPlaceholder }}</td>
          </tr>
          <tr>
            <th class="label">Brix as % Brix When Fully Ripe</th>
            <td class="value" [class.empty]="selectedRow.brix_percent_off_fully_ripe == null">{{ selectedRow.brix_percent_off_fully_ripe | appendIfExists:'%' | default: emptyPlaceholder }}</td>
          </tr>
        </table>
      </div>
      <div class="column dark">
        <table>
          <tr *ngIf="showColourData">
            <th class="label-column label" colspan="2">Colour</th>
          </tr>
          <tr *ngIf="showColourData">
            <th class="label">Average</th>
            <td class="value" [class.empty]="!selectedRow.hue_average">{{ selectedRow.hue_average || emptyPlaceholder }}</td>
          </tr>
          <tr *ngIf="showColourData">
            <th class="label">Threshold</th>
            <td class="value" [class.empty]="!selectedRow.colour_threshold">{{ selectedRow.colour_threshold || emptyPlaceholder }}</td>
          </tr>
          <tr>
            <th class="label-column label" colspan="2">Firmness</th>
          </tr>
          <tr>
            <td class="label">Average</td>
            <td class="value" [class.empty]="!selectedRow.pressure_average">{{ selectedRow.pressure_average || emptyPlaceholder }}</td>
          </tr>
          <tr>
            <td class="label">Softs Fractile</td>
            <td class="value" [class.empty]="!selectedRow.softs_fractile">{{ selectedRow.softs_fractile || emptyPlaceholder }}</td>
          </tr>
          <tr>
            <td class="label">% < 4kgf</td>
            <td class="value" [class.empty]="selectedRow.firmness_precent_less_than_4kgf == null">{{ selectedRow.firmness_precent_less_than_4kgf | appendIfExists:'%' | default: emptyPlaceholder }}</td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</app-widget>
