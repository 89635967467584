import { Component, HostListener, OnDestroy } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { GaService } from './shared/services/ga.service';
import { TitleService } from './shared/services/title.service';
import { EnvIndicatorService } from './shared/services/env-indicator.service';
import { Subscription } from 'rxjs';

export const DEFAULT_TITLE = 'Grower Portal';

// Overwrite window to permit setting the debug var on it to any object
// You can use it from anywhere in the app.
declare global {
  interface Window { debug: any; }
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnDestroy {
  private currentLocationPathName: string;

  constructor(
    private router: Router,
    private ga: GaService,
    private titleService: TitleService,
    private envIndicatorService: EnvIndicatorService
  ) {
    this.titleService.setDefaultTitle(DEFAULT_TITLE);
    this.titleService.setDocumentTitle();
    this.ga.init();
    this.ga.updateUseAnalytics();
    this.envIndicatorService.setIndicator();

    this.routeEventSubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        const title = this.titleService.getTitleFromActivatedRoute();
        this.titleService.setDocumentTitle(title);
        if (this.currentLocationPathName !== window.location.pathname) {
          this.ga.pageView(event.urlAfterRedirects);
          this.currentLocationPathName = window.location.pathname;
        }
      }
    });
  }
  private readonly routeEventSubscription: Subscription;
  @HostListener('window:unload')
  @HostListener('window:beforeunload')
  @HostListener('window:pagehide')
  gaExit() {
    this.ga.exit();
  }

  ngOnDestroy() {
    this.routeEventSubscription?.unsubscribe();
  }
}
