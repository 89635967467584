import { NgModule } from '@angular/core';
import { AppendIfExistsPipe } from 'app/shared/pipes/append-if-exists.pipe';

@NgModule({
  declarations: [
    AppendIfExistsPipe
  ],
  exports: [
    AppendIfExistsPipe
  ],
})
export class AppendIfExistsModule {}
