import { Directive, OnInit, DoCheck, OnDestroy, Input, ElementRef } from '@angular/core';
import { DatePickerComponent } from '@progress/kendo-angular-dateinputs';

@Directive({
  selector: '[clearDatePickerButton]'
})
export class ClearDatePickerDirective implements OnInit, DoCheck, OnDestroy {
  @Input() id: string;
  private button: HTMLElement;
  private clickHandler = () => {
    this.component.valueChange.emit(null);
  }

  constructor(private component: DatePickerComponent, private elRef: ElementRef) {}

  ngOnInit() {
    this.button = document.createElement('span');
    this.button.addEventListener('click', this.clickHandler);
    this.button.className = 'clear-input k-icon k-i-close';
    this.elRef.nativeElement.appendChild(this.button);
  }

  ngDoCheck() {
    if (this.component.value === null) {
      this.button.classList.add('hidden');
    } else {
      this.button.classList.remove('hidden');
    }
  }

  ngOnDestroy() {
    this.button.removeEventListener('click', this.clickHandler);
  }
}
