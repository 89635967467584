import { Component, HostBinding, OnInit } from '@angular/core';
import { SeasonDropdownItem } from 'app/grower-portal-dashboard/grower-portal-dashboard.component';
import { HttpService } from 'app/shared/services/http.service';
import { finalize, forkJoin, Observable, take, tap } from 'rxjs';
import { SimpleBarChartItem } from 'app/shared/simple-bar-chart/simple-bar-chart.component';
import { isMobile } from 'app/shared/services/utils.service';

const SEASONS_ENDPOINT = 'harvest/audits/reports/contractor_ranking_seasons';
const GET_ENDPOINT = 'harvest/audits/reports/contractor_ranking';
const SUMMARY_ENDPOINT = 'harvest/audits/reports/contractor_ranking/aggregates';

export interface Season {
  season: number;
}

export interface ContractorRankingItem {
  contractor_id: number;
  contractor_name: string;
  is_self_pick: boolean;
  rank: number;
  season: number;
  total_fail_percentage: number;
  number_of_audits: number;
  percentage_rain_pick: number;
  percentage_drop_fruit: number;
  percentage_fruit_unpicked: number;
  percentage_fruit_off_ground: number;
  percentage_driving_fast: number;
  percentage_rough_handling: number;
  percentage_bags_banging: number;
  percentage_overfilled_bins: number;
  percentage_fruit_hit_by_machinery: number;
  percentage_supervisor_not_at_bin_trailer: number;
  percentage_short_stalks: number;
  percentage_long_stalks: number;
  percentage_fresh_cuts: number;
  percentage_old_cuts: number;
  percentage_fresh_punctures: number;
  percentage_old_punctures: number;
  percentage_explosives: number;
}

export interface SummaryData {
  percentage_bags_banging: number;
  percentage_driving_fast: number;
  percentage_drop_fruit: number;
  percentage_explosives: number;
  percentage_fresh_cuts: number;
  percentage_fresh_punctures: number;
  percentage_fruit_hit_by_machinery: number;
  percentage_fruit_off_ground: number;
  percentage_fruit_unpicked: number;
  percentage_long_stalks: number;
  percentage_old_cuts: number;
  percentage_old_punctures: number;
  percentage_overfilled_bins: number;
  percentage_rain_pick: number;
  percentage_rough_handling: number;
  percentage_short_stalks: number;
  percentage_supervisor_not_at_bin_trailer: number;
  number_of_audits: number;
  season: number;
}
export const DATA_LENGTH_SCALING_THRESHOLD = 25;

@Component({
  selector: 'app-picking-contractor-ranking',
  templateUrl: './picking-contractor-ranking.component.html',
  styleUrls: ['./picking-contractor-ranking.component.scss']
})
export class PickingContractorRankingComponent implements OnInit {
  @HostBinding('class.sidebar-closed') isSidebarClosed = isMobile();
  availableSeasons: SeasonDropdownItem[] = [];
  selectedSeason: SeasonDropdownItem;
  criteriaFields = [
    { title: 'Rain Pick', field: 'percentage_rain_pick', type: 'control' },
    { title: 'Drop Fruit', field: 'percentage_drop_fruit' },
    { title: 'Fruit Unpicked', field: 'percentage_fruit_unpicked' },
    { title: 'Fruit Off Ground', field: 'percentage_fruit_off_ground' },
    { title: 'Driving Fast', field: 'percentage_driving_fast' },
    { title: 'Rough Handling', field: 'percentage_rough_handling', type: 'control' },
    { title: 'Bags Banging', field: 'percentage_bags_banging' },
    { title: 'Overfilled Bins', field: 'percentage_overfilled_bins' },
    { title: 'Fruit Hit By Machinery', field: 'percentage_fruit_hit_by_machinery' },
    { title: 'Supervisor Not At Bin Trailer', field: 'percentage_supervisor_not_at_bin_trailer' },
    { title: 'Short Stalks', field: 'percentage_short_stalks', type: 'control' },
    { title: 'Long Stalks', field: 'percentage_long_stalks' },
    { title: 'Fresh Cuts', field: 'percentage_fresh_cuts' },
    { title: 'Old Cuts', field: 'percentage_old_cuts' },
    { title: 'Fresh Punctures', field: 'percentage_fresh_punctures' },
    { title: 'Old Punctures', field: 'percentage_old_punctures' },
    { title: 'Explosives', field: 'percentage_explosives' }
  ];
  loadingMessage: string;
  worstRank = 0;
  showSelfPicks = true;
  summaryDataColumns = { label: 'Criteria', valueLabel: '% Failed' };
  summaryData: SimpleBarChartItem[] = null;
  numberOfAudits = 0;
  data: ContractorRankingItem[] = [];
  dataLengthScalingFactor = 1;

  constructor(
    private http: HttpService
  ) {}

  ngOnInit(): void {
    this.loadSeasons().subscribe({
      next: this.loadData.bind(this),
      error: this.hideLoading.bind(this)
    });
  }

  toggleSidebar() {
    this.isSidebarClosed = !this.isSidebarClosed;
  }

  selectSeason(season: SeasonDropdownItem): void {
    if (season.value !== this.selectedSeason.value) {
      this.selectedSeason = season;
      this.loadData();
    }
  }

  toggleSelfPicks(): void {
    this.showSelfPicks = !this.showSelfPicks;
    this.loadData();
  }

  private loadSeasons(): Observable<Object> {
    this.showLoading('Loading Seasons...');
    return this.http.get(SEASONS_ENDPOINT)
      .pipe(
        take(1),
        tap((seasons: Season[]) => {
          this.availableSeasons = seasons.map(season => ({ label: season.season, value: season.season }));
          this.selectedSeason = this.availableSeasons[0];
        })
      );
  }

  private loadData(): void {
    this.showLoading('Loading Contractor Ranking...');
    forkJoin({
      data: this.http.get(GET_ENDPOINT, { params: { season: this.selectedSeason.value, hide_self_picks: !this.showSelfPicks }}),
      summaryData: this.http.get(SUMMARY_ENDPOINT, { params: { season: this.selectedSeason.value, hide_self_picks: !this.showSelfPicks }})
    }).pipe(
      take(1),
      finalize(this.hideLoading.bind(this))
    )
    .subscribe((result: { data: ContractorRankingItem[], summaryData: SummaryData }) => {
      this.data = result.data;
      this.summaryData = this.formatSummaryData(result.summaryData);
      this.setDataLengthScalingFactor();
      this.setWorstRank();
    });
  }

  private formatSummaryData(data: SummaryData): SimpleBarChartItem[] {
    this.numberOfAudits = data.number_of_audits || 0;
    return Object.keys(data).reduce((result, key) => {
      if (!['season', 'number_of_audits'].includes(key)) {
        result.push({
          label: this.criteriaFields.find(field => field.field === key)?.title || key,
          value: 100 * data[key],
          suffix: '%'
        });
      }
      return result;
    }, []);
  }

  private showLoading(message: string) {
    this.loadingMessage = message;
  }

  private hideLoading() {
    this.loadingMessage = null;
  }

  private setWorstRank() {
    this.worstRank = this.data.reduce((worst, item) => Math.max(worst, item.rank), 0);
  }

  // If there are fewer than DATA_LENGTH_SCALING_THRESHOLD items,
  // their heatmap color will be proportionally scaled so the items don't appear as bad.
  private setDataLengthScalingFactor() {
    this.dataLengthScalingFactor = Math.min(1, this.data.length / DATA_LENGTH_SCALING_THRESHOLD);
  }
}
