import { HostBinding, Directive } from '@angular/core';
import { GaService } from 'app/shared/services/ga.service';

export interface Tab {
  title: string;
  varietyId: number;
  growMethodId: number;
}

export interface DataCategoryTab {
  title: string;
  index?: number;
  cssClass?: string;
  id?: string;
  hidden?: boolean;
}

export enum Level {
  Orchard,
  Ma,
  PackDate
}

@Directive()
export abstract class Details {
  emptyPlaceholder = 'N/A';
  tabs: Tab[];
  selectedTab: Tab;
  selectedDataCategoryTab: DataCategoryTab;
  @HostBinding('class.sidebar-closed') isSidebarClosed = false;

  constructor(
    protected ga: GaService
  ) {}

  abstract onTabSelect(event);
  protected abstract selectGridRow(row);
  protected abstract showColumns(category: string);
  abstract selectSeason(season: any);
  protected abstract findSelectedRow();
  protected setColumnWidthClass(tab: DataCategoryTab) {}

  toggleSidebar() {
    this.isSidebarClosed = !this.isSidebarClosed;
    this.ga.event('button', 'click', 'details sidebar toggle', this.isSidebarClosed);
  }

  onGridRowSelect(row) {
    this.ga.event('grid', 'change', 'details row select');
    this.selectGridRow(row);
  }

  onDataCategoryTabSelect(tab: DataCategoryTab) {
    this.ga.event('tab', 'change', 'details data category tab', tab.title);
    this.selectedDataCategoryTab = tab;
    this.showColumns(tab.title);
    this.setColumnWidthClass(tab);
  }

  protected sortTabs() {
    this.tabs.sort((a, b) => {
      return a.title.localeCompare(b.title);
    });
  }

  protected initSelectedTab() {
    // Only initialize the selected tab if it's not already set or the
    // previously selected tab is not found in the selected season's tabs
    if (!(this.selectedTab && this.selectedTabExists())) {
      this.selectedTab = this.getSelectedTab();
    }
  }

  protected selectedTabExists(): boolean {
    for (const tab of this.tabs) {
      if (tab.varietyId === this.selectedTab.varietyId && tab.growMethodId === this.selectedTab.growMethodId) {
        return true;
      }
    }
    return false;
  }

  protected getSelectedTab(): Tab {
    const selectedRow = this.findSelectedRow();
    if (selectedRow) {
      for (const tab of this.tabs) {
        if (tab.varietyId === selectedRow.variety_id && tab.growMethodId === selectedRow.grow_method_id) {
          return tab;
        }
      }
    }
    return this.tabs[0];
  }

  protected filterData(data: any[], varietyId: number, growMethodId: number): any[] {
    return data.filter((item) => {
      return item.variety_id === varietyId && item.grow_method_id === growMethodId;
    });
  }

  protected getGroupedLabel(groupedLabels) {
    return groupedLabels.reduce((result, groupLabel, index) => {
      return result + `<div class="group-label level-${ index }">${ groupLabel }</div>`;
    }, '');
  }
}
