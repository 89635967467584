import { TutorialStep, AttachDirection } from 'app/shared/services/tutorial.service';

class OrchardCalendarStep extends TutorialStep {
  constructor(step) {
    super(step);
    this.id = 'calendar';
    this.title = 'Orchard Calendar';
    this.arrow = true;
  }
}

export function getCalendarSteps(): OrchardCalendarStep[] {
  return  [
    new OrchardCalendarStep ({
      beforeShowPromise: () => {
        return new Promise<void>((resolve) => {
          setTimeout(() => {
            window.scrollTo(0, 0);
            resolve();
          }, 500);
        });
      },
      attachTo: {
        element: '.orchard-calendar-row',
        on: AttachDirection.bottom
      },
      scrollTo: true,
      text: `The orchard calendar provides a convenient view of tasks to perform throughout the year. To make this clear, tasks are grouped into categories which can be expanded by clicking on the headings.`
    }),
    new OrchardCalendarStep ({
      attachTo: {
        element: '.orchard-calendar-content > div.previous',
        on: AttachDirection.right
      },
      text: `Use the arrows to the left and right of the calendar to navigate the timeline. Alternatively, you can also click and drag the timeline across.`
    }),
    new OrchardCalendarStep ({
      attachTo: {
        element: '.variety-and-grow-method-dropdown',
        on: AttachDirection.bottom
      },
      text: `If you are associated with multiple Grow Methods/Varieties, use the selector to switch between them.`
    }),
    new OrchardCalendarStep ({
      attachTo: {
        element: '.today-button',
        on: AttachDirection.bottom
      },
      text: `Use the "Today" button to move reset the calendar timeline back to today's date.`
    }),
    new OrchardCalendarStep ({
      attachTo: {
        element: '.toggle-calendar-button',
        on: AttachDirection.bottom
      },
      text: `Use the "Hide Calendar" button to toggle the calendar widget betwen shown and hidden.`
    })
  ];
}
