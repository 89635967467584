import { Injectable } from '@angular/core';
import { CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from './services/auth.service';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private authService: AuthService
  ) {}

  canActivate(_, state: RouterStateSnapshot) {
    if (this.authService.isTokenExpired()) {
      const queryParams = {};
      if (!this.isNextRoot(state)) {
        queryParams['next'] = state.url;
      }
      this.authService.clear();
      this.router.navigate(['/login'], { queryParams: queryParams });
      return false;
    } else {
      return true;
    }
  }

  private isNextRoot(state: RouterStateSnapshot): boolean {
    const url = (state.url || '').split('?')[0];
    return ['/dashboard', '/login'].includes(url);
  }
}
