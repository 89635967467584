import { Component, HostBinding, Input } from '@angular/core';

export interface SimpleTab {
  title: string;
  cssClass?: string;
  id?: string;
}

@Component({
  selector: 'app-simple-tabs',
  templateUrl: './simple-tabs.component.html',
  styleUrls: ['./simple-tabs.component.scss']
})
export class SimpleTabsComponent {
  @Input() selected: SimpleTab = null;
  @Input() tabs: SimpleTab[];
  @Input() onSelect = null;

  _cssClass = 'tabstrip';
  @Input()
  @HostBinding('class')
  set cssClass(value: string) {
    this._cssClass = value + ' tabstrip';
  }
  get cssClass() {
    return this._cssClass;
  }

  constructor() { }

  selectTab(tab) {
    this.selected = tab;
    if (typeof(this.onSelect) === 'function') {
      this.onSelect(this.selected);
    }
  }
}
