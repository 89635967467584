<div class="breadcrumbs-panel">
  <app-breadcrumbs [confirmAction]="confirmBreadcrumbAction.bind(this)"></app-breadcrumbs>
</div>

<section class="content-panel">
  <header>
    <h2>{{ agreement.name }} - {{ agreement.entity_name }}</h2>
  </header>
  <div *ngIf="showAgreement || agreement.signed_at">
    <agreement-viewer
      [agreement]="agreement"
      (documentLoaded)="onDocumentLoaded()">
    </agreement-viewer>
    <button *ngIf="!agreement.signed_at"
      class="button sign-button ok round large icon-div"
      (click)="goToForm()"
      [class.visible]="signButtonEnabled">
      <i [class]="'fas' + (agreement.is_user_signer ? ' fa-edit' : ' fa-eye')"></i>
      {{ agreement.is_user_signer ? 'Sign' : 'View' }}
    </button>
  </div>
  <div *ngIf="!showAgreement">
    <ng-template containerRef></ng-template>
  </div>
</section>
