import { Component, EventEmitter, Inject, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { NgxExtendedPdfViewerComponent, pdfDefaultOptions } from 'ngx-extended-pdf-viewer';
import { AgreementListItem, AgreementsService } from 'app/agreements/agreements.service';
import { finalize } from 'rxjs';
import { StateService } from 'app/shared/services/state.service';
import { Browser } from 'app/shared/services/browser.service';
import { DialogRef, DialogResult } from '@progress/kendo-angular-dialog';
import { AuthService } from 'app/shared/services/auth.service';
import { AgreementPageComponent } from 'app/agreements/agreement-page/agreement-page.component';
import { TutorialService } from 'app/shared/services/tutorial.service';

export const LOADING_AGREEMENT_MESSAGE = 'Loading agreement';

@Component({
  selector: 'agreement-viewer',
  templateUrl: './agreement-viewer.component.html',
  styleUrls: ['./agreement-viewer.component.scss']
})
export class AgreementViewerComponent implements OnInit {
  agreementErrorMessage: string;
  isAgreementAlreadySigned = false;
  @ViewChild('pdfViewer', { static: false }) pdfViewer: NgxExtendedPdfViewerComponent;
  @ViewChild('agreementErrorContent', { static: true }) agreementErrorContent: TemplateRef<any>;
  @Output() documentLoaded = new EventEmitter();

  private _agreement: AgreementListItem;
  @Input()
  set agreement(value: AgreementListItem) {
    this._agreement = value;
    this.isAgreementAlreadySigned = !!this._agreement.signed_at;
    if (value?.uuid) {
      this.loadPdf();
    }
  }

  isLoadingError = false;
  _lastViewedPage: number;
  _totalPages: number;
  constructor(
    private agreementsService: AgreementsService,
    private authService: AuthService,
    public stateService: StateService,
    private tutorialService: TutorialService,
    @Inject(AgreementPageComponent) private parent: AgreementPageComponent
  ) {
    pdfDefaultOptions.assetsFolder = 'grower-portal/assets';
  }

  ngOnInit(): void {
    this.tutorialService.hideTutorialFooterButton();
  }

  onLoadError(error: Error) {
    const browser = new Browser();
    const user = this.authService.getUser();
    if (browser.isOutdated) {
      this.agreementErrorMessage = `
        <p>Sorry ${ user.firstname }, your browser <em class="capitalize">(${ browser.name } ${ browser.version })</em> is too outdated to view your agreement.</p>
        <p>Consider updating or installing an alternate browser and try again.</p>
        <div class="h-divider"><span class="text">OR</span></div>
      `;
    } else {
      this.agreementErrorMessage = `
        <p>Sorry ${ user.firstname }, your agreement failed to load.</p>
        <p>Please contact your grower representative for help, or...</p>
      `;
    }
    this.agreementsService.showErrorDialog(this.agreementErrorContent, this.errorDialogCallback.bind(this), this.isAgreementAlreadySigned);
    throw(error);
  }

  private errorDialogCallback(result: DialogResult, dialog: DialogRef) {
    if (result['text'] === 'Download Agreement') {
      dialog.dialog.instance.cssClass += ' downloaded';
      window.open(this.agreementsService.getAgreementDocumentUrl(this._agreement.uuid, !this.isAgreementAlreadySigned), '_blank').focus();
    } else if (result['text'] === 'Sign Agreement') {
      this.parent.goToForm();
    } else if (result['text'] === 'Cancel') {
      this.parent.returnToLists();
    }
  }

  onPageChange(page: number) {
    this._lastViewedPage = page;
  }

  onPagesLoaded({ pagesCount }) {
    this._lastViewedPage = 1;
    this._totalPages = pagesCount;
  }

  private loadPdf(): void {
    this.stateService.setLoading(true, LOADING_AGREEMENT_MESSAGE);
    this.agreementsService.fetchDocument(this._agreement.uuid)
      .pipe(finalize(() => this.stateService.setLoading(false)))
      .subscribe({
        next: (data) => {
          this.isLoadingError = false;
          this.documentLoaded.emit();
          this.pdfViewer.src = data;
        },
        error: () => {
          this.isLoadingError = true;
        }
      });
  }
}
