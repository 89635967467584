import { Tab } from 'app/interfaces/tab.interface';
import { uniqueObjectArrayByKey } from 'app/shared/services/utils.service';

export abstract class Tabbed {
  tabs: Tab[];
  protected selectedTab: Tab;

  protected abstract initSelectedTab();
  protected abstract tabReducer(result: Tab[], object: any): Tab[];

  protected selectFirstTab() {
    if (this.tabs) {
      this.selectTab(this.tabs[0]);
    }
  }

  protected selectTab(tab: Tab) {
    this.selectedTab = tab;
    this.selectedTab.selected = true;
  }

  protected setTabs(data: any[], reducer: (result: Tab[], object: any) => Tab[] ) {
    this.tabs = uniqueObjectArrayByKey(
      data.reduce(reducer, []),
      'title'
    );
  }

  protected sortTabs() {
    if (this.tabs) {
      this.tabs.sort((a, b) => {
        return a.title.localeCompare(b.title);
      });
    }
  }
}
