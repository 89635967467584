<header *ngIf="columns.label || columns.valueLabel" class="header">
  <div class="column">{{ columns.label }}</div>
  <div class="column">{{ columns.valueLabel }}</div>
</header>
<div class="rows">
  <ng-container *ngFor="let row of chartData">
    <div [class]="'row ' + row.cssClass || ''"
         [class.is-error]="row.errorMessage">
      <i class="error-icon fa fa-exclamation-triangle"></i>
      <div class="text">
        <div class="label">{{ row.label }}</div>
        <div class="error-message">
          {{ row.errorMessage }}
        </div>
      </div>
      <div class="bar" [style.width]="row.percent + '%'"></div>
      <div *ngIf="!valueOutsideBar" class="value">
        <ng-container *ngIf="row.value != null; then valueBlock; else placeholderBlock"></ng-container>
      </div>
    </div>

    <div *ngIf="valueOutsideBar" class="value">
      <ng-container *ngIf="row.value != null; then valueBlock; else placeholderBlock"></ng-container>
    </div>

    <ng-template #valueBlock>
      {{ row.prefix || '' }}{{ row.value | number : '1.' + (row.precision || precision) + '-' + (row.precision || precision ) }}{{ row.suffix || '' }}
    </ng-template>
    <ng-template #placeholderBlock>
      {{ placeholder }}
    </ng-template>
  </ng-container>
</div>
