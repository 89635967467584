import { Component, Input, HostBinding, OnInit } from '@angular/core';

@Component({
  selector: 'loading-indicator',
  templateUrl: './loading-indicator.component.html',
  styleUrls: ['./loading-indicator.component.scss']
})
export class LoadingIndicatorComponent implements OnInit {
  @Input() radius = 100;
  @Input() thickness = 5;
  @Input() targetSelector: string = null;
  @Input() message: string;
  @Input() externalMessage: string;
  @Input() links: { label: string, url: string }[];
  @Input() showPercent = true;
  @Input() @HostBinding('class.is-grower-portal') isGrowerPortal = false;
  @Input() @HostBinding('class.is-light') isLight = false;
  @Input() @HostBinding('class.is-absolute') isAbsolute = false;
  @Input() @HostBinding('class.is-modal') isModal = false;
  @Input() @HostBinding('class.no-fade') noFade = false;
  private _value = 0;
  private _total = 100;
  private _isSpinnyMode = true;
  size: number;
  adjustedRadius: number;
  circumference: number;
  formattedPercent: number;
  progressBarValue: number;

  constructor() {}

  ngOnInit() {
    this.size = 2 * this.radius;
    this.adjustedRadius = this.radius - this.thickness;
    this.circumference = 2 * Math.PI * this.adjustedRadius;
    this.configureSpinnyMode();
    this.update();
  }

  @Input()
  set value(value: number) {
    if (!this._isSpinnyMode && value != null) {
      this._value = value;
      this.update();
    }
  }

  @Input()
  set total(total: number) {
    if (!this._isSpinnyMode && total != null) {
      this._total = total;
      this.update();
    }
  }

  @Input()
  set isSpinnyMode(isSpinnyMode: boolean) {
    this._isSpinnyMode = isSpinnyMode;
    this.configureSpinnyMode();
    this.update();
  }

  get isSpinnyMode() {
    return this._isSpinnyMode;
  }

  update() {
    const percent = this._value / this._total;
    this.formattedPercent = this.formatPercent(percent);
    this.progressBarValue = this.circumference * (1 - percent);
  }

  private configureSpinnyMode() {
    if (this._isSpinnyMode) {
      this.showPercent = false;
      this.message = this.message != null ? this.message : 'Loading...';
      this._value = Math.round(this._total / 2);
    } else {
      this._value = 0;
    }
  }

  private formatPercent(percent: number) {
    let result = Math.ceil(100 * percent);

    if (result < 0) {
      result = 0;
    } else if (result > 100) {
      result = 100;
    }

    return result;
  }
}
