import { Component, ViewChild, ElementRef, HostBinding, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService, User } from 'app/shared/services/auth.service';
import { HttpService } from 'app/shared/services/http.service';
import { TitleService } from 'app/shared/services/title.service';
import { SettingsService } from 'app/shared/services/settings.service';
import { environment } from 'environments/environment';
import { randomBgCssClass, getQueryParams, isGrowerPortal } from 'app/shared/services/utils.service';
import { UserProductsService } from 'app/shared/services/user-products.service';
import { UserOverrideComponent } from 'app/shared/user-override/user-override.component';
import { Subscription } from 'rxjs';

export interface LoginItem {
  token?: string;
  user?: User;
  ephemeral_token?: string;
  method?: string;
  masked_email?: string;
}
export const LOGIN_ERROR_MESSAGE = 'Login error.';
export const ERROR_LOCK_TIME = 2000;
const ERROR_FLASH_TIME = 5000;
export const FOCUS_DELAY = 300;
const ANIMATION_DURATION = 350;
const NUM_BG_IMAGES = 7;

@Component({
  selector: 'login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss', './login-media-queries.component.scss']
})
export class LoginComponent implements OnDestroy {
  username = '';
  password = '';
  ephemeralToken = '';
  maskedEmail = '';
  mfaToken = '';
  passwordResetUsername = '';
  loginHelpName = environment.LOGIN_HELP_NAME;
  isUsernameError = false;
  isPasswordError = false;
  loginErrorMessage: string;
  messageHandle = null;
  isPasswordResetUsernameError = false;
  isBusy = false;
  isUsernameClearVisible = false;
  isPasswordClearVisible = false;
  isMFAClearVisible = false;
  isPasswordResetUsernameClearVisible = false;
  isResetPasswordFormVisible = false;
  isResetPasswordDone = false;
  isResetPasswordError = false;
  usernameErrorHandle = null;
  passwordErrorHandle = null;
  loginErrorHandle = null;
  passwordResetErrorHandle = null;
  loginSubTitle = 'Login';
  passwordResetSubTitle = 'Password Reset Request';
  loginParamName = environment.LOGIN_PARAM_NAME || 'username';
  isProductSelectorVisible = false;
  isGrowerPortal = false;
  showMFA = false;
  private loginSubscription: Subscription;
  private resetPasswordSubscription: Subscription;
  @HostBinding('class') class = randomBgCssClass(NUM_BG_IMAGES);
  @ViewChild('backToLoginPageButton', { static: false }) backToLoginPageButton: ElementRef;
  @ViewChild('backToResetPasswordPageButton', { static: false }) backToResetPasswordPageButton: ElementRef;
  @ViewChild('usernameInputField', { static: false }) usernameInputField: ElementRef;
  @ViewChild('mfaTokenInputField', { static: false }) mfaTokenInputField: ElementRef;
  @ViewChild('resetPasswordUsernameInputField', { static: false }) resetPasswordUsernameInputField: ElementRef;

  constructor(
    private router: Router,
    private authService: AuthService,
    private userProductsService: UserProductsService,
    private http: HttpService,
    private titleService: TitleService
  ) {
    this.isGrowerPortal = isGrowerPortal();
    this.redirectLoggedInUsersToDashboard();
  }

  ngOnDestroy() {
    this.loginSubscription?.unsubscribe();
    this.resetPasswordSubscription?.unsubscribe();
  }

  flashUsernameError() {
    this.clearMessages();
    this.isUsernameError = true;
    this.usernameErrorHandle = setTimeout(() => {
      this.isUsernameError = false;
    }, ERROR_FLASH_TIME);
  }

  flashPasswordError() {
    this.clearMessages();
    this.isPasswordError = true;
    this.passwordErrorHandle = setTimeout(() => {
      this.isPasswordError = false;
    }, ERROR_FLASH_TIME);
  }

  flashLoginError(message) {
    this.clearMessages();
    this.loginErrorMessage = message;
    this.loginErrorHandle = setTimeout(() => {
      this.loginErrorMessage = null;
    }, ERROR_FLASH_TIME);
  }

  private clearMessages() {
    if (this.messageHandle) {
      clearTimeout(this.messageHandle);
    }
    if (this.loginErrorHandle) {
      clearTimeout(this.loginErrorHandle);
    }
    this.loginErrorMessage = null;
  }

  validateUsername() {
    if (this.username.length > 0) {
      this.isUsernameError = false;
      return true;
    } else {
      this.flashUsernameError();
      return false;
    }
  }

  validatePassword() {
    if (this.password.length >= SettingsService.MIN_PASSWORD_LENGTH &&
      this.hasDigit(this.password) &&
      this.hasUpperCaseLetter(this.password) &&
      this.hasLowerCaseLetter(this.password)
    ) {
      this.isPasswordError = false;
      return true;
    } else {
      this.flashPasswordError();
      return false;
    }
  }

  private redirectLoggedInUsersToDashboard() {
    if (!this.authService.isTokenExpired()) {
      this.router.navigateByUrl('/');
    }
  }

  private hasDigit(str: string): boolean {
    return /\d/.test(str);
  }

  private hasUpperCaseLetter(str: string): boolean {
    return /[A-Z]/.test(str);
  }

  private hasLowerCaseLetter(str: string): boolean {
    return /[a-z]/.test(str);
  }

  private validateLoginForm(): boolean {
    const isError = this.validateUsername();
    return this.validatePassword() && isError;
  }

  private validateMFA(): boolean {
    const isValid = /^\d{6}$/.test(this.mfaToken);
    if (!isValid) {
      this.flashLoginError('Please enter a valid verification code.');
    }
    return isValid;
  }

  login() {
    if (this.validateLoginForm()) {
      this.lock();
      const data = {
        username: this.username,
        password: this.password,
        origin: window.location.origin
      };
      this.loginSubscription = this.http.post('auth/login/', data, { isUnauthenticated: true }, false).subscribe(
        (response: LoginItem) => {
          if (response.ephemeral_token) {
            this.performMFA(response);
          } else {
            this.loginSuccess(response);
          }
        },
        (error) => {
          this.unlock(ERROR_LOCK_TIME);
          this.loginError(error.error?.detail || LOGIN_ERROR_MESSAGE);
        }
      );
    }
  }

  resendMFA(): boolean {
    this.login();
    return false;
  }

  loginMFA() {
    if (this.validateMFA()) {
      this.lock();
      const data = {
        ephemeral_token: this.ephemeralToken,
        code: this.mfaToken,
        origin: window.location.origin
      };
      this.loginSubscription.unsubscribe();
      this.loginSubscription = this.http.post('auth/login/code/', data, { isUnauthenticated: true }, false).subscribe(
        (response: LoginItem) => {
          this.loginSuccess(response);
        },
        (error) => {
          this.unlock(ERROR_LOCK_TIME);
          this.loginError(error.error?.error || LOGIN_ERROR_MESSAGE);
        }
      );
    }
  }

  private performMFA(response: LoginItem) {
    this.showMFA = true;
    this.ephemeralToken = response.ephemeral_token;
    this.maskedEmail = response.masked_email;
    this.unlock(ANIMATION_DURATION);
    setTimeout(() => {
      this.mfaTokenInputField.nativeElement.focus();
    });
  }

  navigateNext() {
    const params = getQueryParams(window.location.search);
    if (params['next']) {
      // Slice to remove preceding /
      let next = decodeURIComponent(params['next']).slice(1);
      if (environment.LEGACY_BASE_URL) {
        next = environment.LEGACY_BASE_URL + next;
      }
      window.location.href = next;
    } else {
      this.router.navigateByUrl('/');
    }
  }

  keyUpUsername(event) {
    this.showHideUsernameClear();
    if (event.keyCode === 13) {
      this.login();
    }
  }

  keyUpPassword(event) {
    this.showHidePasswordClear();
    if (event.key === 'Enter') {
      this.login();
    }
  }

  keyUpMFA(event) {
    this.showHideMFAClear();

    if (event.key === 'Enter') {
      this.loginMFA();
    }
  }

  keyUpPasswordResetUsername(event) {
    this.showHidePasswordResetUsernameClear();
    if (event.key === 'Enter') {
      this.resetPassword();
    }
  }

  private showProductSelector() {
    this.isProductSelectorVisible = true;
  }

  private async loginSuccess(data: LoginItem) {
    this.loginErrorMessage = null;
    this.authService.setToken(data.token);
    this.authService.setUser(data.user);

    if (this.isGrowerPortal) {
      const userOverride = UserOverrideComponent.getOverride();
      this.userProductsService.clearState();
      await this.userProductsService.init(userOverride, true, false);
    }

    this.unlock();

    if (this.isGrowerPortal && this.userProductsService.isProductSelectionRequired()) {
      this.showProductSelector();
    } else {
      this.navigateNext();
    }
  }

  private loginError(message = 'Wrong username or password.') {
    this.flashLoginError(message);
  }

  private lock() {
    this.isBusy = true;
  }

  private unlock(timeout?: number) {
    if (timeout) {
      setTimeout(() => {
        this.isBusy = false;
      }, timeout);
    } else {
      this.isBusy = false;
    }
  }

  clearUsername() {
    this.isUsernameClearVisible = false;
    this.username = '';
  }

  clearPassword() {
    this.isPasswordClearVisible = false;
    this.password = '';
  }

  clearMFA() {
    this.isMFAClearVisible = false;
    this.mfaToken = '';
  }

  clearPasswordResetUsername() {
    this.isPasswordResetUsernameClearVisible = false;
    this.passwordResetUsername = '';
  }

  showHideUsernameClear() {
    this.isUsernameClearVisible = this.username.length > 0;
  }

  showHidePasswordClear() {
    this.isPasswordClearVisible = this.password.length > 0;
  }

  showHideMFAClear() {
    this.isMFAClearVisible = this.mfaToken.length > 0;
  }

  showHidePasswordResetUsernameClear() {
    this.isPasswordResetUsernameClearVisible = this.passwordResetUsername.length > 0;
  }

  showLoginForm() {
    this.titleService.setDocumentSubTitle(this.loginSubTitle);
    this.isResetPasswordDone = false;
    this.isResetPasswordError = false;
    this.isUsernameClearVisible = false;
    this.isPasswordClearVisible = false;
    this.isPasswordResetUsernameClearVisible = false;
    this.isResetPasswordFormVisible = false;
    this.showMFA = false;
    setTimeout(() => {
      if (this.usernameInputField && this.usernameInputField.nativeElement) {
        this.usernameInputField.nativeElement.focus();
      }
    }, FOCUS_DELAY);
    return false;
  }

  showResetPasswordForm() {
    this.titleService.setDocumentSubTitle(this.passwordResetSubTitle);
    this.passwordResetUsername = this.username;
    this.isResetPasswordDone = false;
    this.isResetPasswordError = false;
    this.isUsernameClearVisible = false;
    this.isPasswordClearVisible = false;
    this.isPasswordResetUsernameClearVisible = false;
    this.isResetPasswordFormVisible = true;
    this.showMFA = false;
    setTimeout(() => {
      if (this.resetPasswordUsernameInputField && this.resetPasswordUsernameInputField.nativeElement) {
        this.resetPasswordUsernameInputField.nativeElement.focus();
      }
    }, FOCUS_DELAY);
    return false;
  }

  resetPassword() {
    if (this.validateResetPasswordForm()) {
      this.lock();

      const data = {
        username: this.passwordResetUsername
      };
      this.resetPasswordSubscription = this.http.post('auth/send_password_reset_token', data, { isUnauthenticated: true }, false).subscribe(
        (response) => {
          this.unlock();
          this.resetPasswordDone();
        },
        (error) => {
          // This should only be hit if the server is having actual issues
          this.unlock();
          if (error.status === 500) {
            this.resetPasswordError();
          } else {
            this.resetPasswordDone();
          }
        }
      );
    }
  }

  private validateResetPasswordForm(): boolean {
    return this.validatePasswordResetUsername();
  }

  private validatePasswordResetUsername(): boolean {
    if (this.passwordResetUsername.length > 0) {
      this.isPasswordResetUsernameError = false;
      return true;
    } else {
      this.flashPasswordResetUsernameError();
      return false;
    }
  }

  flashPasswordResetUsernameError() {
    if (this.passwordResetErrorHandle) {
      clearTimeout(this.passwordResetErrorHandle);
    }

    this.isPasswordResetUsernameError = true;
    this.passwordResetErrorHandle = setTimeout(() => {
      this.isPasswordResetUsernameError = false;
    }, ERROR_FLASH_TIME);
  }

  private resetPasswordDone() {
    this.backToLoginPageButton.nativeElement.focus();
    this.isResetPasswordDone = true;
  }

  private resetPasswordError() {
    this.backToResetPasswordPageButton.nativeElement.focus();
    this.isResetPasswordError = true;
  }
}
