import { TutorialStep, AttachDirection } from 'app/shared/services/tutorial.service';

class MapWeatherWidgetStep extends TutorialStep {
  constructor(step) {
    super(step);
    this.id = 'map-weather-widget';
    this.title = 'Map & Weather Widget';
    this.arrow = true;
  }
}

export function getMapWidgetSteps(tutorialService): MapWeatherWidgetStep[] {
  return [
    new MapWeatherWidgetStep ({
      beforeShowPromise: () => {
        return new Promise<void>((resolve) => {
          tutorialService.clickElement('map-weather');
          resolve();
        });
      },
      attachTo: {
        element: 'div:nth-child(1) > app-tabbed-content > div.content',
        on: AttachDirection.top
      },
      scrollTo: true,
      text: 'The Map & Weather widget provides the upcoming weather forecast for your orchard(s) and a detailed map of the property.'
    }),
    new MapWeatherWidgetStep ({
      beforeShowPromise: () => {
        return new Promise<void>((resolve) => {
          tutorialService.clickElement('daily-tab');
          resolve();
        });
      },
      attachTo: {
        element: '.orchard-weather',
        on: AttachDirection.right
      },
      text: 'The weather is organised into daily and hourly views. The daily tab shows weather for today and the next two days. Click on one of these days to see an hourly view of that day.'
    }),
    new MapWeatherWidgetStep ({
      beforeShowPromise: () => {
        return new Promise<void>((resolve) => {
          tutorialService.clickElement('hourly-tab');
          resolve();
        });
      },
      attachTo: {
        element: '.orchard-weather',
        on: AttachDirection.right
      },
      text: 'The hourly forecast gives a more detailed view of the weather including temperate, humidity, windspeed and direction. Use the arrows on the left and right sides, or click and drag to scroll along the timeline.'
    }),
    new MapWeatherWidgetStep ({
      attachTo: {
        element: '.map',
        on: AttachDirection.right
      },
      text: '<p>The map notes key areas of your orchard(s), such as entrances, toilets, parking, shelters and block information.</p><p>Click and drag on the map to move around. Use the marker button in the bottom left to recentre the map view.</p><p>Use the + and - buttons in the top left corner to zoom in and out.</p><p>Hover over the layers button in the top right to show or hide layers of the map or switch between simple and satellite maps.</p><p>Use the \'Print Map\' button in the bottom right to generate a printable PDF copy of the orchard map.</p>'
    }),
    new MapWeatherWidgetStep ({
      beforeShowPromise: () => {
        return new Promise<void>((resolve) => {
          tutorialService.clickElement('map-weather');
          resolve();
        });
      },
      attachTo: {
        element: 'grow-orchard-map-widget > app-widget > header > div > dropdown.header-dropdown',
        on: AttachDirection.bottom
      },
      text: 'If you are associated with multiple orchards use the “Orchard” button in the top right change to the selected orchard.'
    })
  ];
}
