import { ShepherdService } from 'angular-shepherd';
import { User, AuthService } from './auth.service';
import { isMobile } from './utils.service';
import { Injectable } from '@angular/core';

export const TUTORIAL_KEY = 'tutorials_enabled_';

export const DEFAULT_BUTTONS = [
  {
    classes: 'shepherd-button-primary',
    text: 'Previous',
    type: 'back'
  },
  {
    classes: 'shepherd-button-primary',
    text: 'Next',
    type: 'next'
  },
  {
    classes: 'shepherd-button-primary',
    text: 'Home',
    action() {
      return this.show(0);
    }
  },
  {
    classes: 'shepherd-button-secondary',
    text: 'Cancel',
    action() {
      return this.cancel();
    }
  }
];

export enum AttachDirection {
  none = '',
  auto = 'auto', // Choose the side with the most space
  autostart = 'auto-start',
  autoend = 'auto-end',
  top = 'top',
  topstart = 'top-start',
  topend = 'top-end',
  bottom = 'bottom',
  bottomstart = 'bottom-start',
  bottomend = 'bottom-end',
  left = 'left',
  leftstart = 'left-start',
  leftend = 'left-end',
  right = 'right',
  rightstart = 'right-start',
  rightend = 'right-end'
}

export class TutorialStep {
  id: string;
  title: string;
  text: string;
  beforeShowPromise?: any;
  attachTo: { element: string, on: AttachDirection };
  showOn: any;
  buttons: any;
  classes: string;
  highlightClass: string;
  arrow: boolean;
  scrollTo?: boolean;
  popperOptions?: any;
  cancelIcon = { enabled: true };

  constructor ({
    id,
    title,
    text,
    beforeShowPromise = null,
    attachTo = null,
    buttons = DEFAULT_BUTTONS,
    classes = 'tutorial',
    highlightClass = '',
    arrow = false,
    scrollTo = false,
    popperOptions = null
  }) {
    this.id = id;
    this.title = title;
    this.text = text;
    this.beforeShowPromise = beforeShowPromise;
    this.attachTo = attachTo || undefined;
    this.buttons = buttons;
    this.classes = classes;
    this.highlightClass = highlightClass;
    this.arrow = arrow;
    this.scrollTo = scrollTo;
    this.popperOptions = popperOptions;

    if (this.attachTo) {
      this.showOn = () => {
        const element = document.querySelector(this.attachTo.element);
        return element !== null && window.getComputedStyle(element).display !== 'none' ? true : false;
      };
    }
  }
}

export interface TutorialToken {
  user: any;
  page: string;
  enabled: boolean;
}

@Injectable()
export class TutorialService {
  tutorialsEnabled = true;
  activeUser: User;
  page: string;
  localStorageKey: string;
  configurableButtons: string[] = ['Cancel', 'I\'m Not Interested', 'End Tutorial'];
  isFooterButtonVisible = true;

  constructor (
    private authService: AuthService,
    private shepherdService: ShepherdService
  ) {}

  run (pageName, getSteps: (context: any, options?: any) => any[], options: any = null) {
    if (!isMobile()) {
      this.shepherdService.modal = true;
      this.page = pageName;
      this.activeUser = this.authService.getUser();
      this.localStorageKey = `${ TUTORIAL_KEY }${ this.activeUser.id }_${ this.page }`;
      this.setTutorialEnabledFromToken();
      const steps = getSteps(this, options);
      this.configureButtons(steps);
      this.shepherdService.addSteps(steps);
      this.showTutorialFooterButton();

      if (this.tutorialsEnabled) {
        this.startTutorial();
      }
    }
  }

  // Can be used to update the list of buttons to be reconfigured
  setConfigurableButtons(configurableButtons) {
    this.configurableButtons = configurableButtons;
  }

  configureButtons(steps) {
    steps.forEach((step) => {
      step.buttons.forEach((button) => {
        this.configureButton(button);
      });
    });
  }

  private configureButton(button) {
    if (this.configurableButtons.includes(button.text) && !button.alreadyConfigured) {
      button.alreadyConfigured = true;
      const originalAction = button.action.bind(this);
      button.action = () => {
        if (typeof(originalAction) === 'function') {
          originalAction();
        }
        this.disableTutorial();
      };
    }
  }

  setTutorialEnabledFromToken() {
    const token = this.getToken();
    if (token) {
      this.tutorialsEnabled = token.enabled;
    } else if (token === null) {
      this.tutorialsEnabled = true;
    }
  }

  private getToken(): TutorialToken {
    return JSON.parse(localStorage.getItem(this.localStorageKey)) as TutorialToken;
  }

  private setToken(page: String): void {
    localStorage.setItem(this.localStorageKey, JSON.stringify({
      'enabled': this.tutorialsEnabled
    }));
  }

  clickElement(id: string) {
    const elementToClick = document.getElementById(id);
    if (elementToClick) {
      elementToClick.click();
    }
  }

  private startTutorial() {
    this.shepherdService.confirmCancel = false;
    if (this.shepherdService.tourObject) {
      this.shepherdService.start();
      this.bindHideEvent();
    }
  }

  showTutorial() {
    this.tutorialsEnabled = true;
    this.startTutorial();
  }

  disableTutorial() {
    this.tutorialsEnabled = false;
    this.setToken(this.page);
  }

  cancel() {
    this.shepherdService.cancel();
  }

  show(key) {
    this.shepherdService.show(key);
  }

  showTutorialFooterButton() {
    setTimeout(() => {
      this.isFooterButtonVisible = true;
    });
  }

  hideTutorialFooterButton() {
    setTimeout(() => {
      this.isFooterButtonVisible = false;
    });
  }

  private bindHideEvent() {
    const overlay = document.querySelector('.shepherd-modal-overlay-container');
    overlay.addEventListener('click', () => {
      this.cancel();
    });
  }
}
