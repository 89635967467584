<header *ngIf="!hideHeader && !(areHeaderDropdownsEmpty && areHeaderButtonsEmpty)"
        class="header"
        [class.desktop-only]="areHeaderDropdownsEmpty && areAllHeaderButtonsDesktopOnly">
  <div class="header-column">
    <dropdown *ngIf="dropdownItems?.length"
              class="header-dropdown"
              [items]="dropdownItems"
              [current]="selectedDropdownItem"
              [handle]="dropdownHandle"
              [maxHeight]=300
              [highlightSelected]="true"
              (currentChange)="selectDropdownItem($event)">
      <button class="button transparent tiny rounded bordered">
        {{ dropdownItemLabel }}: {{ selectedDropdownItem?.label }}
        <i class="fas fa-chevron-right right"></i>
      </button>
    </dropdown>
    <dropdown *ngIf="secondDropdownItems?.length"
              class="header-dropdown"
              [items]="secondDropdownItems"
              [current]="selectedSecondDropdownItem"
              [handle]="secondDropdownHandle"
              [maxHeight]=300
              [highlightSelected]="true"
              (currentChange)="selectSecondDropdownItem($event)">
      <button class="button transparent tiny rounded bordered">
        {{ secondDropdownItemLabel }}: {{ selectedSecondDropdownItem?.label }}
        <i class="fas fa-chevron-right right"></i>
      </button>
    </dropdown>
    <dropdown *ngIf="thirdDropdownItems?.length"
              class="header-dropdown"
              [items]="thirdDropdownItems"
              [current]="selectedThirdDropdownItem"
              [handle]="thirdDropdownHandle"
              [maxHeight]=300
              [highlightSelected]="true"
              (currentChange)="selectThirdDropdownItem($event)">
      <button class="button transparent tiny rounded bordered">
        {{ thirdDropdownItemLabel }}: {{ selectedThirdDropdownItem?.label }}
        <i class="fas fa-chevron-right right"></i>
      </button>
    </dropdown>
    <button *ngFor="let headerButton of headerButtons"
            (click)="headerButtonClick(headerButton)"
            [disabled]="headerButton.disabled"
            [class]="'button rounded tiny transparent bordered header-button ' + (headerButton.cssClass || '')"
            [class.desktop-only]="headerButton.isDesktopOnly"
            [class.disabled]="headerButton.disabled">
      {{ headerButton.text }}
    </button>
  </div>
</header>

<div *ngIf="!hasNoData" class="content">
  <ng-content select=".no-scroll"></ng-content>
  <div *ngIf="!hideScrollPane" class="scroll-panel">
    <div class="scroll-panel-content">
      <ng-content></ng-content>
    </div>
  </div>
  <footer *ngIf="displayCurrentTimestamp && updatedAt" class="footer">
    <div class="current-as-of-panel">
      Current as of: <strong>{{ updatedAt | date: dateFormat }}</strong>
    </div>
    <!--TODO: implement-->
    <!--<div class="export-panel">-->
      <!--Export:-->
      <!--<a href="#excel-export" class="link" (click)="exportFile('excel')">Excel</a>,-->
      <!--<a href="#pdf-export" class="link" (click)="exportFile('pdf')">PDF</a>-->
    <!--</div>-->
  </footer>
</div>

<loading-indicator [class.visible]="isLoading"
                   [radius]="30"
                   [thickness]="4"
                   [isLight]="true"
                   [isAbsolute]="true"
                   message=""
                   [externalMessage]="loadingMessage">
</loading-indicator>

<div *ngIf="hasNoData"
     class="no-data-message visible"
     [class.no-header]="hideHeader">
  <i class="fas fa-exclamation-triangle"></i>
  <div class="message">{{ noDataMessage }}</div>
</div>
