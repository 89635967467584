<main-menu class="main-menu dark"
           [currentUser]="currentUser"
           [hasSearch]="false"
           [hasAccountLink]="false"
           [quickMenuItems]="quickMenuItems"
           [menuCategories]="menuCategories"
           [noColumnRule]="true"
           [isMenuLoadRequired]="false">
</main-menu>

<div class="main"
     [class.locked]="state.data.isLocked"
     [class.has-user-override]="userOverride"
     (scroll)="onScroll($event)">
  <div *ngIf="userOverride"
       class="user-override-message">
    <div class="message">You are impersonating <strong>{{ userOverride.name }}</strong>.</div>
    <button (click)="cancelOverride()"
            class="button rounded tiny inline transparent bordered cancel-override">
      Cancel
    </button>
  </div>

  <div *ngIf="isInitialized" class="page-content"
       [class.override-padding]="userOverride">
    <router-outlet></router-outlet>
    <loading-indicator [class.visible]="stateService.loading"
                       [message]="stateService.loadingMessage"
                       [isGrowerPortal]="true"
                       [radius]="75"></loading-indicator>
  </div>
</div>

<app-footer [displayHelpToggle]="true"
            [displayTutorialButton]="tutorialService.isFooterButtonVisible"
            [displayFeedbackButton]="true">
</app-footer>

<app-document-viewer [class.visible]="document"
                     [document]="document"
                     [closeWindowHandler]="closeDocumentViewer.bind(this)">
</app-document-viewer>

<app-notification></app-notification>
