<div class="error" *ngIf="isLoadingError">
  <mat-icon class="icon">warning</mat-icon>
  <strong class="error-heading">Could not load PDF.</strong>
</div>
<ngx-extended-pdf-viewer
  *ngIf="!isLoadingError"
  #pdfViewer
  [showToolbar]="true"
  [sidebarVisible]="false"
  [showTextEditor]="false"
  [showDrawEditor]="false"
  [contextMenuAllowed]="false"
  [showOpenFileButton]="false"
  [showRotateButton]="false"
  [language]="'en-GB'"
  (pdfLoadingFailed)="onLoadError($event)"
  (pageChange)="onPageChange($event)"
  (pagesLoaded)="onPagesLoaded($event)">
</ngx-extended-pdf-viewer>

<ng-template #agreementErrorContent>
  <div class="agreement-error-content">
    <p [innerHTML]="agreementErrorMessage"></p>
    <p>You can download your agreement by clicking the <strong class="download-agreement">Download Agreement</strong> button.</p>
    <p *ngIf="!isAgreementAlreadySigned">You can then sign your agreement by clicking the <strong class="sign-agreement">Sign Agreement</strong> button.</p>
  </div>
</ng-template>
