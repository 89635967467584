<app-widget [isLoading]="isLoading"
            [loadingMessage]="loadingMessage"
            noDataMessage="No orchard data available."
            [hasNoData]="!orchards?.length"
            [hideHeader]="!orchards?.length"
            dropdownItemLabel="Orchard"
            [dropdownItems]="orchards"
            [selectedDropdownItem]="selectedOrchard"
            [onDropdownItemChange]="selectOrchard.bind(this)"
            dropdownHandle="orchardMapWidgetOrchardDropdown"
            [headerButtons]="headerButtons"
            [displayCurrentTimestamp]="false"
            [hideScrollPane]="true">
  <app-orchard-weather #orchardWeather
                       class="orchard-weather no-scroll"
                       [class.hidden]="hideWeather"
                       [displayError]="displayWeatherError"
                       (error)="weatherError($event)"
                       (complete)="weatherComplete($event)">
  </app-orchard-weather>
  <app-orchard-map #orchardMap
                   class="no-scroll map"
                   (error)="mapError($event)"
                   (complete)="mapComplete($event)">
  </app-orchard-map>
</app-widget>
