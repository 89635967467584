<div class="breadcrumbs-panel">
  <app-breadcrumbs></app-breadcrumbs>
</div>
<div #main class="main">
  <section *ngFor="let category of resourceService.categories" class="category">
    <h3>{{ category.label }}</h3>
    <div *ngIf="resourceService.isUiRefreshed" class="resource-groups">
      <ng-container *ngFor="let resourceGroup of category.resourceGroups">
        <grower-portal-resource-tile *ngIf="resourceGroup.component || resourceGroup.items.length"
                                     [label]="resourceGroup.group_name"
                                     [icon]="resourceGroup.icon"
                                     [badge]="resourceGroup.badge"
                                     [meta]="resourceGroup.meta"
                                     (click)="goToResourceGroup(resourceGroup)"></grower-portal-resource-tile>
      </ng-container>
    </div>
  </section>
</div>
