import { TutorialStep, AttachDirection } from 'app/shared/services/tutorial.service';

const OGR_DETAILS_BUTTONS = [
  {
    classes: 'shepherd-button-primary',
    text: 'Previous',
    type: 'back'
  },
  {
    classes: 'shepherd-button-primary',
    text: 'Next',
    type: 'next'
  },
  {
    classes: 'shepherd-button-secondary',
    text: 'Cancel',
    action() {
      return this.cancel();
    }
  }
];

export function getOgrDetailsSteps(tutorialService) {
  const steps: TutorialStep[] = [
    new TutorialStep ({
      id: 'intro',
      title: 'OGR Details',
      text: 'The OGR Details page provides a detailed view of payment sources and costs, broken down by maturity area and season.',
      scrollTo: true,
      buttons: [
        {
          classes: 'shepherd-button-primary',
          text: 'Next',
          type: 'next'
        },
        {
          classes: 'shepherd-button-secondary',
          text: 'I\'m Not Interested',
          action() {
            return this.cancel();
          }
        }
      ]
    }),
    // Variety Tabs
    new TutorialStep ({
      id: 'variety-tabs',
      title: 'Variety Tabs',
      text: 'If you grow multiple varieties, you can switch between them with the Variety and Grow Method tabs here.',
      attachTo: {
        element: '#k-tabstrip-tab-0',
        on: AttachDirection.right
      },
      scrollTo: true,
      buttons: OGR_DETAILS_BUTTONS
    }),
    // Season Selector
    new TutorialStep ({
      id: 'season-selector',
      title: 'Season Selection',
      text: 'Use this Season dropdown to change between seasons.',
      attachTo: {
        element: 'dropdown[handle=ogrDetailsSeasonDropdown]',
        on: AttachDirection.bottom
      },
      scrollTo: true,
      buttons: OGR_DETAILS_BUTTONS
    }),
    // Forecast Selector
    new TutorialStep ({
      id: 'forecast-selector',
      title: 'Forecast Selection',
      text: 'Use the forecast dropdown to change to an earlier OGR for the current season.',
      attachTo: {
        element: 'dropdown[handle=ogrDetailsForecastDropdown]',
        on: AttachDirection.bottom
      },
      scrollTo: true,
      buttons: OGR_DETAILS_BUTTONS
    }),
    // Expand KPIN to show MAs
    new TutorialStep ({
      id: 'table-kpins',
      title: 'KPINs and Maturity Areas',
      text: 'Click on the arrow next to a KPIN to view the inventory data by Maturity Area.',
      attachTo: {
        element: 'app-simple-grid .level-0 .fixed-narrow',
        on: AttachDirection.right
      },
      scrollTo: true,
      buttons: OGR_DETAILS_BUTTONS
    }),
    // Group by on graph
    new TutorialStep ({
      id: 'graph-group-switch',
      title: 'Graphs: X Axis',
      text: 'Use this switch to toggle the chart grouping between KPIN or MA and season.',
      attachTo: {
        element: '.index-chart-column .group-switch',
        on: AttachDirection.top
      },
      scrollTo: true,
      buttons: OGR_DETAILS_BUTTONS
    }),
    // Income components
    new TutorialStep ({
      id: 'income-components',
      title: 'Income Components',
      text: 'The Income Components pie chart shows the percentage of your income split into individual sources for the current and previous season. The outer ring represents the season currently selected and the inner ring represents the previous season for the selected KPIN or MA.',
      attachTo: {
        element: '.sidebar-content .income-components-panel',
        on: AttachDirection.bottom
      },
      scrollTo: true,
      buttons: OGR_DETAILS_BUTTONS
    }),
    // Column descriptions
    new TutorialStep ({
      id: 'column-descriptions',
      title: 'Column Descriptions',
      text: 'Here you can find brief descriptions of each column under the selected payments tab. You can also view this by hovering your mouse over the column’s heading.',
      beforeShowPromise: () => {
        return new Promise<void>((resolve) => {
          tutorialService.clickElement('fruit-payments-tab');
          resolve();
        });
      },
      attachTo: {
        element: '.sidebar-content .pages',
        on: AttachDirection.top
      },
      scrollTo: true,
      buttons: OGR_DETAILS_BUTTONS
    }),
    // Fruit Payments
    new TutorialStep ({
      id: 'fruit-payments',
      title: 'Fruit Payments',
      text: 'Under the Fruit Payments tab is a detailed view of income for Submit & Progress, KiwiStart and Zespri.',
      beforeShowPromise: () => {
        return new Promise<void>((resolve) => {
          tutorialService.clickElement('fruit-payments-tab');
          resolve();
        });
      },
      attachTo: {
        element: '.data-category-content',
        on: AttachDirection.bottom
      },
      scrollTo: true,
      buttons: OGR_DETAILS_BUTTONS
    }),
    // Service Payments
    new TutorialStep ({
      id: 'service-payments',
      title: 'Service Payments',
      text: 'Under the Service Payments tab is a detailed view of income from Zespri’s Packtype Differential, Time Service Payment, Storage Incentives and Supplier Accountability.',
      beforeShowPromise: () => {
        return new Promise<void>((resolve) => {
          tutorialService.clickElement('service-payments-tab');
          resolve();
        });
      },
      attachTo: {
        element: '.data-category-content',
        on: AttachDirection.bottom
      },
      scrollTo: true,
      buttons: OGR_DETAILS_BUTTONS
    }),
    // Post Harvest Costs
    new TutorialStep ({
      id: 'post-harvest-costs',
      title: 'Post Harvest-Costs',
      text: 'Under the Post Harvest Costs tab is a breakdown of the costs incurred post-harvest. This includes Base Pack, Reject Surcharge, Packing Differential, Base Cool Storage, Cartage, Maturity Tests, Administration and Logistics.',
      beforeShowPromise: () => {
        return new Promise<void>((resolve) => {
          tutorialService.clickElement('post-harvest-costs-tab');
          resolve();
        });
      },
      attachTo: {
        element: '.data-category-content',
        on: AttachDirection.bottom
      },
      scrollTo: true,
      buttons: OGR_DETAILS_BUTTONS
    }),
    // Net Time & Storage
    new TutorialStep ({
      id: 'net-time-and-storage',
      title: 'Net Time & Storage',
      text: 'Under the Net Time & Storage tab we have grouped together the total net time & storage costs and payments for your convenience. This includes Time Service Payments, Storage Incentives, Onshore Fruit Loss, and Time & CC/RK Charges.',
      beforeShowPromise: () => {
        return new Promise<void>((resolve) => {
          tutorialService.clickElement('net-time-and-storage-tab');
          resolve();
        });
      },
      attachTo: {
        element: '.data-category-content',
        on: AttachDirection.bottom
      },
      scrollTo: true,
      buttons: OGR_DETAILS_BUTTONS
    }),
    // Sundry Payments
    new TutorialStep ({
      id: 'sundry-payments',
      title: 'Sundry Payments',
      text: 'Under the Sundry Payments tab is where you will find various other payments that aren’t easily categorised under the other tabs. These include Loyalty Premium, Class 2 Returns, NSS, Japan Premium and other income which includes Class 3 Returns, Used Packaging and DIFOTIS',
      beforeShowPromise: () => {
        return new Promise<void>((resolve) => {
          tutorialService.clickElement('sundry-payments-tab');
          resolve();
        });
      },
      attachTo: {
        element: '.data-category-content',
        on: AttachDirection.bottom
      },
      scrollTo: true,
      buttons: OGR_DETAILS_BUTTONS
    }),
    // Clicking on payments graph
    new TutorialStep ({
      id: 'payments-graph',
      title: 'Payments Graph',
      text: 'Click on a bar in the graph below to get a detailed view of the components that make up the total payment for that maturity area or KPIN.',
      beforeShowPromise: () => {
        return new Promise<void>((resolve) => {
          tutorialService.clickElement('fruit-payments-tab');
          resolve();
        });
      },
      attachTo: {
        element: '.detail .column.data-category',
        on: AttachDirection.left
      },
      scrollTo: true,
      buttons: OGR_DETAILS_BUTTONS
    }),
    // OGR Report Button
    new TutorialStep ({
      id: 'ogr-report',
      title: 'OGR Report',
      text: 'Use the OGR Report buttons here and at the bottom of the side bar to download a PDF of your full OGR Report',
      attachTo: {
        element: '.download-report-button-group',
        on: AttachDirection.bottom
      },
      scrollTo: true,
      buttons: OGR_DETAILS_BUTTONS
    })
  ];
  return steps;
}
