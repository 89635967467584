import { Component, EventEmitter, OnDestroy, Output } from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormControl,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR, ValidationErrors,
  Validator,
  Validators
} from '@angular/forms';
import { Subscription } from 'rxjs';

@Component({
  selector: 'agreement-food-safety-declaration',
  templateUrl: './agreement-food-safety-declaration.component.html',
  styleUrls: ['./agreement-food-safety-declaration.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: AgreementFoodSafetyDeclarationComponent
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: AgreementFoodSafetyDeclarationComponent
    }
  ]
})
export class AgreementFoodSafetyDeclarationComponent implements ControlValueAccessor, OnDestroy, Validator {
  @Output() onClose = new EventEmitter();

  onChangeSubs: Subscription[] = [];

  private childFieldsSpraysApplied = [
    'spray_applicator_growsafe_certified',
    'approved_chemical_storage',
    'approved_chemicals_applied',
    'spray_diary_current'
  ];

  private childFieldsFertiliserApplied = [
    'fertiliser_diary_current'
  ];

  form = new FormGroup({
    // Have any sprays been applied on your orchard?
    sprays_applied: new FormControl(null, [Validators.required]),
    // All sprays have been applied by someone with a valid GROWSAFE certificate
    spray_applicator_growsafe_certified: new FormControl(null),
    // Approved chemical storage/disposal
    approved_chemical_storage: new FormControl(null),
    // Only approved chemicals applied
    approved_chemicals_applied: new FormControl(null),
    // Spray diary kept current
    spray_diary_current: new FormControl(null),
    // Has any fertiliser been applied to your orchard?
    fertilisers_applied: new FormControl(null, [Validators.required]),
    // Have you maintained a fertiliser diary?
    fertiliser_diary_current: new FormControl(null),
    // Records of receivals maintained (e.g. GST records)
    receivals_records_maintained: new FormControl(null, [Validators.required]),
    // Adequate toilet and wash facilities
    adequate_toilet_facilities: new FormControl(null, [Validators.required]),
  });
  isVisible = false;
  windowHeader: string;
  defaultWindowHeader = 'Food Safety Declaration';
  buttons: any = [
    {
      label: 'Save',
      action: this.saveForm.bind(this),
      primary: true,
      cssClass: 'large',
      icon: 'fas fa-file-circle-check'
    }
  ];
  currentSpraysApplied: boolean;
  currentFertilisersApplied: boolean;

  onTouched: Function = () => {};

  protected closeWindow() {
    this.isVisible = false;
    this.onClose.emit();
  }

  private saveForm() {
    this.form.markAllAsTouched();
    if (!this.form.valid) {
      return;
    }
    this.closeWindow();
  }

  ngOnDestroy() {
    for (const sub of this.onChangeSubs) {
      sub.unsubscribe();
    }
  }

  open(headerContent: string) {
    this.windowHeader = `${ this.defaultWindowHeader }${ headerContent ? ` - ${ headerContent }` : '' }`;
    this.isVisible = true;
  }

  protected toggleSpraysApplied() {
    const spraysApplied = this.form.get('sprays_applied').value;
    if (this.currentSpraysApplied !== spraysApplied) {
      this.currentSpraysApplied = spraysApplied;
      this.updateFormControls(this.childFieldsSpraysApplied, this.currentSpraysApplied);
    }
  }

  protected toggleFertilisersApplied() {
    const fertilisersApplied = this.form.get('fertilisers_applied').value;
    if (this.currentFertilisersApplied !== fertilisersApplied) {
      this.currentFertilisersApplied = fertilisersApplied;
      this.updateFormControls(this.childFieldsFertiliserApplied, this.currentFertilisersApplied);
    }
  }

  private updateFormControls(controlNames: string[], enable: boolean) {
    controlNames.forEach(controlName => {
      const control = this.form.controls[controlName];
      control?.reset();
      if (enable) {
        control?.setValidators([Validators.required]);
      } else {
        control?.clearValidators();
      }
      control?.updateValueAndValidity();
    });
  }

  registerOnChange(onChange: any): void {
    const sub = this.form.valueChanges.subscribe(onChange);
    this.onChangeSubs.push(sub);
  }

  registerOnTouched(onTouched: Function) {
    this.onTouched = onTouched;
  }

  setDisabledState(disabled: boolean) {
    if (disabled) {
      this.form.disable();
    } else {
      this.form.enable();
    }
  }

  writeValue(value: any) {
    if (value) {
      this.form.setValue(value, { emitEvent: false });
    }
  }

  validate(control: AbstractControl): ValidationErrors | null {
    // Magically called by the parent form group to check the validity of this control.
    // Returns an overall error instead of individual field errors.
    return this.form.valid ? null : { required: true };
  }
}
