import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'toFrontendUrl'
})
export class ToFrontendUrlPipe implements PipeTransform {
  transform(value: string): any {
    return value ? value.replace(/^\/ip2/, '/ip') : value;
  }
}
