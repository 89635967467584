import { ChangeDetectorRef, Component, OnInit, ViewChild, HostBinding } from '@angular/core';
import { StateService } from 'app/shared/services/state.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AgreementListItem, AgreementsService } from 'app/agreements/agreements.service';
import { ContainerRefDirective } from 'app/directives/container-ref.directive';
import {
  AgreementFormComponent,
  BaseAgreementData
} from 'app/agreements/agreement-page/agreement-form/agreement-form.component';
import {
  AgreementFormKiwifruitPackingComponent
} from 'app/agreements/agreement-page/agreement-form/agreement-form-kiwifruit-packing/agreement-form-kiwifruit-packing.component';
import {
  AgreementFormKiwifruitSupplyComponent
} from 'app/agreements/agreement-page/agreement-form/agreement-form-kiwifruit-supply/agreement-form-kiwifruit-supply.component';
import { UserOverrideComponent } from 'app/shared/user-override/user-override.component';
import {
  AgreementFormAvocadoPackingComponent
} from 'app/agreements/agreement-page/agreement-form/agreement-form-avocado-packing/agreement-form-avocado-packing.component';
import { GaService } from 'app/shared/services/ga.service';

export const FORM_CLASS_REGISTRY = {
 'avocado_packing': AgreementFormAvocadoPackingComponent,
 'kiwifruit_packing': AgreementFormKiwifruitPackingComponent,
 'kiwifruit_supply': AgreementFormKiwifruitSupplyComponent
};

@Component({
  selector: 'agreement-page',
  templateUrl: './agreement-page.component.html',
  styleUrls: ['./agreement-page.component.scss']
})
export class AgreementPageComponent implements OnInit {
  @ViewChild(ContainerRefDirective, { static: false }) agreementForm!: ContainerRefDirective;
  @HostBinding('class.visible') isVisible = false;
  agreement: AgreementListItem = {} as AgreementListItem;
  showAgreement = false;
  signButtonEnabled = false;
  private impersonatingGrower = false;

  constructor(
    public stateService: StateService,
    private activatedRoute: ActivatedRoute,
    private changeDetectorRef: ChangeDetectorRef,
    private router: Router,
    private agreementsService: AgreementsService,
    protected ga: GaService
  ) {}

  ngOnInit(): void {
    const agreement = window.history.state as AgreementListItem;
    if (agreement.uuid) {
      this.agreement = agreement;
      this.showAgreement = true;
      this.impersonatingGrower = !!UserOverrideComponent.getOverride();
    } else {
      this.returnToLists();
    }
  }

  onDocumentLoaded() {
    this.signButtonEnabled = true;
    this.isVisible = true;
  }

  goToForm() {
    this.showAgreement = false;
    this.changeDetectorRef.detectChanges();

    const componentClass = FORM_CLASS_REGISTRY[this.agreement.agreement_type];
    if (!componentClass) {
      throw new Error(`Requested agreement form component name does not exist, "${ this.agreement.agreement_type }"`);
    }
    this.ga.event(
      'button',
      'click',
      `agreement page: ${ this.agreement.name } ${ this.agreement.is_user_signer ? 'sign' : 'view' }`,
      this.agreement.entity_name
    );
    this.createComponent(componentClass);
  }

  confirmBreadcrumbAction(): Promise<boolean> {
    if (this.showAgreement || !this.agreement.is_user_signer || this.impersonatingGrower) {
      // The form is not visible or the users is a viewer, so no need to confirm
      return Promise.resolve(true);
    } else {
      return new Promise((resolve) => {
        this.agreementsService.showConfirmDialog(result => resolve(result['text'] === 'Yes'));
      });
    }
  }

  returnToLists() {
    this.router.navigate(['/agreements'], {
      relativeTo: this.activatedRoute
    });
  }

  private createComponent(componentClass) {
    const viewContainerRef = this.agreementForm.viewContainerRef;
    viewContainerRef.clear();
    const componentRef = viewContainerRef.createComponent<AgreementFormComponent<BaseAgreementData>>(componentClass);
    componentRef.instance.agreement = this.agreement;
    componentRef.instance.cancelCallback = this.closeForm.bind(this);
  }

  private closeForm() {
    this.showAgreement = true;
  }
}
