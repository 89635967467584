import {
  Component,
  Input,
  ViewChild,
  ComponentFactoryResolver,
  ViewContainerRef,
  ComponentRef,
  Injector
} from '@angular/core';
import {GaService} from 'app/shared/services/ga.service';

export interface TabAndComponent {
  title: string;
  id: string;
  component: any;
  createdComponent?: ComponentRef<unknown>;
  current?: boolean;
  helpKey?: string;
  componentInputs?: object;
  onSelect?: Function;
}

@Component({
  selector: 'app-tabbed-content',
  templateUrl: './tabbed-content.component.html',
  styleUrls: ['./tabbed-content.component.scss']
})
export class TabbedContentComponent {
  private _tabs: TabAndComponent[];
  @ViewChild('content', { read: ViewContainerRef, static: true }) content: ViewContainerRef;
  current: TabAndComponent;
  private selectedTabIndex = 0;

  @Input()
  set tabs(tabs: TabAndComponent[]) {
    this.selectedTabIndex = 0;
    this._tabs = tabs;
    this.initTabs();
  }
  get tabs(): TabAndComponent[] {
    return this._tabs;
  }

  constructor(
    private injector: Injector,
    private componentFactoryResolver: ComponentFactoryResolver,
    private ga: GaService
  ) {}

  selectTab(index: number, tab: TabAndComponent = null) {
    if (index !== this.selectedTabIndex) {
      this.ga.event('tab', 'change', tab.title, tab.id);
      this.selectedTabIndex = index;
      this.loadComponent(index);
    }

    if (tab && typeof(tab.onSelect) === 'function') {
      tab.onSelect();
    }
  }

  private initTabs() {
    if (this.tabs && this.tabs.length) {
      this.tabs.forEach((tab: TabAndComponent, index: number) => {
        if (tab.current) {
          this.selectedTabIndex = index;
        }
        const componentFactory = this.componentFactoryResolver.resolveComponentFactory(tab.component);
        tab.createdComponent = componentFactory.create(this.injector);

        // Assign input values to the component instance
        Object.assign(tab.createdComponent.instance, tab.componentInputs);
      });
      this.loadComponent(this.selectedTabIndex);
    }
  }

  private loadComponent(index: number) {
    if (this.current) {
      this.content.detach(0);
    }
    this.current = this.tabs[index];
    this.content.insert(this.current.createdComponent.hostView);
  }
}
