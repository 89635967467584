import { Injectable } from '@angular/core';
import { SettingsService } from './settings.service';
import { DatePipe } from '@angular/common';

@Injectable()
export class OrchardCalendarService {

  private datePipe = new DatePipe('en-NZ');
  humanDateFormat = SettingsService.CALENDAR_DATE_FORMAT;

  showItemContent(category, item, messageService) {
    const message = {
      title: `${category}: ${item.title}`,
      content: `
        <div class="light space-s"><i class="far fa-calendar icon"></i> ${ this.getFormattedDateRange(item) }</div>
        ${ item.content || item.title }
      `
    };
      messageService.send(SettingsService.SHOW_DOCUMENT_MESSAGE, message);
  }

  private getFormattedDateRange(item) {
    const formattedStart = this.datePipe.transform(item.start, this.humanDateFormat);
    const formattedEnd = this.datePipe.transform(item.end, this.humanDateFormat);
    const startDate = new Date(item.start);
    const endDate = new Date(item.end);
    return startDate.getTime() === endDate.getTime() ? formattedStart : formattedStart + ' - ' + formattedEnd;
  }
}
