import { TutorialStep, AttachDirection } from 'app/shared/services/tutorial.service';

class FlowerBudCountsWidgetStep extends TutorialStep {
  constructor(step) {
    super(step);
    this.id = 'flower-bud-counts-widget';
    this.title = 'Flower Bud Counts Widget';
    this.arrow = true;
  }
}

export function getFlowerBudCountsWidgetSteps(tutorialService): FlowerBudCountsWidgetStep[] {
  return [
    new FlowerBudCountsWidgetStep ({
      beforeShowPromise: () => {
        return new Promise<void>((resolve) => {
          tutorialService.clickElement('flower-bud-counts');
          resolve();
        });
      },
      attachTo: {
        element: 'div:nth-child(1) > app-tabbed-content > div.content',
        on: AttachDirection.right
      },
      text: 'The Flower Bud Counts widget provides a high-level summary of the flower counts Trevelyan’s performs on your orchard. The actual data is available once counting has been completed on your orchard. The crop estimate itself, and assumptions used, are available once assumptions for your orchard have been checked and signed off as giving a realistic crop estimate.'
    }),
    new FlowerBudCountsWidgetStep ({
      attachTo: {
        element: 'app-widget.flower app-simple-grid > table > tbody > tr > td.row-select.ng-star-inserted',
        on: AttachDirection.right
      },
      text: 'If you are associated with multiple KPINs, use the radio buttons on the left side of the table to switch between them.'
    }),
    new FlowerBudCountsWidgetStep ({
      attachTo: {
        element: 'app-widget.flower app-simple-grid > table > thead > tr:nth-child(1) > th:nth-child(3)',
        on: AttachDirection.bottom
      },
      text: 'The Flowers column shows the number average number of flower buds counted per m2. This value is used as the basis for the other data in the row.'
    }),
    new FlowerBudCountsWidgetStep ({
      attachTo: {
        element: 'app-widget.flower app-simple-grid > table > tbody > tr > td:nth-child(11)',
        on: AttachDirection.bottom
      },
      text: 'Click on the download buttons here to generate Crop Estimate and Scorecard reports in PDF form.'
    }),
    new FlowerBudCountsWidgetStep ({
      attachTo: {
        element: 'app-widget.flower .details',
        on: AttachDirection.top
      },
      text: 'The graph below shows flower bud count results for your orchard(s) up to 3 seasons back from the currently selected season. The X axis displays the season, while the Y axis displays the selected value. Clicking on the bold headings in the table above changes what is displayed on the Y axis.'
    }),
    new FlowerBudCountsWidgetStep ({
      beforeShowPromise: () => {
        return new Promise<void>((resolve) => {
          tutorialService.clickElement('flower-bud-counts');
          resolve();
        });
      },
      attachTo: {
        element: 'app-widget.flower > header > div > dropdown',
        on: AttachDirection.bottom
      },
      text: 'Use the Season selector to change which season’s data is displayed.'
    }),
  ];
}
