import { Component, OnInit, Input } from '@angular/core';
import { VarietyGrowMethodTab } from '../interfaces/tab.interface';
import { TabbedVarietyGrowMethod } from '../lib/tabbed-variety-grow-method';
import { HttpService } from 'app/shared/services/http.service';
import { SeasonDropdownItem } from 'app/grower-portal-dashboard/grower-portal-dashboard.component';
import { WidgetHeaderButton } from 'app/widget/widget.component';
import { INVENTORY_SEASONS_AND_WEEKS_ENDPOINT } from 'app/inventory-details/inventory-details.component';
import { OGR_SEASONS_AND_FORECASTS_ENDPOINT } from 'app/ogr-details/ogr-details.component';
import { uniqueObjectArrayByKey } from 'app/shared/services/utils.service';
import { GaService } from 'app/shared/services/ga.service';

const OGR_ENDPOINT = 'growers/kiwifruit/ogr/orchard_summary/';

interface WithSeason {
  seasons: number;
  [index: string]: any;
}

@Component({
  selector: 'app-ogr-inventory-widget',
  templateUrl: './ogr-inventory-widget.component.html',
  styleUrls: ['./ogr-inventory-widget.component.scss']
})
export class OgrInventoryWidgetComponent extends TabbedVarietyGrowMethod implements OnInit {
  @Input() updatedAt: Date = null;
  public tabs: VarietyGrowMethodTab[] = [];
  public gridColumns = [
    { label: 'KPIN', rowspan: 2, align: 'left', key: 'grower_number', cssClass: 'kpin' },
    { label: 'OGR / Tray', align: 'center', cssClass: 'ogr_per_tray', childColumns: [
        { label: '$', key: 'ogr_per_tray', cssClass: 'ogr_per_tray_cost', isSelectable: true, precision: 2 },
        { label: 'Rank', key: 'ogr_per_tray_rank', cssClass: 'ogr_per_tray_rank', isSelectable: true, subValue: 'rank_string' }
      ]
    },
    { label: 'OGR / Ha', align: 'center', cssClass: 'ogr_per_ha', childColumns: [
        { label: '$', key: 'ogr_per_ha', cssClass: 'ogr_per_ha_cost', isSelectable: true, precision: 2 },
        { label: 'Rank', key: 'ogr_per_ha_rank', cssClass: 'ogr_per_ha_rank', isSelectable: true, subValue: 'rank_string' }
      ]
    },
    { label: 'Shipped', cssClass: '', childColumns: [{ label: '%', key: 'percentage_shipped', zeroIsValid: true, precision: 1 }] },
    { label: 'Ordered', cssClass: '', childColumns: [{ label: '%', key: 'percentage_on_order', zeroIsValid: true, precision: 1 }] },
    { label: 'Fruit Loss', cssClass: '', childColumns: [{ label: '%', key: 'percentage_fl_grower_liability', zeroIsValid: true, precision: 2 }] },
    { label: 'In Store', cssClass: '', childColumns: [{ label: '%', key: 'percentage_in_store', zeroIsValid: true, precision: 1 }] }
  ];
  public isLoading = true;
  public gridData: any = [];
  public gridDataFiltered: any = [];
  public areDetailsVisible = true;
  public initialOgrMessage: string = null;
  private selectedRows: any[] = [];
  private selectedColumnKey = 'ogr_per_ha';
  private groups = [
    { label: 'KPIN', primary: 'grower_number', secondary: 'season', secondarySortOrder: 'desc' },
    { label: 'Season', primary: 'season', secondary: 'grower_number', isSelected: true, sortOrder: 'desc' }
  ];
  detailPrecision = 2;
  detailData: any = {};
  detailsChartYAxis = {};
  availableSeasons: SeasonDropdownItem[] = null;
  selectedSeason: SeasonDropdownItem = null;
  headerButtons: WidgetHeaderButton[] = [
    {
      text: 'OGR Details',
      routerLink: '/ogr-details',
      isDesktopOnly: true,
      cssClass: 'ogr-details-button',
      getQueryParams: () => ({ season: this.selectedSeason?.value })
    },
    {
      text: 'Inventory Details',
      routerLink: '/inventory-details',
      isDesktopOnly: true,
      cssClass: 'inventory-details-button',
      getQueryParams: () => ({ season: this.selectedSeason?.value })
    }
  ];

  constructor(
    private http: HttpService,
    private ga: GaService
  ) {
    super();
  }

  async ngOnInit() {
    this.availableSeasons = await this.getAvailableSeasons();

    if (this.availableSeasons?.length) {
      this.selectedSeason = this.availableSeasons[0];
      this.fetchGridData();
      this.setDetailsChartYAxis();
    } else {
      this.isLoading = false;
    }
  }

  onTabSelect(event: any) {
    this.ga.event('tab', 'change', 'ogr & inventory widget variety gm tab', event.title);
    this.selectedTab = this.tabs[event.index];
    this.filterGridData(this.selectedTab);
  }

  onGridColumnSelect(columnKey) {
    this.selectedColumnKey = columnKey;
    this.setDetailData();
    this.setDetailsChartYAxis();
    this.setDetailPrecision();
  }

  onGridRowSelect(row, index, selectedRows) {
    this.selectRows(selectedRows);
  }

  selectSeason(season: SeasonDropdownItem) {
    this.selectedSeason = season;
    this.fetchGridData();
  }

  private async getAvailableSeasons(): Promise<SeasonDropdownItem[]> {
    const seasonsData = await this.fetchSeasons();
    const seasons = seasonsData.map((item) => ({ label: item.season, value: item.season }));
    const uniqueSeasons = uniqueObjectArrayByKey(seasons, 'value');
    return uniqueSeasons.sort((a, b) => b.value - a.value);
  }

  private async fetchSeasons(): Promise<WithSeason[]>  {
    const inventorySeasons: WithSeason[] = await this.fetchInventorySeasons();
    const ogrSeasons: WithSeason[] = await this.fetchOgrSeasons();
    return (inventorySeasons || []).concat((ogrSeasons || []));
  }

  private fetchInventorySeasons(): Promise<any> {
    return this.http.get(INVENTORY_SEASONS_AND_WEEKS_ENDPOINT).toPromise();
  }

  private fetchOgrSeasons(): Promise<any> {
    return this.http.get(OGR_SEASONS_AND_FORECASTS_ENDPOINT).toPromise();
  }

  private selectRows(selectedRows) {
    this.selectedRows = selectedRows.map(item => item.grower_number);
    this.setDetailData();
  }

  private async fetchGridData() {
    this.isLoading = true;
    const params: any = { season: this.selectedSeason.value };

    this.gridData = await this.http.get(OGR_ENDPOINT, { params: params }).toPromise();

    if (this.gridData) {
      this.setTabs(this.gridData, this.tabReducer.bind(this));
      this.sortTabs();
      this.initRankString();

      if (this.tabs && this.tabs.length) {
        this.initSelectedTab();

        this.filterGridData(this.selectedTab);
        if (this.gridDataFiltered && this.gridDataFiltered.length) {
          this.setDetailData();
        }
      }
      this.updatedAt = new Date(); // This will come from the server
    }
    this.isLoading = false;
  }

  protected tabReducer(result: VarietyGrowMethodTab[], obj: any): VarietyGrowMethodTab[] {
    if (obj.season === this.selectedSeason.value) {
      result.push({
        title: obj.variety + obj.grow_method,
        variety_code: obj.variety,
        grow_method_code: obj.grow_method,
        selected: false
      });
    }
    return result;
  }

  private initRankString() {
    this.gridData.forEach((item) => {
      item.rank_string = item.number_of_results_in_group ? 'of ' + item.number_of_results_in_group : null;
    });
  }

  private filterGridData(tab: VarietyGrowMethodTab) {
    this.gridDataFiltered = this.gridData.filter(obj => {
        return obj.variety === tab.variety_code &&
          obj.grow_method === tab.grow_method_code &&
          obj.season === this.selectedSeason.value;
      }
    );

    for (let i = 0; i < this.gridDataFiltered.length; i++) {
      const item = this.gridDataFiltered[i];
      this.initialOgrMessage = item['initial_ogr_message'];
      if (this.initialOgrMessage) {
        break;
      }
    }

    this.sortGridData();
  }

  private sortGridData() {
    this.gridDataFiltered.sort((a, b) => {
      return a.grower_number.localeCompare(b.grower_number);
    });
  }

  private setDetailsChartYAxis() {
    this.detailsChartYAxis = this.getDetailsChartYAxis();
  }

  private getDetailsChartYAxis() {
    switch (this.selectedColumnKey) {
      case 'ogr_per_tray': return { label: 'OGR / Tray', unit: '$' };
      case 'ogr_per_tray_rank': return { label: 'OGR / Tray', unit: '(Rank)' };
      case 'ogr_per_ha': return { label: 'OGR / Ha', unit: '$' };
      case 'ogr_per_ha_rank': return { label: 'OGR / Ha', unit: '(Rank)' };
      default: {}
    }
  }

  private setDetailData() {
    const data = [];
    this.gridData.forEach((item) => {
      if (this.selectedRows.indexOf(item.grower_number) >= 0 &&
          item.variety === this.selectedTab.variety_code &&
          item.grow_method === this.selectedTab.grow_method_code) {
        data.push({ season: item.season, grower_number: item.grower_number, value: item[this.selectedColumnKey] });
      }
    });

    this.detailData = {
      groups: this.groups,
      data: data
    };
  }

  private setDetailPrecision() {
    switch (this.selectedColumnKey) {
      case 'ogr_per_tray':
      case 'ogr_per_ha': this.detailPrecision = 2; break;
      case 'ogr_per_tray_rank':
      case 'ogr_per_ha_rank': this.detailPrecision = 0; break;
      default: this.detailPrecision = 0;
    }
  }
}
