import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { AuthService, User } from './auth.service';
import { UserOverrideComponent, UserOverride } from '../user-override/user-override.component';

export interface GTagData {
  user_id: number | string; // Note: user_id is a reserved parameter which can not be set as a custom dimension.
  userId: number | string;  // userId was added just in case the reserved user_id can not be used.
  user_name: string;
  product: string;
  page_title?: string;
  page_path?: string;
  page_location?: string;
  event_category?: string;
  event_label?: string;
  event_value?: string;
}

@Injectable({
  providedIn: 'root'
})
export class GaService {
  private product: string = null;
  private isInitialized = false;
  private analyticsEnabled = false;
  private useAnalytics = false;
  private user: User = null;
  private userName: string = null;

  constructor(
    private authService: AuthService
  ) {
    this.analyticsEnabled = !!environment.GOOGLE_ANALYTICS_TRACKING_CODE;
  }

  init() {
    if (this.analyticsEnabled && !this.isInitialized) {
      this.initGtag();
      this.injectGaIntoDom();
      this.isInitialized = true;
      this.useAnalytics = true;
    }
  }

  updateProduct(product: string) {
    this.product = product;
  }

  updateUseAnalytics(useWithoutUser = false) {
    if (this.isInitialized) {
      if (useWithoutUser) {
        this.user = null;
        this.userName = null;
        this.useAnalytics = this.analyticsEnabled;
      } else {
        const impersonatedUser = this.getImpersonatedUser();
        this.user = this.getUser();
        this.userName = this.getUserFullName(this.user);
        this.useAnalytics = this.analyticsEnabled && this.user && !impersonatedUser;
      }
    }
  }

  pageView(url: string) {
    if (this.useAnalytics) {
      url = this.cleanupUrl(url);
      let data = this.getCommonData();
      data = Object.assign(data, { page_path: url });
      this.gtag('event', 'page_view', data);
    }
  }

  event(category: string, action: string, label: string, value: any = null) {
    if (this.useAnalytics) {
      let data = this.getCommonData();
      data = Object.assign(data, { event_category: category, event_label: label });

      if (value) {
        data['event_value'] = typeof(value) === 'string' ? value : JSON.stringify(value);
      }

      this.gtag('event', action, data);
    }
  }

  exit() {
    this.pageView('/exit');
  }

  timingEvent(label: string, value: number, eventCategory = 'HTTP request') {
    if (this.useAnalytics) {
      let data = this.getCommonData();
      data = Object.assign(data, { event_category: eventCategory, event_label: label, event_value: value });
      this.gtag('event', 'timing_complete', data);
    }
  }

  private getCommonData(additionalData: any = null): GTagData {
    const userId = this.user ? this.user.id : null;
    return {
      user_id: userId,
      userId,
      user_name: this.userName,
      product: this.product,
      page_location: window.location.href,
      page_title: this.getPageTitle(),
    };
  }

  private getPageTitle(): string {
    return document.title.replace(/Grower Portal[ -]*/, '').trim();
  }

  private cleanupUrl(url: string): string {
    if (url.match(/^\/password_reset/)) {
      url = '/password_reset';
    }
    return url;
  }

  private injectGaIntoDom() {
    const script = window.document.createElement('script');
    script.setAttribute('async', '');
    script.src = 'https://www.googletagmanager.com/gtag/js?id=' + environment.GOOGLE_ANALYTICS_TRACKING_CODE;
    window.document.getElementsByTagName('head')[0].appendChild(script);
  }

  private initGtag() {
    window['dataLayer'] = window['dataLayer'] || [];
    this.gtag('js', new Date());
    this.gtag('config', environment.GOOGLE_ANALYTICS_TRACKING_CODE);
  }

  // NOTE: ...args added to circumvent typescript complaining about the number of arguments.
  // Since JS does not care about the number of parameters passed to a function using this is safe for browsers like IE.
  private gtag(...args) {
    window['dataLayer'].push(arguments);
  }

  private getUser(): User {
    return this.authService.getUser();
  }

  private getImpersonatedUser(): UserOverride {
    return UserOverrideComponent.getOverride();
  }

  private getUserFullName(user: User): string {
    return user ? [user.firstname, user.surname].join(' ') : null;
  }
}
