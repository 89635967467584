import {
  Component,
  Input,
  HostBinding,
  ElementRef,
  ChangeDetectionStrategy,
  ChangeDetectorRef
} from '@angular/core';

const TOOLTIP_TRANSITION_TIME = 0.3; // seconds
export const TOOLTIP_LINE_WIDTH = 3;

export interface TooltipData {
  width?: number;
  title?: string;
  content: string;
  footer?: string;
  left?: number;
  isVisible?: boolean;
  display?: 'block'|'none';
}

export interface TooltipPosition {
  top: number;
  left: number;
}

@Component({
  selector: 'app-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TooltipComponent {
  @Input()
  set hasOpacityTransition(value: boolean) {
    if (value) {
      this._transitions.push(`opacity ${ TOOLTIP_TRANSITION_TIME }s`);
      this.setTransition();
    }
  }

  @Input()
  set hasPositionTransition(value: boolean) {
    if (value) {
      this._transitions.push(`left ${ TOOLTIP_TRANSITION_TIME }s, top ${ TOOLTIP_TRANSITION_TIME }s`);
      this.setTransition();
    }
  }

  @Input()
  set visible(value: boolean) {
    this.isVisible = value;
    this.changeDetectorRef.markForCheck();
  }
  get visible() {
    return this.isVisible;
  }

  private _top = 0;
  @Input()
  set top(value: number) {
    this._top = value;
    this.styleTop = value ? this.getStyleTop(this._top) : null;
  }
  get top() {
    return this._top;
  }
  @HostBinding('style.top') styleTop = null;

  private _bottom = 0;
  @Input()
  set bottom(value: number) {
    this._bottom = value;
    this.styleBottom = value ? this.getStyleBottom(this._bottom) : null;
  }
  get bottom() {
    return this._bottom;
  }
  @HostBinding('style.bottom') styleBottom = null;

  private _width = 200;
  @Input()
  get width() {
    return this._width;
  }
  set width(value: number) {
    this._width = value;
    this.styleWidth = value ? this._width + 'px' : null;
  }
  @HostBinding('style.width') styleWidth = null;

  private _left = 0;
  @Input()
  set left(value: number) {
    this._left = value;
    this.styleLeft = value ? this.getStyleLeftAndPositionLine(this._left) : null;
  }
  get left() {
    return this._left;
  }
  @HostBinding('style.left') styleLeft = null;

  @Input() title = '';
  @Input() hasFooter = false;
  @Input() footer = '';
  @Input() hasArrow = true;
  @Input() direction = 'north';
  @Input() @HostBinding('class.has-line') hasLine = false;
  @Input() @HostBinding('style.display') display = 'block';
  @Input() limits = { left: 5, top: 0, right: 5, bottom: 0 };
  @Input() lineHeight: number;
  lineLeft: number;
  lineRight: number;
  lineWidth = TOOLTIP_LINE_WIDTH;
  halfLineWidth = TOOLTIP_LINE_WIDTH / 2;

  private _transitions: string[] = [];
  @HostBinding('style.transition') transition = null;
  @HostBinding('class.visible') isVisible = false;

  constructor(
    private element: ElementRef,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  private setTransition() {
    this.transition = this._transitions.join(', ');
  }

  private getStyleTop(value: number): string {
    if (value < this.limits.top) {
      value = this.limits.top;
    }
    return value + 'px';
  }

  private getStyleBottom(value: number): string {
    if (value < this.limits.bottom) {
      value = this.limits.bottom;
    }
    return value + 'px';
  }

  private getStyleLeftAndPositionLine(value: number): string {
    const clientWidth = document.documentElement.clientWidth;
    const offset = this.getLeftOffset();

    if (value + offset < this.limits.left) {
      // Line is switched to left side
      this.positionLine(0, null);
      value = this.hasLine ? value - this.lineWidth : this.limits.left;
    } else if (value + this.width + offset > clientWidth - this.limits.right) {
      // Line is switched to right side
      this.positionLine(null, 0);
      value = clientWidth - this.width - this.limits.right;
    } else {
      // Line is switched to right side
      this.positionLine(null, 0);
      value += offset;
    }
    return (this.hasLine ? value + this.halfLineWidth : value) + 'px';
  }

  private positionLine(left: number, right: number) {
    if (this.hasLine) {
      this.lineLeft = left;
      this.lineRight = right;
    }
  }

  private getLeftOffset() {
    let offset = 0;
    if (this.hasArrow || this.hasLine) {
      if (this.direction === 'north' || this.direction === 'south') {
        offset = -(this.width / 2);
      } else if (this.direction === 'east') {
        offset = -this.width;
      }
    }
    return offset;
  }
}
