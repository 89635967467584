<div class="breadcrumbs-panel">
  <app-breadcrumbs></app-breadcrumbs>
</div>

<section class="content">
  <ng-container *ngIf="outstandingAgreements.length || completedAgreements.length; else emptyAgreementsPanel">
    <div *ngIf="outstandingAgreements.length" class="panel to-sign">
      <h4>
        <i class="fas fa-exclamation-triangle"></i>
        Agreements to sign
      </h4>
      <div class="panel-content">
        <ng-container *ngFor="let agreement of outstandingAgreements">
          <ng-container *ngTemplateOutlet="agreementLink;context:{ agreement }"></ng-container>
        </ng-container>
      </div>
    </div>

    <div *ngIf="completedAgreements.length" class="panel">
      <h4>
        <i class="fas fa-check"></i>
        Completed agreements
      </h4>
      <div class="panel-content">
        <ng-container *ngFor="let agreement of completedAgreements">
          <ng-container *ngTemplateOutlet="agreementLink;context:{ agreement }"></ng-container>
        </ng-container>
      </div>
    </div>
  </ng-container>

  <ng-template #agreementLink let-agreement=agreement>
    <a [routerLink]="['./', agreement.uuid]"
       [state]="agreement"
       class="item-link"
       [class.hide-user-type]="agreement.signed_at">
      <div [class]="'user-type fas' + (agreement.is_user_signer ? ' fa-edit' : ' fa-eye')"></div>
      <div class="group">
        <div class="item strong">{{ agreement.name }}</div>
        <div class="item">
          {{ agreement.entity_name }}
        </div>
      </div>
      <div *ngIf="agreement.signed_at && agreement.signed_by" class="group meta">
        <div class="item signed-at">
          <i class="far fa-calendar-check"></i>
          {{ agreement.signed_at|date:dateFormat }}</div>
        <div class="item signed-by">
          <i class="fas fa-user-edit"></i>
          {{ agreement.signed_by }}
        </div>
      </div>
      <div class="fas fa-chevron-right link-indicator"></div>
    </a>
  </ng-template>

  <ng-template #emptyAgreementsPanel>
    <div class="no-data-message visible large light">
      <i class="fas fa-exclamation-triangle"></i>
      <div class="message">There are no agreements.</div>
    </div>
  </ng-template>
</section>
