import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatNumber'
})
export class FormatNumberPipe implements PipeTransform {
  // Set to placeholder if null(ish)
  // Format to specified precision
  transform(value: number, precision = 0, placeholder = ''): any {
    return value == null ? placeholder : value.toFixed(precision);
  }
}
