import { Injectable } from '@angular/core';

@Injectable()
export class SettingsService {
  public static HELP_TOGGLE_MESSAGE = 'help-toggle';
  public static CLEAR_SEARCH_MESSAGE = 'clear-search';
  public static SHOW_DOCUMENT_MESSAGE = 'show-document';
  public static LEGACY_CONTENT_LOAD_MESSAGE = 'legacy-content-load';
  public static LONG_DATE_TIME_FORMAT = 'EEE MMM Do h:mm a';
  public static SHORT_DATE_TIME_FORMAT = 'DD/MM/YYYY HH:mm';
  public static KENDO_SHORT_DATE_TIME_FORMAT = 'dd/MM/yyyy HH:mm';
  public static KENDO_DATE_TIME_FORMAT_NO_YEAR = 'dd MMM HH:mm';
  public static KENDO_DATE_FORMAT_NO_YEAR = 'dd MMM';
  public static PUBLIC_DATE_TIME_FORMAT = 'dd/MM/yyyy hh:mm a';
  public static LONG_DATE_FORMAT = 'EEE MMM Do';
  public static SHORT_DATE_FORMAT = 'DD/MM/YYYY';
  public static ANGULAR_SHORT_DATE_FORMAT = 'dd/MM/YYYY';
  public static ANGULAR_SHORT_DATE_TIME_FORMAT = 'dd/MM/YYYY HH:mm:ss';
  public static SHORT_WEEKDAY_DATE_FORMAT = 'EEE dd/MM/yy';
  public static KENDO_SHORT_DATE_FORMAT = 'dd/MM/yyyy';
  public static KENDO_SHORT_EXPANDED_DATE_FORMAT = 'dd-MMM-yyyy';
  public static SERVER_DATE_FORMAT = 'YYYY-MM-DD';
  public static SERVER_TIME_FORMAT = 'HH:mm:ss';
  public static SERVER_DATE_TIME_FORMAT = 'YYYY-MM-DDTHH:mm:ssZ';
  public static TIME_FORMAT = 'h:mm a';
  public static PACK_OUT_DATE_FORMAT = 'D-MMM-YY';
  public static MIN_PASSWORD_LENGTH = 8;
  public static CALENDAR_DATE_FORMAT = 'E MMM d';
  public static DEBOUNCE_TIME = 500;
  public static DEFAULT_CHART_TRANSITION_DURATION = 250;

  constructor() { }
}
