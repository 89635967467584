import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { HttpService } from 'app/shared/services/http.service';
import { WidgetHeaderButton } from 'app/widget/widget.component';
import {
  SampleInformationItem,
  SampleInformationWidget
} from 'app/sample-information-widget/sample-information-widget';
import { GaService } from 'app/shared/services/ga.service';

export const SEASONS_AND_ORCHARDS_ENDPOINT = 'growers/avocado/maturity_samples/seasons_and_orchards/';
export const DATA_ENDPOINT = 'growers/avocado/maturity_samples/summary/';
const CTP_REPORT_ENDPOINT = '/api2/v1/orchards/maturity_areas/reports/avocado/clearance_to_pick/';
const RESULTS_REPORT_ENDPOINT = '/api2/v1/orchards/maturity_samples/sample/{sampleNumber}/results/';

export interface AvocadoSampleInformationItem extends SampleInformationItem {
  dry_matter_fruit_above_208: number;
}

@Component({
  selector: 'grow-avocado-sample-information-widget',
  templateUrl: './avocado-sample-information-widget.component.html',
  styleUrls: ['./avocado-sample-information-widget.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AvocadoSampleInformationWidgetComponent extends SampleInformationWidget<AvocadoSampleInformationItem> implements OnInit {
  @Input() updatedAt: Date = new Date();
  @Input() emptyPlaceholder = 'NA';
  headerButtons: WidgetHeaderButton[] = [
    {
      text: 'Latest CTP',
      disabled: false,
      getUrl: () => {
        return this.getCtpReportUrl(CTP_REPORT_ENDPOINT);
      }
    }
  ];
  gridColumns = [
    { label: 'PPIN', align: 'left', key: 'grower_number', rowspan: 2 },
    { label: 'MA', align: 'left', key: 'maturity_area', rowspan: 2 },
    { label: 'Type', align: 'left', key: 'type', rowspan: 2 },
    { label: 'Sample #', align: 'left', key: 'sample_number', rowspan: 2, cssClass: 'nowrap' },
    { label: 'Collected', align: 'left', key: 'collect_date', rowspan: 2 },
    {
      label: 'Weight', align: 'center', childColumns: [
        { label: 'Avg', key: 'fresh_weight_average', cssClass: 'fresh-weight-average', precision: 1 }
      ]
    },
    {
      label: 'Dry Matter', align: 'center', childColumns: [
        { label: 'Avg', key: 'dry_matter_average', cssClass: 'dry-matter-average', precision: 1 },
        { label: 'Fruit > 20.8%', key: 'dry_matter_fruit_above_208', cssClass: 'dry-matter-fruit-above-208', precision: 1 }
      ]
    },
    {
      label: 'Status',
      align: 'left',
      cssClass: 'center-header',
      key: 'sample_status',
      subValue: 'clearance_date',
      badValue: 'Failed',
      goodValue: 'Cleared',
      rowspan: 2
    },
    {
      label: 'Sample Results',
      align: 'center',
      key: 'report',
      allowEmpty: true,
      cssClass: 'download-report fixed-very-narrow',
      action: (item) => {
        return this.onDownloadSampleResultsClick(RESULTS_REPORT_ENDPOINT, item);
      },
      rowspan: 2
    }
  ];

  constructor(
    protected http: HttpService,
    protected changeDetectorRef: ChangeDetectorRef,
    protected ga: GaService
  ) {
    super(http, changeDetectorRef, ga);
  }

  async ngOnInit() {
    await this.execWithLoading(() => {
      return this.init(DATA_ENDPOINT, SEASONS_AND_ORCHARDS_ENDPOINT);
    });
  }
}
