import { TutorialStep, AttachDirection } from 'app/shared/services/tutorial.service';

const INVENTORY_DETAILS_BUTTONS = [
  {
    classes: 'shepherd-button-primary',
    text: 'Previous',
    type: 'back'
  },
  {
    classes: 'shepherd-button-primary',
    text: 'Next',
    type: 'next'
  },
  {
    classes: 'shepherd-button-secondary',
    text: 'Cancel',
    action() {
      return this.cancel();
    }
  }
];

export function getInventoryDetailsSteps(tutorialService) {
  const steps: TutorialStep[] = [
    new TutorialStep ({
      id: 'intro',
      title: 'Inventory Details',
      text: 'The Inventory Details page provides a detailed view of your inventory and shows you how much of your fruit is in store, has been ordered, shipped or lost.',
      buttons: [
        {
          classes: 'shepherd-button-primary',
          text: 'Next',
          type: 'next'
        },
        {
          classes: 'shepherd-button-secondary',
          text: 'I\'m Not Interested',
          action() {
            return this.cancel();
          }
        }
      ]
    }),
    // Variety Tabs
    new TutorialStep ({
      id: 'variety-tabs',
      title: 'Variety Tabs',
      text: 'If you grow multiple varieties, you can switch between them with the Variety and Grow Method tabs here.',
      attachTo: {
        element: '#k-tabstrip-tab-0',
        on: AttachDirection.right
      },
      buttons: INVENTORY_DETAILS_BUTTONS
    }),
    // Season Selector
    new TutorialStep ({
      id: 'season-selector',
      title: 'Season Selection',
      text: 'Use this Season dropdown to change the current season.',
      attachTo: {
        element: 'dropdown[handle=inventoryDetailsSeasonDropdown]',
        on: AttachDirection.bottom
      },
      buttons: INVENTORY_DETAILS_BUTTONS
    }),
    // Week Selector
    new TutorialStep ({
      id: 'week-selector',
      title: 'Week Selection',
      text: 'Use the week dropdown to change the week you want to view. This affects all data shown on this page.',
      attachTo: {
        element: 'dropdown[handle=inventoryDetailsWeekDropdown]',
        on: AttachDirection.bottom
      },
      buttons: INVENTORY_DETAILS_BUTTONS
    }),
    // Expand KPIN to show MAs
    new TutorialStep ({
      id: 'table-kpins',
      title: 'KPINs and Maturity Areas',
      text: 'Click on the arrow next to a KPIN to view the inventory data by Maturity Area',
      attachTo: {
        element: 'app-simple-grid .level-0 .fixed-narrow',
        on: AttachDirection.right
      },
      buttons: INVENTORY_DETAILS_BUTTONS
    }),
    // Inventory Status
    new TutorialStep ({
      id: 'inventory-status',
      title: 'Inventory Status',
      text: 'The Inventory Status section provides a breakdown of trays by size that are in store, ordered, shipped or lost.',
      beforeShowPromise: () => {
        return new Promise<void>((resolve) => {
          tutorialService.clickElement('inventory-status');
          resolve();
        });
      },
      attachTo: {
        element: '.data-category-content',
        on: AttachDirection.left
      },
      buttons: INVENTORY_DETAILS_BUTTONS
    }),
    // Status Selector
      new TutorialStep ({
        id: 'inventory-status-selector',
        title: 'Inventory Status: Comparisons',
        text: 'Use the dropdown to change the comparison to trays in store, ordered, shipped or lost.',
        attachTo: {
          element: 'app-simple-grid thead dropdown',
          on: AttachDirection.left
        },
        buttons: INVENTORY_DETAILS_BUTTONS
      }),
    // Shipped
    new TutorialStep ({
      id: 'shipped',
      title: 'Shipped',
      text: 'The Shipped section shows the cumulative percentage of trays shipped each week.',
      beforeShowPromise: () => {
        return new Promise<void>((resolve) => {
          tutorialService.clickElement('shipped');
          resolve();
        });
      },
      attachTo: {
        element: '.data-category-content',
        on: AttachDirection.left
      },
      buttons: INVENTORY_DETAILS_BUTTONS
    }),
    // Click column headers
    new TutorialStep ({
      id: 'shipped-column-headers',
      title: 'Shipped: Week Heading',
      text: 'Click on one of these week headings to plot that week’s data on the graph below.',
      attachTo: {
        element: 'app-simple-grid thead tr:last-child th.week-column:nth-child(11)',
        on: AttachDirection.left
      },
      buttons: INVENTORY_DETAILS_BUTTONS
    }),
    // Week navigator
    new TutorialStep ({
      id: 'shipped-week-navigator',
      title: 'Shipped: Week Range',
      text: 'Use these arrow buttons to scroll through the range of weeks shown.',
      attachTo: {
        element: 'app-simple-grid .pager',
        on: AttachDirection.left
      },
      buttons: INVENTORY_DETAILS_BUTTONS
    }),
    // Grower Compensation
    new TutorialStep ({
      id: 'grower-compensation',
      title: 'Grower Compensation',
      text: 'The Grower Compensation section shows the trays lost and the combined value of trays shipped and ordered for each ISO week.',
      beforeShowPromise: () => {
        return new Promise<void>((resolve) => {
          tutorialService.clickElement('grower-compensation');
          resolve();
        });
      },
      attachTo: {
        element: '.data-category-content',
        on: AttachDirection.left
      },
      buttons: INVENTORY_DETAILS_BUTTONS
    }),
    // Click column headers
    new TutorialStep ({
      id: 'grower-compensation-column-headers',
      title: 'Grower Compensation: Week Heading',
      text: 'Click on one of the week headings to plot that week’s data on the graph below.',
      attachTo: {
        element: 'app-simple-grid thead tr:last-child th.week-column:nth-child(11)',
        on: AttachDirection.left
      },
      buttons: INVENTORY_DETAILS_BUTTONS
    }),
    // Week Navigator
    new TutorialStep ({
      id: 'grower-compensation-week-navigator',
      title: 'Grower Compensation: Week Range',
      text: 'Use these arrow buttons to scroll through the range of weeks shown.',
      attachTo: {
        element: 'app-simple-grid .pager',
        on: AttachDirection.left
      },
      buttons: INVENTORY_DETAILS_BUTTONS
    }),
    // Expand graph button
    new TutorialStep ({
      id: 'expand-graph-button',
      title: 'Graphs: Expansion',
      text: 'Click these buttons in the top right corner of the graphs to view the graph in full screen.',
      attachTo: {
        element: 'app-inventory-details .index-chart-column button',
        on: AttachDirection.top
      },
      buttons: INVENTORY_DETAILS_BUTTONS
    }),
    // Graph Options/Hovers
    new TutorialStep ({
      id: 'graph-legend',
      title: 'Graphs: Legend',
      text: 'Hover your mouse over the items in the legend below to highlight the corresponding item in the graph.',
      attachTo: {
        element: '.index-chart-column .legend',
        on: AttachDirection.top
      },
      scrollTo: true,
      buttons: INVENTORY_DETAILS_BUTTONS
    }),
    new TutorialStep ({
      id: 'graph-plot-value-switch',
      title: 'Graphs: Y Axis',
      text: 'Use the switch below to swap between showing total packed trays and the percentage of trays packed.',
      attachTo: {
        element: '.index-chart-column .plot-value-switch',
        on: AttachDirection.top
      },
      scrollTo: true,
      buttons: INVENTORY_DETAILS_BUTTONS
    }),
    new TutorialStep ({
      id: 'graph-group-switch',
      title: 'Graphs: X Axis',
      text: 'Use the switch to swap the grouping between maturity areas in the season and a breakdown of maturity areas in store, ordered, shipped or lost.',
      attachTo: {
        element: '.index-chart-column .group-switch',
        on: AttachDirection.top
      },
      scrollTo: true,
      buttons: INVENTORY_DETAILS_BUTTONS
    }),
    // Inventory Report button
    new TutorialStep ({
      id: 'report-button',
      title: 'Downloading Reports',
      text: 'Click the Inventory Report button to generate a report based on the currently selected Variety/Grow Method and KPIN in PDF format.',
      attachTo: {
        element: '.download-report-button-group',
        on: AttachDirection.bottom
      },
      scrollTo: true,
      buttons: [
        {
          classes: 'shepherd-button-primary',
          text: 'End Tutorial',
          action() {
            return this.cancel();
          }
        }
      ]
    })
  ];
  return steps;
}
