<div class="progress-panel" [class.spinny-mode]="isSpinnyMode">
  <svg [ngStyle]="{ width: size + 'px', height: size + 'px' }">
    <circle class="progress-track"
      [attr.r]="adjustedRadius"
      [attr.stroke-width]="thickness"
      [attr.cx]="radius"
      [attr.cy]="radius">
    </circle>
    <circle class="progress-bar"
      [attr.r]="adjustedRadius"
      [attr.stroke-width]="thickness+1"
      [attr.cx]="radius"
      [attr.cy]="radius"
      [attr.stroke-dasharray]="circumference"
      [attr.stroke-dashoffset]="progressBarValue">
    </circle>
  </svg>
  <div class="text-panel" [class.show-percent]="showPercent">
    <div *ngIf="message?.length > 0" class="message" [style.padding]="thickness + 'px'">{{ message }}</div>
    <div *ngIf="showPercent" class="percent">{{ formattedPercent }}%</div>
  </div>
</div>
<div *ngIf="externalMessage" class="external-message">{{ externalMessage }}</div>
<div *ngIf="links" class="links">
  <a *ngFor="let link of links" class="button" [href]="link.url">{{ link.label }}</a>
</div>
<div class="overlay"></div>
