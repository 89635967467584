<app-widget [updatedAt]="updatedAt"
            [dropdownItems]="availableSeasons"
            [selectedDropdownItem]="selectedSeason"
            [onDropdownItemChange]="selectSeason.bind(this)"
            [isLoading]="isLoading"
            [hasNoData]="!tabs?.length"
            [headerButtons]="headerButtons"
            loadingMessage="Loading OGR & inventory data..."
            noDataMessage="No OGR or inventory data available."
            dropdownHandle="ogrInventoryWidgetSeasonDropdown">
  <kendo-tabstrip *ngIf="tabs?.length" class="tabstrip"
                  (tabSelect)="onTabSelect($event)">
    <kendo-tabstrip-tab *ngFor="let tab of tabs"
                        [title]="tab.title"
                        [selected]="tab.selected">
        <ng-template kendoTabContent>
          <app-simple-grid [selectAll]="true"
                           [columns]="gridColumns"
                           [data]="gridDataFiltered"
                           [selectedColumnKey]="selectedColumnKey"
                           [rowSelectType]="'multi'"
                           [rowSelectCallback]="onGridRowSelect.bind(this)"
                           [hasColumnSelect]="!initialOgrMessage"
                           [columnSelectCallback]="onGridColumnSelect.bind(this)">
          </app-simple-grid>
        </ng-template>
    </kendo-tabstrip-tab>
  </kendo-tabstrip>

  <div class="details" [class.visible]="areDetailsVisible">
    <app-grouped-bar-chart *ngIf="!initialOgrMessage; else initialOgrMessageTemplate"
                           [groups]="detailData.groups"
                           [data]="detailData.data"
                           [yAxis]="detailsChartYAxis"
                           [precision]="detailPrecision"
                           [minValue]="0">
    </app-grouped-bar-chart>

    <ng-template #initialOgrMessageTemplate>
      <div class="no-data-message visible">
        <i class="fas fa-exclamation-triangle"></i>
        <div class="message">{{ initialOgrMessage }}</div>
      </div>
    </ng-template>
  </div>
</app-widget>
