import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostBinding, Input, OnInit } from '@angular/core';
import { AxisSettings, ChartMargin } from 'app/interfaces/chart.interface';
import { BarChartDataItem, BarChartGroup } from '../grouped-bar-chart/grouped-bar-chart.component';
import {
  LineChartDataItem,
  TickPadding,
  TooltipConfig
} from '../line-chart/line-chart.component';
import { ResizeService } from 'app/shared/services/resize.service';
import { Subject } from 'rxjs';
import { SettingsService } from 'app/shared/services/settings.service';

export interface MultiChartData {
  line?: LineChartDataItem[];
  bar?: {
    data: BarChartDataItem[],
    groups: BarChartGroup[]
  };
}

@Component({
  selector: 'app-multi-chart',
  templateUrl: './multi-chart.component.html',
  styleUrls: ['./multi-chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MultiChartComponent implements OnInit {
  @Input() barGroups: BarChartGroup[] = [];
  @Input() legendLocation: 'top'|'right'|'bottom'|'left' = 'bottom';
  @Input() showAverage = false;
  @Input() showXGrid = true;
  @Input() showYGrid = true;
  @Input() minYValue = 0;
  @Input() showBarValue = false;
  @Input() hasTooltip = true;
  @Input() isYValueAPercentage = false;
  @Input() tickPadding: TickPadding;
  @Input() tooltipConfig: TooltipConfig;
  @Input() axisSettings: AxisSettings;
  @Input() tooltipX: Subject<string>;
  @Input() forceUpdate: Subject<any>;
  @Input() transitionDuration = SettingsService.DEFAULT_CHART_TRANSITION_DURATION;
  @Input() smallestMaxY = Number.MIN_VALUE; // This value is used when actual max Y value is 0. It controls the Y Axis domain
  @Input() @HostBinding('class.extended-domain') isExtendedDomain = false;

  private _data: MultiChartData = {};
  @Input()
  set data(data: MultiChartData) {
    this._data = data;
    this.setMaxY();
    this.addTooltipData();
    this.setLegendItems();
  }
  get data(): MultiChartData {
    return this._data;
  }

  private _margin: ChartMargin = { top: 15, right: 50, bottom: 30, left: 35 };
  @Input()
  set margin(data: ChartMargin) {
    this._margin = data;
    this.updateBarChartMargin();
  }
  get margin(): ChartMargin {
    return this._margin;
  }

  barLeftOffset: number;
  barChartGroupWidth: number;
  barChartMargin: ChartMargin = this._margin;
  maxYValue = 100;
  isHidden = false;
  legendItems: any[];
  selectedLegendItem: any;

  constructor(
    public resize: ResizeService,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.maxYValue = 100;
    this.resize.addOnStartHandler(this.resizeStarted.bind(this));
    this.resize.addOnEndHandler(this.resizeEnded.bind(this));
  }

  onLineChartXStepChange(value: number) {
    this.barChartGroupWidth = value;
    this.barLeftOffset = -value / 2;
  }

  onLegendItemMouseOver(legendItem) {
    this.selectedLegendItem = legendItem;
  }

  onLegendItemMouseOut() {
    this.selectedLegendItem = null;
  }

  private resizeStarted() {
    this.isHidden = true;
    this.changeDetectorRef.markForCheck();
  }

  private resizeEnded() {
    this.isHidden = false;
    this.changeDetectorRef.markForCheck();
  }

  private updateBarChartMargin() {
    Object.assign(this.barChartMargin, this._margin);

    // Adjust for borders to properly align with line chart
    this.barChartMargin.bottom -= 1;
    this.barChartMargin.left += 2;
  }

  private setMaxY() {
    if (this.data) {
      const maxY = this.getMaxYBar(0);
      this.maxYValue = this.getMaxYLine(maxY) || this.smallestMaxY;
    }
  }

  private getMaxYBar(maxY): number {
    if (this.data.bar && this.data.bar.data) {
      this.data.bar.data.forEach((item) => {
        if (item.value > maxY) {
          maxY = item.value;
        }
      });
    }
    return maxY;
  }

  private getMaxYLine(maxY): number {
    if (this.data.line) {
      this.data.line.forEach((line) => {
        line.values.forEach((value) => {
          if (value.y > maxY) {
            maxY = value.y;
          }
        });
      });
    }
    return maxY;
  }

  private setLegendItems() {
    const legendItemLookup = new Map();

    if (this.data && this.data.bar && this.data.bar.groups && this.data.bar.groups.length && this.data.bar.data) {
      const secondaryKey = this.data.bar.groups[0].secondary;
      this.data.bar.data.forEach((item) => {
        const secondaryValue = item[secondaryKey];
        if (!legendItemLookup.has(secondaryValue)) {
          legendItemLookup.set(secondaryValue, item);
        }
      });
    }

    if (this.data && this.data.line) {
      this.data.line.forEach((item) => {
        if (!legendItemLookup.has(item.label)) {
          legendItemLookup.set(item.label, item);
        }
      });
    }

    const result = [];
    let index = 0;
    for (const key of legendItemLookup.keys()) {
      const item = legendItemLookup.get(key);
      if (!item.onlyTooltip) {
        const cssClass = item.cssClass || `item-${index}`;
        result.push({ label: key, cssClass: cssClass, index: item.index || index });
        index++;
      }
    }
    this.legendItems = result;
  }

  private addTooltipData() {
    if (this.data && this.data.bar && this.data.bar.groups && this.data.bar.groups.length && this.data.bar.data) {
      const lookup = new Map();
      const primaryKey = this.data.bar.groups[0].primary;
      const secondaryKey = this.data.bar.groups[0].secondary;
      this.data.bar.data.forEach((item: BarChartDataItem) => {
        const secondaryValue = item[secondaryKey];
        if (lookup.has(secondaryValue)) {
          lookup.get(secondaryValue).push(this.getTooltipItem(item, primaryKey));
        } else {
          lookup.set(secondaryValue, [this.getTooltipItem(item, primaryKey)]);
        }
      });

      if (!this.data.line) {
        this.data.line = [];
      }

      let index = 0;
      for (const key of lookup.keys()) {
        const tooltipOnlyDataItem: LineChartDataItem = {
          label: key,
          cssClass: 'item-' + index,
          onlyTooltip: true,
          tooltipPosition: 'content',
          values: lookup.get(key)
        };
        this.data.line.push(tooltipOnlyDataItem);
        index++;
      }
    }
  }

  private getTooltipItem(item: BarChartDataItem, primaryKey: string): { [index: string]: number|string } {
    return Object.assign({}, item, { x: item[primaryKey], y: item.value });
  }
}
