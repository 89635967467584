import { Component, Input } from '@angular/core';
import { HelpService } from '../services/help.service';
import { SettingsService } from '../services/settings.service';
import { MessageService } from '../services/message.service';
import { Document } from '../document-viewer/document-viewer.component';
import { GaService } from '../services/ga.service';

@Component({
    selector: 'app-help-button',
    template: `
    <button *ngIf="helpService.helpEnabled"
            class="help"
            (click)="showHelpText(helpKey)">
      <i class="fas fa-question-circle"></i>
    </button>
    `,
    styleUrls: ['./help-button.component.scss']
})

export class HelpButtonComponent {

  @Input() helpKey: string;

  constructor(
    public helpService: HelpService,
    private messageService: MessageService,
    private ga: GaService
  ) {}

  public async showHelpText(helpKey: string) {
    this.ga.event('help button', 'show', helpKey);
    const document: Document = await this.helpService.get(helpKey);
    this.messageService.send(SettingsService.SHOW_DOCUMENT_MESSAGE, document);
  }
}
