import { Component, Input, OnDestroy } from '@angular/core';
import { HelpService } from 'app/shared/services/help.service';
import { TutorialService } from 'app/shared/services/tutorial.service';
import { Document } from 'app/shared/document-viewer/document-viewer.component';
import { HttpService } from 'app/shared/services/http.service';
import { MessageService } from 'app/shared/services/message.service';
import { SettingsService } from 'app/shared/services/settings.service';
import { Subscription } from 'rxjs';
import { GaService } from 'app/shared/services/ga.service';

const TERMS_OF_USE_PATH = '/assets-shared/policies/trevelyans-terms-of-use.html';
const PRIVACY_POLICY_PATH = '/assets-shared/policies/trevelyans-privacy-policy.html';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnDestroy {
  @Input() displayHelpToggle: boolean;
  @Input() displayTutorialButton: boolean;
  @Input() displayFeedbackButton: boolean;

  currentYear = (new Date()).getFullYear();
  termsOfUse: string;
  privacyPolicy: string;
  isFooterSelected = false;
  private readonly getTermsOfUseSubscription: Subscription;
  private readonly getPrivacyPolicySubscription: Subscription;

  constructor(
    private http: HttpService,
    private helpService: HelpService,
    private tutorialService: TutorialService,
    private messageService: MessageService,
    private ga: GaService
  ) {
    const requestOptions = { responseType: 'text', isUnauthenticated: true, noPrefix: true };
    this.getTermsOfUseSubscription = this.http.get(TERMS_OF_USE_PATH, requestOptions).subscribe((data: string) => {
      this.termsOfUse = data;
    });
    this.getPrivacyPolicySubscription = this.http.get(PRIVACY_POLICY_PATH, requestOptions).subscribe((data: string) => {
      this.privacyPolicy = data;
    });
  }

  ngOnDestroy() {
    this.getTermsOfUseSubscription?.unsubscribe();
    this.getPrivacyPolicySubscription?.unsubscribe();
  }

  toggleHelp() {
    this.helpService.toggle();
  }

  showTermsOfUse() {
    this.ga.event('button', 'click', 'show terms of use');
    const document: Document = {
      title: 'Terms of Use',
      content: this.termsOfUse,
      isAdaptive: true
    };
    this.messageService.send(SettingsService.SHOW_DOCUMENT_MESSAGE, document);
    return false;
  }

  showPrivacyPolicy() {
    this.ga.event('button', 'click', 'show privacy policy');
    const document: Document = {
      title: 'Privacy Policy',
      content: this.privacyPolicy,
      isAdaptive: true
    };
    this.messageService.send(SettingsService.SHOW_DOCUMENT_MESSAGE, document);
    return false;
  }

  showTutorial() {
    this.ga.event('button', 'click', 'show tutorial');
    this.tutorialService.showTutorial();
  }

  showFeedbackForm() {
    this.ga.event('button', 'click', 'show feedback form');
    const document: Document = {
      title: 'Your Feedback',
      content: 'feedback',
      showSurvey: false,
      showSaveUser: false,
      sendFeedbackNow: true,
      isAdaptive: true
    };
    this.messageService.send(SettingsService.SHOW_DOCUMENT_MESSAGE, document);
    return false;
  }

  expandFooter() {
    this.isFooterSelected = true;
  }

  collapseFooter() {
    this.isFooterSelected = false;
  }
}
