<div class="chart-pages">
  <div class="chart-page main-page">
    <app-grouped-bar-chart class="grouped-bar-chart"
                           [groups]="groups"
                           [data]="baseSeasonData"
                           [minValue]="minValue"
                           [minValueIfAllValuesArePositive]="0"
                           [maxValue]="maxValue"
                           [yAxis]="yAxis"
                           [precision]="precision"
                           [numGridLines]="numGridLines"
                           [type]="type"
                           legendLocation="bottom"
                           [showAverage]="showAverage"
                           [groupClickCallback]="showHistoryPage.bind(this)"></app-grouped-bar-chart>
  </div>
  <div #historyPage class="chart-page history-page">
    <header>
      <button class="button transparent tiny rounded bordered back-button" (click)="back()">
        <i class="fas fa-chevron-left"></i>
        Back
      </button>
      <h4>
        <span class="level">{{ levelName + ':' }}</span>
        <span class="title">{{ title }}</span>
      </h4>
    </header>
    <app-grouped-bar-chart class="grouped-bar-chart"
                           [groups]="historyGroups"
                           [data]="historyData"
                           [minValue]="minValue"
                           [minValueIfAllValuesArePositive]="minValueIfAllValuesArePositive"
                           [maxValue]="maxValue"
                           [yAxis]="yAxis"
                           [precision]="precision"
                           [numGridLines]="numGridLines"
                           type="side-by-side"
                           legendLocation="bottom"
                           [showAverage]="showHistoryAverage"></app-grouped-bar-chart>
  </div>
</div>