import { TutorialStep, AttachDirection } from 'app/shared/services/tutorial.service';

class OgrInventoryWidgetStep extends TutorialStep {
  constructor(step) {
    super(step);
    this.id = 'ogr-inventory-widget';
    this.title = 'OGR & Inventory Widget';
    this.arrow = true;
  }
}

export function getOgrInventoryWidgetSteps(tutorialService): OgrInventoryWidgetStep[] {
  return [
    new OgrInventoryWidgetStep ({
      id: 'ogr-inventory-widget',
      beforeShowPromise: () => {
        return new Promise<void>((resolve) => {
          tutorialService.clickElement('ogr-inventory');
          resolve();
        });
      },
      attachTo: {
        element: 'div:nth-child(2) > app-tabbed-content > div.content',
        on: AttachDirection.top
      },
      text: 'The OGR & Inventory widget provides information on your OGR and the status of your fruit during harvest.'
    }),
    new OgrInventoryWidgetStep ({
      attachTo: {
        element: 'app-ogr-inventory-widget > app-widget > div > div > div > kendo-tabstrip > ul',
        on: AttachDirection.top
      },
      text: 'Use the tabs here to switch between available Variety/Grow Method combinations.'
    }),
    new OgrInventoryWidgetStep ({
      attachTo: {
        element: 'app-ogr-inventory-widget app-simple-grid > table > thead > tr:nth-child(1) > th.center.row-select.ng-star-inserted',
        on: AttachDirection.right
      },
      text: 'If you are associated with multiple KPINs, use the check boxes to the left to select which ones to display in the graph below.'
    }),
    new OgrInventoryWidgetStep ({
      id: 'ogr-inventory-widget',
      attachTo: {
        element: 'app-ogr-inventory-widget app-simple-grid > table > thead > tr:nth-child(1) > th.center.even.ogr_per_tray.ng-star-inserted',
        on: AttachDirection.bottom
      },
      text: 'Under OGR/Tray you can see your current OGR per tray and orchards ranking among other Trevelyan’s orchards.'
    }),
    new OgrInventoryWidgetStep ({
      beforeShowPromise: () => {
        return new Promise<void>((resolve) => {
          tutorialService.clickElement('ogr-inventory');
          resolve();
        });
      },
      attachTo: {
        element: 'app-ogr-inventory-widget app-simple-grid > table > thead > tr:nth-child(1) > th.center.odd.ogr_per_ha.ng-star-inserted',
        on: AttachDirection.bottom
      },
      text: 'Under OGR/Ha you can see your current OGR per hectare and how that ranks among other Trevelyan’s orchards.'
    })
  ];
}
