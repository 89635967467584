<window-container
  *ngIf="document"
  class="window-container"
  [class.limited-width]="document.limitWidth !== false"
  [class.adaptive]="document.isAdaptive"
  [isVisible]="true"
  [draggable]="false"
  [resizable]="false"
  [minWindowHeight]="minWindowHeight"
  [windowTitle]="document.title"
  [closeWindow]="closeWindow.bind(this)">
  <div *ngIf="document.content !== 'feedback'" class="content-panel" [innerHTML]="document.content"></div>
  <div *ngIf="document.content === 'feedback'" class="content-panel">
    <feedback-form
      [messageType]="document.messageType"
      [topMessage]="document.topMessage"
      [thankYouMessage]="document.thankYouMessage"
      [additionalData]="document.additionalData"
      [showSurvey]="document.showSurvey"
      [sendFeedbackNow]="document.sendFeedbackNow"
      [showSaveUser]="document.showSaveUser">
    </feedback-form>
  </div>
</window-container>
<div class="overlay" (click)="outsideClick()"></div>
