<div class="logo"></div>
<div class="overlay"></div>
<div class="content" [class.busy]="isBusy">

  <div class="done-message-panel" [class.visible]="isPasswordResetSuccess">
    <i class="large-icon fas fa-check"></i>
    <div class="panel-content">
      <p>Your password was successfully changed.</p>
    </div>
    <button #backToLoginPageButton class="confirmation-button button ok" (click)="showLoginForm()">
      <i class="fas fa-chevron-left"></i>
      Return to the Login Form
    </button>
  </div>

  <div class="error-panel" [class.visible]="isError">
    <i class="large-icon fas fa-exclamation-triangle error"></i>
    <div class="panel-content">
      <p>{{ errorMessage }}</p>
      <p>Please contact <strong>{{ loginHelpName }}</strong> for further assistance.</p>
    </div>
    <button #tryAgainButton class="confirmation-button button" (click)="resetForm()">
      <i class="fas fa-chevron-left"></i>
      Click here to try again
    </button>
  </div>

  <h2>Password Reset</h2>

  <div class="password-reset-content">
    <p class="top-message">
      Enter a new <strong>password</strong> and matching <strong>password confirmation</strong>:
    </p>
    <div class="password-requirements" [class.error]="isPasswordError">
      Your password must be at least <strong>{{this.minPasswordLength}}</strong> characters long and contain <strong>lowercase</strong> and <strong>UPPERCASE</strong> letters and at least <strong>1 number</strong>.
    </div>
    <div class="input-panel">
      <div class="input-row password-input-row" [class.error]="isPasswordError">
        <input #passwordInputField
               type="password"
               autocomplete="new-password"
               autofocus
               class="password"
               placeholder="Password"
               [(ngModel)]="password"
               (keyup)="returnKeySetPassword($event)">
        <i class="icon fas fa-lock"></i>
        <i class="clear fas fa-times-circle" [class.visible]="isPasswordClearVisible" (click)="clearPassword()"></i>
      </div>

      <div class="input-row password-confirmation-input-row" [class.error]="isPasswordConfirmationError">
        <input #passwordConfirmationInputField
               type="password"
               autocomplete="new-password"
               class="password-confirmation"
               placeholder="Confirm Password"
               [(ngModel)]="passwordConfirmation"
               (keyup)="returnKeySetPassword($event)">
        <i class="icon fas fa-lock"></i>
        <i class="clear fas fa-times-circle" [class.visible]="isPasswordConfirmationClearVisible" (click)="clearPasswordConfirmation()"></i>
        <div class="password-missmatch-message" [class.visible]="isPasswordConfirmationError">Confirmation does not match password!</div>
      </div>
    </div>
    <button class="button set-password" (click)="setPassword()">
      Set new password
    </button>
  </div>
</div>
