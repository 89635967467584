import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { SettingsService } from 'app/shared/services/settings.service';
import { DropdownItem } from 'app/shared/dropdown/dropdown.component';
import { Router } from '@angular/router';
import { isFunction } from 'app/shared/services/utils.service';
import { GaService } from 'app/shared/services/ga.service';

export interface WidgetHeaderButton {
  text: string;
  isDesktopOnly?: boolean;
  disabled?: boolean;
  cssClass?: string;

  // Two methods of routing, provide an Angular routerLink, or a function that returns the url to navigate to
  routerLink?: string;
  getQueryParams?: () => Object;

  getUrl?: () => string;
  existingWindow?: boolean;
}

@Component({
  selector: 'app-widget',
  templateUrl: './widget.component.html',
  styleUrls: ['./widget.component.scss']
})
export class WidgetComponent implements OnInit {
  @Input() displayCurrentTimestamp = true;
  @Input() updatedAt: Date = null;
  @Input() isLoading = false;
  @Input() hideHeader = false;
  @Input() loadingMessage = 'Loading data...';
  @Input() noDataMessage = 'No data available.';
  @Input() hideScrollPane = false;

  private _headerButtons: WidgetHeaderButton[] = [];
  @Input()
  get headerButtons(): WidgetHeaderButton[] {
    return this._headerButtons;
  }
  set headerButtons(value: WidgetHeaderButton[]) {
    this._headerButtons = value;
    this.setAreHeaderButtonsEmpty();
    this.setAreAllHeaderButtonsDesktopOnly();
  }

  private _dropdownItems: DropdownItem[];
  @Input()
  get dropdownItems(): DropdownItem[] {
    return this._dropdownItems;
  }
  set dropdownItems(value: DropdownItem[]) {
    this._dropdownItems = value;
    this.setAreHeaderDropdownsEmpty();
  }
  @Input() dropdownItemLabel = 'Season';
  @Input() selectedDropdownItem: DropdownItem;
  @Input() onDropdownItemChange: (item: DropdownItem) => any;
  @Input() dropdownHandle: string;

  private _secondDropdownItems: DropdownItem[];
  @Input()
  get secondDropdownItems(): DropdownItem[] {
    return this._secondDropdownItems;
  }
  set secondDropdownItems(value: DropdownItem[]) {
    this._secondDropdownItems = value;
    this.setAreHeaderDropdownsEmpty();
  }
  @Input() secondDropdownItemLabel = '';
  @Input() selectedSecondDropdownItem: DropdownItem;
  @Input() onSecondDropdownItemChange: (item: DropdownItem) => any;
  @Input() secondDropdownHandle: string;

  private _thirdDropdownItems: DropdownItem[];
  @Input()
  get thirdDropdownItems(): DropdownItem[] {
    return this._thirdDropdownItems;
  }
  set thirdDropdownItems(value: DropdownItem[]) {
    this._thirdDropdownItems = value;
    this.setAreHeaderDropdownsEmpty();
  }
  @Input() thirdDropdownItemLabel = '';
  @Input() selectedThirdDropdownItem: DropdownItem;
  @Input() onThirdDropdownItemChange: (item: DropdownItem) => any;
  @Input() thirdDropdownHandle: string;

  @Input() @HostBinding('class.has-no-data') hasNoData = false;

  dateFormat = SettingsService.PUBLIC_DATE_TIME_FORMAT;
  areHeaderButtonsEmpty = false;
  areHeaderDropdownsEmpty = false;
  areAllHeaderButtonsDesktopOnly = false;

  constructor(
    private router: Router,
    private ga: GaService
  ) {}

  ngOnInit() {
    this.initHeader();
  }

  selectDropdownItem(item: DropdownItem) {
    if (!this.selectedDropdownItem || (this.selectedDropdownItem && this.selectedDropdownItem.label !== item.label)) {
      this.ga.event('dropdown', 'change', item.label, item.value);
      this.selectedDropdownItem = item;
      if (typeof(this.onDropdownItemChange) === 'function') {
        this.onDropdownItemChange(this.selectedDropdownItem);
      }
    }
  }

  selectSecondDropdownItem(item: DropdownItem) {
    if (!this.selectedSecondDropdownItem || (this.selectedSecondDropdownItem && this.selectedSecondDropdownItem.label !== item.label)) {
      this.ga.event('dropdown', 'change', item.label, item.value);
      this.selectedSecondDropdownItem = item;
      if (typeof(this.onSecondDropdownItemChange) === 'function') {
        this.onSecondDropdownItemChange(this.selectedSecondDropdownItem);
      }
    }
  }

  selectThirdDropdownItem(item: DropdownItem) {
    if (!this.selectedThirdDropdownItem || (this.selectedThirdDropdownItem && this.selectedThirdDropdownItem.label !== item.label)) {
      this.ga.event('dropdown', 'change', item.label, item.value);
      this.selectedThirdDropdownItem = item;
      if (typeof(this.onThirdDropdownItemChange) === 'function') {
        this.onThirdDropdownItemChange(this.selectedThirdDropdownItem);
      }
    }
  }

  headerButtonClick(button: WidgetHeaderButton) {
    if (button.routerLink) {
      let queryParams = {};
      if (isFunction(button.getQueryParams)) {
        queryParams = button.getQueryParams();
      }
      this.router.navigate([button.routerLink], { queryParams: queryParams });
    } else if (isFunction(button.getUrl)) {
      const url = button.getUrl();
      if (url) {
        this.ga.event('button', 'click', button.text, url);
        if (button.existingWindow) {
          window.location.href = url;
        } else {
          window.open(url, '_blank');
        }
      }
    }
  }

  exportFile(type: string) {
    // TODO: implement
  }

  private initHeader() {
    this.setAreHeaderDropdownsEmpty();
    this.setAreHeaderButtonsEmpty();
    this.setAreAllHeaderButtonsDesktopOnly();
  }

  private setAreHeaderDropdownsEmpty() {
      this.areHeaderDropdownsEmpty = !(
      this.dropdownItems?.length ||
      this.secondDropdownItems?.length ||
      this.thirdDropdownItems?.length
    );
  }

  private setAreHeaderButtonsEmpty() {
      this.areHeaderButtonsEmpty = !this.headerButtons?.length;
  }

  private setAreAllHeaderButtonsDesktopOnly() {
    this.areAllHeaderButtonsDesktopOnly = !this.headerButtons?.find(headerButton => !headerButton.isDesktopOnly);
  }
}
