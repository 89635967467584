import { TutorialStep, AttachDirection } from 'app/shared/services/tutorial.service';

class AvocadoSampleInformationWidgetStep extends TutorialStep {
  constructor(step) {
    super(step);
    this.id = 'avocado-sample-information-widget';
    this.title = 'Avocado Sample Information Widget';
    this.arrow = true;
    this.scrollTo = true;
  }
}

export function getAvocadoSampleInformationWidgetSteps(tutorialService): AvocadoSampleInformationWidgetStep[] {
  return [
    new AvocadoSampleInformationWidgetStep ({
      beforeShowPromise: () => {
        return new Promise<void>((resolve) => {
          tutorialService.clickElement('avocado-sample-information-widget');
          resolve();
        });
      },
      attachTo: {
        element: '.col.last-child app-tabbed-content > .content',
        on: AttachDirection.top
      },
      text: 'The Sample Information widget provides information on the sample testing results on your orchard(s).'
    }),
    new AvocadoSampleInformationWidgetStep ({
      attachTo: {
        element: 'grow-avocado-sample-information-widget app-simple-grid > table > tbody > tr > td.row-select',
        on: AttachDirection.right
      },
      text: 'If you are associated with multiple PPINs, use the radio buttons on the left side of the table to switch between them.'
    }),
    new AvocadoSampleInformationWidgetStep ({
      attachTo: {
        element: 'grow-avocado-sample-information-widget app-widget kendo-tabstrip > ul li:first-child',
        on: AttachDirection.top
      },
      text: 'Use these tabs to switch between available Varieties and Grow Methods.'
    }),
    new AvocadoSampleInformationWidgetStep ({
      beforeShowPromise: () => {
        return new Promise<void>((resolve) => {
          tutorialService.clickElement('avocado-sample-information-widget');
          resolve();
        });
      },
      attachTo: {
        element: 'grow-avocado-sample-information-widget > app-widget > header .header-column',
        on: AttachDirection.bottom
      },
      text: 'The data displayed by the widget is determined by the Season, PPIN and Maturity Area filters. Use the filters to adjust the displayed data.'
    })
  ];
}
