import { Component } from '@angular/core';
import {
  AgreementFormComponent, BaseCommitmentFormData, OrchardList
} from 'app/agreements/agreement-page/agreement-form/agreement-form.component';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { HttpService } from 'app/shared/services/http.service';
import { StateService } from 'app/shared/services/state.service';
import { Router } from '@angular/router';
import { NotificationService } from 'app/notification/notification.service';
import { GrowerPortalScrollService } from 'app/grower-portal-layout/grower-portal-scroll.service';
import {
  ChangeableField,
  ChangeRequestFormControl,
  ChangeRequestFormGroup
} from 'app/agreements/agreement-page/agreement-form/changeable-field/changeable-field.component';
import { AgreementsService } from 'app/agreements/agreements.service';
import { GaService } from 'app/shared/services/ga.service';
import { MetadataFormGroup } from 'app/shared/forms/metadata-form-group';

export interface KiwifruitPackingData extends BaseCommitmentFormData {
  entity_bank_account_name: ChangeableField<string>;
}

@Component({
  selector: 'agreement-form-kiwifruit-packing',
  templateUrl: './agreement-form-kiwifruit-packing.component.html',
  styleUrls: ['../agreement-form.component.scss']
})
export class AgreementFormKiwifruitPackingComponent extends AgreementFormComponent<KiwifruitPackingData> {
  endpoint = 'kiwifruit_packing';
  data = {} as KiwifruitPackingData;
  changeRequestsForm = new ChangeRequestFormGroup({
    postal_address_line_1: new ChangeRequestFormControl('authorized_signer_user_id'),
    postal_address_line_2: new ChangeRequestFormControl('authorized_signer_user_id'),
    postal_address_city: new ChangeRequestFormControl('authorized_signer_user_id'),
    postal_address_country: new ChangeRequestFormControl('authorized_signer_user_id'),
    postal_address_postal_code: new ChangeRequestFormControl('authorized_signer_user_id', { validators: this.postalCodeValidators }),
    physical_address_line_1: new ChangeRequestFormControl('authorized_signer_user_id'),
    physical_address_line_2: new ChangeRequestFormControl('authorized_signer_user_id'),
    physical_address_city: new ChangeRequestFormControl('authorized_signer_user_id'),
    physical_address_country: new ChangeRequestFormControl('authorized_signer_user_id'),
    physical_address_postal_code: new ChangeRequestFormControl('authorized_signer_user_id', { validators: this.postalCodeValidators }),
    phone_number_mobile: new ChangeRequestFormControl('authorized_signer_user_id', { validators: this.mobileNumberValidators }),
    entity_bank_account_name: new ChangeRequestFormControl('entity_id'),
    entity_bank_account_number: new ChangeRequestFormControl('entity_id'),
    entity_gst_number: new ChangeRequestFormControl('entity_id'),
  });
  form = new FormGroup({
    change_requests: this.changeRequestsForm,
    orchard_list: new FormArray([]),
    signature_value: new FormControl('', [Validators.required]),
  });
  successMessage = 'Thank you for signing the Kiwifruit packing agreement.';

  constructor(
    private _http: HttpService,
    private _router: Router,
    private _stateService: StateService,
    private _notificationService: NotificationService,
    private _growerPortalScrollService: GrowerPortalScrollService,
    private _agreementsService: AgreementsService,
    protected _ga: GaService
  ) {
    super(_http, _router, _stateService, _notificationService, _growerPortalScrollService, _agreementsService, _ga);
  }

  get orchardFormGroups(): MetadataFormGroup<OrchardList>[] {
    return this.form.controls['orchard_list'].controls as MetadataFormGroup<OrchardList>[];
  }

  protected handleFormData() {
    super.handleFormData();

    const orchardListFormArray = this.form.get('orchard_list') as FormArray;
    for (const orchard of this.data.orchard_list) {
      const orchardFormGroup = new MetadataFormGroup<OrchardList>({
        orchard_id: new FormControl(orchard.orchard_id),
        association_id: new FormControl(orchard.association_id),
        included: new FormControl(orchard.included),
      });
      orchardFormGroup.metadata = orchard;
      orchardListFormArray.push(orchardFormGroup);
    }

    this.changeRequestsForm.patchValue(this.data);
  }
}
