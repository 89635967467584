import { Component, OnInit, OnDestroy, HostBinding } from '@angular/core';
import { AuthService } from 'app/shared/services/auth.service';
import { StateService } from 'app/shared/services/state.service';
import { MessageService } from 'app/shared/services/message.service';
import { SettingsService } from 'app/shared/services/settings.service';
import { HelpService } from 'app/shared/services/help.service';
import { Document } from 'app/shared/document-viewer/document-viewer.component';
import { UserOverrideComponent, UserOverride } from 'app/shared/user-override/user-override.component';
import { GaService } from 'app/shared/services/ga.service';
import { UserProductsService, Product } from 'app/shared/services/user-products.service';
import { Subscription } from 'rxjs';
import { TutorialService } from 'app/shared/services/tutorial.service';
import { GrowerPortalScrollService } from 'app/grower-portal-layout/grower-portal-scroll.service';

declare var require: any;

@Component({
  selector: 'app-grower-portal-layout-component',
  templateUrl: './grower-portal-layout.component.html',
  styleUrls: ['./grower-portal-layout.component.scss']
})
export class GrowerPortalLayoutComponent implements OnInit, OnDestroy {
  currentUser = {};
  userOverride: UserOverride;
  quickMenuItems = [];
  menuCategories = [];
  document: Document = null;
  messageSubscription: Subscription;
  private selectedProductChangeSubscription: Subscription;
  @HostBinding('class.initialized') isInitialized = false;

  constructor(
    private authService: AuthService,
    public state: StateService,
    private messageService: MessageService,
    public helpService: HelpService,
    private ga: GaService,
    private userProductsService: UserProductsService,
    public tutorialService: TutorialService,
    public stateService: StateService,
    private growerPortalScrollService: GrowerPortalScrollService
  ) {
    this.flagBodyAsAuthenticated();
    this.currentUser = this.authService.getUser();
    this.userOverride = UserOverrideComponent.getOverride();
    this.messageSubscription = this.messageService.read().subscribe(this.onMessage.bind(this));
    this.ga.updateUseAnalytics();
    this.selectedProductChangeSubscription = this.userProductsService.selectedProductChange.subscribe((product: Product) => {
      this.configureMenuForProduct(product?.product_name);
      this.ga.updateProduct(product?.product_name);
      this.ga.event('product', 'change', product?.product_name);
      this.userProductsService.redirectUnsupportedProducts();
    });
  }

  async ngOnInit() {
    this.helpService.getHelp();
    await this.userProductsService.init(this.userOverride);
    this.isInitialized = true;
  }

  ngOnDestroy() {
    this.messageSubscription?.unsubscribe();
    this.selectedProductChangeSubscription?.unsubscribe();
  }

  onScroll(event) {
    this.growerPortalScrollService.onScroll(event);
  }

  async cancelOverride() {
    this.ga.updateUseAnalytics();
    UserOverrideComponent.cancelOverride();
  }

  closeDocumentViewer() {
    this.document = null;
  }

  onMessage(message) {
    if (message.text === SettingsService.SHOW_DOCUMENT_MESSAGE) {
      this.document = message.data;
    }
  }

  private configureMenuForProduct(productName: string) {
    if (productName === 'Avocado') {
      this.configureAvocadoMenu();
    } else {
      this.configureKiwifruitMenu();
    }
  }

  private configureAvocadoMenu() {
    this.quickMenuItems = [
      { label: 'Agreements', icon: 'fa-file-contract', url: '/agreements' },
      { label: 'Resources', icon: 'fa-shapes', url: '/resources' },
    ];
    this.menuCategories = [
      {
        items: [
          { label: 'Agreements', icon: 'fa-file-contract', url: '/agreements' },
          { label: 'Resources', icon: 'fa-shapes', url: '/resources' },
        ]
      }
    ];
  }

  private configureKiwifruitMenu() {
    this.quickMenuItems = [
      { label: 'Agreements', icon: 'fa-file-contract', url: '/agreements' },
      { label: 'Resources', icon: 'fa-shapes', url: '/resources' },
      { label: 'Packout Details', icon: 'fa-industry', url: '/packout-details' },
      { label: 'OGR Details', icon: 'fa-dollar-sign', url: '/ogr-details' },
      { label: 'Inventory Details', icon: 'fa-truck', url: '/inventory-details' }
    ];
    this.menuCategories = [
      {
        items: [
          { label: 'Agreements', icon: 'fa-file-contract', url: '/agreements' },
          { label: 'Resources', icon: 'fa-shapes', url: '/resources' },
          { label: 'Packout Details', icon: 'fa-industry', url: '/packout-details', desktopOnly: true },
          { label: 'OGR Details', icon: 'fa-dollar-sign', url: '/ogr-details', desktopOnly: true },
          { label: 'Inventory Details', icon: 'fa-truck', url: '/inventory-details', desktopOnly: true }
        ]
      }
    ];
  }

  private flagBodyAsAuthenticated() {
    document.body.classList.add('authenticated');
  }
}
