import { Injectable } from '@angular/core';
import { filter, Subscription } from 'rxjs';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class ResourceScrollService {
  categoryPageScrollElement: HTMLElement = null;
  groupPageScrollElement: HTMLElement = null;
  resourcePageScrollElement: HTMLElement = null;

  private categoryPageScrollTop = 0;
  private groupPageScrollTop = 0;
  private routerSubscription: Subscription;

  constructor(
    private router: Router
  ) {
    this.init();
  }

  init() {
    this.routerSubscription?.unsubscribe();
    this.routerSubscription = this.router.events.pipe(
      filter((event) => event instanceof NavigationStart || event instanceof NavigationEnd)
    ).subscribe((event: NavigationStart | NavigationEnd) => {
      if (event instanceof NavigationStart) {
        this.captureScrollPositions(event.url);
      } else {
        this.applyScrollPositions(event.url);
      }
    });
  }

  destroy() {
    this.routerSubscription?.unsubscribe();
  }

  private captureScrollPositions(url: string) {
    if (this.isResourcePage(url)) {
      if (this.groupPageScrollElement) {
        this.groupPageScrollTop = this.groupPageScrollElement.scrollTop;
      }
      this.categoryPageScrollElement = null;
    } else if (this.isResourceGroupPage(url)) {
      if (this.categoryPageScrollElement) {
        this.categoryPageScrollTop = this.categoryPageScrollElement.scrollTop;
      }
    } else if (this.isResourceCategoryPage(url)) {
      this.groupPageScrollTop = 0;
    } else {
      this.categoryPageScrollTop = 0;
      this.groupPageScrollTop = 0;
      this.destroy();
    }
  }

  private applyScrollPositions(url: string) {
    if (this.isResourcePage(url)) {
      setTimeout(() => {
        this.resourcePageScrollElement?.scrollTo(0, 0);
      });
    } else if (this.isResourceGroupPage(url)) {
      setTimeout(() => {
        this.groupPageScrollElement?.scrollTo(0, this.groupPageScrollTop);
      });
    } else if (this.isResourceCategoryPage(url)) {
      setTimeout(() => {
        this.categoryPageScrollElement?.scrollTo(0, this.categoryPageScrollTop);
      });
    }
  }

  private isResourcePage(url: string) {
    return /(?:\/[\w-]+){3,}/.test(url);
  }

  private isResourceGroupPage(url: string) {
    return url.includes('/resources/');
  }

  private isResourceCategoryPage(url: string) {
    return url === '/resources';
  }
}
