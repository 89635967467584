<grower-portal-badge *ngIf="badge"
                     class="badge rounded"
                     [type]="badge.type"
                     [icon]="badge.icon"
                     [value]="badge.value"
                     [text]="badge.text"></grower-portal-badge>
<header #tileHeader [class.image-loaded]="image">
  <div class="placeholder" [style.background-image]="'url(' + placeholder + ')'"></div>
  <div class="image" [style.background-image]="'url(' + (image || placeholder) + ')'"></div>
  <div class="overlay"></div>
  <div class="icons">
    <i [class]="'fas ' + icon "></i>
    <i *ngIf="product" [class]="'light product i-' + product"></i>
  </div>
  <h2>{{ label }}</h2>
</header>
<div *ngIf="text" class="content">
  {{ text }}
</div>
<div *ngIf="meta?.length" class="meta">
  <div *ngFor="let item of meta" class="meta-item" [attr.title]="item.title || null">
    <i *ngIf="item.icon" [class]="'fas ' + item.icon"></i>
    <div *ngIf="item.text" class="text">{{ item.text }}</div>
  </div>
</div>
<grower-portal-message-overlay [visible]="!!message"
                               [type]="message?.type"
                               [message]="message?.text" ></grower-portal-message-overlay>
