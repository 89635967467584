import { throwError as observableThrowError, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Injectable, NgZone } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpErrorResponse,
  HttpHandler,
  HttpEvent
} from '@angular/common/http';
import { Router } from '@angular/router';
import { AuthService } from 'app/shared/services/auth.service';

@Injectable()
export class MaintenanceInterceptor implements HttpInterceptor {
  constructor(
    private authService: AuthService,
    private ngZone: NgZone,
    private router: Router,
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next
      .handle(request).pipe(
      catchError(response => {
        if (response instanceof HttpErrorResponse) {
          switch (response.status) {
            case 503:
              this.router.navigateByUrl('/maintenance', { skipLocationChange: true });
              break;
            case 401:
              this.authService.clear();
              this.ngZone.run(() => {
                this.router.navigateByUrl('/login');
              });
          }
        }
        return observableThrowError(response);
      }));
  }
}
