<div class="breadcrumbs-panel">
  <app-breadcrumbs></app-breadcrumbs>
</div>
<section class="main">
  <nav class="side-nav">
    <div class="side-nav-inner">
      <a [routerLink]="['/resources/', resourceService.currentResourceGroup.id]">
        <div class="nav-item-content">
          <i class="fas fa-chevron-left"></i>
          <div class="text-wrap">
            <div class="text">Back</div>
          </div>
        </div>
      </a>
      <a *ngFor="let resource of resourceService.currentResourceGroup.items"
         [class.current]="resource.name === resourceService.currentResource.name"
         (click)="onResourceClick(resource)">
        <div class="nav-item-content">
          <i [class]="'fas ' + resourceService.currentResourceGroup.icon"></i>
          <div class="text-wrap">
            <div class="text">{{ resource.name }}</div>
          </div>
        </div>
        <i *ngIf="resource.item" [class]="'fas download-indicator ' + (resource.message?.type === 'loading' ? 'fa-circle-notch fa-spin' : 'fa-cloud-arrow-down')"></i>
      </a>
    </div>
  </nav>
  <section #content class="content">
    <ng-template containerRef></ng-template>
  </section>
</section>
