import { Component, OnInit, Output, EventEmitter, Input, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { OrchardWeatherService, WeatherForecastDaily, WeatherForecastHourly } from './orchard-weather.service';
import { Subject, of, Subscription, catchError } from 'rxjs';
import { LatLng } from 'app/shared/orchard-map/orchard-map.component';
import { GaService } from 'app/shared/services/ga.service';

export const INTERVAL_DURATION = 200;
const MOMENTUM_SCROLL_TIME = 800;

@Component({
  selector: 'app-orchard-weather',
  templateUrl: './orchard-weather.component.html',
  styleUrls: ['./orchard-weather.component.scss'],
  providers: [OrchardWeatherService]
})
export class OrchardWeatherComponent implements OnInit, OnDestroy {
  dailyWeatherForecasts: WeatherForecastDaily[];
  hourlyWeatherForecasts: WeatherForecastHourly[];
  loadData: Subject<LatLng> = new Subject<LatLng>();
  dowIdPrefix = 'hourly-forecast-dow-';
  selectedTabTitle = 'Daily Forecast';

  private hourWidth: number;
  private intervalHandle;
  private isDragging = false;
  private startScrollLeft: number;
  private dragStartX: number;
  private currentDowElement;
  private abbreviatedDowElement;
  private loadDataSubscription: Subscription;
  private loadWeatherSubscription: Subscription;
  private touchEndTimeoutHandle;

  @Input() displayError = false;
  @Output() error = new EventEmitter();
  @Output() complete = new EventEmitter();
  @ViewChild('dailyForecast', { static: false }) dailyForecast: ElementRef;
  @ViewChild('hourlyForecast', { static: false }) hourlyForecast: ElementRef;

  constructor(
    private weather: OrchardWeatherService,
    private ga: GaService
  ) {}

  ngOnInit() {
    this.init();
  }

  ngOnDestroy() {
    this.loadDataSubscription?.unsubscribe();
    this.loadWeatherSubscription?.unsubscribe();
  }

  onTabSelect(event: any) {
    this.ga.event('tab', 'change', 'map & weather widget tab', event.title);
    this.selectedTabTitle = event.title;
    if (!this.hourWidth && this.selectedTabTitle === 'Hourly Forecast') {
      this.setHourWidth();
    }
  }

  previous() {
    this.scrollHourlyForecast(-this.hourWidth || 0);
  }

  startPrevious() {
    clearInterval(this.intervalHandle);
    this.intervalHandle = setInterval(() => {
      this.previous();
    }, INTERVAL_DURATION);
  }

  next() {
    this.scrollHourlyForecast(this.hourWidth || 0);
  }

  startNext() {
    clearInterval(this.intervalHandle);
    this.intervalHandle = setInterval(() => {
      this.next();
    }, INTERVAL_DURATION);
  }

  endMouseDown() {
    clearInterval(this.intervalHandle);
  }

  scrollHourlyForecastIntoView(dow) {
    this.onTabSelect({ title: 'Hourly Forecast' });
    setTimeout(() => {
      const target = document.getElementById(this.dowIdPrefix + dow);
      if (target) {
        this.hourlyForecast.nativeElement.scrollLeft = target.parentElement.offsetLeft;
      }
    });
  }

  dragStart(x: number) {
    this.isDragging = true;
    this.dragStartX = x;
    this.startScrollLeft = this.hourlyForecast.nativeElement.scrollLeft;
  }

  touchStart(x: number) {
    this.dragStartX = x;
  }

  drag(x: number) {
    if (this.isDragging) {
      this.hourlyForecast.nativeElement.scrollLeft = this.startScrollLeft + this.dragStartX - x;
      this.positionHourlyDayOfWeek();
    }
  }

  dragEnd() {
    this.isDragging = false;
    this.updateScrollLeft();
    this.positionHourlyDayOfWeek();
  }

  touchEnd() {
    if (this.touchEndTimeoutHandle) {
      clearTimeout(this.touchEndTimeoutHandle);
    }

    this.touchEndTimeoutHandle = setTimeout(() => {
      this.updateScrollLeft();
      this.positionHourlyDayOfWeek();
    }, MOMENTUM_SCROLL_TIME);
  }

  private init() {
    this.loadDataSubscription = this.loadData.subscribe((coords: LatLng) => {
      this.dailyWeatherForecasts = [];
      this.hourlyWeatherForecasts = [];
      this.loadWeatherSubscription = this.weather.fetch(coords.lat, coords.lng).pipe(
        catchError((error) => {
          this.error.emit(error);
          this.displayError = true;
          return of();
        })
      ).subscribe((data) => {
        this.dailyWeatherForecasts = data['daily'];
        this.hourlyWeatherForecasts = data['hourly'];
        this.displayError = false;
        this.complete.emit();
      });
    });
  }

  private positionHourlyDayOfWeek() {
    if (this.currentDowElement) {
      this.currentDowElement.style.left = '0px';
    }

    if (this.abbreviatedDowElement) {
      this.abbreviatedDowElement.classList.remove('abbreviated');
    }

    const scrollLeft = this.hourlyForecast?.nativeElement?.scrollLeft;
    if (scrollLeft != null) {
      const currentDowIndex = this.getDowIndexByLeftPosition(scrollLeft);
      const currentDow = this.getDowByIndex(currentDowIndex);
      this.currentDowElement = this.getDowElement(currentDow);

      const nextDowIndex = currentDowIndex + 1;
      const nextDow = this.getDowByIndex(nextDowIndex);

      if (currentDow !== nextDow) {
        // Next and previous days are next to one another
        this.abbreviatedDowElement = this.currentDowElement;
        this.abbreviatedDowElement.classList.add('abbreviated');
      } else {
        this.abbreviatedDowElement = null;
      }

      this.currentDowElement = document.getElementById(this.dowIdPrefix + currentDow);
      this.currentDowElement.style.left = (this.hourlyForecast.nativeElement.scrollLeft - this.currentDowElement.parentElement.offsetLeft) + 'px';
    }
  }

  private scrollHourlyForecast(scrollLeftIncrement: number) {
    this.hourlyForecast.nativeElement.scrollLeft += scrollLeftIncrement;
    this.updateScrollLeft();
    this.positionHourlyDayOfWeek();
  }

  private setHourWidth() {
    setTimeout(() => {
      const hourElements = this.hourlyForecast.nativeElement.getElementsByClassName('hour');
      if (hourElements.length) {
        this.hourWidth = hourElements[0].offsetWidth;
      }
    });
  }

  private updateScrollLeft() {
    const scrollLeft = this.hourlyForecast?.nativeElement?.scrollLeft;
    if (scrollLeft != null) {
      this.hourlyForecast.nativeElement.scrollLeft = this.hourWidth * Math.round(scrollLeft / this.hourWidth);
    }
  }

  private getDowElement(dow: string) {
    return document.getElementById(this.dowIdPrefix + dow);
  }

  private getDowIndexByLeftPosition(left: number) {
    return Math.floor(left / this.hourWidth);
  }

  private getDowByIndex(index: number) {
    const hourlyWeatherForecast = this.hourlyWeatherForecasts[index];
    return hourlyWeatherForecast ? hourlyWeatherForecast.dow : null;
  }
}
