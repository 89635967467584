<a class="crumb home" routerLink="/">
  <i class="fas fa-home crumb-content" (click)="confirm($event, { url: '/', query: {}, title: '' })"></i>
</a>
<a *ngIf="breadcrumbs.length <= 1"
   class="crumb mobile"
   routerLink="/">
  <i class="fas fa-chevron-left" (click)="confirm($event, { url: '/', query: {}, title: '' })"></i>
</a>
<a *ngIf="breadcrumbs.length > 1"
   class="crumb mobile"
   [routerLink]="breadcrumbs[breadcrumbs.length - 2].url"
   [queryParams]="breadcrumbs[breadcrumbs.length - 2].query">
  <i class="fas fa-chevron-left" (click)="confirm($event, breadcrumbs[breadcrumbs.length - 2])"></i>
</a>
<a *ngFor="let crumb of breadcrumbs; last as isLast"
   class="crumb"
   [class.previous]="!isLast"
   [class.current]="isLast"
   [routerLink]="crumb.url"
   [queryParams]="crumb.query">
  <span class="crumb-content" (click)="confirm($event, crumb)">
    <span class="page-title">{{ crumb.title }}</span>
    <span *ngIf="updatedAt && isLast" class="updated-at">
      Current as of
      <span class="date">{{ updatedAt | date: dateFormat }}</span>
    </span>
  </span>
</a>
