import { TutorialStep, AttachDirection } from 'app/shared/services/tutorial.service';

class KiwifruitProductionWidgetStep extends TutorialStep {
  constructor(step) {
    super(step);
    this.id = 'kiwifruit-production-widget';
    this.title = 'Kiwifruit Production Widget';
    this.arrow = true;
    this.scrollTo = true;
  }
}

export function getKiwifruitProductionWidgetSteps(tutorialService): KiwifruitProductionWidgetStep[] {
  return [
    new KiwifruitProductionWidgetStep ({
      beforeShowPromise: () => {
        return new Promise<void>((resolve) => {
          tutorialService.clickElement('kiwifruit-production');
          resolve();
        });
      },
      attachTo: {
        element: '.col:first-child > app-tabbed-content > .content',
        on: AttachDirection.top
      },
      scrollTo: true,
      text: 'The Production Widget provides a high-level summary of harvest and packing progress for your orchard(s). Data is updated every time a days packing is completed and reconciled.'
    }),
    new KiwifruitProductionWidgetStep ({
      attachTo: {
        element: 'app-kiwifruit-production-widget kendo-tabstrip > ul li:first-child',
        on: AttachDirection.bottom
      },
      text: 'Use these tabs to switch between available Varieties and Grow Methods.'
    }),
    new KiwifruitProductionWidgetStep ({
      attachTo: {
        element: 'app-kiwifruit-production-widget app-simple-grid > table > tbody > tr > td.row-select',
        on: AttachDirection.right
      },
      text: 'If you are associated with multiple KPINs, use the radio buttons on the left side of the table to switch between them.'
    }),
    new KiwifruitProductionWidgetStep ({
      attachTo: {
        element: 'app-kiwifruit-production-widget .column.chart',
        on: AttachDirection.top
      },
      text: 'Here we display the percentage of bins packed over the number of reconciled bins.'
    }),
    new KiwifruitProductionWidgetStep ({
      attachTo: {
        element: 'app-kiwifruit-production-widget .comparison-panel',
        on: AttachDirection.top
      },
      text: 'Here you can see how you compare against other orchards at a similar altitude (+/- 10m) and against the Trevelyan’s averages. Green values indicate an improvement over the averages, while red values indicate a decline.'
    }),
    new KiwifruitProductionWidgetStep ({
      attachTo: {
        element: 'app-kiwifruit-production-widget header dropdown',
        on: AttachDirection.bottom
      },
      text: 'Use the Season selector to switch between available seasons.'
    }),
    new KiwifruitProductionWidgetStep ({
      beforeShowPromise: () => {
        return new Promise<void>((resolve) => {
          tutorialService.clickElement('kiwifruit-production');
          resolve();
        });
      },
      attachTo: {
        element: 'app-kiwifruit-production-widget .packout-details-button',
        on: AttachDirection.bottom
      },
      text: 'Click the Packout Details button to see a more in-depth report.'
    })
  ];
}
