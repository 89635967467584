const ALTERNATE_MOBILE_BEHAVIOUR_CLASS = 'alternate-mobile-behaviour';

export class AlternativeMobileBehaviour {
  protected enableAlternateMobileBehaviour() {
    document.body.classList.add(ALTERNATE_MOBILE_BEHAVIOUR_CLASS);
  }

  protected disableAlternateMobileBehaviour() {
    document.body.classList.remove(ALTERNATE_MOBILE_BEHAVIOUR_CLASS);
  }
}
