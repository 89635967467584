<div class="search-panel"
     (click)="showGlobalSearch()">
  Search
</div>
<div class="shortcut-keys">
  <div class="key">/</div>
</div>
<button class="button icon-only" type="button" (click)="showGlobalSearch()">
  <i class="fas fa-search"></i>
</button>
