<form [formGroup]="form">

  <div class="card-header">Signer Details</div>
  <div class="row card-row">
    <div class="card">
      <div class="row">
        <div class="label">Authorised Signer</div>
        <div class="control"></div>
        <div class="value">{{ data.authorized_signer_name }}</div>
      </div>

      <changeable-field formGroupName="change_requests"
                        label="Postal Address"
                        [data]="data"
                        [dataKeys]="[
                          'postal_address_line_1',
                          'postal_address_line_2',
                          'postal_address_city',
                          'postal_address_country',
                          'postal_address_postal_code'
                        ]"
                        [controls]="[
                          changeRequestsForm.controls['postal_address_line_1'],
                          changeRequestsForm.controls['postal_address_line_2'],
                          changeRequestsForm.controls['postal_address_city'],
                          changeRequestsForm.controls['postal_address_country'],
                          changeRequestsForm.controls['postal_address_postal_code']
                        ]">
        <kendo-formfield showHints="initial" showErrors="initial">
          <kendo-label [for]="postalAddressLine1"
                       text="Address Line 1">
          </kendo-label>
          <kendo-textbox #postalAddressLine1 formControlName="postal_address_line_1">
          </kendo-textbox>
        </kendo-formfield>
        <kendo-formfield showHints="initial" showErrors="initial">
          <kendo-label [for]="postalAddressLine2"
                       text="Address Line 2">
          </kendo-label>
          <kendo-textbox #postalAddressLine2 formControlName="postal_address_line_2">
          </kendo-textbox>
        </kendo-formfield>
        <kendo-formfield showHints="initial" showErrors="initial">
          <kendo-label [for]="postalAddressCity"
                       text="City">
          </kendo-label>
          <kendo-textbox #postalAddressCity formControlName="postal_address_city">
          </kendo-textbox>
        </kendo-formfield>
        <kendo-formfield showHints="initial" showErrors="initial">
          <kendo-label [for]="postalAddressCountry"
                       text="Country">
          </kendo-label>
          <kendo-textbox #postalAddressCountry formControlName="postal_address_country">
          </kendo-textbox>
        </kendo-formfield>
        <kendo-formfield showHints="initial" showErrors="initial">
          <kendo-label [for]="postalAddressPostalCode"
                       text="Postcode">
          </kendo-label>
          <kendo-numerictextbox #postalAddressPostalCode
                                [spinners]="false"
                                [decimals]="0"
                                [format]="postcodeFormatOptions"
                                formControlName="postal_address_postal_code">
          </kendo-numerictextbox>
          <kendo-formerror>Error: Invalid Postcode</kendo-formerror>
        </kendo-formfield>
      </changeable-field>

      <changeable-field formGroupName="change_requests"
                        label="Home Address"
                        [data]="data"
                        [dataKeys]="[
                          'physical_address_line_1',
                          'physical_address_line_2',
                          'physical_address_city',
                          'physical_address_country',
                          'physical_address_postal_code'
                        ]"
                        [controls]="[
                          changeRequestsForm.controls['physical_address_line_1'],
                          changeRequestsForm.controls['physical_address_line_2'],
                          changeRequestsForm.controls['physical_address_city'],
                          changeRequestsForm.controls['physical_address_country'],
                          changeRequestsForm.controls['physical_address_postal_code']
                        ]">
        <ng-container dataValue *ngIf="data.physical_address_same_as_postal">
          Same as Postal Address.
        </ng-container>

        <kendo-formfield showHints="initial" showErrors="initial">
          <kendo-label [for]="physicalAddressLine1"
                       text="Address Line 1">
          </kendo-label>
          <kendo-textbox #physicalAddressLine1 formControlName="physical_address_line_1">
          </kendo-textbox>
        </kendo-formfield>
        <kendo-formfield showHints="initial" showErrors="initial">
          <kendo-label [for]="physicalAddressLine2"
                       text="Address Line 2">
          </kendo-label>
          <kendo-textbox #physicalAddressLine2 formControlName="physical_address_line_2">
          </kendo-textbox>
        </kendo-formfield>
        <kendo-formfield showHints="initial" showErrors="initial">
          <kendo-label [for]="physicalAddressCity"
                       text="City">
          </kendo-label>
          <kendo-textbox #physicalAddressCity formControlName="physical_address_city">
          </kendo-textbox>
        </kendo-formfield>
        <kendo-formfield showHints="initial" showErrors="initial">
          <kendo-label [for]="physicalAddressCountry"
                       text="Country">
          </kendo-label>
          <kendo-textbox #physicalAddressCountry formControlName="physical_address_country">
          </kendo-textbox>
        </kendo-formfield>
        <kendo-formfield showHints="initial" showErrors="initial">
          <kendo-label [for]="physicalAddressPostalCode"
                       text="Postcode">
          </kendo-label>
          <kendo-numerictextbox #physicalAddressPostalCode
                                [spinners]="false"
                                [decimals]="0"
                                [format]="postcodeFormatOptions"
                                formControlName="physical_address_postal_code">
          </kendo-numerictextbox>
          <kendo-formerror>Error: Invalid Postcode</kendo-formerror>
        </kendo-formfield>
      </changeable-field>

      <changeable-field formGroupName="change_requests"
                        label="Mobile Number"
                        [data]="data"
                        dataKeys="phone_number_mobile"
                        [controls]="changeRequestsForm.controls['phone_number_mobile']">
        <kendo-formfield showHints="initial" showErrors="initial">
          <kendo-label [for]="phoneNumberMobile"
                       text="Mobile Number">
          </kendo-label>
          <kendo-maskedtextbox #phoneNumberMobile
                               prompt=""
                               [maskValidation]="false"
                               mask="00000000000"
                               formControlName="phone_number_mobile">
          </kendo-maskedtextbox>
          <kendo-formerror>Error: Invalid Mobile Number</kendo-formerror>
        </kendo-formfield>
      </changeable-field>

      <div class="row">
        <div class="label">Email Address</div>
        <div class="control"></div>
        <div class="value">{{ data.email_address }}</div>
      </div>
    </div>
  </div>

  <div class="card-header">Entity Details</div>
  <div class="row card-row">
    <div class="card">
      <div class="row">
        <div class="label">Full Name of Legal Entity</div>
        <div class="control"></div>
        <div class="value">{{ data.entity_name }}</div>
      </div>

      <changeable-field formGroupName="change_requests"
                        label="Bank Name"
                        [data]="data"
                        dataKeys="entity_bank_name"
                        [controls]="changeRequestsForm.controls['entity_bank_name']">
        <kendo-formfield showHints="initial" showErrors="initial">
          <kendo-label [for]="entityBankName"
                       text="Bank Name">
          </kendo-label>
          <kendo-textbox #entityBankName formControlName="entity_bank_name">
          </kendo-textbox>
        </kendo-formfield>
      </changeable-field>

      <changeable-field formGroupName="change_requests"
                        label="Bank Branch"
                        [data]="data"
                        dataKeys="entity_bank_branch"
                        [controls]="changeRequestsForm.controls['entity_bank_branch']">
        <kendo-formfield showHints="initial" showErrors="initial">
          <kendo-label [for]="entityBankBranch"
                       text="Bank Branch">
          </kendo-label>
          <kendo-textbox #entityBankBranch formControlName="entity_bank_branch">
          </kendo-textbox>
        </kendo-formfield>
      </changeable-field>

      <changeable-field formGroupName="change_requests"
                        label="Bank Account Number"
                        [data]="data"
                        dataKeys="entity_bank_account_number"
                        [controls]="changeRequestsForm.controls['entity_bank_account_number']">
        <kendo-formfield showHints="initial" showErrors="initial">
          <kendo-label [for]="entityBankAccountNumber"
                       text="Bank Account Number">
          </kendo-label>
          <kendo-maskedtextbox #entityBankAccountNumber
                               formControlName="entity_bank_account_number"
                               includeLiterals="true"
                               mask="00-0000-0000000-000">
          </kendo-maskedtextbox>
        </kendo-formfield>
      </changeable-field>

      <changeable-field formGroupName="change_requests"
                        label="GST Number"
                        [data]="data"
                        dataKeys="entity_gst_number"
                        [controls]="changeRequestsForm.controls['entity_gst_number']">
        <kendo-formfield showHints="initial" showErrors="initial">
          <kendo-label [for]="entityGstNumber"
                       text="GST Number">
          </kendo-label>
          <kendo-maskedtextbox #entityGstNumber
                               formControlName="entity_gst_number"
                               includeLiterals="true"
                               mask="000-000-000">
          </kendo-maskedtextbox>
        </kendo-formfield>
      </changeable-field>

      <changeable-field formGroupName="change_requests"
                        label="Security Interests"
                        [data]="data"
                        dataKeys="entity_security_interest"
                        [controls]="changeRequestsForm.controls['entity_security_interest']">
        <kendo-formfield showHints="initial" showErrors="initial">
          <kendo-label [for]="entitySecurityInterest"
                       text="Security Interests">
          </kendo-label>
          <kendo-textarea #entitySecurityInterest rows="3" formControlName="entity_security_interest">
          </kendo-textarea>
        </kendo-formfield>
      </changeable-field>
    </div>
  </div>


  <div class="card-header">KPINs</div>
  <div class="row card-row">
    <div class="value" formArrayName="orchard_list">
      <div *ngFor="let orchardForm of orchardFormGroups; let i = index"
           [formGroupName]="i"
           class="orchard card">
        <div class="control">
          <input #included
                 kendoCheckBox
                 type="checkbox"
                 formControlName="included"/>
        </div>
        <div class="orchard-details" [class.not-included]="!included.checked">
            <strong>{{ orchardForm.metadata.grower_number }}, {{ orchardForm.metadata.orchard_name }}</strong>
            <div>{{ orchardForm.metadata.orchard_address }}</div>
            <div *ngIf="orchardForm.metadata.biogro_number">
              BioGro #: {{ orchardForm.metadata.biogro_number }}
              <ng-container *ngIf="orchardForm.metadata.biogro_expiry_date">
                (Expires {{ orchardForm.metadata.biogro_expiry_date | date: dateFormat }})
              </ng-container>
            </div>
            <div *ngFor="let area of orchardForm.metadata.variety_grow_methods">
              {{ area }}
            </div>
          </div>
      </div>
    </div>
  </div>

  <div class="signing-formfield">
    <kendo-formfield>
      <span #signatureRow></span>
      <kendo-textbox class="signing-field"
                     placeholder="Type your name here"
                     formControlName="signature_value">
      </kendo-textbox>
      <kendo-formerror>
        <i class="fas fa-exclamation-triangle"></i>
        Your signature is required.
      </kendo-formerror>
    </kendo-formfield>
  </div>

  <div *ngIf="isDisabled; else signHereMessage" class="sign-here disabled">
    Signing disabled when impersonating a grower.
  </div>

  <ng-template #signHereMessage>
    <div class="sign-here" [class.below]="signSuffix === 'Below'">
      Sign {{ signSuffix }}
    </div>
  </ng-template>

  <div *ngIf="data.countersigner_signature_image" class="countersigner-row">
    <div class="countersigner-row-content">
      <div class="countersigner-signature"
           [style.backgroundImage]="'url(/api/media/' + data.countersigner_signature_image + ')'"></div>
      <div class="countersigner-signature-description">
        {{ data.countersigner_signature_description | lowercase }}
      </div>
    </div>
  </div>

  <div class="controls">
    <div *ngIf="isFormInvalid" class="invalid-form-message">
      <i class="fas fa-exclamation-triangle"></i>
      Please complete all required fields.
    </div>
    <button class="button rounded" (click)="cancel()">
      <i class="fas fa-chevron-left"></i>
      {{ isViewOnly ? 'Back' : 'Cancel' }}
    </button>
    <button
      class="button ok rounded save-button"
      [class.disabled]="isFormInvalid"
      type="button"
      (click)="saveForm()">
      <i class="fas fa-file-circle-check"></i>
      Save
    </button>
  </div>
</form>
