import { getLocalStorageValue, setLocalStorageValue } from 'app/shared/services/utils.service';
import {
  Component,
  OnInit,
  AfterViewInit,
  ElementRef,
  Input,
  ViewChild,
  HostBinding,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  OnDestroy
} from '@angular/core';
import { DatePipe } from '@angular/common';
import { ResizeService } from 'app/shared/services/resize.service';
import { HttpService, PaginatedResponse } from 'app/shared/services/http.service';
import * as d3 from 'd3';
import { MessageService } from 'app/shared/services/message.service';
import { formatServerDate } from 'app/shared/services/utils.service';
import { TooltipData, TooltipPosition } from 'app/tooltip/tooltip.component';
import { DropdownItem } from 'app/shared/dropdown/dropdown.component';
import { GaService } from 'app/shared/services/ga.service';
import { SummaryPipe } from 'app/pipes/summary.pipe';
import { fixDateStringFormat } from 'app/shared/services/utils.service';
import { OrchardCalendarService } from 'app/shared/services/orchard-calendar.service';
import { Subscription, forkJoin, BehaviorSubject, of, Observable } from 'rxjs';
import { switchMap, debounceTime, distinctUntilChanged, catchError } from 'rxjs/operators';
import { SettingsService } from 'app/shared/services/settings.service';
import { AuthService, User } from 'app/shared/services/auth.service';
import { ProductNameToId } from 'app/shared/services/user-products.service';

// Keep this the same as css
const WEEK_IN_DAYS = 7;
const MAX_NUM_SUMMARY_WORDS = 50;
export const PAGE_SIZE = 1000;

export interface OrchardCalendarData {
  base: Observable<PaginatedResponse>;
  events: Observable<PaginatedResponse>;
}

export interface OrchardCalendarItem {
  start: string;
  end: string;
  title: string;
  content?: string;
  cleanContent?: string;
  variety?: string;
  grow_method?: string;
  left?: number;
  width?: number;
  rowNumber?: number;
  maxTitleWidth?: number;
  inView?: boolean;
}

export interface OrchardCalendarCategory {
  id: number;
  index: number;
  name: string;
  colour: string;
  order: number;
  grouped?: boolean;
  items?: OrchardCalendarItem[];
  title?: string;
  left?: number;
  headerLeft?: number;
  width?: number;
  height?: number;
  isEmpty?: boolean;
  isOpen?: boolean;
  isDark?: boolean;
  inView?: boolean;
}

export interface VarietyAndGrowMethodDropdownItem extends DropdownItem {
  label: string;
  value: {
    variety_id: number, grow_method_id: number
  };
}

@Component({
  selector: 'app-orchard-calendar-component',
  templateUrl: './orchard-calendar.component.html',
  styleUrls: ['./orchard-calendar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OrchardCalendarComponent implements OnInit, OnDestroy, AfterViewInit {
  data: OrchardCalendarCategory[];
  element: any;
  activeUser: User;
  stateLocalStorageKey: string;
  now = this.addDayToDate(new Date(), 0);
  nowLeft = 0;
  nowWidth = 0;
  gridItems = [];
  humanDateFormat = 'E MMM d';
  xTranslation = 0;
  isDataLoading = false;
  isError = false;
  private datePipe = new DatePipe('en-NZ');
  private gridWidth: number;
  private timeScale: d3.ScaleTime<number, number>;
  private singleDayWidth: number;
  private minSingleDayWidth = 10;
  private minNumberOfWeeksToDisplay = 3; // Min of one week back and 2 weeks forward
  private maxNumberOfWeeksToDisplay = 10; // Max of one week back and 8 weeks forward
  private itemLineHeight = 36;
  private itemGroupedLineHeight = 28;
  private itemLineSpacing = 5;
  private darkColourThreshold = 80;
  private numWordsForCategoryTitle = 5;

  dragStartX: number = null;
  private dragXTranslation: number = null;
  private isDragging = false;

  private gridItemBufferSize = 10;
  private gridStartLimit = 0;
  private gridEndLimit = 0;

  isTooltipVisible = false;
  tooltipData = {} as TooltipData;
  tooltipPosition = {} as TooltipPosition;
  tooltipWidth = 300;
  showExpandAllButton = false;
  isExpanded = false;

  @Input() daysBack = 7;
  @Input() daysForward = 54;
  @Input() gridInterval = 7;
  @Input() buttonWidth = 50;
  @Input() minDragDiff = 50;
  @Input() showHideCalendarButton = true;
  @Input() title = 'My Orchard Calendar';
  private numDays: number;
  private startDate: Date;
  private endDate: Date;
  private dataSubscription: Subscription;
  private observableRequestParameters = new BehaviorSubject({});
  private isInitialLoadComplete = false;
  private readonly toggleHelpSubscription: Subscription;

  @Input() @HostBinding('class.embedded') embedded = false;
  @ViewChild('grid', { static: false }) grid: ElementRef;
  @ViewChild('categories', { static: false }) categories: ElementRef;
  @HostBinding('class.transparent') isHidden = true;
  @HostBinding('class.collapsed') calendarIsCollapsed = false;

  selectedVarietyAndGrowMethod: VarietyAndGrowMethodDropdownItem = null;
  varietiesAndGrowMethods: VarietyAndGrowMethodDropdownItem[] = [];

  constructor(
    private elementRef: ElementRef,
    private resize: ResizeService,
    private http: HttpService,
    private messageService: MessageService,
    private ga: GaService,
    private authService: AuthService,
    private summaryPipe: SummaryPipe,
    private orchardCalendarService: OrchardCalendarService,
    private changeDetectorRef: ChangeDetectorRef,
  ) {
    this.element = this.elementRef.nativeElement;
    this.resize.addOnStartHandler(this.resizeStarted.bind(this), 'width');
    this.resize.addOnEndHandler(this.resizeEnded.bind(this), 'width');
    this.toggleHelpSubscription = this.messageService.read().subscribe(this.onMessage.bind(this));
  }

  ngOnInit() {
    this.setupDomainDates();
    this.activeUser = this.authService.getUser();
    this.stateLocalStorageKey = 'calendar_state_' + this.activeUser.id;
    if (this.showHideCalendarButton) {
      this.applyCalendarStateToken();
    }
  }

  ngOnDestroy() {
    this.toggleHelpSubscription?.unsubscribe();
    this.dataSubscription?.unsubscribe();
  }

  ngAfterViewInit() {
    setTimeout(async () => {
      this.setup();
      await this.getVarietiesAndGrowMethods();
      this.updateRequestParameters();
      this.dataSubscription = this.subscribeToData();
      this.isHidden = false;
      this.changeDetectorRef.markForCheck();
    });
  }

  toggleCalendar() {
    this.calendarIsCollapsed = !this.calendarIsCollapsed;
    this.ga.event('button', 'click', 'my orchard calendar toggle', this.calendarIsCollapsed ? 'hidden' : 'visible');
    setLocalStorageValue(this.stateLocalStorageKey, this.calendarIsCollapsed);
    window.dispatchEvent(new Event('resize'));
  }

  toggleExpandAll() {
    this.isExpanded = !this.isExpanded;
    this.data.forEach(category => {
      category.isOpen = this.isExpanded;
    });
  }

  private expandAll() {
    this.data.forEach(category => {
      category.isOpen = true;
    });
  }

  private applyCalendarStateToken() {
    const state = getLocalStorageValue(this.stateLocalStorageKey);
    if (state) {
      this.calendarIsCollapsed = JSON.parse(state);
    }
  }

  showItemContent(category, item) {
    this.ga.event('button', 'click', 'my orchard calendar show item', item.title);
    this.orchardCalendarService.showItemContent(category, item, this.messageService);
  }

  nextWeek() {
    this.ga.event('button', 'click', 'my orchard calendar next week');
    this.xTranslation -= this.gridItems[this.gridItems.length - 1].width;
    this.updateGridStart();
    this.updateRequestParameters();
  }

  previousWeek() {
    this.ga.event('button', 'click', 'my orchard calendar previous week');
    this.xTranslation += this.gridItems[0].width;
    this.updateGridEnd();
    this.updateRequestParameters();
  }

  onItemMouseEnter(item, event) {
    this.tooltipData = {
      title: item.title,
      content: item.cleanContent,
      footer: this.getFormattedDateRange(item)
    };
    this.updateTooltipPosition(event);
    this.isTooltipVisible = true;
  }

  onTouchStart(event) {
    this.dragStart(event.changedTouches[0].pageX);
  }

  onMouseDown(event) {
    this.dragStart(event.pageX);
  }

  onTouchMove(event) {
    this.drag(event.changedTouches[0].pageX);
  }

  onMouseMove(event) {
    this.drag(event.pageX);
    if (this.isTooltipVisible) {
      this.updateTooltipPosition(event);
    }
  }

  updateTooltipPosition(event) {
    const target = event.target;
    const rect = target.getBoundingClientRect();
    this.tooltipPosition = {
      top: rect.top + rect.height + 10,
      left: event.pageX
    };
  }

  onTouchEnd(event) {
    this.dragEnd(event.changedTouches[0].pageX);
  }

  onMouseUp(event) {
    this.dragEnd(event.pageX);
    event.stopPropagation();
  }

  onMouseLeave(event) {
    this.dragEnd(event.pageX);
  }

  onItemMouseLeave() {
    this.isTooltipVisible = false;
  }

  preventDefault(event) {
    event.stopPropagation();
  }

  toggleCategory(category: OrchardCalendarCategory) {
    if (!this.isDragging) {
      category.isOpen = !category.isOpen;
      this.ga.event('button', 'click', 'my orchard calendar category toggle', category.name);
    }
  }

  selectVarietyAndGrowMethod(varietyAndGrowMethod: VarietyAndGrowMethodDropdownItem) {
    if (this.selectedVarietyAndGrowMethod.label !== varietyAndGrowMethod.label) {
      this.selectedVarietyAndGrowMethod = varietyAndGrowMethod;
      this.updateRequestParameters();
      this.ga.event('dropdown', 'change', 'my orchard calendar variety gm', varietyAndGrowMethod.label);
    }
  }

  goToToday() {
    this.ga.event('button', 'click', 'my orchard calendar today');
    if (this.xTranslation) {
      this.xTranslation = 0;
      this.setupGrid(false);
      setTimeout(() => {
        this.updateRequestParameters();
      });
    }
  }

  private onMessage(message) {
    if (message.text === SettingsService.HELP_TOGGLE_MESSAGE) {
      this.changeDetectorRef.markForCheck();
    }
  }

  private getFormattedDateRange(item) {
    const formattedStart = this.datePipe.transform(item.start, this.humanDateFormat);
    const formattedEnd = this.datePipe.transform(item.end, this.humanDateFormat);
    return item.start === item.end ? formattedStart : formattedStart + ' - ' + formattedEnd;
  }

  private setup() {
    this.setGridWidth();
    this.setDateRange();
    this.setupDomainDates();
    this.setTimeScale();
    this.setupNow();
    this.setSingleDayWidth();
    this.setupGrid();
  }

  private update() {
    this.setGridWidth();
    this.setDateRange();
    this.setupDomainDates();
    this.setTimeScale();
    this.setupNow();
    this.setSingleDayWidth();
    this.updateGridItems();
    this.configureData(this.data, this.numWordsForCategoryTitle);
    this.configureCategories();
  }

  private async getVarietiesAndGrowMethods(): Promise<any> {
    this.isDataLoading = true;
    const result: any = await this.http.get('orchard_calendar/options/', { params: { product_id: ProductNameToId.Kiwifruit }}).toPromise();
    this.isDataLoading = false;
    if (result) {
      this.varietiesAndGrowMethods = result.map((item) => {
        return {
          label: item.variety_code + item.grow_method_code,
          value: { variety_id: item.variety_id, grow_method_id: item.grow_method_id }
        };
      });
      if (this.varietiesAndGrowMethods.length) {
        this.selectedVarietyAndGrowMethod = this.varietiesAndGrowMethods[0];
      }
    }
    return result;
  }

  private updateRequestParameters() {
    const range = this.getDateRangeFromOffset();
    const requestParameters = {
      pageSize: PAGE_SIZE,
      start: formatServerDate(range[0]),
      end: formatServerDate(range[1]),
      product_id: ProductNameToId.Kiwifruit
    };

    if (this.selectedVarietyAndGrowMethod) {
      requestParameters['variety_id'] = this.selectedVarietyAndGrowMethod.value.variety_id;
      requestParameters['grow_method_id'] = this.selectedVarietyAndGrowMethod.value.grow_method_id;
    }

    this.observableRequestParameters.next(requestParameters);
  }

  private subscribeToData() {
    return this.observableRequestParameters.pipe(
      debounceTime(SettingsService.DEBOUNCE_TIME),
      distinctUntilChanged(),
      switchMap((params) => {
        this.isError = false;
        this.isDataLoading = true;
        this.changeDetectorRef.markForCheck();
        return forkJoin(this.getDataRequests(params) as any);
      }),
      catchError(() => {
        this.isError = true;
        return of({});
      })
    ).subscribe(
      (result: OrchardCalendarData) => {
        this.isDataLoading = false;
        this.updateData(result);
        if (this.isExpanded) {
          this.expandAll();
        }
      }
    );
  }

  private getDataRequests(params): OrchardCalendarData {
    return {
      base: this.http.get('orchard_calendar/', { params: params }, true, true) as Observable<PaginatedResponse>,
      events: this.http.get('orchard_calendar/events/', { params: params }, true, true) as Observable<PaginatedResponse>
    };
  }

  private updateData(data: OrchardCalendarData) {
    const result = Object.values(data).reduce((accumulator, item) => {
      if (item && item.results) {
        accumulator = accumulator.concat(item.results);
      }
      return accumulator;
    }, []);
    this.configureData(result, this.numWordsForCategoryTitle);
    this.updateRenderedData(result);
    this.configureCategories();
    this.isInitialLoadComplete = true;
    this.changeDetectorRef.markForCheck();
    return result;
  }

  private updateRenderedData(newData: any[]) {
    if (this.data) {
      const oldDataIdToIndex = this.data.reduce((result, oldDataItem, index) => {
        oldDataItem.index = index;
        result[oldDataItem.id] = oldDataItem;
        return result;
      }, {} as any);

      const oldDataIds = Object.keys(oldDataIdToIndex).map(x => '' + x);
      newData.forEach((newDataItem) => {
        if (oldDataIds.indexOf('' + newDataItem.id) >= 0) {
          const oldDataItem = oldDataIdToIndex[newDataItem.id];
          newDataItem.isOpen = oldDataItem.isOpen;
          this.data[oldDataItem.index] = newDataItem;
        } else {
          this.data.push(newDataItem);
        }
      });

      this.sortBy(this.data, 'order');
    } else {
      this.data = newData;
    }
  }

  private setGridWidth() {
    this.gridWidth = this.grid.nativeElement.offsetWidth;
  }

  // The number of weeks is based on the display / grid width
  private setDateRange() {
    const minWeekWidth = this.minSingleDayWidth * WEEK_IN_DAYS;
    let numberOfWeeks = Math.floor(this.gridWidth / minWeekWidth);
    if (numberOfWeeks < this.minNumberOfWeeksToDisplay) {
      numberOfWeeks = this.minNumberOfWeeksToDisplay;
    }
    if (numberOfWeeks > this.maxNumberOfWeeksToDisplay) {
      numberOfWeeks = this.maxNumberOfWeeksToDisplay;
    }
    this.daysBack = WEEK_IN_DAYS;
    this.daysForward = (numberOfWeeks - 1) * WEEK_IN_DAYS;
    this.numDays = this.daysForward + this.daysBack;
  }

  private setTimeScale() {
    this.timeScale = d3.scaleTime().domain([this.startDate, this.endDate]).range([0, this.gridWidth]);
  }

  private setupDomainDates() {
    this.setStartDate();
    this.setEndDate();
  }

  private setStartDate() {
    this.startDate = this.getPreviousMonday(this.addDayToDate(this.now, -this.daysBack));
  }

  private setEndDate() {
    // Starting from the startDate ensures that we fall on week bounds
    this.endDate = this.addDayToDate(this.startDate, this.numDays);
  }

  private addDayToDate(sourceDate: Date | string, daysToAdd: number): Date {
    const date = new Date(sourceDate);
    date.setDate(date.getDate() + daysToAdd);
    date.setHours(0);
    date.setMinutes(0);
    date.setSeconds(0);
    return date;
  }

  private getPreviousMonday(date: Date) {
    const day = date.getDay();
    // Weekdays start on Sunday (0), Saturday (6)
    const daysBackToMonday = day === 0 ? 6 : date.getDay() - 1;
    return this.addDayToDate(date, -daysBackToMonday);
  }

  private setupNow() {
    this.nowLeft = this.timeScale(this.now);
    this.nowWidth = this.timeScale(this.addDayToDate(this.now, 1)) - this.nowLeft;
  }

  private resizeStarted() {
    this.isHidden = true;
  }

  private resizeEnded() {
    this.update();
    this.isHidden = false;
    this.changeDetectorRef.markForCheck();
  }

  private configureData(data: OrchardCalendarCategory[], numWordsForCategoryTitle = 1) {
    if (!data) { return; }
    let isFirstCategoryOpened = false;

    this.sortBy(data, 'order').forEach((category: OrchardCalendarCategory) => {
      // Open the first grouped category
      if (!this.isInitialLoadComplete && !isFirstCategoryOpened && category.grouped) {
        category.isOpen = true;
        isFirstCategoryOpened = true;
      }

      if (category.items && category.items.length) {
        let smallestLeft;
        let highestRight;
        let right;
        const categoryTitleParts = [];

        category.isDark = this.getColorBrightness(category.colour) < this.darkColourThreshold;

        category.items.forEach((item: OrchardCalendarItem) => {
          item.start = fixDateStringFormat(item.start);
          item.end = fixDateStringFormat(item.end);

          if (!item.content) {
            item.content = item.title || '';
          }

          categoryTitleParts.push(this.getCategoryTitle(item.title, numWordsForCategoryTitle));

          const fromDate = this.addDayToDate(item.start, 0);
          const endDate = this.addDayToDate(item.end, 0);
          item.left = this.getItemLeftPosition(fromDate);
          item.width = this.getItemWidth(fromDate, endDate, item);
          item.cleanContent = this.summaryPipe.transform(item.content, MAX_NUM_SUMMARY_WORDS);
          right = item.left + item.width;

          if (smallestLeft == null || item.left < smallestLeft) {
            smallestLeft = item.left;
          }
          if (highestRight == null || right > highestRight) {
            highestRight = right;
          }
        });

        if (!smallestLeft || !highestRight) {
          category.isEmpty = true;
        } else {
          category.title = this.deDup(categoryTitleParts).join(', ');
          category.left = smallestLeft;
          category.width = highestRight - smallestLeft;

          // Adjust the left positioning to account for the category being shifted
          category.items.forEach((item: OrchardCalendarItem) => {
            item.left -= category.left;
          });
        }

        this.itemPacker(category);
      } else {
        category.isEmpty = true;
      }
    });
  }

  private configureCategories() {
    if (!this.data) { return; }
    this.data.forEach((category: OrchardCalendarCategory) => {
      if (category.items && category.items.length) {
        this.configureCategoryHeader(category);
        this.configureCategoryDisplay(category);
        category.items.forEach((item: OrchardCalendarItem) => {
          this.configureItemDisplay(category, item);
        });
      }
    });
  }

  private configureCategoryHeader(category: OrchardCalendarCategory) {
    if (this.xTranslation > 0) {
      const diff = this.xTranslation + category.left;
      if (diff < 0) {
        category.headerLeft = -diff;
      } else {
        category.headerLeft = 0;
      }
    } else {
      const xTranslationAbs = -this.xTranslation;
      if (category.left < xTranslationAbs) {
        category.headerLeft = xTranslationAbs - category.left;
      } else {
        category.headerLeft = 0;
      }
    }
  }

  private configureCategoryDisplay(category: OrchardCalendarCategory) {
    category.inView = this.xTranslation + category.left + category.width > 0 && this.xTranslation + category.left < this.gridWidth;
  }

  private configureItemDisplay(category: OrchardCalendarCategory, item: OrchardCalendarItem) {
    item.inView = this.xTranslation + category.left + item.left + item.width > 0 &&
      this.xTranslation + category.left + item.left < this.gridWidth;
  }

  private deDup(source: any[]): any[] {
    return source.filter((value, i) => source.indexOf(value) === i);
  }

  private getCategoryTitle(src: string, numWords = 1): string {
    return this.getWords(src, numWords).replace(/[\W\- ]+$/, '');
  }

  private getWords(src: string, numWords = 1): string {
    return this.stripTags(src).split(' ').slice(0, numWords).join(' ');
  }

  private stripTags(src) {
    return src.replace(/<[^>]+>/g, '');
  }

  private getItemLeftPosition(fromDate: Date): number {
    return this.timeScale(fromDate);
  }

  private getItemWidth(fromDate: Date, endDate: Date, item: OrchardCalendarItem): number {
    const singleDayAdjustment = fromDate.getDate() === endDate.getDate() ? this.singleDayWidth : 0;
    const right = this.timeScale(endDate) + singleDayAdjustment;
    return right - item.left;
  }

  private setSingleDayWidth() {
    const day0 = this.addDayToDate(new Date(), 0);
    const day1 = this.addDayToDate(day0, 1);
    this.singleDayWidth = this.timeScale(day1) - this.timeScale(day0);
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Item Packer
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  private itemPacker(category) {
    const previousItems = [];
    const initialSpacing = category.grouped ? this.itemLineSpacing : -this.itemLineSpacing;
    let numberOfRows = 0;
    this.sortBy(category.items, 'left').forEach((item) => {
      this.packItem(item, previousItems, numberOfRows, category.grouped);
      if (item.rowNumber > numberOfRows) {
        numberOfRows = item.rowNumber;
      }
      previousItems.push(item);
    });
    const itemLineHeight = category.grouped ? this.itemGroupedLineHeight : this.itemLineHeight;
    category.height = ((numberOfRows + 1) * (itemLineHeight + this.itemLineSpacing)) + initialSpacing;
  }

  private packItem(item, previousItems, numberOfRows, isGrouped = true) {
    if (previousItems.length) {
      // Try to fit the new item as high up the previous item list as possible
      let itemPacked = false;
      const itemsInRows = this.itemsToRows(previousItems);

      for (const rowKey of Object.keys(itemsInRows)) {
        const rowItems = itemsInRows[rowKey];
        const lastItemInRow = rowItems[rowItems.length - 1];
        if (lastItemInRow && (item.left > lastItemInRow.left + lastItemInRow.width + 0.5)) {
          item.rowNumber = lastItemInRow.rowNumber;
          itemPacked = true;
          this.adjustMaxTitleWidthOfPreviousItem(lastItemInRow, item);
          break;
        }
      }

      if (!itemPacked) {
        // Create a new row
        item.rowNumber = numberOfRows + 1;
      }
    } else {
      item.rowNumber = 0;
    }

    const initialSpacing = isGrouped ? this.itemLineSpacing : 0;
    const itemLineHeight = isGrouped ? this.itemGroupedLineHeight : this.itemLineHeight;
    item.top = (item.rowNumber * (itemLineHeight + this.itemLineSpacing)) + initialSpacing;
  }

  private itemsToRows(items): { string: OrchardCalendarItem[] } {
    return items.reduce((result, item) => {
      if (result[item.rowNumber]) {
        result[item.rowNumber].push(item);
      } else {
        result[item.rowNumber] = [item];
      }
      return result;
    }, {});
  }

  private adjustMaxTitleWidthOfPreviousItem(previousItem, newItem) {
    previousItem.maxTitleWidth = newItem.left - previousItem.left;
  }

  private sortBy(items: any[], field: string): any[] {
    return items.sort((a, b) =>  a[field] - b[field]);
  }

  private getColorBrightness(colour: string): number {
    const rgb = parseInt(colour, 16);
    // ITU-R BT.709
    return 0.2126 * ((rgb >> 16) & 0xff) + 0.7152 * ((rgb >>  8) & 0xff) + 0.0722 * ((rgb >>  0) & 0xff);
  }

  private getDateRangeFromOffset(): [Date, Date] {
    const translateX = this.getTranslateXValueFromElement(this.grid.nativeElement);
    const startDate = this.timeScale.invert(-translateX);
    const endDate = this.addDayToDate(startDate, this.numDays);
    return [startDate, endDate];
  }

  private getTranslateXValueFromElement(nativeElement): number {
    return parseFloat(nativeElement.style.transform.replace(/[^\-\d.]/g, ''));
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Drag
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  private dragStart(x: number) {
    this.dragStartX = x;
    this.dragXTranslation = this.xTranslation;
    this.isDragging = false;
  }

  private drag(x: number) {
    if (this.dragStartX != null) {
      this.isDragging = true;
      this.xTranslation = this.dragXTranslation + x - this.dragStartX;
      this.dragUpdateGrid(x);
    }
  }

  private dragEnd(x: number) {
    if (Math.abs(x - this.dragStartX) < this.minDragDiff) {
      // Don't do anything on a 'click'
      this.xTranslation = this.dragXTranslation;
      this.dragStartX = null;
      this.isDragging = false;
      return;
    }

    if (!this.isDragging) {
      // Don't do anything if the user simply moved the mouse in and out of the component
      return;
    }

    const [startDate, _] = this.getDateRangeFromOffset();
    let closestMonday = this.getPreviousMonday(startDate);

    // When the user is dragging to the left make sure the previous monday is ahead
    if (x < this.dragStartX) {
      closestMonday = this.addDayToDate(closestMonday, 7);
    }
    this.xTranslation = -this.getItemLeftPosition(closestMonday);
    this.updateRequestParameters();
    this.dragUpdateGrid(x);
    this.dragStartX = null;
    setTimeout(() => {
      this.isDragging = false;
    });
  }

  private dragUpdateGrid(x: number) {
    if (x < this.dragStartX) {
      this.updateGridStart();
    } else {
      this.updateGridEnd();
    }
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // GRID
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  private setupGrid(setLimits = true) {
    this.addFirstGridItem();
    // Double calls are intentional
    this.pushGridItems();
    this.pushGridItems();
    this.unshiftGridItems();
    this.unshiftGridItems();
    if (setLimits) {
      this.setGridLimits();
    }
  }

  private addFirstGridItem() {
    const newDate = this.startDate;
    const nextDate = this.addDayToDate(newDate, this.gridInterval);
    const left = this.timeScale(newDate);
    const width = this.timeScale(nextDate) - left;

    this.gridItems = [{
      left: left,
      width: width,
      date: newDate,
      nextDate: nextDate,
      showMonth: newDate.getMonth() !== nextDate.getMonth(),
      isOdd: true
    }];
  }

  private pushGridItems() {
    let previousGridItem = this.gridItems[this.gridItems.length - 1];
    for (let i = 0; i < this.gridItemBufferSize; i++) {
      previousGridItem = this.makePushGridItem(previousGridItem);
      this.gridItems.push(previousGridItem);
    }
  }

  private updateGridStart() {
    if (this.gridItems[this.gridItems.length - this.gridItemBufferSize].left + this.xTranslation < this.gridStartLimit) {
      this.pushGridItems();
      this.shiftGridItems();
    }
  }

  private updateGridEnd() {
    if (
      this.gridItems
      && this.gridItems[this.gridItemBufferSize]
      && this.gridItems[this.gridItemBufferSize].left + this.xTranslation > this.gridEndLimit
    ) {
      this.unshiftGridItems();
      this.popGridItems();
    }
  }

  private makePushGridItem(previousGridItem) {
    const newDate = previousGridItem.nextDate;
    const nextDate = this.addDayToDate(newDate, this.gridInterval);
    const left = this.timeScale(newDate);
    const width = this.timeScale(nextDate) - left;

    return {
      left: left,
      width: width,
      date: newDate,
      nextDate: nextDate,
      showMonth: previousGridItem.date.getMonth() !== newDate.getMonth(),
      isOdd: !previousGridItem.isOdd
    };
  }

  private shiftGridItems() {
    for (let i = 0; i < this.gridItemBufferSize; i++) {
      this.gridItems.shift();
    }
  }

  private unshiftGridItems() {
    let gridItem = this.gridItems[0];
    for (let i = 0; i < this.gridItemBufferSize; i++) {
      gridItem = this.makeUnshiftGridItem(gridItem);
      this.gridItems.unshift(gridItem);
    }
  }

  private makeUnshiftGridItem(firstGridItem) {
    const nextDate = firstGridItem.date;
    const newDate = this.addDayToDate(nextDate, -this.gridInterval);
    const left = this.timeScale(newDate);
    const width = this.timeScale(nextDate) - left;
    firstGridItem.showMonth = newDate.getMonth() !== nextDate.getMonth();

    return {
      left: left,
      width: width,
      date: newDate,
      nextDate: nextDate,
      showMonth: false,
      isOdd: !firstGridItem.isOdd
    };
  }

  private popGridItems() {
    for (let i = 0; i < this.gridItemBufferSize; i++) {
      this.gridItems.pop();
    }
  }

  private setGridLimits() {
    this.gridStartLimit = this.gridItems[this.gridItems.length - this.gridItemBufferSize].left;
    this.gridEndLimit = this.gridItems[this.gridItemBufferSize].left;
  }

  private updateGridItems() {
    let left;
    let width;
    this.gridItems.forEach((gridItem) => {
      left = this.timeScale(gridItem.date);
      width = this.timeScale(gridItem.nextDate) - left;
      gridItem.left = left;
      gridItem.width = width;
    });
  }
}
