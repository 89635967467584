import { Injectable } from '@angular/core';

export interface ScrollCallbacks {
  [index: string]: (event: MouseEvent) => any;
}

@Injectable()
export class GrowerPortalScrollService {
  private callbacks: ScrollCallbacks = {};

  addCallback(name: string, callback: (event: MouseEvent) => any) {
    this.callbacks[name] = callback;
  }

  onScroll(event: MouseEvent) {
    Object.values(this.callbacks).forEach((callback) => {
      callback(event);
    });
  }
}
