import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { HttpService } from 'app/shared/services/http.service';
import { WidgetHeaderButton } from 'app/widget/widget.component';
import {
  SampleInformationItem,
  SampleInformationWidget
} from 'app/sample-information-widget/sample-information-widget';
import { GaService } from 'app/shared/services/ga.service';

export const GOLD_VARIETIES = ['GA'];
export const SEASONS_AND_ORCHARDS_ENDPOINT = 'growers/kiwifruit/maturity_samples/seasons_and_orchards/';
export const DATA_ENDPOINT = 'growers/kiwifruit/maturity_samples/summary/';
const CTP_REPORT_ENDPOINT = '/api2/v1/orchards/maturity_areas/reports/kiwifruit/clearance_to_pick/';
const RESULTS_REPORT_ENDPOINT = '/api2/v1/orchards/maturity_samples/sample/{sampleNumber}/results/';

export interface KiwifruitSampleInformationItem extends SampleInformationItem {
  brix_average: number;
  brix_fractile_lower: number;
  brix_fractile_upper: number;
  brix_percent_off_fully_ripe: number;
  hue_average: number;
  colour_threshold: number;
  pressure_average: number;
  firmness_precent_less_than_4kgf: number;
  softs_fractile: number;
  percentage_black_seeds: number;
  dry_matter_threshold: number;
}

export interface SeasonAndOrchardItem {
  season: number;
  grower_number: string;
  orchard_id: number;
}

@Component({
  selector: 'grow-kiwifruit-sample-information-widget',
  templateUrl: './kiwifruit-sample-information-widget.component.html',
  styleUrls: ['./kiwifruit-sample-information-widget.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class KiwifruitSampleInformationWidgetComponent extends SampleInformationWidget<KiwifruitSampleInformationItem> implements OnInit {
  @Input() updatedAt: Date = null;
  @Input() emptyPlaceholder = 'NA';
  gridColumns = [
    { label: 'KPIN', align: 'left', key: 'grower_number', rowspan: 2 },
    { label: 'MA', align: 'left', key: 'maturity_area', rowspan: 2 },
    { label: 'Type', align: 'left', key: 'type', rowspan: 2 },
    { label: 'Collected', align: 'left', key: 'collect_date', rowspan: 2 },
    {
      label: 'Brix', align: 'center', cssClass: 'brix', childColumns: [
        { label: 'Avg', align: 'left', key: 'brix_average', precision: 1 },
        { label: 'BWFR', align: 'left', key: 'brix_percent_off_fully_ripe', unit: '%', tooltipContent: 'Brix as % Brix When Fully Ripe' },
      ]
    },
    {
      label: 'Firmness', align: 'center', cssClass: 'firmness',  childColumns: [
        { label: 'Avg', align: 'left', key: 'pressure_average', precision: 1 },
        { label: '% < 4kgf', align: 'left', key: 'firmness_precent_less_than_4kgf', precision: 1, unit: '%' },
      ]
    },
    {
      label: 'Status',
      align: 'left',
      key: 'sample_status',
      subValue: 'clearance_date',
      badValue: 'Failed',
      goodValue: 'Cleared',
      rowspan: 2
    },
    {
      label: 'Report',
      align: 'center',
      key: 'report',
      allowEmpty: true,
      cssClass: 'download-report',
      action: (item) => {
        return this.onDownloadSampleResultsClick(RESULTS_REPORT_ENDPOINT, item);
      },
      rowspan: 2
    }
  ];
  headerButtons: WidgetHeaderButton[] = [
    {
      text: 'CTP',
      disabled: true,
      getUrl: () => {
        return this.getCtpReportUrl(CTP_REPORT_ENDPOINT);
      }
    }
  ];
  showColourData = false;
  showBlackSeedData = false;

  constructor(
    protected http: HttpService,
    protected changeDetectorRef: ChangeDetectorRef,
    protected ga: GaService
  ) {
    super(http, changeDetectorRef, ga);
  }

  async ngOnInit() {
    await this.execWithLoading(() => {
      return this.init(DATA_ENDPOINT, SEASONS_AND_ORCHARDS_ENDPOINT);
    });
  }

  onGridRowSelect(row: KiwifruitSampleInformationItem) {
    this.selectRow(row);
    this.updateHeaderButtonState();
  }

  protected showDetails() {
    setTimeout(() => {
      this.areDetailsVisible = true;
    }, 500);
  }

  protected updateFieldVisibility() {
    if (GOLD_VARIETIES.includes(this.selectedTab.variety_code)) {
      this.showBlackSeedData = false;
      this.showColourData = true;
    } else {
      this.showBlackSeedData = true;
      this.showColourData = false;
    }
  }

  private updateHeaderButtonState() {
    if (this.selectedRow?.ctp_complete) {
      this.enableCtpButton();
    } else {
      this.disableCtpButton();
    }
    this.changeDetectorRef.markForCheck();
  }

  private enableCtpButton() {
    this.headerButtons[0].disabled = false;
  }

  private disableCtpButton() {
    this.headerButtons[0].disabled = true;
  }
}
