import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { WindowContainerComponent } from './window-container.component';
import { WindowModule } from '@progress/kendo-angular-dialog';
import { LoadingIndicatorModule } from '../loading-indicator/loading-indicator.module';

@NgModule({
  declarations: [
    WindowContainerComponent
  ],
  imports: [
    CommonModule,
    ButtonsModule,
    WindowModule,
    LoadingIndicatorModule
  ],
  exports: [
    WindowContainerComponent
  ],
})
export class WindowContainerModule {}
