import { TutorialStep, AttachDirection } from 'app/shared/services/tutorial.service';

class LatestNewsWidgetStep extends TutorialStep {
  constructor(step) {
    super(step);
    this.id = 'latest-news';
    this.title = 'Latest News';
    this.arrow = true;
    this.scrollTo = true;
  }
}

export function getLatestNewsSteps(): LatestNewsWidgetStep[] {
  return [
    new LatestNewsWidgetStep ({
      attachTo: {
        element: '.latest-news-card',
        on: AttachDirection.left
      },
      text: 'The Latest News widget helps you keep track of any Trevelyan’s news updates and events.'
    }),
    new LatestNewsWidgetStep ({
      attachTo: {
        element: '.news-item',
        on: AttachDirection.bottom
      },
      text: 'Clicking on a heading expands the item to show a preview of the content.'
    }),
    new LatestNewsWidgetStep ({
      attachTo: {
        element: '.latest-news-card .more-button',
        on: AttachDirection.bottom
      },
      text: 'Click on the More button to view the update in full.'
    }),
  ];
}
