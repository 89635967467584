<table [class.alternating-column-bg]="alternatingColumnBg" [class.has-column-select]="hasColumnSelect">
  <colgroup *ngIf="colgroup">
    <col *ngFor="let col of colgroup" [span]="col.span" [class]="col.cssClass">
  </colgroup>
  <thead [class.multi-row-header]="headerRows?.length > 1">
    <tr *ngFor="let row of headerRows; index as i">
      <ng-container *ngFor="let column of row; index as j">
        <th *ngIf="!(hiddenColumns.includes(column.tabGroup) || hiddenColumns.includes(column.key))"
            [class]="column.align + ' ' + column.cssClass"
            [class.selected-row]="data && selectedRowIndexes && selectedRowIndexes.length === data.length"
            [class.selected-column]="selectedColumnKey === column.key"
            [class.selectable]="column.isSelectable"
            [class.has-tooltip]="column.tooltipContent"
            [class.has-pager]="column.pager"
            [class.has-dropdown]="column.dropdown"
            [attr.colspan]="column.colspan"
            [attr.rowspan]="column.rowspan"
            (click)="selectColumn(column, $event, j)"
            (mouseenter)="showBasicTooltip(column.tooltipContent, $event)"
            (mouseleave)="hideTooltip()">
          <app-grid-pager *ngIf="column.pager"
                          class="pager"
                          [label]="column.pager.label"
                          [separator]="column.pager.separator"
                          [pageSize]="column.pager.pageSize"
                          [currentPage]="column.pager.currentPage || 1"
                          [onChange]="column.pager.onChange"
                          [from]="column.pager.from"
                          [to]="column.pager.to">
          </app-grid-pager>
          <dropdown *ngIf="column.dropdown?.items?.length && column.dropdown?.current"
                    class="dropdown"
                    [items]="column.dropdown.items"
                    [current]="column.dropdown.current"
                    [handle]="'gridDropdownRow' + i + 'Col' + j"
                    [highlightSelected]="true"
                    [maxHeight]="maxDropdownHeight"
                    [alignLeft]="true"
                    (currentChange)="column.dropdown.onChange($event)">
            <button class="button transparent tiny rounded bordered">
              {{ column.dropdown.prefix || '' }}{{ column.dropdown.current.label }}
              <i class="fas fa-chevron-right right"></i>
            </button>
          </dropdown>
          <span class="label" [innerHTML]="column.label"></span>
          <div *ngIf="column.hasSelectAll"
               class="multi-select"
               (click)="toggleSelectAllRows()">
          </div>
        </th>
      </ng-container>
    </tr>
  </thead>
  <tbody>
    <ng-container *ngFor="let row of data; index as i">
      <tr *ngIf="!row.isHidden"
        [class]="'row level-' + row.level"
        [class.selected-row]="selectedRowIndexes.indexOf(i) >= 0"
        [class.open]="row.isOpen"
        [class.not-selectable]="row.isNotSelectable"
        (click)="delayedSelectRow(row, i, $event, true)"
        (dblclick)="toggleRow(row, $event)">
        <td *ngIf="rowSelectType !== 'none' && showRowSelect"
            class="row-select"
            (click)="selectRow(row, i, $event)">
          <div [class]="rowSelectType +'-select'"></div>
        </td>
      <ng-container *ngFor="let column of flatColumns; index as j">
        <td *ngIf="!(hiddenColumns.includes(column.tabGroup) || hiddenColumns.includes(column.key)) && (!row.error || (j <= row.error.startColumn || j >= row.error.startColumn + row.error.numColumns))"
            [class]="column.align + ' ' + column.cssClass"
            [class.empty]="column.zeroIsValid ? row[column.key] == null : !row[column.key]"
            [class.selectable]="(!row.error || j !== row.error.startColumn) && column.isSelectable"
            [class.selected-column]="(!row.error || j !== row.error.startColumn) && selectedColumnKey === column.key"
            [class.delta]="column.isDelta"
            [class.negative]="row[column.key] < 0"
            [class.positive]="row[column.key] > 0"
            [class.has-heat-map]="column.hasHeatMap"
            [class.actionable]="(!row.error || j !== row.error.startColumn) && column.action"
            [class.has-message]="column.messages && column.messages[row[column.key]]"
            [class.has-highlight]="gridTooltip.coordinates.row === i && gridTooltip.coordinates.column === j"
            [class.error]="row.error && j === row.error.startColumn"
            [attr.colspan]="!row.error || j < row.error.startColumn ? 1 : row.error.numColumns"
            (click)="onCellClick(row, column, $event)"
            (mouseenter)="showTooltip(i, j, column.key, row, $event)"
            (mouseleave)="hideTooltip()">
          <div *ngIf="row.error && j === row.error.startColumn" class="error-message">
            <i class="fas fa-exclamation-triangle"></i>
            {{row.error.message}}
          </div>
          <ng-container *ngIf="!row.error || j !== row.error.startColumn">
            <div class="value"
              [class.good]="column.goodValue != null && (row[column.key] === column.goodValue)"
              [class.bad]="column.badValue != null && (row[column.key] === column.badValue)">
              {{ row[column.key] | formatValue: column.prefix:column.precision:column.emptyPlaceholder||emptyPlaceholder:column.allowEmpty: column.isPercent }}
              <span class="unit" *ngIf="row[column.key] && column.unit">
                {{ column.unit }}
              </span>
              <span class="sub-value" *ngIf="(row[column.key] || column.alwaysShowSubValue) && column.subValue">
                {{ row[column.subValue] | formatValue:'': column.subValuePrecision }}
              </span>
            </div>
            <div *ngIf="j === 0 && row.children && row.children.length"
                class="row-toggle fas fa-chevron-right"
                (click)="toggleRow(row, $event)">
            </div>
            <div *ngIf="column.hasHeatMap" class="heat-map-item" [style.opacity]="row[column.key]/row['heat_map_max']"></div>
          </ng-container>
        </td>
      </ng-container>
    </tr>
    </ng-container>
  </tbody>
</table>
<app-tooltip class="tooltip"
             [direction]="gridTooltip.dir"
             [width]="gridTooltip.width"
             [top]="gridTooltip.position.top"
             [bottom]="gridTooltip.position.bottom"
             [left]="gridTooltip.position.left"
             [visible]="!!(gridTooltip.data?.length || gridTooltip.content)">
  <div *ngIf="gridTooltip.content" [innerHTML]="gridTooltip.content"></div>
  <ng-container *ngIf="gridTooltip.data?.length">
    <div *ngFor="let row of gridTooltip.data" class="row" [class.is-empty]="row.isEmpty">
      <div class="label">{{ row.label }}</div>
      <div class="value">{{ row.value }}</div>
    </div>
  </ng-container>
</app-tooltip>
