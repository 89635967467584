<div class="controls">
  <div class="control hide-self-picks-switch">
    <kendo-label [for]="selfPicksSwitch" text="Self Picks?"></kendo-label>
    <kendo-switch #selfPicksSwitch
                  [checked]="showSelfPicks"
                  onLabel="Yes"
                  offLabel="No"
                  (valueChange)="toggleSelfPicks()">
    </kendo-switch>
  </div>
  <div class="control">
    <dropdown *ngIf="availableSeasons?.length"
              class="season-selector"
              [items]="availableSeasons"
              [current]="selectedSeason"
              handle="packoutDetailsSeasonDropdown"
              [highlightSelected]="true"
              (currentChange)="selectSeason($event)">
      <button class="button transparent tiny rounded bordered">
        Season: {{ selectedSeason.label }}
        <i class="fas fa-chevron-right right"></i>
      </button>
    </dropdown>
  </div>
</div>

<div class="main-content">
  <kendo-grid class="document-library-grid"
              [kendoGridBinding]="data"
              [sortable]="true">
    <kendo-grid-column class="contractor-name"
                       field="contractor_name"
                       title="Contractor">
      <ng-template kendoGridCellTemplate let-dataItem>
        <div class="cell-content">
          <div class="text">{{ dataItem['contractor_name'] }}</div>
          <div *ngIf="dataItem['is_self_pick']" class="badge" title="Self pick">SP</div>
        </div>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column class="center rank heatmap"
                       headerClass="vertical center rank"
                       field="rank"
                       title=""
                       [width]="50">
      <ng-template kendoGridHeaderTemplate>
        <div title="Rank is calculated by weighting the failure percentage so that contractors with a similar score but more audits are ranked higher.">
          <span>Rank</span>
          <i class="fas fa-question-circle"></i>
        </div>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        <app-heatmap-item class="heatmap-cell"
                          [percent]="worstRank > 1 ? ((dataItem['rank'] - 1) / (worstRank - 1)) * dataLengthScalingFactor : 0">
          {{ dataItem['rank'] }}
        </app-heatmap-item>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column class="center number-of-audits"
                       headerClass="vertical center number-of-audits"
                       field="number_of_audits"
                       title="Audits"
                       [width]="50"></kendo-grid-column>
    <kendo-grid-column class="spacer hide-on-mobile"
                       headerClass="spacer hide-on-mobile"></kendo-grid-column>
    <kendo-grid-column *ngFor="let criteria of criteriaFields"
                       class="heatmap hide-on-mobile"
                       headerClass="vertical center heatmap hide-on-mobile"
                       [field]="criteria.field"
                       [title]="criteria.title"
                       [width]="50">
      <ng-template kendoGridCellTemplate let-dataItem let-column="column">
        <app-heatmap-item class="heatmap-cell"
                          [percent]="dataItem[column.field]">
          {{ dataItem[column.field] | percent }}
        </app-heatmap-item>
      </ng-template>
    </kendo-grid-column>
  </kendo-grid>
</div>
<div class="sidebar">
  <div class="sidebar-handle" (click)="toggleSidebar()"></div>
  <div class="pages">
    <div class="page">
      <div class="panel">
        <h4>Audit Summary</h4>
        <h5>Total Audits: <strong>{{ numberOfAudits }}</strong></h5>
      </div>
      <div class="panel">
        <h5>Control Points</h5>
        <app-simple-bar-chart [columns]="summaryDataColumns"
                              [data]="summaryData"
                              [altTheme]="true"
                              [valueOutsideBar]="true"
                              [valueBg]="true"
                              [precision]="1"></app-simple-bar-chart>
      </div>
    </div>
  </div>
</div>
<loading-indicator [class.visible]="loadingMessage"
                   [isAbsolute]="true"
                   [message]="loadingMessage"
                   [radius]="75"
                   [isGrowerPortal]="true"></loading-indicator>
