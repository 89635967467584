import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { HttpService } from 'app/shared/services/http.service';
import { HelpService } from 'app/shared/services/help.service';
import { CalculatorBase } from 'app/calculators/calculator-base';
import { Orchard, VarietyGrowMethod, VarietyOption } from 'app/interfaces/calculator.interface';
import { Observable, of, finalize, switchMap, take, tap } from 'rxjs';
import { TutorialService } from 'app/shared/services/tutorial.service';
import { PACKING_CHARGES_ENDPOINT, PackingCharge } from 'app/packout-details/packout-details.component';

export const ORCHARDS_LIST_ENDPOINT = 'growers/kiwifruit/calculators/cosmetic_thinning/orchards/';

export interface ValidatableFields {
  [index: string]: {
    min?: number;
    max?: number;
    isValid?: boolean;
  };
}

// Picking rate is an median of the 2023 GA and HW bin rates, over an average of 85 trays per bin
const PICKING_RATE_PER_BIN = 53.405;
// Cartage is the 2023 "Te Puke Area" rate, over an average of 85 trays per bin
const CARTAGE_RATE_PER_BIN = 7.55;

@Component({
  selector: 'cosmetic-thinning-calculator',
  templateUrl: './cosmetic-thinning-calculator.component.html',
  styleUrls: ['./cosmetic-thinning-calculator.component.scss']
})
export class CosmeticThinningCalculatorComponent extends CalculatorBase implements OnInit {
  @Input() @HostBinding('class.embedded') embedded = false;
  readonly helpKey = 'cosmetic-thinning-calculator';
  chargeableRejectsBeforeThinningPercent = 0;
  chargeableRejectsBeforeThinningValue = 0;
  chargeableRejectsAfterThinningPercent = 0;
  chargeableRejectsAfterThinningValue = 0;
  traysPerHa = 12425;
  savings = {
    surcharge: 0,
    picking: 0,
    cartage: 0,
    total: 0
  };
  costOfThinning = 0;
  marginalBenefit = 0;
  validatableFields: ValidatableFields = {
    chargeableRejectsBeforeThinningPercent: { min: 0, max: 100, isValid: true },
    chargeableRejectsAfterThinningPercent: { min: 0, max: 100, isValid: true },
    traysPerHa: { min: 0, isValid: true },
    costOfThinning: { min: 0, isValid: true }
  };
  private packingCharges: PackingCharge[] = [];

  constructor(
    private _helpService: HelpService,
    private http: HttpService,
    private tutorialService: TutorialService
  ) {
    super(_helpService);
  }

  ngOnInit() {
    this.tutorialService.hideTutorialFooterButton();
    this.fetchCalculatorDescription();
    this.load(this.fetchOrchards.bind(this));
  }

  calculate() {
    this.validate();
    this.chargeableRejectsBeforeThinningValue = this.getChargeableRejectsValue(this.chargeableRejectsBeforeThinningPercent);
    this.chargeableRejectsAfterThinningValue = this.getChargeableRejectsValue(this.chargeableRejectsAfterThinningPercent);
    const chargeableRejectsValueDiff = this.chargeableRejectsBeforeThinningValue - this.chargeableRejectsAfterThinningValue;
    const chargeableRejectsPercentDiff = this.chargeableRejectsBeforeThinningPercent - this.chargeableRejectsAfterThinningPercent;
    this.savings.surcharge = chargeableRejectsValueDiff * this.traysPerHa;
    this.savings.picking = PICKING_RATE_PER_BIN / 85 * chargeableRejectsPercentDiff * this.traysPerHa / 100;
    this.savings.cartage = CARTAGE_RATE_PER_BIN / 85 * chargeableRejectsPercentDiff * this.traysPerHa / 100;
    this.savings.total = this.savings.surcharge + this.savings.picking + this.savings.cartage;
    this.marginalBenefit = this.savings.total - this.costOfThinning;
  }

  onVarietySelect(variety: VarietyOption) {
    super.onVarietySelect(variety);
    this.setDefaults(variety);
    this.calculate();
  }

  private validate() {
    Object.keys(this.validatableFields).forEach((field) => {
      this.validateField(field);
    });
  }

  private validateField(field: string) {
    const rules = this.validatableFields[field];
    const value = this[field];
    rules.isValid = !(isNaN(value) || (rules.min != null && (value < rules.min)) || (rules.max != null && (value > rules.max)));
  }

  private getChargeableRejectsValue(chargeableRejects: number): number {
    const packingCharge = this.packingCharges.find((charge) => {
      return charge.variety === this.selectedVariety.variety_id &&
        charge.grow_method === this.selectedGrowMethod.grow_method_id;
    });

    if (!packingCharge) {
      return 0;
    }

    let rejectCharge = 0;
    const topBracket = packingCharge.rejects_second_bracket_limit;
    const middleBracket = packingCharge.rejects_first_bracket_limit;

    if (chargeableRejects > topBracket) {
      rejectCharge += (chargeableRejects - topBracket) * packingCharge.rejects_third_bracket_rate;
      chargeableRejects = topBracket;
    }
    if (chargeableRejects > middleBracket) {
      rejectCharge += (chargeableRejects - middleBracket) * packingCharge.rejects_second_bracket_rate;
      chargeableRejects = middleBracket;
    }
    rejectCharge += (chargeableRejects) * packingCharge.rejects_first_bracket_rate;

    return rejectCharge;
  }

  private load(dataGetter: Function) {
    this.isLoading = true;
    const result = dataGetter() as Observable<Object>;
    result.pipe(
      take(1),
      finalize(() => this.isLoading = false)
    ).subscribe();
  }

  private fetchOrchards(): Observable<Object> {
    return this.http.get(ORCHARDS_LIST_ENDPOINT)
      .pipe(
        switchMap((orchardOptions: Orchard[]) => {
          this.orchardOptions = orchardOptions;
          if (this.orchardOptions.length) {
            this.onOrchardSelect(this.orchardOptions[0]);
            return this.fetchPackingChargesForSeason();
          }
          return of();
        })
      );
  }

  private fetchPackingChargesForSeason(): Observable<Object> {
    // The fallback params lets the server return the latest previous seasons charges if the current season has no charges
    const params: any = { season: this.selectedOrchard.season, fallback: 1 };

    return this.http.get(PACKING_CHARGES_ENDPOINT, { params: params })
      .pipe(
        tap((packingCharges: PackingCharge[]) => {
          this.packingCharges = packingCharges;
          if (this.packingCharges.length > 0) {
            this.calculate();
          }
        })
      );
  }

  private setDefaults(variety: VarietyOption) {
    this.chargeableRejectsBeforeThinningPercent = variety.data.estimated_chargeable_rejects_before_thinning || 0;
    this.chargeableRejectsAfterThinningPercent = variety.data.estimated_chargeable_rejects_after_thinning || 0;
    this.traysPerHa = variety.data.trays_per_ha || (variety.variety_code === 'HW' ? 10000 : 14000);
  }

  protected getUniqueVarietyOptions(varietyGrowMethods: VarietyGrowMethod[]): VarietyOption[] {
    const uniqueVarieties = varietyGrowMethods.reduce((result, item) => {
      result[item.variety_id] = item;
      return result;
    }, {});
    return Object.keys(uniqueVarieties).map(key => {
      const variety = uniqueVarieties[key];
      return {
        variety_id: variety.variety_id,
        variety_name: variety.variety_name,
        variety_code: variety.variety_code,
        data: {
          estimated_chargeable_rejects_after_thinning: variety.estimated_chargeable_rejects_after_thinning,
          estimated_chargeable_rejects_before_thinning: variety.estimated_chargeable_rejects_before_thinning,
          trays_per_ha: variety.trays_per_ha
        }
      };
    });
  }
}
