<grower-portal-badge *ngIf="badge"
                     class="badge rounded"
                     [type]="badge.type"
                     [icon]="badge.icon"
                     [value]="badge.value"
                     [text]="badge.text"></grower-portal-badge>
<i [class]="'fas ' + icon"></i>
<div class="content">
  <h2>{{ label }}</h2>
  <div *ngIf="meta?.length" class="meta">
    <div *ngFor="let item of meta" class="meta-item">
      <i *ngIf="item.icon" [class]="'fas ' + item.icon"></i>
      <div class="text">{{ item.text }}</div>
    </div>
  </div>
  <i class="more-arrow fa fa-chevron-right"></i>
</div>
