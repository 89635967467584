import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  ViewChild,
  ElementRef,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  EventEmitter,
  Output
} from '@angular/core';
import { compare } from '../shared/services/utils.service';
import * as d3 from 'd3';
import { AxisSettings, ChartMargin } from 'app/interfaces/chart.interface';
import { TooltipData } from 'app/tooltip/tooltip.component';
import { ResizeService } from 'app/shared/services/resize.service';
import { Subject, Subscription } from 'rxjs';
import { SettingsService } from 'app/shared/services/settings.service';
import { FormatValuePipe } from 'app/shared/pipes/format-value.pipe';

export interface TooltipColumnConfig {
  key: string | number;
  label?: string;
  unit?: string;
  precision?: number;
  isPercent?: boolean;
}

export interface TooltipConfig {
  width?: number;
  title?: {
    prefix?: string;
    key?: string;
    suffix?: string;
  };
  content: {
    showHeader: boolean;
    columns: TooltipColumnConfig[];
  };
  showFooter?: boolean;
  precision?: number;
  isPercent?: boolean;
  emptyPlaceholder?: string;
}

export interface LineChartDataItemValue {
  x: string;
  y: number;
  isHidden?: boolean;
  [index: string]: number|string|boolean;
}

export interface LineChartDataItem {
  label: string;
  cssClass: string;
  values: LineChartDataItemValue[];
  onlyTooltip?: boolean;
  tooltipPosition?: 'footer'|'content';
}

export interface TickPadding {
  x: number;
  y: number;
}

@Component({
  selector: 'app-line-chart',
  templateUrl: './line-chart.component.html',
  styleUrls: ['./line-chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LineChartComponent implements OnInit, OnDestroy {
  @Input() legendLocation: 'top'|'bottom' = 'bottom';
  @Input() noDataMessage = 'No data.';
  @Input() noDataSubMessage = 'Please select an item above.';
  @Input() showAverage = true;
  @Input() showLegend = true;
  @Input() minYValue: number;
  @Input() maxYValue: number;
  @Input() showXGrid = false;
  @Input() showYGrid = true;
  @Input() tickPadding: TickPadding = { x: 15, y: 15 };
  @Input() margin: ChartMargin = { top: 15, right: 10, bottom: 30, left: 35 };
  @Input() axisSettings: AxisSettings = null;
  @Input() tooltipConfig: TooltipConfig;
  @Input() hasTooltip = false;
  @Input() hasNoDataMessage = true;
  @Input() transitionDuration = SettingsService.DEFAULT_CHART_TRANSITION_DURATION;
  @Input() numTicks = 10;
  @Input() includeGridLineAboveHighestValue = true;
  @Input() isYValueAPercentage = false;
  @Output() xStepChange = new EventEmitter<number>();
  @ViewChild('chartLegend', { static: true }) chartLegend: ElementRef;
  @ViewChild('chart', { static: true }) chart: ElementRef;
  @ViewChild('chartContainer', { static: true }) chartContainer: ElementRef;
  @ViewChild('chartFrame', { static: true }) chartFrame: ElementRef;
  hasNoData = true;
  width = 300;
  height = 300;
  legendItems: any[] = [];
  hideSvg = false;
  hideLegend = false;
  tooltip: TooltipData = { title: '', content: '', footer: '', left: 0, isVisible: false, display: 'block' };
  chartData: LineChartDataItem[] = [];
  private unfilteredData: LineChartDataItem[] = [];
  private chartD3: any = null;
  private xValues: string[];
  private xScale;
  private xScaleInverse;
  private yScale;
  private line;
  private lineGroups;
  private lines;
  private xAxis;
  private yAxis;
  private xAxisElement;
  private yAxisElement;
  private range: { x: [number, number], y: [number, number] };
  private domain: { x?: [number, number], y: [number, number] };
  private interactiveLayer: any;
  private xStep: number;
  private chartContentWidth: number;
  private chartContentHeight: number;
  private hideTooltipTimeoutHandle: any;
  private previousTooltipXValue: string|number;
  private previousTooltipXIndex: number;
  private tooltipSubscription: Subscription;
  private forceUpdateSubscription: Subscription;
  private formatValuePipe = new FormatValuePipe();
  private readonly X_UPDATE_DELAY = 100;
  private readonly HIDE_TOOLTIP_DELAY = 2000;

  @Input()
  set tooltipX(tooltipSubject: Subject<string|number>) {
    if (tooltipSubject) {
      this.tooltipSubscription = tooltipSubject.subscribe((value) => {
        if (value === 'force-hide') {
          this.hideTooltip(true);
        } else if (value === 'update-x') {
          if (this.previousTooltipXIndex) {
            this.updateTooltipX(this.X_UPDATE_DELAY);
          }
        } else {
          this.toggleTooltipAtValue(value);
        }
      });
    }
  }

  @Input()
  set data(data) {
    if (data?.length) {
      this.hasNoData = false;
      if (this.chartData.length) {
        if (this.dataChanged(data)) {
          this.unfilteredData = data;
          this.chartData = this.filterData(data);
          this.update();
          this.hideTooltip();
        }
      } else {
        this.unfilteredData = data;
        this.chartData = this.filterData(data);
        setTimeout(this.create.bind(this));
      }
    } else {
      this.hasNoData = true;
      this.changeDetectorRef.markForCheck();
    }
  }
  get data() {
    return this.chartData;
  }

  @Input()
  set selectedLine(item) {
    if (item) {
      this.highlightLine(item);
    } else {
      this.removeHighlights();
    }
  }

  @Input()
  set forceUpdate(subject: Subject<any>) {
    if (subject) {
      this.forceUpdateSubscription = subject.subscribe(() => {
        this.update();
      });
    }
  }

  constructor(
    private elementRef: ElementRef,
    private changeDetectorRef: ChangeDetectorRef,
    public resize: ResizeService
  ) {}

  ngOnInit() {
    this.chartD3 = d3.select(this.chart.nativeElement);
    this.resize.addOnEndHandler(this.resizeEnded.bind(this));
  }

  ngOnDestroy() {
    this.tooltipSubscription?.unsubscribe();
    this.forceUpdateSubscription?.unsubscribe();
  }

  private resizeEnded() {
    this.update();
  }

  onLegendItemMouseOver(item) {
    this.highlightLine(item);
  }

  onLegendItemMouseOut() {
    this.removeHighlights();
  }

  onMouseLeave() {
    if (this.hasTooltip) {
      this.hideTooltipWithDelay();
    }
  }

  update() {
    if (this.chartData?.length && this.lineGroups) {
      this.hideLegend = false;
      if (this.showLegend) {
        this.setLegendItems();
      }

      // SVG / fixed height component must be hidden before the update!
      // Otherwise the height calculations will include the potentially larger than required chart.
      // This will result in an ugly (and unnecessary scrollbar)
      this.hideSvg = true;
      setTimeout(() => {
        if (this.init()) {
          this.updateChartAndAxis();
          this.updateInteractiveLayer();
          this.hideSvg = false;
          this.changeDetectorRef.markForCheck();
        }
      });
    }
    this.changeDetectorRef.markForCheck();
  }

  private dataChanged(newData) {
    return !compare(newData, this.chartData);
  }

  private create() {
    this.setLegendItems();
    setTimeout(() => {
      if (this.init()) {
        this.createAxis();
        this.createInteractiveLayer();
        this.createChart();
        this.changeDetectorRef.markForCheck();
      }
    });
  }

  private init() {
    this.setDimensions();
    this.setRange();
    if (this.isRangeValid() && this.data && this.data.length) {
      this.setDomain();
      this.setXValues();
      if (this.xValues?.length) {
        this.setScales();
        this.setLineFunction();
        this.setChartContentDimensions();
        this.setAxis();
        return true;
      }
      return false;
    }
    return false;
  }

  private isRangeValid(): boolean {
    return (this.range.x[1] - this.range.x[0] > 0) && (this.range.y[0] - this.range.y[1] > 0);
  }

  private setDimensions() {
    const boundingRectChartContainer = this.chartContainer.nativeElement.getBoundingClientRect();
    this.width = boundingRectChartContainer.width;
    this.height = boundingRectChartContainer.height;
  }

  private setLegendItems() {
    this.legendItems = this.chartData.map((item, index) => {
      return { index: index, cssClass: item.cssClass, label: item.label };
    });
    this.changeDetectorRef.markForCheck();
  }

  private createAxis() {
    this.xAxisElement = this.chartD3.append('g')
      .attr('class', 'axis x')
      .attr('transform', `translate(0, ${ this.height - this.margin.bottom - this.margin.top })`)
      .call(this.xAxis);

    this.yAxisElement = this.chartD3.append('g')
      .attr('class', 'axis y')
      .call(this.yAxis);
  }

  private createInteractiveLayer() {
    this.interactiveLayer = this.chartD3.append('g')
      .attr('class', 'interactive-layer');
    this.interactiveLayer
      .selectAll('.interactive-item')
      .data(this.xValues)
      .enter()
      .append('rect')
      .attr('class', 'interactive-item')
      .attr('x', (_, i) => {
        return (i * this.xStep) - (this.xStep / 2);
      })
      .attr('data-index', (_, i) => i)
      .attr('y', 0)
      .attr('width', this.xStep)
      .attr('height', this.chartContentHeight)
      .on('mouseover', this.onMouseOver.bind(this));
  }

  private updateInteractiveLayer() {
    const items = this.interactiveLayer.selectAll('.interactive-item').data(this.xValues);
    items.enter()
      .append('rect')
      .merge(items)
      .attr('class', 'interactive-item')
      .attr('y', 0)
      .attr('x', (_, i) => {
        return (i * this.xStep) - (this.xStep / 2);
      })
      .attr('data-index', (_, i) => i)
      .attr('width', this.xStep)
      .attr('height', this.chartContentHeight)
      .on('mouseover', this.onMouseOver.bind(this));
    items.exit().remove();
  }

  private onMouseOver(event) {
    const index = parseInt(event.target.dataset.index, 10);
    const x = event.target.x.baseVal.value + (this.xStep / 2) + this.margin.left;
    this.configureTooltip(index, x);
  }

  private createChart() {
    this.lineGroups = this.chartD3.append('g').attr('class', 'line-groups');
    this.lines = this.lineGroups
      .selectAll('.line-group')
      .data(this.chartData)
      .enter()
      .append('g')
      .attr('class', 'line-group')
      .append('path')
      .attr('class', d => 'line ' + d.cssClass)
      .attr('d', d => this.line(d.values.filter(line => !line.isHidden)));
  }

  private updateChartAndAxis() {
    const lineGroupElements = this.lineGroups
      .selectAll('.line-group')
      .data(this.chartData);

    lineGroupElements.exit().remove();

    lineGroupElements.enter()
      .append('g')
      .attr('class', 'line-group')
      .append('path')
      .attr('class', d => 'updated-line line ' + d.cssClass)
      .attr('d', d => this.line(d.values.filter(line => !line.isHidden)));

    lineGroupElements
      .select('.line')
      .interrupt()
      .transition()
      .duration(this.transitionDuration)
      .attr('d', d => this.line(d.values.filter(line => !line.isHidden)));

    this.lines = this.lineGroups.selectAll('.line');

    this.xAxisElement
      .attr('transform', `translate(0, ${ this.height - this.margin.bottom - this.margin.top })`)
      .call(this.xAxis);

    this.yAxisElement
      .call(this.yAxis);
  }

  private setLineFunction() {
    this.line = d3.line()
      // .curve(d3.curveCatmullRom.alpha(0.5))
      .curve(d3.curveMonotoneX)
      .x((d: any) => this.xScale(d.x))
      .y((d: any) => this.yScale(d.y));
  }

  // The chart fills the provided container
  private setRange() {
    this.range = {
      x: [0, this.width - this.margin.left - this.margin.right],
      y: [this.height - this.margin.top - this.margin.bottom, 0]
    };
  }

  private setDomain() {
    let minY = Number.MAX_VALUE;
    let maxY = Number.MIN_VALUE;

    this.data.forEach((line) => {
      line.values.forEach((value) => {
        if (value.y < minY) {
          minY = value.y;
        }
        if (value.y > maxY) {
          maxY = value.y;
        }
      });
    });

    if (this.minYValue != null) {
      minY = this.minYValue;
    }

    if (this.maxYValue != null) {
      maxY = this.maxYValue;
    } else if (this.includeGridLineAboveHighestValue) {
      const yStep = (maxY - minY) / this.numTicks;
      maxY += yStep;

      if (this.isYValueAPercentage && maxY > 100) {
        maxY = 100;
      }
    }

    this.domain = {
      y: [minY, maxY]
    };
  }

  private highlightLine(item) {
    this.lines?.attr?.('class', (d) => {
      let cssClass = 'line ' + d.cssClass;
      if (item.cssClass === d.cssClass) {
        cssClass += ' selected';
      }
      return cssClass;
    });
  }

  private removeHighlights() {
    this.lines?.attr?.('class', (d) => {
      return 'line ' + d.cssClass;
    });
  }

  private setScales() {
    this.xStep = ((this.range.x[1] - this.range.x[0]) / (this.xValues.length - 1)) - 0.000001;
    const xRange = d3.range(this.range.x[0], this.range.x[1], this.xStep);
    this.xScale = d3.scaleOrdinal()
      .domain(this.xValues)
      .range(xRange);

    this.xScaleInverse = d3.scaleQuantize()
      .domain(d3.extent(xRange))
      .range(this.xValues.map((_, i) => i));

    this.yScale = d3.scaleLinear()
      .domain(this.domain.y)
      .range(this.range.y);

    this.xStepChange.emit(this.xStep);
  }

  private setChartContentDimensions() {
    this.chartContentWidth = Math.abs(this.range.x[1] - this.range.x[0]);
    this.chartContentHeight = Math.abs(this.range.y[1] - this.range.y[0]);
  }

  private setAxis() {
    this.xAxis = d3.axisBottom(this.xScale)
      .tickPadding(this.tickPadding.x)
      .tickSize(-this.chartContentHeight)
      .tickValues(this.xValues);

    this.yAxis = d3.axisLeft(this.yScale)
      .tickPadding(this.tickPadding.y)
      .tickSize(-this.chartContentWidth)
      .ticks(this.numTicks);
  }

  private setXValues() {
    this.xValues = this.getXValues();
  }

  private getXValues(): string[] {
    return this.data[0].values.map(value => value.x.toString());
  }

  private updateTooltipX(delay = 0) {
    setTimeout(() => {
      const x = (this.previousTooltipXIndex * this.xStep) + this.margin.left;
      this.configureTooltip(this.previousTooltipXIndex, x);
    }, delay);
  }

  private configureTooltip(index: number, x: number) {
    if (this.hasTooltip && this.tooltipConfig && this.tooltip) {
      clearTimeout(this.hideTooltipTimeoutHandle);
      this.tooltip.width = this.tooltipConfig.width || 230;
      this.tooltip.content = this.createTooltipContent(index);
      this.tooltip.title = this.createTooltipTitle(index);
      this.tooltip.footer = this.createTooltipFooter(index);
      this.tooltip.left = x;
      this.tooltip.isVisible = true;
      this.tooltip.display = 'block';
      this.changeDetectorRef.markForCheck();
      this.previousTooltipXIndex = index;
    }
  }

  private createTooltipTitle(index) {
    if (this.tooltipConfig && this.tooltipConfig.title) {
      let value: number|string = '';
      if (this.tooltipConfig.title.key && this.chartData?.length) {
        const firstChartDataItem = this.chartData[0];
        value = firstChartDataItem.values[index].x || '';
      }
      return `${ this.tooltipConfig.title.prefix || '' }${ value }${ this.tooltipConfig.title.suffix }`;
    }
  }

  private createTooltipContent(index: number): string {
    return this.tooltipConfig ? this.createTooltipContentFromConfig(index) : this.createTooltipContentBasic(index);
  }

  private createTooltipContentBasic(index: number): string {
    return this.unfilteredData.reduce((html, item) => {
      if (item.tooltipPosition !== 'footer') {
        html += `<div class="row">
          <span class="label ${ item.cssClass }-color-only">${ item.label }:</span>
          <span class="value">${ this.formatTooltipValue(item.values[index].y, this.tooltipConfig.precision, this.tooltipConfig.isPercent) }${ this.axisSettings.y.unit }</span>
        </div>`;
      }
      return html;
    }, '');
  }

  private createTooltipContentFromConfig(index: number): string {
    const head = `<tr>${ this.createTooltipHeadColumns() }</tr>`;
    const body = this.unfilteredData.reduce((result, item: LineChartDataItem) => {
      if (item.tooltipPosition !== 'footer') {
        result += `<tr>
          <th class="${ item.cssClass }-color-only">${ item.label }:</th>
          ${ this.createTooltipBodyColumns(index, item) }
        </tr>`;
      }
      return result;
    }, '');
    return `<table>
      <thead>${ head }</thead>
      <tbody>${ body }</tbody>
    </table>`;
  }

  private createTooltipHeadColumns(): string {
    return this.tooltipConfig.content.columns.reduce((html: string, item: TooltipColumnConfig) => {
      return html + `<th>${ item.label || '' }</th>`;
    }, '<th></th>');
  }

  private createTooltipBodyColumns(index: number, item: LineChartDataItem): string {
    return this.tooltipConfig.content.columns.reduce((bodyColumns: string, column: TooltipColumnConfig) => {
      const value = item.values[index][column.key] as string|number;
      const precision = column.precision || this.tooltipConfig.precision;
      const isPercent = column.isPercent || this.tooltipConfig.isPercent;
      return bodyColumns + `<td>${ this.formatTooltipValue(value, precision, isPercent) }${ column.unit || '' }</td>`;
    }, '');
  }

  private createTooltipFooter(index: number): string {
    return this.unfilteredData.reduce((html: string, item: LineChartDataItem) => {
      if (item.tooltipPosition === 'footer') {
        const formattedValue = this.formatTooltipValue(item.values[index].y, this.tooltipConfig.precision);
        const suffix = formattedValue ? (this.axisSettings.y.unit || '') : this.tooltipConfig.emptyPlaceholder || '';
        html += `<div class="row">
          <span class="label ${ item.cssClass }-color-only">${ item.label }:</span>
          <span class="value">${ formattedValue }${ suffix }</span>
        </div>`;
      }
      return html;
    }, '');
  }

  private hideTooltipWithDelay(delay: number = this.HIDE_TOOLTIP_DELAY, force = false) {
    this.hideTooltipTimeoutHandle = setTimeout(() => {
      this.hideTooltip(force);
    }, delay);
  }

  private hideTooltip(force = false) {
    // This removes the tooltip right away, bypassing any transitions.
    if (force) {
      this.tooltip.display = 'none';
    }
    this.tooltip.isVisible = false;
    this.changeDetectorRef.markForCheck();
    this.previousTooltipXValue = null;
  }

  private toggleTooltipAtValue(value: string|number) {
    if (this.previousTooltipXValue === value) {
      this.hideTooltip();
    } else {
      this.setTooltipAtValue(value);
      this.previousTooltipXValue = value;
    }
  }

  private setTooltipAtValue(value: string|number) {
    if (this.xValues && this.xValues.length && value) {
      const index = this.xValues.indexOf(value.toString());
      if (index >= 0) {
        this.configureTooltip(index, (index * this.xStep) + this.margin.left);
      }
    }
  }

  private filterData(data: LineChartDataItem[]): LineChartDataItem[] {
    return data.filter(item => !item.onlyTooltip);
  }

  private formatTooltipValue(value: number|string, precision: number, isPercent = false): number|string {
    return this.formatValuePipe.transform(value, '', precision, '', false, isPercent);
  }
}
