import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { AuthService, User } from 'app/shared/services/auth.service';
import { environment } from 'environments/environment';
import * as Sentry from '@sentry/angular';
declare var require: any;

const EMPTY_PLACEHOLDER = 'N/A';

@Injectable()
export class TrevErrorHandler extends ErrorHandler {
  constructor(
    private authService: AuthService
  ) {
    super();

    if (environment['SENTRY_DSN']) {
      Sentry.withScope((scope) => {
        const currentUser: User = this.authService.getUser();
        const appVersion = this.getBitbucketBuildNumber();

        scope.setUser({
          'id': currentUser ? currentUser.id.toString() : EMPTY_PLACEHOLDER,
          'username': currentUser ? this.getFormattedUser(currentUser) : EMPTY_PLACEHOLDER
        });
        scope.setTag('app.build_number', appVersion || EMPTY_PLACEHOLDER);
        scope.setTag('app.name', environment['APP_NAME'] || EMPTY_PLACEHOLDER);
      });
    }
  }

  handleError(err: any): void {
    if (environment['SENTRY_DSN']) {
      Sentry.captureException(err);
    } else {
      super.handleError(err);
    }
  }

  private getFormattedUser(user) {
    return [user.firstname, user.surname, `(${ user.id })`].join(' ');
  }

  private getBitbucketBuildNumber(): string {
    try {
      // This will generate a warning if the file is missing
      // The warning is safe to ignore
      const { BITBUCKET_BUILD_NUMBER } = require('../../../../version.json');
      return BITBUCKET_BUILD_NUMBER;
    } catch (e) {
      return null;
    }
  }
}
