import { TutorialStep, AttachDirection } from 'app/shared/services/tutorial.service';

class KiwifruitSampleInformationWidgetStep extends TutorialStep {
  constructor(step) {
    super(step);
    this.id = 'kiwifruit-sample-information-widget';
    this.title = 'Kiwifruit Sample Information Widget';
    this.arrow = true;
    this.scrollTo = true;
  }
}

export function getKiwifruitSampleInformationWidgetSteps(tutorialService): KiwifruitSampleInformationWidgetStep[] {
  return [
    new KiwifruitSampleInformationWidgetStep ({
      beforeShowPromise: () => {
        return new Promise<void>((resolve) => {
          tutorialService.clickElement('kiwifruit-sample-information-widget');
          resolve();
        });
      },
      attachTo: {
        element: 'div:nth-child(2) > app-tabbed-content > .content',
        on: AttachDirection.top
      },
      text: 'The Sample Information widget provides information on the sample testing results on your orchard(s).'
    }),
    new KiwifruitSampleInformationWidgetStep ({
      attachTo: {
        element: 'grow-kiwifruit-sample-information-widget app-simple-grid tbody > tr > td.row-select',
        on: AttachDirection.right
      },
      text: 'If you are associated with multiple KPINs, use the radio buttons on the left side of the table to switch between them.'
    }),
    new KiwifruitSampleInformationWidgetStep ({
      attachTo: {
        element: 'grow-kiwifruit-sample-information-widget app-widget kendo-tabstrip > ul li:first-child',
        on: AttachDirection.top
      },
      text: 'Use these tabs to switch between available Varieties and Grow Methods.'
    }),
    new KiwifruitSampleInformationWidgetStep ({
      attachTo: {
        element: 'grow-kiwifruit-sample-information-widget > app-widget > header .header-column',
        on: AttachDirection.bottom
      },
      text: 'The data displayed by the widget is determined by the Season, KPIN and Maturity Area filters. Use the filters to adjust the displayed data.'
    }),
    new KiwifruitSampleInformationWidgetStep ({
      attachTo: {
        element: 'grow-kiwifruit-sample-information-widget app-simple-grid thead tr:first-child th.brix',
        on: AttachDirection.top
      },
      text: 'Under the Brix heading, we have the average brix and the brix when fully ripe as a percentage.'
    }),
    new KiwifruitSampleInformationWidgetStep ({
      attachTo: {
        element: 'grow-kiwifruit-sample-information-widget app-simple-grid thead tr:first-child th.firmness',
        on: AttachDirection.top
      },
      text: 'Under the Firmness heading, we show the average firmness and firmness as a percentage under 4kg of force.'
    }),
    new KiwifruitSampleInformationWidgetStep ({
      beforeShowPromise: () => {
        return new Promise<void>((resolve) => {
          tutorialService.clickElement('kiwifruit-sample-information-widget');
          resolve();
        });
      },
      attachTo: {
        element: 'grow-kiwifruit-sample-information-widget .details',
        on: AttachDirection.top
      },
      text: 'More detailed information on the selected sample is provided down here.'
    }),
  ];
}
