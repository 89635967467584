import { TutorialService } from 'app/shared/services/tutorial.service';
import { KiwifruitCountsWidgetComponent } from 'app/kiwifruit-counts-widget/kiwifruit-counts-widget.component';
import { Component, AfterViewInit, OnInit, OnDestroy } from '@angular/core';
import { DropdownItem } from 'app/shared/dropdown/dropdown.component';
import { KiwifruitProductionWidgetComponent } from 'app/kiwifruit-production-widget/kiwifruit-production-widget.component';
import { OgrInventoryWidgetComponent } from 'app/ogr-inventory-widget/ogr-inventory-widget.component';
import { OrchardMapWidgetComponent } from 'app/orchard-map-widget/orchard-map-widget.component';
import { getGrowerDashboardSteps } from './grower-portal-dashboard-tutorial';
import { KiwifruitSampleInformationWidgetComponent } from 'app/kiwifruit-sample-information-widget/kiwifruit-sample-information-widget.component';
import { AvocadoSampleInformationWidgetComponent } from 'app/avocado-sample-information-widget/avocado-sample-information-widget.component';
import { Product, UserProductsService } from 'app/shared/services/user-products.service';
import { AvocadoProductionWidgetComponent } from 'app/avocado-production-widget/avocado-production-widget.component';
import { Subscription } from 'rxjs';
import { TabAndComponent } from 'app/tabbed-content/tabbed-content.component';

export interface SeasonDropdownItem extends DropdownItem {
  label: number;
  value: number;
}

interface WidgetScheduleLimit {
  day: number;
  month: number;
}

interface WidgetTabs {
  [index: string]: TabAndComponent[];
}

@Component({
  selector: 'app-grower-portal-dashboard-component',
  templateUrl: './grower-portal-dashboard.component.html',
  styleUrls: ['./grower-portal-dashboard.component.scss']
})
export class GrowerPortalDashboardComponent implements OnInit, AfterViewInit, OnDestroy {
  switchableWidgetTabs: WidgetTabs = {
    Kiwifruit: [
      {
        title: 'Map & Weather',
        id: 'map-weather',
        component: OrchardMapWidgetComponent,
        onSelect: () => {
          window.dispatchEvent(new Event('resize'));
        }
      },
      {
        title: 'Production',
        id: 'kiwifruit-production',
        helpKey: 'production',
        component: KiwifruitProductionWidgetComponent,
        current: this.isWidgetCurrent({ month: 3, day: 15 }, { month: 7, day: 1 })
      },
      {
        title: 'Flower Bud Counts',
        id: 'flower-bud-counts',
        helpKey: 'flower-bud-counts',
        component: KiwifruitCountsWidgetComponent,
        current: this.isWidgetCurrent({ month: 10, day: 1 }, { month: 11, day: 31 }),
        componentInputs: { countType: 'flower' }
      },
      {
        title: 'Fruit Counts',
        id: 'fruit-counts',
        helpKey: 'fruit-counts',
        component: KiwifruitCountsWidgetComponent,
        current: this.isWidgetCurrent({ month: 1, day: 1 }, { month: 3, day: 1 }),
        componentInputs: { countType: 'fruit' }
      }
    ],
    Avocado: [
      {
        title: 'Map & Weather',
        id: 'map-weather',
        component: OrchardMapWidgetComponent,
        onSelect: () => {
          window.dispatchEvent(new Event('resize'));
        }
      }
    ]
  };

  additionalSwitchableWidgetTabs: WidgetTabs = {
    Kiwifruit: [
      {
        title: 'OGR & Inventory',
        id: 'ogr-inventory',
        helpKey: 'ogr',
        component: OgrInventoryWidgetComponent
      },
      {
        title: 'Sample Information',
        id: 'kiwifruit-sample-information-widget',
        component: KiwifruitSampleInformationWidgetComponent,
        current: this.isWidgetCurrent({ month: 2, day: 20 }, { month: 6, day: 9 }),
      }
    ],
    Avocado: [
      {
        title: 'Production',
        id: 'avocado-production',
        current: true,
        component: AvocadoProductionWidgetComponent
      },
      {
        title: 'Sample Information',
        id: 'avocado-sample-information-widget',
        component: AvocadoSampleInformationWidgetComponent,
        current: false
      }
    ]
  };

  product: Product;
  readonly DEFAULT_PRODUCT_NAME = 'Kiwifruit';
  private readonly selectedProductChangeSubscription: Subscription;

  constructor(
    private tutorialService: TutorialService,
    private userProductsService: UserProductsService
  ) {
    this.selectedProductChangeSubscription = this.userProductsService.selectedProductChange.subscribe((product) => {
      this.updateProduct(product);
      this.configureTutorial();
    });
  }

  ngOnInit() {
    this.updateProduct(this.userProductsService.getSelectedProduct());
  }

  ngOnDestroy() {
    this.selectedProductChangeSubscription?.unsubscribe();
  }

  ngAfterViewInit () {
    this.configureTutorial();
  }

  private updateProduct(product) {
    this.product = product;
  }

  private configureTutorial() {
    if (this.product) {
      this.tutorialService.run('dashboard', getGrowerDashboardSteps, {
        product: this.product.product_name
      });
    }
  }

  private isWidgetCurrent(from: WidgetScheduleLimit, to: WidgetScheduleLimit): boolean {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const fromDate = new Date(currentYear, from.month - 1, from.day);
    const toDate = new Date(currentYear, to.month - 1, to.day);
    return currentDate >= fromDate && currentDate <= toDate;
  }
}
