<div class="notification-panel" (click)="hide()">
  <div class="content-wrapper">
    <i [class]="icon"></i>
    <div class="content">
      <h4 *ngIf="title">{{ title }}</h4>
      <div class="message" [innerHTML]="message"></div>
    </div>
  </div>
  <div *ngIf="showCloseButton" class="controls">
    <button class="button rounded small hover-danger">
      <i class="fas fa-times"></i>
      Close
    </button>
  </div>
</div>
