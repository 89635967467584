import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { titleCase } from 'app/shared/services/utils.service';

@Injectable()
export class TitleService {
  private defaultTitle = '';
  private defaultSeparator = ' - ';

  constructor(
    private activatedRoute: ActivatedRoute
  ) {}

  setDefaultTitle(value: string) {
    this.defaultTitle = value;
  }

  setDefaultSeparator(value: string) {
    this.defaultSeparator = value;
  }

  // Sets the entire title to the specified value
  // Sets the title to the default title if a new title is not supplied
  setDocumentTitle(title: string = null): void {
    document.title = title || this.defaultTitle;
  }

  // Sets the last portion of the title: title = defaultTitle + defaultSeparator + subTitle
  // Ex: Information Portal - Your Subtitle
  setDocumentSubTitle(subTitle: string): void {
    setTimeout(() => {
      document.title = this.getTitle(subTitle);
    });
  }

  getTitleFromActivatedRoute(): string {
    let subTitle = '';
    let routeItem = this.activatedRoute;
    while (routeItem.firstChild) {
      routeItem = routeItem.firstChild;
    }

    if (routeItem && routeItem.snapshot && routeItem.snapshot.data) {
      // Get the subtitle from the route data
      subTitle = routeItem.snapshot.data.title;
    } else {
      if (routeItem && routeItem.url) {
        // Convert the route path into a subtitle
        subTitle = titleCase(routeItem.url[0].path);
      }
    }
    return this.getTitle(subTitle);
  }

  private getTitle(subTitle: string = ''): string {
    subTitle = subTitle ? this.defaultSeparator + subTitle : '';
    return this.defaultTitle + subTitle;
  }
}
