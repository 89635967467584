import { BaseFilterCellComponent, FilterService } from '@progress/kendo-angular-grid';
import { Input, Component } from '@angular/core';
import { CompositeFilterDescriptor } from '@progress/kendo-data-query';

@Component({
  selector: 'dropdown-filter',
  template: `
    <kendo-dropdownlist [data]="data"
                        (valueChange)="onChange($event)"
                        [value]="selectedValue"
                        [valuePrimitive]="true"
                        [textField]="textField"
                        [valueField]="valueField">
    </kendo-dropdownlist>
  `,
})

export class DropdownFilterComponent extends BaseFilterCellComponent {

  get selectedValue(): any {
    const filter = this.filterByField(this.valueField);
    return filter ? filter.value : null;
  }

  @Input() declare filter: CompositeFilterDescriptor;
  @Input() data: any[];
  @Input() textField: string;
  @Input() valueField: string;

  constructor(
    filterService: FilterService
    ) {
    super(filterService);
  }

  public onChange(value: any): void {
    this.applyFilter(
      value === null ? this.removeFilter(this.valueField) : this.updateFilter({
        field: this.valueField,
        operator: 'eq',
        value: value
      })
    );
  }
}
