import { Component, OnInit, ViewChild, ElementRef, HostBinding, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { from as observableFrom, Subscription } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HttpService } from 'app/shared/services/http.service';
import { SettingsService } from 'app/shared/services/settings.service';
import { environment } from 'environments/environment';
import { ActivatedRoute } from '@angular/router';
import { randomBgCssClass } from 'app/shared/services/utils.service';

const ERROR_FLASH_TIME = 5000;
const NUM_BG_IMAGES = 6;

@Component({
  selector: 'app-password-reset',
  templateUrl: './password-reset.component.html',
  styleUrls: ['./password-reset.component.scss', './password-reset-media-queries.component.scss']
})
export class PasswordResetComponent implements OnInit, OnDestroy {
  password = '';
  passwordConfirmation = '';
  loginHelpName = environment.LOGIN_HELP_NAME;
  minPasswordLength = SettingsService.MIN_PASSWORD_LENGTH;
  isPasswordError = false;
  isPasswordConfirmationError = false;
  isError = false;
  isPasswordResetSuccess = false;
  isBusy = false;
  isPasswordClearVisible = false;
  isPasswordConfirmationClearVisible = false;
  passwordErrorHandle = null;
  passwordConfirmationErrorHandle = null;
  paramSubscription = null;
  uidb64 = '';
  token = '';
  errorMessage = 'Password reset failed.';
  private setPasswordSubscription: Subscription;
  @HostBinding('class') class = randomBgCssClass(NUM_BG_IMAGES);
  @ViewChild('backToLoginPageButton', { static: true }) backToLoginPageButton: ElementRef;
  @ViewChild('passwordInputField', { static: true }) passwordInputField: ElementRef;
  @ViewChild('passwordConfirmationInputField', { static: true }) passwordConfirmationInputField: ElementRef;
  @ViewChild('tryAgainButton', { static: true }) tryAgainButton: ElementRef;

  constructor(
    private router: Router,
    private http: HttpService,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit() {
    this.paramSubscription = this.activatedRoute.params.subscribe(params => {
       this.uidb64 = params['uidb64'];
       this.token = params['token'];
    });
  }

  ngOnDestroy() {
    this.paramSubscription?.unsubscribe();
    this.setPasswordSubscription?.unsubscribe();
  }

  flashPasswordError() {
    if (this.passwordErrorHandle) {
      clearTimeout(this.passwordErrorHandle);
    }

    this.isPasswordError = true;
    this.passwordErrorHandle = setTimeout(() => {
      this.isPasswordError = false;
    }, ERROR_FLASH_TIME);
  }

  flashPasswordConfirmationError() {
    if (this.passwordConfirmationErrorHandle) {
      clearTimeout(this.passwordConfirmationErrorHandle);
    }

    this.isPasswordConfirmationError = true;
    this.passwordConfirmationErrorHandle = setTimeout(() => {
      this.isPasswordConfirmationError = false;
    }, ERROR_FLASH_TIME);
  }

  showError() {
    this.isError = true;
    this.tryAgainButton.nativeElement.focus();
  }

  resetForm() {
    this.isBusy = false;
    this.isError = false;
    this.password = '';
    this.passwordConfirmation = this.password;
  }

  validatePassword() {
    if (this.password.length >= this.minPasswordLength &&
      this.hasDigit(this.password) &&
      this.hasUpperCaseLetter(this.password) &&
      this.hasLowerCaseLetter(this.password)
    ) {
      this.isPasswordError = false;
      return true;
    } else {
      this.flashPasswordError();
      return false;
    }
  }

  validatePasswordConfirmation() {
    if (this.password === this.passwordConfirmation) {
      this.isPasswordConfirmationError = false;
      return true;
    } else {
      this.flashPasswordConfirmationError();
      return false;
    }
  }

  setPassword() {
    if (this.validateForm()) {
      this.lock();
      const url = 'auth/password_reset/' + this.uidb64 + '/' + this.token + '/';
      const data = {
        new_password1: this.password,
        new_password2: this.passwordConfirmation
      };

      this.setPasswordSubscription = this.http.post(url, data, {}, true, true, [400]).pipe(
        catchError((response) => {
          this.unlock(ERROR_FLASH_TIME);
          this.setPasswordResetError(response);
          return observableFrom([]);
        })
      ).subscribe(() => {
        this.unlock();
        this.loginSuccess();
      });
    }
  }

  returnKeySetPassword(event) {
    this.showHidePasswordClear();
    this.showHidePasswordConfirmationClear();
    if (event.keyCode === 13) {
      this.setPassword();
    }
  }

  clearPassword() {
    this.isPasswordClearVisible = false;
    this.password = '';
  }

  clearPasswordConfirmation() {
    this.isPasswordConfirmationClearVisible = false;
    this.passwordConfirmation = '';
  }

  showHidePasswordClear() {
    this.isPasswordClearVisible = this.password.length > 0;
  }

  showHidePasswordConfirmationClear() {
    this.isPasswordConfirmationClearVisible = this.passwordConfirmation.length > 0;
  }

  showLoginForm() {
    this.router.navigateByUrl('/login');
  }

  private hasDigit(str: string): boolean {
    return /\d/.test(str);
  }

  private hasUpperCaseLetter(str: string): boolean {
    return /[A-Z]/.test(str);
  }

  private hasLowerCaseLetter(str: string): boolean {
    return /[a-z]/.test(str);
  }

  private validateForm(): boolean {
    const isPasswordValid = this.validatePassword();
    const isPasswordConfirmationValid = this.validatePasswordConfirmation();

    // Set focus to the offending field
    if (!isPasswordValid) {
      this.passwordInputField.nativeElement.focus();
    } else if (!isPasswordConfirmationValid) {
      this.passwordConfirmationInputField.nativeElement.focus();
    }

    return isPasswordValid && isPasswordConfirmationValid;
  }

  private loginSuccess() {
    this.backToLoginPageButton.nativeElement.focus();
    this.isPasswordResetSuccess = true;
  }

  private setPasswordResetError(errorResponse) {
    if (errorResponse && errorResponse.error && errorResponse.error.errors && errorResponse.error.errors.length > 0) {
      this.errorMessage = errorResponse.error.errors[0];
    } else {
      this.errorMessage = 'Password reset failed!';
    }

    this.passwordInputField.nativeElement.focus();
    this.showError();
  }

  private lock() {
    this.isBusy = true;
  }

  private unlock(timeout?: number) {
    if (timeout) {
      setTimeout(() => {
        this.isBusy = false;
      }, timeout);
    } else {
      this.isBusy = false;
    }
  }
}
