import { Component, Input, OnInit } from '@angular/core';
import { TitleCasePipe } from '@angular/common';
import { VarietyGrowMethodTab } from '../interfaces/tab.interface';
import { TabbedVarietyGrowMethod } from '../lib/tabbed-variety-grow-method';
import { HttpService } from 'app/shared/services/http.service';
import { RowError } from 'app/simple-grid/simple-grid.component';
import { SeasonDropdownItem } from 'app/grower-portal-dashboard/grower-portal-dashboard.component';
import { openGrowerReport } from 'app/shared/services/utils.service';
import { GaService } from 'app/shared/services/ga.service';

const DATA_ENDPOINT = 'growers/kiwifruit/counts/summary/';
const SEASONS_ENDPOINT = 'growers/kiwifruit/counts/seasons/';
const REPORT_ENDPOINT = '/api2/v1/counts/reports/crop_estimate/grower/';
const SCORECARD_ENDPOINT = '/api2/v1/counts/reports/scorecard/';

@Component({
  selector: 'grow-kiwifruit-counts-widget',
  templateUrl: './kiwifruit-counts-widget.component.html',
  styleUrls: ['./kiwifruit-counts-widget.component.scss']
})
export class KiwifruitCountsWidgetComponent extends TabbedVarietyGrowMethod implements OnInit {
  @Input() updatedAt: Date = null;
  @Input() emptyPlaceholder = 'NA';
  tabs: VarietyGrowMethodTab[] = [];
  gridColumns: any = [];
  rowErrors: RowError[] = [];
  isLoading = true;
  gridData: any = [];
  gridDataFiltered: any = [];
  areDetailsVisible = true;
  private selectedColumnKey: string;
  detailData: any = {};
  detailPrecision = 1;
  detailsChartYAxis = {};
  selectedGrowerNumber;
  private groups = [
    { label: 'Season', primary: 'season', isSelected: true, sortOrder: 'desc' }
  ];

  availableSeasons: SeasonDropdownItem[] = [];
  selectedSeason: SeasonDropdownItem = null;

  countTypeLabel: string;
  @Input() countType: 'flower'|'fruit';

  constructor(
    private http: HttpService,
    private titlecase: TitleCasePipe,
    protected ga: GaService
  ) {
    super();
  }

  async ngOnInit() {
    this.isLoading = true;
    this.countTypeLabel = this.pluralizeCountType();
    this.selectedColumnKey = `${ this.countTypeLabel }_per_m2`;
    this.setDetailsChartYAxis();
    this.initGridColumns();
    if (this.countType === 'flower') {
      this.spliceEstimatedFruitColumns();
    }
    this.initRowErrors();

    this.availableSeasons = await this.getSeasons();
    if (this.availableSeasons && this.availableSeasons.length) {
      this.selectedSeason = this.availableSeasons[0];
      this.fetchGridData();
    } else {
      this.isLoading = false;
    }
  }

  initGridColumns() {
    this.gridColumns = [
      { label: 'KPIN', rowspan: 2, align: 'left', key: 'grower_number' },
      {
        label: `${this.titlecase.transform(this.countTypeLabel)}`, childColumns: [
          { label: 'per m<sup>2</sup>', key: `${ this.countTypeLabel }_per_m2`, isSelectable: true, precision: 1 }
        ]
      },
      { label: 'Export Size', childColumns: [{ label: '%', key: 'export_size_percentage', precision: 0 }] },
      { label: 'Class 1', childColumns: [{ label: '%', key: 'class_1_percentage', precision: 0 }] },
      { label: 'Size', childColumns: [{ label: 'Avg', key: 'average_size', precision: 1 }] },
      {
        label: 'Estimated Trays', align: 'center', childColumns: [
          { label: 'Total', key: 'class_1_trays_total', isSelectable: true, precision: 0 },
          { label: 'per Ha', key: 'class_1_trays_per_ha', isSelectable: true, precision: 0 }
        ]
      },
      {
        label: 'Reports', align: 'center', childColumns: [
          {
            label: 'Crop Estimate',
            align: 'center',
            key: 'report',
            allowEmpty: true,
            cssClass: 'download-report',
            action: this.onDownloadReportButtonClick.bind(this)
          },
          {
            label: 'Scorecard',
            align: 'center',
            key: 'report',
            allowEmpty: true,
            cssClass: 'download-report',
            action: this.onDownloadScorecardButtonClick.bind(this)
          }
        ]
      }
    ];
  }

  spliceEstimatedFruitColumns() {
    this.gridColumns.splice(2, 0, {
      label: 'Estimated Fruit', align: 'center', childColumns: [
        { label: 'per Flower', key: 'fruit_per_flower', precision: 2 },
        { label: 'per m<sup>2</sup>', key: 'fruit_per_m2', precision: 1 }
      ]
    });
  }

  private pluralizeCountType() {
    let str: string = this.countType;
    if (str === 'flower') {
      str = str + 's';
    }
    return str;
  }

  onTabSelect(event: any) {
    this.ga.event('tab', 'change', `${ this.countTypeLabel } counts widget tab`, event.title);
    this.selectedTab = this.tabs[event.index];
    this.filterGridData(this.selectedTab);
    this.setDetailData();
  }

  onGridColumnSelect(columnKey) {
    this.ga.event('grid', 'change', `${ this.countTypeLabel } counts widget grid column`, columnKey);
    this.selectedColumnKey = columnKey;
    this.setDetailData();
    this.setDetailsChartYAxis();
    this.setDetailPrecision();
  }

  onGridRowSelect(row, index, selectedRows) {
    this.ga.event('grid', 'change', `${ this.countTypeLabel } counts widget grid row`, row.grower_number);
    this.selectRows(row);
  }

  onDownloadReportButtonClick(item) {
    const link = REPORT_ENDPOINT + this.countType +
      '/?season=' + item.season +
      '&growernumber=' + item.grower_number +
      '&variety=' + item.variety_id +
      '&growmethod=' + item.grow_method_id;
    this.ga.event('button', 'click', 'counts widget report download', link);
    openGrowerReport(link);
  }

  onDownloadScorecardButtonClick(item) {
    const link = SCORECARD_ENDPOINT + this.countType + '/' +
      '?orchard_id=' + item.orchard_id +
      '&normalize=1' +
      '&variety_id=' + item.variety_id +
      '&grow_method_id=' + item.grow_method_id;
    this.ga.event('button', 'click', 'counts widget scorecard download', link);
    openGrowerReport(link);
  }

  selectSeason(season: SeasonDropdownItem) {
    if (this.selectedSeason && this.selectedSeason.label !== season.label) {
      this.selectedSeason = season;
      this.fetchGridData();
    }
  }

  private selectRows(selectedRow) {
    this.selectedGrowerNumber = selectedRow.grower_number;
    this.setDetailData();
  }

  private async fetchGridData() {
    this.isLoading = true;
    const params: any = { season: this.selectedSeason.value };
    this.gridData = await this.http.get(DATA_ENDPOINT + this.countType + '/', { params: params }).toPromise();
    if (this.gridData) {
      this.setTabs(this.gridData.filter(obj => obj.season === this.selectedSeason.value), this.tabReducer.bind(this));
      this.sortTabs();
      if (this.tabs && this.tabs.length) {
        this.initSelectedTab();
        this.filterGridData(this.selectedTab);
        if (this.gridDataFiltered && this.gridDataFiltered.length) {
          this.setDetailData();
        }
      }
      this.updatedAt = new Date(); // This will come from the server
    }
    this.isLoading = false;
  }

  protected tabReducer(result: VarietyGrowMethodTab[], obj: any): VarietyGrowMethodTab[] {
    result.push({
      title: obj.variety_code + obj.grow_method_code,
      variety_code: obj.variety_code,
      variety_id: obj.variety_id,
      grow_method_code: obj.grow_method_code,
      grow_method_id: obj.grow_method_id,
      selected: false
    });
    return result;
  }

  private filterGridData(tab: VarietyGrowMethodTab) {
    this.gridDataFiltered = this.gridData.filter(obj => {
      return obj.variety_code === tab.variety_code &&
        obj.grow_method_code === tab.grow_method_code &&
        obj.season === this.selectedSeason.value;
    });
  }

  private setDetailsChartYAxis() {
    this.detailsChartYAxis = this.getDetailsChartYAxis();
  }

  private getDetailsChartYAxis() {
    switch (this.selectedColumnKey) {
      case `${ this.countTypeLabel }_per_m2`: return {
        label: this.titlecase.transform(this.countTypeLabel), unit: 'per m<sup>2</sup>'
      };
      case 'class_1_trays_total': return { label: 'Trays', unit: 'Total' };
      case 'class_1_trays_per_ha': return { label: 'Trays', unit: 'per Ha' };
    }
  }

  private setDetailData() {
    const data = [];
    if (this.gridData) {
      this.gridData.forEach((item) => {
        if ( this.selectedGrowerNumber &&
          item.grower_number.slice(0, 4) === this.selectedGrowerNumber.slice(0, 4) &&
          item.variety_code === this.selectedTab.variety_code &&
          item.grow_method_code === this.selectedTab.grow_method_code
        ) {
          data.push({ season: item.season, value: item[this.selectedColumnKey], grower: item.grower_number });
        }
      });
    }
    this.detailData = {
      groups: this.groups,
      data: data
    };
  }

  private setDetailPrecision() {
    switch (this.selectedColumnKey) {
      case `${ this.countTypeLabel }_per_m2`: this.detailPrecision = 1; break;
      case 'class_1_trays_total': this.detailPrecision = 0; break;
      case 'class_1_trays_per_ha': this.detailPrecision = 0; break;
      default: this.detailPrecision = 1;
    }
  }

  private initRowErrors() {
    const estimateFinalisedNumColumns = this.countType === 'fruit' ? 6 : 8;
    const allBaysCountedNumColumns = this.countType === 'fruit' ? 8 : 10;
    this.rowErrors = [
      { key: 'all_bays_counted', value: false, startColumn: 1, numColumns: allBaysCountedNumColumns, message: 'All monitor bays not yet counted' },
      { key: 'estimate_finalised', value: false, startColumn: 2, numColumns: estimateFinalisedNumColumns, message: 'Crop estimate not yet finalised' }
    ];
  }

  private async getSeasons(): Promise<SeasonDropdownItem[]> {
    const data = await this.fetchSeasons();

    return data.map((item: { season: number }) => {
      return { label: item.season, value: item.season };
    });
  }

  private fetchSeasons(): Promise<any> {
    return this.http.get(SEASONS_ENDPOINT).toPromise();
  }
}
