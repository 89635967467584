<header *ngIf="title" class="title">{{ title }}</header>
<div class="content">
  <ng-content></ng-content>
</div>
<footer *ngIf="hasFooter" class="footer">
  {{ footer }}
  <ng-content select=".footer"></ng-content>
</footer>
<div *ngIf="hasArrow" [class]="'triangle ' + direction"></div>
<div *ngIf="hasLine" class="line"
     [style.width]="lineWidth + 'px'"
     [style.height]="(lineHeight || 0) + 'px'"
     [style.left]="lineLeft != null ? lineLeft + 'px' : null"
     [style.right]="lineRight != null ? lineRight + 'px' : null"></div>
