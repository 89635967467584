import { Injectable, OnDestroy, TemplateRef } from '@angular/core';
import { HttpService } from 'app/shared/services/http.service';
import { first, Observable, Subscription } from 'rxjs';
import {
  DialogRef,
  DialogResult,
  DialogService,
  DialogSettings
} from '@progress/kendo-angular-dialog';
import { environment } from 'environments/environment';

export const ENDPOINT = 'people/agreements/';

export interface AgreementListItem {
  uuid: string;
  name: string;
  entity_name: string;
  agreement_type: string;
  is_user_signer: boolean;
  signed_at?: Date;
  signed_by?: string;
}

@Injectable()
export class AgreementsService implements OnDestroy {
  private cancelDialogSubscription: Subscription;
  private errorDialogSubscription: Subscription;

  constructor(
    private http: HttpService,
    private dialogService: DialogService
  ) {}

  ngOnDestroy() {
    this.cancelDialogSubscription?.unsubscribe();
    this.errorDialogSubscription?.unsubscribe();
  }

  readAll(): Observable<AgreementListItem[]> {
    return this.http.get(ENDPOINT) as Observable<AgreementListItem[]>;
  }

  fetchDocument(uuid: string): Observable<Blob> {
    const url = `${ ENDPOINT }${ uuid }/document/`;
    return this.http.get(url, {
      responseType: 'blob',
    }) as Observable<Blob>;
  }

  getAgreementDocumentUrl(uuid, includeBlankForm = false): string {
    let url = `${environment.API_BASE_URL}${ ENDPOINT }${ uuid }/document/`;
    if (includeBlankForm) {
      url += '?include_blank_form=1';
    }
    return url;
  }

  showConfirmDialog(callback: (result: DialogResult) => any = (_) => {}) {
    const dialog: DialogRef = this.dialogService.open({
      title: 'Please Confirm',
      content: 'Are you sure you want to leave this page? All unsaved changes will be lost.',
      actions: [
        { text: 'Yes' },
        { text: 'No', primary: true }
      ],
      width: 350,
      minWidth: 300
    } as DialogSettings);

    this.cancelDialogSubscription = dialog.result.pipe(first()).subscribe((result) => {
      callback(result);
    });
  }

  showErrorDialog(content: string | TemplateRef<unknown>, callback: (result: DialogResult, dialog: DialogRef) => any, isAlreadySigned = false, title = 'Error') {
    const actions = [
        { text: 'Cancel', cssClass: 'cancel' },
        { text: 'Download Agreement', cssClass: 'download-agreement button ok' }
    ];
    if (!isAlreadySigned) {
      actions.push({ text: 'Sign Agreement', cssClass: 'sign-agreement button ok' });
    }

    const errorDialog = this.dialogService.open({
      title,
      content,
      cssClass: 'agreement-error-dialog',
      width: 670,
      actions,
      preventAction: (result: DialogResult) => {
        callback(result, errorDialog);
        return result['text'] === 'Download Agreement';
      }
    } as DialogSettings);

    this.errorDialogSubscription = errorDialog.result.pipe(first()).subscribe((result) => {
      callback(result, errorDialog);
    });
  }
}
