import { Component, ElementRef, ViewChild } from '@angular/core';
import { ResourceGroup } from 'app/resources/resource.interfaces';
import { ActivatedRoute, Router } from '@angular/router';
import { ResourceService } from 'app/resources/resource.service';
import { ResourceBaseComponent } from 'app/resources/resource-base/resource-base.component';
import { ResourceScrollService } from 'app/resources/resource-scroll.service';

@Component({
  selector: 'grower-portal-resource-category-page',
  templateUrl: './resource-category-page.component.html',
  styleUrls: ['./resource-category-page.component.scss']
})
export class ResourceCategoryPageComponent extends ResourceBaseComponent {
  @ViewChild('main', { static: true }) main: ElementRef;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public resourceService: ResourceService,
    protected resourceScrollService: ResourceScrollService
  ) {
    super(resourceService, resourceScrollService);
  }

  ngOnInit() {
    super.ngOnInit();
    this.resourceScrollService.categoryPageScrollElement = this.main.nativeElement;
  }

  goToResourceGroup(resourceGroup: ResourceGroup) {
    this.resourceService.currentResourceGroup = resourceGroup;
    return this.router.navigate([resourceGroup.id], {
      relativeTo: this.activatedRoute
    });
  }
}
