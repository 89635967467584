import { Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'grower-portal-badge',
  templateUrl: './badge.component.html',
  styleUrls: ['./badge.component.scss']
})
export class BadgeComponent {
  @Input() @HostBinding('class') type: string = null;
  @Input() value: number | string;
  @Input() text: string;
  @Input() icon: string;
}
