<div class="content">
  <header class="header">
    <div class="column index">
      <div class="breadcrumbs-panel">
        <app-breadcrumbs></app-breadcrumbs>
        <app-help-button class="help-button" helpKey="packout-details"></app-help-button>
      </div>
      <dropdown *ngIf="data?.length && availableSeasons?.length"
                [items]="availableSeasons"
                [current]="selectedSeason"
                handle="packoutDetailsSeasonDropdown"
                [highlightSelected]="true"
                (currentChange)="selectSeason($event)">
        <button class="button transparent tiny rounded bordered">
          Season: {{ selectedSeason.label }}
          <i class="fas fa-chevron-right right"></i>
        </button>
      </dropdown>
    </div>
    <div *ngIf="tabs?.length" class="column data-category">
      <div *ngIf="selectedRow" class="download-report-button-group">
        <button class="button transparent tiny rounded bordered"
                (click)="openScorecardReport()">
          <i class="fas fa-download"></i>
          Scorecard
        </button>
        <button class="button transparent tiny rounded bordered"
                (click)="openPackoutReport()">
          <i class="fas fa-download"></i>
          {{ packoutReportLevelLabel }} Packout Report
        </button>
      </div>
      <div class="data-category-content">
        <app-simple-tabs *ngIf="dataCategoryTabs?.length"
                         class="data-category-tabstrip"
                         [tabs]="dataCategoryTabs"
                         [selected]="selectedDataCategoryTab"
                         [onSelect]="onDataCategoryTabSelect.bind(this)">
        </app-simple-tabs>
      </div>
    </div>
  </header>
  <kendo-tabstrip *ngIf="tabs?.length" class="data-grid tabstrip"
                  (tabSelect)="onTabSelect($event)">
    <kendo-tabstrip-tab *ngFor="let tab of tabs"
                        [title]="tab.title"
                        [selected]="tab.title === selectedTab.title">
        <ng-template kendoTabContent>
          <app-simple-grid [columns]="gridColumns"
                           [hiddenColumns]="hiddenColumns"
                           [data]="data"
                           [alternatingColumnBg]="false"
                           rowSelectType="single"
                           [showRowSelect]="false"
                           [rowSelectCallback]="onGridRowSelect.bind(this)"
                           [toggleRowCallback]="onGridRowToggle.bind(this)"
                           [emptyPlaceholder]="emptyPlaceholder"
                           [selectedRowIndexes]="selectedGridRowIndexes"
                           [rowTooltip]="gridRowTooltip">
          </app-simple-grid>
        </ng-template>
    </kendo-tabstrip-tab>
  </kendo-tabstrip>

  <section *ngIf="data?.length" class="detail">
    <div class="column index">
      <app-grouped-bar-chart [groups]="productionChartGroups"
                             [data]="productionChartData"
                             [yAxis]="{ label: 'Avg. Class 1 Trays Per Ha', unit: '' }"
                             [minValue]="0"
                             [precision]="1"
                             legendLocation="bottom"
                             [showAverage]="false"
                             [chartAverages]="productionChartAverages"
                             [noDataMessage]="errorMessage"
                             noDataSubMessage=""></app-grouped-bar-chart>
    </div>
    <div [class]="'column data-category ' + selectedDataCategoryTab.title | lowercase">
      <div class="pages">
        <div class="page rejects">
          <app-grouped-bar-chart class="chart"
                                 [groups]="rejectsChartGroups"
                                 [data]="rejectsChartData"
                                 legendLocation="bottom"
                                 postfix="%"
                                 [precision]="1"
                                 [showAverage]="false"
                                 type="stacked"
                                 [minValue]="0"
                                 [chartAverages]="rejectChartAverages"
                                 [noDataMessage]="errorMessage"
                                 noDataSubMessage=""></app-grouped-bar-chart>
        </div>
        <div class="page profile">
          <app-line-chart #categoryProfileChart
                          class="chart"
                          [data]="profileChartData"
                          legendLocation="bottom"
                          [hasTooltip]="true"
                          [tooltipConfig]="categoryProfileChartTooltipConfig"
                          [showXGrid]="true"
                          [showAverage]="false"
                          [isYValueAPercentage]="true"
                          [minYValue]="0"
                          [noDataMessage]="errorMessage"
                          noDataSubMessage=""></app-line-chart>
        </div>
        <div class="page key-markets">
          Key Markets category
        </div>
      </div>
    </div>
  </section>
</div>

<div *ngIf="data?.length"
     [class]="'sidebar ' + selectedDataCategoryTab.title | lowercase">
  <div class="sidebar-handle" (click)="toggleSidebar()"></div>
  <div class="pages">
    <div class="page rejects">
      <loading-indicator [class.visible]="isRejectDataLoading"
                         [radius]="30"
                         [thickness]="4"
                         [isLight]="true"
                         message=""
                         externalMessage="Loading reject data..."
                         [isAbsolute]="true"></loading-indicator>
      <div *ngIf="rejectCategoriesChartData?.current?.data?.length" class="panel">
        <h4 class="sidebar-header">
          Rejects
          <span class="sub-heading">
            <span class="label">Sample Size: </span>
            <span class="value">{{ rejectsSampleSize }}</span>
          </span>
        </h4>
        <app-pie-chart [width]="pieChartSize"
                       valuePostfix="%"
                       [showLegend]="true"
                       [showValues]="true"
                       [thickness]="9"
                       [showValuesInLegend]="true"
                       [height]="pieChartSize"
                       [data]="rejectCategoriesChartData"></app-pie-chart>
      </div>
      <div *ngIf="rejectsData?.length" class="panel flex-column">
        <div class="flex-column scroll-grid rejects-grid">
          <header class="header">
            <table class="grid">
              <thead>
                <tr>
                  <th rowspan="2" class="left bottom-border reject">Reject</th>
                  <th rowspan="2" class="left bottom-border category">Category</th>
                  <th colspan="2" class="right shaded">{{ selectedSeason.label }}</th>
                  <th *ngIf="hasPreviousSeasonRejects" class="right previous-percentage">{{ previousSeason.label }}</th>
                </tr>
                <tr>
                  <th class="right bottom-border current-reject-sum shaded">#</th>
                  <th class="right bottom-border current-percentage shaded">%</th>
                  <th *ngIf="hasPreviousSeasonRejects" class="right bottom-border previous-percentage">%</th>
                </tr>
              </thead>
            </table>
          </header>

          <div class="scroll-panel">
            <div class="scroll-panel-content">
              <table class="grid">
                <tbody>
                  <tr *ngFor="let item of rejectsData">
                    <td class="reject">{{ item.name }}</td>
                    <td class="category">
                      <div [class]="'indicator ' + item.cssClass"></div>
                      {{ item.category }}
                    </td>
                    <td class="current-reject-sum right shaded">{{ item.current.reject_sum }}</td>
                    <td class="current-percentage right shaded">{{ item.current.percentage | number: '1.1-1' }}</td>
                    <td *ngIf="hasPreviousSeasonRejects" class="previous-percentage right">{{ item.previous.percentage | number: '1.1-1' }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="!rejectsData?.length" class="no-data-message visible">
        <i class="fas fa-exclamation-triangle"></i>
        <div class="message">{{ errorMessage }}</div>
      </div>
    </div>
    <div class="page profile">
      <div *ngIf="showTzgBySizeChart; else tzgNotAvailableTemplate" class="panel">
        <h4>TZG By Size</h4>
        <app-simple-bar-chart [data]="tzgBySizeChartData"
                              [columns]="tzgBySizeChartColumns"
                              [precision]="2"></app-simple-bar-chart>
      </div>

      <ng-template #tzgNotAvailableTemplate>
        <div class="no-data-message visible">
          <i class="fas fa-exclamation-triangle"></i>
          <div class="message">{{ tzgUnavailableMessage || errorMessage }}</div>
        </div>
      </ng-template>
    </div>
    <div class="page key-markets">
      Key Markets Sidebar
    </div>
  </div>
  <div class="controls" [class.hidden]="hideSidebarControls">
    <table *ngIf="packoutReportSummaryData?.length"
           class="packout-report-summary-data">
      <tr *ngFor="let item of packoutReportSummaryData">
        <th>{{ item.label }}</th>
        <td>{{ item.value }}</td>
      </tr>
    </table>
    <ng-container *ngIf="selectedRow">
      <button class="button fill report-button margin-bottom-small"
              (click)="openScorecardReport()">
        Download Orchard Scorecard
      </button>
      <button class="button fill report-button"
              [class.flash]="isPackoutReportButtonFlashing"
              (click)="openPackoutReport()">
        Download {{ packoutReportLevelLabel }} Packout Report
      </button>
    </ng-container>
  </div>
</div>

<loading-indicator [class.visible]="isLoading"
                   [radius]="30"
                   [thickness]="4"
                   [isLight]="true"
                   message=""
                   externalMessage="Loading production data..."
                   [isAbsolute]="true">
</loading-indicator>

<div class="no-data-message large" [class.visible]="!isLoading && !availableSeasons?.length">
  <i class="fas fa-exclamation-triangle"></i>
  <div class="message">Production data not found.</div>
  <div class="sub-message">No available seasons with production data found.</div>
</div>

<div *ngIf="availableSeasons?.length && selectedSeason" class="no-data-message large" [class.visible]="!isLoading && !data?.length">
  <i class="fas fa-exclamation-triangle"></i>
  <div class="message">Production data not found.</div>
  <div class="sub-message">Try selecting a different season?</div>
  <div class="controls">
    <dropdown class="large"
              [items]="availableSeasons"
              [current]="selectedSeason"
              handle="packoutDetailsNoDataSeasonDropdown"
              [highlightSelected]="true"
              (currentChange)="selectSeason($event)">
      <button class="button transparent rounded bordered">
        Season: {{ selectedSeason.label }}
        <i class="fas fa-chevron-right right"></i>
      </button>
    </dropdown>
  </div>
</div>
