import { Injectable } from '@angular/core';
import { MessageService } from './message.service';
import { SettingsService } from './settings.service';
import { Document } from '../document-viewer/document-viewer.component';
import { HttpService } from '../services/http.service';

const HELP_PATH = 'assets/help.json';

export const HELP_KEY = 'help_enabled';

export interface Help {
  title: string;
  content: string;
}

@Injectable()
export class HelpService {
  document: Document = { title: '', content: '' };
  helpEnabled = true;

  constructor (
    private http: HttpService,
    private messageService: MessageService
  ) {}

  getHelp(): boolean {
    const helpValue = localStorage.getItem(HELP_KEY);
    this.helpEnabled = helpValue ? helpValue === 'true' : this.helpEnabled;
    return this.helpEnabled;
  }

  setHelp(): void {
    localStorage.setItem(HELP_KEY, this.helpEnabled.toString());
  }

  url() {
    return this.http.get(HELP_PATH, { responseType: 'json', noPrefix: true } );
  }

  toggle() {
    this.helpEnabled = !this.helpEnabled;
    this.setHelp();
    this.messageService.send(SettingsService.HELP_TOGGLE_MESSAGE, { isEnabled: this.helpEnabled });
  }

  async getHelpText(key: string): Promise<Help> {
    if (key) {
      const data = await this.url().toPromise();
      return data[key];
    }
  }

  async get(key: string, isAdaptive = true) {
    if (key) {
      const data = await this.getHelpText(key);
      this.document.title = data.title;
      this.document.content = data.content;
      this.document.isAdaptive = isAdaptive;
      return this.document;
    }
  }
}
