<app-breadcrumbs></app-breadcrumbs>
<section class="document-library-panel">
  <div class="title-row">
    <h2 class="desktop-only">Document Library</h2>
    <ng-container *ngIf="!isGrowerPortal">
      <button *ngIf="canUpload && selectedId.length"
              class="button rounded danger"
              (click)="deleteSelected()">
        <i class="fas fa-trash-alt left"></i>
        Delete Selected
      </button>
      <button *ngIf="canUpload"
              class="button rounded"
              (click)="showUpload=true">
        <i class="fas fa-upload left"></i>
        Upload
      </button>
    </ng-container>
  </div>

  <kendo-grid class="document-library-grid"
              [data]="view | async"
              [pageSize]="state.take"
              [pageable]="true"
              [skip]="state.skip"
              [filterable]="true"
              [filter]="state.filter"
              [sort]="state.sort"
              [sortable]="{ allowUnsort: true, mode: 'multiple' }"
              [selectable]="!isGrowerPortal && canUpload"
              [kendoGridSelectBy]="'id'"
              [(selectedKeys)]="selectedId"
              (dataStateChange)="dataStateChange($event)">
    <kendo-grid-column field="orchard_season" title="Season">
      <ng-template kendoGridFilterCellTemplate let-filter>
        <dropdown-filter filterable="true"
                         [filter]="filter"
                         [data]="seasonOptions"
                         textField="orchard_season"
                         valueField="orchard_season">
        </dropdown-filter>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="grower_number" title="Grower Number">
        <ng-template kendoGridFilterCellTemplate let-filter>
            <combobox-filter filterable="true"
                             [filter]="filter"
                             [data]="growerNumberOptions"
                             textField="grower_number"
                             valueField="grower_number">
            </combobox-filter>
          </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="variety_grow_method" title="Variety/Grow Method">
      <ng-template kendoGridFilterCellTemplate let-filter>
          <combobox-filter filterable="true"
                           [filter]="filter"
                           [data]="varietyGrowMethodOptions"
                           textField="variety_grow_method"
                           valueField="variety_grow_method">
          </combobox-filter>
        </ng-template></kendo-grid-column>
    <kendo-grid-column field="type_name" title="Document Type">
        <ng-template kendoGridFilterCellTemplate let-filter>
            <dropdown-filter filterable="true"
                             [filter]="filter"
                             [data]="documentTypeOptions"
                             textField="type_name"
                             valueField="type_name">
            </dropdown-filter>
          </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="description" title="Description" media="xl"></kendo-grid-column>
    <kendo-grid-column field="uploaded_at"
                       title="Uploaded At"
                       [filterable]="false"
                       [format]="KENDO_SHORT_DATE_TIME_FORMAT"
                       media="sm">
    </kendo-grid-column>

    <kendo-grid-column class="right controls-column" headerClass="right controls-column" footerClass="right controls-column">
      <ng-template kendoGridCellTemplate let-dataItem>
        <ng-container *ngIf="!isGrowerPortal && dataItem.type_name === 'Map'; else standardDownloadButton">
          <kendo-splitbutton (buttonClick)="openFile(dataItem.download_link)"
                             (itemClick)="openFile(dataItem.download_link, $event)"
                             [popupSettings]="{ align: 'right', appendTo: 'component' }"
                             [data]="mapButtonOptions">
            <i class="fas fa-download left"></i>
            <span class="control-label">Download</span>
            <ng-template kendoSplitButtonItemTemplate let-dataItem>
              <strong>{{ dataItem.text }}</strong> {{ dataItem.subText }}
            </ng-template>
          </kendo-splitbutton>
        </ng-container>
        <ng-template #standardDownloadButton>
          <button class="button small rounded fill generic" (click)="openFile(dataItem.download_link)">
            <i class="fas fa-download left"></i>
            <span class="control-label">Download</span>
          </button>
        </ng-template>
      </ng-template>
    </kendo-grid-column>
  </kendo-grid>
</section>

<window-container *ngIf="!isGrowerPortal && canUpload && showUpload"
                  [isVisible]="showUpload"
                  [maximizeOnHeaderDoubleClick]="false"
                  [resizable]="false"
                  [blocking]="true"
                  windowWidth="400"
                  windowHeight="600"
                  class="window-container single-line-title flex-window-container top-align vertical-fill"
                  windowTitle="Upload Documents"
                  [closeWindow]="onDialogClose.bind(this)">
  <kendo-upload class="document-upload"
                [autoUpload]="false"
                [saveMethod]="'PUT'"
                [saveField]="'file'"
                [saveUrl]="uploadSaveUrl"
                (error)="uploadErrorHandler($event)"
                (success)="changeDetectorRef.markForCheck()"
                [restrictions]="uploadRestrictions">
  </kendo-upload>
</window-container>
