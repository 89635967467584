import { TutorialService } from 'app/shared/services/tutorial.service';
import {
  Component,
  OnInit,
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  HostBinding,
  ViewChild,
  OnDestroy
} from '@angular/core';
import { HttpService } from 'app/shared/services/http.service';
import { DataCategoryTab, Details, Level, Tab } from 'app/lib/details';
import { LineChartDataItem, TooltipConfig } from 'app/line-chart/line-chart.component';
import { MultiChartData } from 'app/multi-chart/multi-chart.component';
import {
  clone,
  findItem,
  roundTo,
  openGrowerReport,
  uniqueObjectArrayByKey
} from 'app/shared/services/utils.service';
import { ActivatedRoute } from '@angular/router';
import { DropdownItem } from 'app/shared/dropdown/dropdown.component';
import { Subject, Subscription } from 'rxjs';
import { getGridPagerPageRange, getItemCount } from 'app/grid-pager/grid-pager.component';
import { SimpleGridComponent } from 'app/simple-grid/simple-grid.component';
import { BarChartDataItem } from 'app/grouped-bar-chart/grouped-bar-chart.component';
import { SettingsService } from 'app/shared/services/settings.service';
import { getInventoryDetailsSteps } from './inventory-details-tutorial';
import { GaService } from 'app/shared/services/ga.service';

export const INVENTORY_REPORT_FILE_ENDPOINT = '/api2/v1/growers/kiwifruit/inventory/report/';
export const INVENTORY_DETAILS_ENDPOINT = 'growers/kiwifruit/inventory/details/';
export const INVENTORY_SEASONS_AND_WEEKS_ENDPOINT = 'growers/kiwifruit/inventory/seasons_and_weeks/';
export const INVENTORY_ZCC_ENDPOINT = 'growers/kiwifruit/inventory/zcc/';
export const MIN_INVENTORY_SEASON = 2018;

const VARIETY_SIZE_16_TO_39_IDS = [7];
const PAGER_PAGE_SIZE = 6;
const UI_TRANSITION_DURATION = 750;

interface SeasonWithWeekDropdownItem {
  label: number;
  value: number;
  weeks: WeekDropdownItem[];
}

interface WeekDropdownItem {
  label: number;
  value: number;
}

interface WeekRange {
  from: number;
  to: number;
}

interface SeasonAndWeeks {
  season: number;
  weeks: number[];
}

export interface ZespriCompensationCurveSeason {
  season: number;
  compensation_curves: {
    variety_id: number,
    grow_method_id: number,
    weeks: {
      week: number;
      value: number;
    }[];
  }[];
}

interface BaseInventoryData {
  level?: number;
  parent?: BaseInventoryData;
  isHidden?: boolean;
  groupColumnValue?: string;

  // Response data
  orchard_id: number;
  grower_number: string;
  season: number;
  variety: string;
  variety_id: number;
  grow_method: string;
  grow_method_id: number;
  total_packed: number;
  total_shipped: number;
  total_fruit_loss_grower_liability: number;
  total_on_order: number;
  total_in_store: number;
  percentage_shipped: number;
  percentage_fruit_loss_grower_liability: number;
  percentage_on_order: number;
  percentage_in_store: number;
  trays_by_size: {
    shipped: { [index: string]: number };
    fruit_loss_grower_liability: { [index: string]: number };
    on_order: { [index: string]: number };
    in_store: { [index: string]: number };
  };
  percentage_shipped_trays_by_week: { [index: string]: number };
  adjusted_defects_by_week: { [index: string]: number };
  fruit_loss_grower_liability_trays_by_week: { [index: string]: number };
  shipped_and_ordered_trays_by_week: { [index: string]: number };
  // MA level fields
  maturity_area_id?: number;
  maturity_area_name?: string;
  // Orchard level field for storing MAs
  children?: BaseInventoryData[];
}

@Component({
  selector: 'app-inventory-details',
  templateUrl: './inventory-details.component.html',
  styleUrls: ['./inventory-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InventoryDetailsComponent extends Details implements OnInit, OnDestroy, AfterViewInit {
  hasSidebar = false;
  hasScoutingCheck = false;
  forceChartUpdate = new Subject<void>();
  mainLoadingMessage = 'Loading inventory data...';
  zccData: ZespriCompensationCurveSeason[];
  data: BaseInventoryData[];
  rawData: BaseInventoryData[][] = [];
  isLoading = false;
  chartTransitionDuration = SettingsService.DEFAULT_CHART_TRANSITION_DURATION;
  selectedSeason: SeasonWithWeekDropdownItem = null;
  availableSeasons: SeasonWithWeekDropdownItem[] = [];
  availableWeeks: WeekDropdownItem[] = null;
  selectedWeek: WeekDropdownItem = null;
  tooltipConfigShipped: TooltipConfig = {
    title: { prefix: 'Week ', key: 'x', suffix: '' },
    width: 200,
    content: {
      showHeader: true,
      columns: [
        { key: 'y', unit: '%' }
      ]
    },
    precision: 1,
    isPercent: true,
    showFooter: false
  };
  tooltipX = new Subject<string|number>();
  tooltipConfigGrowerCompensation: TooltipConfig = {
    title: { prefix: 'Week ', key: 'x', suffix: '' },
    width: 280,
    content: {
      showHeader: true,
      columns: [
        { key: 'y', label: '% Fruit Loss', unit: '%', precision: 2 },
        { key: 'z', label: 'Total Trays', precision: 1 }
      ]
    },
    showFooter: true,
    precision: 2,
    emptyPlaceholder: '-'
  };
  weeklyBarChartGroups = [
    {
      label: 'ISO Week',
      primary: 'week',
      secondary: 'group',
      isSelected: true
    }
  ];
  growerCompensationChart = {
    axis: {
      x: {
        label: 'ISO Week'
      },
      y: {
        label: 'Trays Lost',
        unit: '%'
      }
    }
  };
  growerCompensationChartData: MultiChartData = null;
  growerCompensationChartPrecision = 2;
  shippedChart = {
    axis: {
      x: {
        label: 'ISO Week'
      },
      y: {
        label: 'Total Shipped',
        unit: '%'
      }
    }
  };
  shippedLineChartData: LineChartDataItem[] = null;
  inventoryDetailsBarChart = {
    groups: [
      {
        label: 'MA',
        master: 'season',
        primary: 'group',
        secondary: 'component',
        isSelected: true
      },
      {
        label: 'Component',
        master: 'season',
        primary: 'component',
        secondary: 'group',
        isSelected: false
      }
    ],
    data: [],
    plotValues: [
      {  label: 'Packed Trays', value: 'value', precision: 1 },
      {  label: '% Trays', value: 'percent', precision: 1, isPercent: true }
    ],
    yAxis: { label: 'Packed Trays' },
    precision: 0
  };
  inventoryStatusBarChart = {
    groups: [
      {
        label: 'Size',
        master: 'group',
        primary: 'size',
        secondary: 'component',
        isSelected: true
      },
      {
        label: 'Component',
        master: 'group',
        primary: 'component',
        secondary: 'size',
        isSelected: false
      }
    ],
    data: [],
    plotValues: [
      {  label: 'Packed Trays', value: 'value', precision: 0 },
      {  label: '% Trays', value: 'percent', precision: 1 }
    ],
    yAxis: { label: 'Packed Trays' },
    precision: 0
  };
  scoutingCheckBarChart = {
    groups: [
      {
        label: 'ISO week',
        master: 'ma',
        primary: 'week',
        secondary: 'component',
        isSelected: true
      }
    ],
    data: [
      { ma: 'GA1', week: '1', component: 'Adjusted Defects', value: 3.0 },
      { ma: 'GA2', week: '1', component: 'Adjusted Defects', value: 4.5 },
      { ma: 'GA3', week: '1', component: 'Adjusted Defects', value: 2.2 },
      { ma: 'GA1', week: '2', component: 'Adjusted Defects', value: 0.7 },
      { ma: 'GA2', week: '2', component: 'Adjusted Defects', value: 4.4 },
      { ma: 'GA3', week: '2', component: 'Adjusted Defects', value: 1.7 },
      { ma: 'GA1', week: '3', component: 'Adjusted Defects', value: 1.9 },
      { ma: 'GA2', week: '3', component: 'Adjusted Defects', value: 4.6 },
      { ma: 'GA3', week: '3', component: 'Adjusted Defects', value: 5.9 },
      { ma: 'GA1', week: '4', component: 'Adjusted Defects', value: 3.9 },
      { ma: 'GA2', week: '4', component: 'Adjusted Defects', value: 7.5 },
      { ma: 'GA3', week: '4', component: 'Adjusted Defects', value: 2.6 },
      { ma: 'GA1', week: '5', component: 'Adjusted Defects', value: 3.2 },
      { ma: 'GA2', week: '5', component: 'Adjusted Defects', value: 2.1 },
      { ma: 'GA3', week: '5', component: 'Adjusted Defects', value: 4.9 },
      { ma: 'GA1', week: '6', component: 'Adjusted Defects', value: 3.2 },
      { ma: 'GA2', week: '6', component: 'Adjusted Defects', value: 7.9 },
      { ma: 'GA3', week: '6', component: 'Adjusted Defects', value: 5.9 },
      { ma: 'GA1', week: '7', component: 'Adjusted Defects', value: 0.9 },
      { ma: 'GA2', week: '7', component: 'Adjusted Defects', value: 1.0 },
      { ma: 'GA3', week: '7', component: 'Adjusted Defects', value: 5.1 }
    ],
    yAxis: { label: 'Adjusted Defects', unit: '%' },
    precision: 1
  };
  private _dataCategoryTabs: DataCategoryTab[] = [
    { index: 0, title: 'Inventory Status', cssClass: 'inventory-status', id: 'inventory-status' },
    { index: 1, title: 'Shipped', cssClass: 'shipped', id: 'shipped' },
    { index: 2, title: 'Scouting Check', cssClass: 'scouting-check', id: 'scouting-check', hidden: true },
    { index: 3, title: 'Grower Compensation', cssClass: 'grower-compensation', id: 'grower-compensation', hidden: true }
  ];
  get dataCategoryTabs(): DataCategoryTab[] {
    // Filter out hidden tabs
    return this._dataCategoryTabs.filter(tab => !tab.hidden);
  }
  selectedDataCategoryTab: DataCategoryTab = this.dataCategoryTabs[0];
  groupedLabels = this.getGroupedLabel(['KPIN', 'MA']);
  inventoryDropdown = {
    items: [
      { label: 'In Store', value: 'in_store' },
      { label: 'Ordered', value: 'on_order' },
      { label: 'Shipped', value: 'shipped' },
      { label: 'Fruit Loss', value: 'fruit_loss_grower_liability' }
    ],
    current: { label: 'Shipped', value: 'shipped' },
    onChange: this.onInventoryStatusTabDropdownChange.bind(this)
  };
  gridColumns: any[] = [
    {
      tabGroup: 'main', label: this.groupedLabels, rowspan: 2, align: 'left', key: 'groupColumnValue', cssClass: 'fixed-narrow'
    },
    {
      tabGroup: 'main', label: 'Packed Trays', rowspan: 2, key: 'total_packed', precision: 1
    },
    {
      tabGroup: 'main', label: 'In Store', align: 'center', cssClass: 'two-line-height', childColumns: [
        { tabGroup: 'main', label: 'Total', key: 'total_in_store', precision: 0 },
        { tabGroup: 'main', label: '%', key: 'percentage_in_store', precision: 1, isPercent: true },
      ]
    },
    {
      tabGroup: 'main', label: 'Ordered', align: 'center', cssClass: 'two-line-height', childColumns: [
        { tabGroup: 'main', label: 'Total', key: 'total_on_order', precision: 0 },
        { tabGroup: 'main', label: '%', key: 'percentage_on_order', precision: 1, isPercent: true },
      ]
    },
    {
      tabGroup: 'main', label: 'Shipped', align: 'center', cssClass: 'two-line-height', childColumns: [
        { tabGroup: 'main', label: 'Total', key: 'total_shipped', precision: 0 },
        { tabGroup: 'main', label: '%', key: 'percentage_shipped', precision: 1, isPercent: true },
      ]
    },
    {
      tabGroup: 'main', label: 'Fruit Loss', align: 'center', cssClass: 'divider-right two-line-height', childColumns: [
        { tabGroup: 'main', label: 'Total', key: 'total_fruit_loss_grower_liability', precision: 1 },
        {
          tabGroup: 'main',
          label: '%',
          key: 'percentage_fruit_loss_grower_liability',
          precision: 1,
          cssClass: 'divider-right',
          isPercent: true
        }
      ]
    },
    {
      tabGroup: 'inventory-status',
      label: 'Trays By Size',
      align: 'left',
      cssClass: 'two-line-height',
      evenOddChildren: true,
      childColumns: [
        { tabGroup: 'inventory-status', label: '16', key: 'trays_by_size_16', precision: 0, cssClass: 'size-column' },
        { tabGroup: 'inventory-status', label: '18', key: 'trays_by_size_18', precision: 0, cssClass: 'size-column' },
        { tabGroup: 'inventory-status', label: '22', key: 'trays_by_size_22', precision: 0, cssClass: 'size-column' },
        { tabGroup: 'inventory-status', label: '25', key: 'trays_by_size_25', precision: 0, cssClass: 'size-column' },
        { tabGroup: 'inventory-status', label: '27', key: 'trays_by_size_27', precision: 0, cssClass: 'size-column' },
        { tabGroup: 'inventory-status', label: '30', key: 'trays_by_size_30', precision: 0, cssClass: 'size-column' },
        { tabGroup: 'inventory-status', label: '33', key: 'trays_by_size_33', precision: 0, cssClass: 'size-column' },
        { tabGroup: 'inventory-status', label: '36', key: 'trays_by_size_36', precision: 0, cssClass: 'size-column' },
        { tabGroup: 'inventory-status', label: '39', key: 'trays_by_size_39', precision: 0, cssClass: 'size-column' }
      ], dropdown: this.inventoryDropdown
    },
    {
      tabGroup: 'shipped-vs-week',
      label: 'Cumulative % Trays Shipped by ISO Week',
      align: 'left',
      cssClass: 'week-column two-line-height',
      evenOddChildren: true,
      childColumns: [],
      pager: {
        label: 'Weeks:',
        pageSize: PAGER_PAGE_SIZE,
        separator: '-',
        currentPage: 999,
        onChange: this.onShippedTabPageChange.bind(this)
      }
    },
    {
      tabGroup: 'scouting-check',
      label: '% Adjusted Defects by ISO Week',
      align: 'left',
      cssClass: 'week-column two-line-height',
      evenOddChildren: true,
      childColumns: [],
      pager: {
        label: 'Weeks:',
        pageSize: PAGER_PAGE_SIZE,
        separator: '-',
        currentPage: 999,
        onChange: this.onScoutingCheckTabPageChange.bind(this)
      }
    },
    {
      tabGroup: 'grower-compensation',
      label: 'Trays Lost, <span class="sub-value">Trays Shipped + Trays Ordered + Trays Lost</span> by ISO Week',
      align: 'left',
      cssClass: 'week-column two-line-height',
      evenOddChildren: true,
      childColumns: [],
      pager: {
        label: 'Weeks:',
        pageSize: PAGER_PAGE_SIZE,
        separator: '-',
        currentPage: 999,
        onChange: this.onGrowerCompensationTabPageChange.bind(this)
      }
    }
  ];
  gridHasColumnSelection = false;
  hiddenColumns: number|string[] = [];
  hiddenShippedColumns = [];
  hiddenScoutingCheckColumns = [];
  hiddenGrowerCompensationColumns = [];
  selectedGridRowIndexes = [];
  maxDropdownHeight = 300;
  backData: { season: number, week: number, data: BaseInventoryData[] }[] = [];
  scoutingCheckSidebarChartGroups = [
    {
      label: 'ISO Week',
      primary: 'week',
      secondary: 'defect',
      isSelected: true
    }
  ];
  scoutingCheckSidebarChartData: any[];
  scoutingCheckSidebarData = [
    { week: 1, minors: 1.3, majors: 1.5, over_rots: 1.1, over_ripes: 0.8, p_marks: 0.6, rots: 1.9, sbd: 1.7, softs: 2.1 },
    { week: 2, minors: 1.2, majors: 1.1, over_rots: 0.5, over_ripes: 2.2, p_marks: 2.1, rots: 1.4, sbd: 1.2, softs: 0.8 },
    { week: 3, minors: 3, majors: 3, over_rots: 3, over_ripes: 3, p_marks: 3, rots: 3, sbd: 3, softs: 3 },
    { week: 4, minors: 4, majors: 4, over_rots: 4, over_ripes: 4, p_marks: 4, rots: 4, sbd: 4, softs: 4 },
    { week: 5, minors: 5, majors: 5, over_rots: 5, over_ripes: 5, p_marks: 5, rots: 5, sbd: 5, softs: 5 },
    { week: 6, minors: 6, majors: 6, over_rots: 6, over_ripes: 6, p_marks: 6, rots: 6, sbd: 6, softs: 6 },
    { week: 7, minors: 7, majors: 7, over_rots: 7, over_ripes: 7, p_marks: 7, rots: 7, sbd: 7, softs: 7 },
    { week: 8, minors: 8, majors: 8, over_rots: 8, over_ripes: 8, p_marks: 8, rots: 8, sbd: 8, softs: 8 },
    { week: 9, minors: 9, majors: 9, over_rots: 9, over_ripes: 9, p_marks: 9, rots: 9, sbd: 9, softs: 9 },
    { week: 10, minors: 1, majors: 1, over_rots: 1, over_ripes: 1, p_marks: 1, rots: 1, sbd: 1, softs: 1 },
    { week: 11, minors: 1, majors: 1, over_rots: 1, over_ripes: 1, p_marks: 1, rots: 1, sbd: 1, softs: 1 },
    { week: 12, minors: 2, majors: 2, over_rots: 2, over_ripes: 2, p_marks: 2, rots: 2, sbd: 2, softs: 2 },
    { week: 13, minors: 3, majors: 3, over_rots: 3, over_ripes: 3, p_marks: 3, rots: 3, sbd: 3, softs: 3 },
    { week: 14, minors: 4, majors: 4, over_rots: 4, over_ripes: 4, p_marks: 4, rots: 4, sbd: 4, softs: 4 },
    { week: 15, minors: 5, majors: 5, over_rots: 5, over_ripes: 5, p_marks: 5, rots: 5, sbd: 5, softs: 5 },
    { week: 16, minors: 6, majors: 6, over_rots: 6, over_ripes: 6, p_marks: 6, rots: 6, sbd: 6, softs: 6 },
    { week: 17, minors: 7, majors: 7, over_rots: 7, over_ripes: 7, p_marks: 7, rots: 7, sbd: 7, softs: 7 },
    { week: 18, minors: 8, majors: 8, over_rots: 8, over_ripes: 8, p_marks: 8, rots: 8, sbd: 8, softs: 8 },
    { week: 19, minors: 9, majors: 9, over_rots: 9, over_ripes: 9, p_marks: 9, rots: 9, sbd: 9, softs: 9 },
    { week: 20, minors: 2, majors: 2, over_rots: 2, over_ripes: 2, p_marks: 2, rots: 2, sbd: 2, softs: 2 },
    { week: 21, minors: 2, majors: 2, over_rots: 2, over_ripes: 2, p_marks: 2, rots: 2, sbd: 2, softs: 2 },
    { week: 22, minors: 2, majors: 2, over_rots: 2, over_ripes: 2, p_marks: 2, rots: 2, sbd: 2, softs: 2 },
    { week: 23, minors: 3, majors: 3, over_rots: 3, over_ripes: 3, p_marks: 3, rots: 3, sbd: 3, softs: 3 },
    { week: 24, minors: 4, majors: 4, over_rots: 4, over_ripes: 4, p_marks: 4, rots: 4, sbd: 4, softs: 4 },
    { week: 25, minors: 5, majors: 5, over_rots: 5, over_ripes: 5, p_marks: 5, rots: 5, sbd: 5, softs: 5 },
    { week: 26, minors: 6, majors: 6, over_rots: 6, over_ripes: 6, p_marks: 6, rots: 6, sbd: 6, softs: 6 },
    { week: 27, minors: 7, majors: 7, over_rots: 7, over_ripes: 7, p_marks: 7, rots: 7, sbd: 7, softs: 7 },
    { week: 28, minors: 8, majors: 8, over_rots: 8, over_ripes: 8, p_marks: 8, rots: 8, sbd: 8, softs: 8 },
    { week: 29, minors: 9, majors: 9, over_rots: 9, over_ripes: 9, p_marks: 9, rots: 9, sbd: 9, softs: 9 },
    { week: 30, minors: 1, majors: 1, over_rots: 1, over_ripes: 1, p_marks: 1, rots: 1, sbd: 1, softs: 1 },
    { week: 31, minors: 1, majors: 1, over_rots: 1, over_ripes: 1, p_marks: 1, rots: 1, sbd: 1, softs: 1 },
    { week: 32, minors: 2, majors: 2, over_rots: 2, over_ripes: 2, p_marks: 2, rots: 2, sbd: 2, softs: 2 },
    { week: 33, minors: 3, majors: 3, over_rots: 3, over_ripes: 3, p_marks: 3, rots: 3, sbd: 3, softs: 3 },
    { week: 34, minors: 4, majors: 4, over_rots: 4, over_ripes: 4, p_marks: 4, rots: 4, sbd: 4, softs: 4 },
    { week: 35, minors: 5, majors: 5, over_rots: 5, over_ripes: 5, p_marks: 5, rots: 5, sbd: 5, softs: 5 },
    { week: 36, minors: 6, majors: 6, over_rots: 6, over_ripes: 6, p_marks: 6, rots: 6, sbd: 6, softs: 6 },
    { week: 37, minors: 7, majors: 7, over_rots: 7, over_ripes: 7, p_marks: 7, rots: 7, sbd: 7, softs: 7 },
    { week: 38, minors: 8, majors: 8, over_rots: 8, over_ripes: 8, p_marks: 8, rots: 8, sbd: 8, softs: 8 },
    { week: 39, minors: 9, majors: 9, over_rots: 9, over_ripes: 9, p_marks: 9, rots: 9, sbd: 9, softs: 9 }
  ];
  scoutingCheckSidebarSelectedItem: any = null;
  fullScreenPage: string;
  isUiTransitionInProgress = false;

  @HostBinding('class.no-data') hasNoData = true;
  @ViewChild('mainGrid', { static: false }) mainGrid: SimpleGridComponent;

  private selectedLevel: string;
  private selectedId: number;
  private selectedRow: BaseInventoryData;
  private visibleIsoWeekRange: WeekRange;
  private shippedWeekRange: WeekRange;
  private availableSeasonSubscription: Subscription;

  constructor(
    private http: HttpService,
    private changeDetectorRef: ChangeDetectorRef,
    private activatedRoute: ActivatedRoute,
    private tutorialService: TutorialService,
    protected ga: GaService
  ) {
    super(ga);
  }

  async ngOnInit() {
    this.isLoading = true;
    this.availableSeasons = await this.getSeasonsAndWeeks();

    if (this.availableSeasons && this.availableSeasons.length) {
      this.selectedSeason = this.availableSeasons[0];
      this.createAvailableSeasonSubscription();
      this.updateAvailableWeeks();
      this.selectLatestWeek();
      this.init();
      this.initScoutingCheckSidebar();
    } else {
      this.isLoading = false;
      this.hasNoData = true;
      this.data = [];
      this.changeDetectorRef.markForCheck();
    }
  }

  ngOnDestroy() {
    this.availableSeasonSubscription?.unsubscribe();
  }

  ngAfterViewInit() {
    this.tutorialService.run('inventory-details', getInventoryDetailsSteps);
  }

  onTabSelect(event) {
    const newTab = this.tabs[event.index];
    if (newTab.varietyId !== this.selectedTab.varietyId || newTab.growMethodId !== this.selectedTab.growMethodId) {
      this.ga.event('tab', 'change', 'inventory details variety gm tab', event.title);
      this.startUiTransition();
      this.selectedTab = this.tabs[event.index];
      this.updateData();
      this.updateSelectedGridRowIndexes();
      this.expandIsoWeekGridColumns();
      this.showColumns(this.selectedDataCategoryTab.title);
    }
  }

  selectSeason(season: SeasonWithWeekDropdownItem) {
    if (!this.selectedSeason || this.selectedSeason.label !== season.label) {
      this.ga.event('dropdown', 'change', 'inventory details season change', season.label);
      this.startUiTransition();
      this.selectedSeason = season;
      this.updateAvailableWeeks();
      this.selectLatestWeek();
      this.init();
    }
  }

  selectWeek(week: WeekDropdownItem) {
    if (!this.selectedWeek || this.selectedWeek.label !== week.label) {
      this.ga.event('dropdown', 'change', 'inventory details week change', week.label);
      this.startUiTransition();
      this.selectedWeek = week;
      this.init();
    }
  }

  updateScoutingCheckSidebarChart(item) {
    if (!this.scoutingCheckSidebarSelectedItem) {
      // Initial item selection
      this.scoutingCheckSidebarSelectedItem = item;
    } else if (this.scoutingCheckSidebarSelectedItem.week === item.week) {
      // No need to re-select the same item
      return;
    } else {
      // Unselect original item and select the new item
      this.scoutingCheckSidebarSelectedItem.selected = false;
      this.scoutingCheckSidebarSelectedItem = item;
    }

    this.scoutingCheckSidebarSelectedItem.selected = true;
    this.setScoutingCheckSidebarChartData(this.scoutingCheckSidebarSelectedItem);
  }

  onGridColumnSelect(_, column: any) {
    this.ga.event('grid', 'change', 'inventory details grid column select', column.label);
    this.tooltipX.next(column.label);
  }

  openInventoryReport() {
    if (this.selectedRow) {
      const url = `${INVENTORY_REPORT_FILE_ENDPOINT}${this.selectedRow.orchard_id}/?week=${this.selectedWeek.value}&variety_code=${this.selectedRow.variety}&grow_method_code=${this.selectedRow.grow_method}`;
      this.ga.event('button', 'click', 'inventory details inventory report download', url);
      openGrowerReport(url);
    }
  }

  onGridRowToggle() {
    this.ga.event('grid', 'change', 'inventory details grid row toggle');
    this.triggerChartUpdate();
  }

  toggleSidebar() {
    this.isSidebarClosed = !this.isSidebarClosed;
    this.ga.event('button', 'click', 'inventory details sidebar toggle', this.isSidebarClosed);
    this.triggerChartUpdate();
  }

  enterFullScreen(pageTitle?: string) {
    this.ga.event('button', 'click', 'inventory details full enter', pageTitle);
    this.chartTransitionDuration = 0;
    this.tooltipX.next('update-x');
    this.fullScreenPage = pageTitle || this.selectedDataCategoryTab.title;
    this.triggerChartUpdate();
  }

  exitFullScreen() {
    this.ga.event('button', 'click', 'inventory details full screen exit');
    this.tooltipX.next('force-hide');
    this.fullScreenPage = null;
    this.triggerChartUpdate();
    setTimeout(() => {
      this.chartTransitionDuration = SettingsService.DEFAULT_CHART_TRANSITION_DURATION;
    });
  }

  startUiTransition() {
    if (!this.isUiTransitionInProgress) {
      this.isUiTransitionInProgress = true;
      this.changeDetectorRef.markForCheck();

      setTimeout(() => {
        this.isUiTransitionInProgress = false;
        this.changeDetectorRef.markForCheck();
      }, UI_TRANSITION_DURATION);
    }
  }

  private triggerChartUpdate() {
    this.forceChartUpdate.next();
  }

  private initScoutingCheckSidebar() {
    if (this.scoutingCheckSidebarData && this.scoutingCheckSidebarData.length) {
      this.updateScoutingCheckSidebarChart(this.scoutingCheckSidebarData[0]);
    }
  }

  private setScoutingCheckSidebarChartData(dataRow) {
    this.scoutingCheckSidebarChartData = Object.keys(dataRow || {}).reduce((result, key) => {
      if (key !== 'week' && key !== 'selected') {
        result.push({ week: '', defect: key, value: dataRow[key] });
      }
      return result;
    }, []);
  }

  private expandIsoWeekGridColumns() {
    this.expandShippedGridColumns();
    this.expandGrowerCompensationGridColumns();

    // Uncomment when scouting check is displayed
    // this.expandScoutingCheckGridColumns();
  }

  private expandShippedGridColumns() {
    this.shippedWeekRange = this.getWeekRange('percentage_shipped_trays_by_week_');
    const column = this.gridColumns[7];
    column.pager.from = this.shippedWeekRange.from;
    column.pager.to = this.shippedWeekRange.to;
    this.expandPercentData(this.data, this.shippedWeekRange.from, this.shippedWeekRange.to, 'percentage_shipped_trays_by_week_');
    column.childColumns = this.makeIsoWeekColumns(
      this.shippedWeekRange.from,
      this.shippedWeekRange.to,
      'shipped-vs-week',
      'percentage_shipped_trays_by_week_',
      null,
      0,
      true,
      true,
      ' ',
      true,
      true
    );
    this.initHiddenShippedColumns(this.shippedWeekRange.from, this.shippedWeekRange.to);
  }

  private expandPercentData(data: BaseInventoryData[], from: number, to: number, keyPrefix: string) {
    data.forEach((item) => {
      for (let week = from; week <= to; week++) {
        const value = item[keyPrefix + week];
        // If an item value is not defined but the previous week's value was 100%
        // create a new value and set it to 100%
        if (value == null && item[keyPrefix + (week - 1)] === 100) {
          item[keyPrefix + week] = 100;
        }
      }
    });
    return data;
  }

  private initHiddenShippedColumns(from: number, to: number) {
    const itemCount = getItemCount(from, to);
    const [pageFrom, pageTo] = getGridPagerPageRange(from, itemCount, PAGER_PAGE_SIZE, 999);
    this.onShippedTabPageChange(pageFrom, pageTo, true);
  }

  private expandScoutingCheckGridColumns() {
    const weekRange: WeekRange = this.getWeekRange('adjusted_defects_by_week_');
    const column = this.gridColumns[8];
    column.pager.from = weekRange.from;
    column.pager.to = weekRange.to;
    column.childColumns = this.makeIsoWeekColumns(
      weekRange.from,
      weekRange.to,
      'scouting-check',
      'adjusted_defects_by_week_',
      null,
      0,
      true,
      true
    );
    this.initHiddenScoutingCheckColumns(weekRange.from, weekRange.to);
  }

  private initHiddenScoutingCheckColumns(from: number, to: number) {
    const itemCount = getItemCount(from, to);
    const [pageFrom, pageTo] = getGridPagerPageRange(from, itemCount, PAGER_PAGE_SIZE, 999);
    this.onScoutingCheckTabPageChange(pageFrom, pageTo, true);
  }

  private expandGrowerCompensationGridColumns() {
    const weekRange: WeekRange = this.getWeekRange('fruit_loss_grower_liability_trays_by_week_');
    const column = this.gridColumns[9];
    column.pager.from = weekRange.from;
    column.pager.to = weekRange.to;
    column.childColumns = this.makeIsoWeekColumns(
      weekRange.from,
      weekRange.to,
      'grower-compensation',
      'fruit_loss_grower_liability_trays_by_week_',
      'shipped_and_ordered_trays_by_week_',
      1,
      true,
      true,
      ' '
    );
    this.initHiddenGrowerCompensationColumns(weekRange.from, weekRange.to);
  }

  private initHiddenGrowerCompensationColumns(from: number, to: number) {
    const itemCount = getItemCount(from, to);
    const [pageFrom, pageTo] = getGridPagerPageRange(from, itemCount, PAGER_PAGE_SIZE, 999);
    this.onGrowerCompensationTabPageChange(pageFrom, pageTo, true);
  }

  private getWeekRange(prefix: string): WeekRange {
    let from = 53;
    let to = 0;
    let matchFound = false;
    const pattern = new RegExp(`^${ prefix }(\\d+)$`);
    this.data.forEach((item) => {
      if (item.variety_id === this.selectedTab.varietyId && item.grow_method_id === this.selectedTab.growMethodId) {
        Object.keys(item).forEach((key: string) => {
          const match = key.match(pattern);
          if (match) {
            matchFound = true;
            const value = parseInt(match[1], 10);
            if (value < from) {
              from = value;
            }
            if (value > to) {
              to = value;
            }
          }
        });
      }
    });

    if (!matchFound) {
      // Data did not contain the prefix we were looking for! So, center the range around the selected week instead
      from = this.selectedWeek.value;
      to = from;
    }
    [from, to] = this.makeWeekRangeSafe(from, to);
    return { from: from, to: to };
  }

  // At least PAGER_PAGE_SIZE worth of weeks in range is required
  private makeWeekRangeSafe(from: number, to: number) {
    const expansion = PAGER_PAGE_SIZE - (to - from);
    if (expansion > 0) {
      // Center the existing data in expanded range
      const expansionPerSide = Math.floor(expansion / 2);
      to += expansionPerSide;
      from -= expansionPerSide;
      if (from < 0) {
        to -= from;
        from = 0;
      }
    }
    return [from, to];
  }

  private makeIsoWeekColumns(
    from: number,
    to: number,
    tabGroup: string,
    keyPrefix: string,
    subValue: string = null,
    subValuePrecision = 0,
    isSelectable = true,
    isSameSelectable = true,
    emptyPlaceholder: string = null,
    isPercent = false,
    zeroIsValid = false
  ) {
    const columns = [];
    for (let i = from; i <= to; i++) {
      const column = this.makeIsoWeekColumn(
        tabGroup,
        '' + i,
        keyPrefix + i,
        isSelectable,
        isSameSelectable,
        emptyPlaceholder,
        isPercent,
        zeroIsValid
      );
      if (subValue) {
        column['subValue'] = subValue + i;
        column['alwaysShowSubValue'] = true;
        column['subValuePrecision'] = subValuePrecision;
      }
      columns.push(column);
    }
    return columns;
  }

  private makeIsoWeekColumn(
    tabGroup: string,
    label: string,
    key: string,
    isSelectable: boolean,
    isSameSelectable: boolean,
    emptyPlaceholder: string = null,
    isPercent = false,
    zeroIsValid = false
  ) {
    return {
      tabGroup: tabGroup,
      label: label,
      key: key,
      precision: 1,
      cssClass: 'week-column',
      isSelectable: isSelectable,
      isSameSelectable: isSameSelectable,
      emptyPlaceholder: emptyPlaceholder,
      isPercent: isPercent,
      zeroIsValid: zeroIsValid
    };
  }

  private createAvailableSeasonSubscription() {
    this.availableSeasonSubscription = this.activatedRoute.queryParams.subscribe(params => {
      if (params.season) {
        const selectedSeason = findItem(this.availableSeasons, 'value', parseInt(params.season, 10));
        if (selectedSeason) {
          this.selectedSeason = selectedSeason;
        }
      }
    });
  }

  private async init() {
    this.showLoading();
    this.rawData = await this.getData();
    this.zccData = await this.getZespriCompensationCurveData();
    if (this.zccData) {
      this.prependZeroToZccData();
      this.toggleCompensationCurveTab();
    }

    this.changeDetectorRef.markForCheck();

    this.tabs = this.getTabs(this.rawData[0]);
    if (this.tabs?.length) {
      this.sortTabs();
      this.initSelectedTab();
      this.updateData();
      this.updateSelectedGridRowIndexes();
      this.expandIsoWeekGridColumns();
      this.showColumns(this.selectedDataCategoryTab.title);
    } else {
      this.hasNoData = true;
      this.data = [];
    }

    // The delay ensures the charts update before the page is revealed smoothing out the transition
    setTimeout(this.hideLoading.bind(this));
  }

  private prependZeroToZccData() {
    this.zccData.forEach((item: ZespriCompensationCurveSeason) => {
      (item.compensation_curves || []).forEach((curve) => {
        const firstWeek = curve.weeks[0];
        if (firstWeek.week > 1) {
          curve.weeks.unshift({ week: firstWeek.week - 1, value: 0 });
        }
      });
    });
  }

  private toggleCompensationCurveTab() {
    // Only show the "Grower Compensation" tab if there is data for the selected season
    const seasonsCompensationCurveData = this.zccData
      .filter((item) => item.season === this.selectedSeason.value);
    const compensationCurveTab = findItem(this._dataCategoryTabs, 'id', 'grower-compensation');
    compensationCurveTab.hidden = !seasonsCompensationCurveData.length;
  }

  private updateSelectedGridRowIndexes() {
    let selectedRowIndex = this.findSelectedRowIndex(this.data);
    if (selectedRowIndex == null) {
      selectedRowIndex = this.getFirstMaturityAreaGridRowIndex();
    }

    if (selectedRowIndex != null) {
      this.selectedGridRowIndexes = [selectedRowIndex];
    }
  }

  private findSelectedRowIndex(data: any[]) {
    for (let i = 0; i < this.data.length; i++) {
      const item = data[i];
      if (this.selectedLevel === 'orchard' && item.level === Level.Orchard && item.orchard_id === this.selectedId) {
        return i;
      } else if (this.selectedLevel === 'ma' && item.level === Level.Ma && item.maturity_area_id === this.selectedId) {
        return i;
      }
    }
  }

  private getFirstMaturityAreaGridRowIndex() {
    for (let i = 0; i < this.data.length; i++) {
      if (this.data[i].level === Level.Ma) {
        return i;
      }
    }
  }

  private selectLatestWeek() {
    this.selectedWeek = this.availableWeeks[0];
  }

  private updateAvailableWeeks() {
    this.availableWeeks = this.selectedSeason.weeks;
  }

  protected selectGridRow(row) {
    if (this.selectedRow !== row) {
      this.selectedRow = row;

      if (this.selectedRow) {
        this.setInventoryDetailsChart(this.selectedRow);
        this.setInventoryStatusChart(this.selectedRow);

        if (this.shippedWeekRange) {
          this.setShippedChart(this.selectedRow);
        }

        if (this.visibleIsoWeekRange) {
          this.setGrowerCompensationChart(this.selectedRow, this.visibleIsoWeekRange.from, this.visibleIsoWeekRange.to);
        }
      }
    }
  }

  protected showColumns(category: string) {
    switch (category) {
      case 'Inventory Status': return this.showInventoryStatusColumns();
      case 'Shipped': return this.showShippedColumns();
      case 'Scouting Check': return this.showScoutingCheckColumns();
      case 'Grower Compensation': return this.showGrowerCompensationColumns();
    }
  }

  protected findSelectedRow() {}

  private async getSeasonsAndWeeks(): Promise<SeasonWithWeekDropdownItem[]> {
    const data: SeasonAndWeeks[] = await this.fetchSeasonsAndWeeks();
    return data.map((item: { season: number; weeks: number[] }) => {
      return {
        label: item.season,
        value: item.season,
        weeks: this.getWeeks(item.weeks)
      };
    });
  }

  private fetchSeasonsAndWeeks(): Promise<any> {
    return this.http.get(INVENTORY_SEASONS_AND_WEEKS_ENDPOINT).toPromise();
  }

  private getWeeks(weeks: number[]): WeekDropdownItem[] {
    return weeks.map((week: number) => {
      return { label: week, value: week };
    });
  }

  private showLoading() {
    this.isLoading = true;
    this.changeDetectorRef.markForCheck();
  }

  private hideLoading() {
    this.isLoading = false;
    this.changeDetectorRef.markForCheck();
  }

  private async getData(): Promise<BaseInventoryData[][]> {
    const selectedSeason = this.selectedSeason.value;
    const selectedWeek = this.selectedWeek.value;
    const data = [];
    const currentSeasonData = await this.fetchData(selectedSeason, selectedWeek);

    if (currentSeasonData && currentSeasonData.length) {
      data.push(currentSeasonData);

      for (let i = 1; i <= 2; i++) {
        const season = selectedSeason - i;
        if (season >= MIN_INVENTORY_SEASON) {
          // TODO should it fetch the latest week or the same week as the currently displayed season?
          const latestWeekForSeason = this.getLatestWeekForSeason(season);
          if (latestWeekForSeason) {
            data.push(this.fetchData(season, latestWeekForSeason));
          }
        }
      }
    }
    return Promise.all(data);
  }

  private fetchData(season: number, week: number): Promise<BaseInventoryData[]> {
    const params = { season: season, week: week };
    return this.http.get(INVENTORY_DETAILS_ENDPOINT, { params: params }).toPromise() as Promise<BaseInventoryData[]>;
  }

  private getLatestWeekForSeason(season: number): number {
    const seasonData = this.availableSeasons.find(item => item.value === season);
    if (seasonData && seasonData.weeks && seasonData.weeks.length) {
      return seasonData.weeks[0].value;
    } else {
      return null;
    }
  }

  private getTabs(data: any[]): Tab[] {
    const tabData = [];
    (data || []).forEach((orchardData) => {
      (orchardData.children || []).forEach((maturityAreaData) => {
        tabData.push({
          title: maturityAreaData.variety + maturityAreaData.grow_method,
          varietyId: maturityAreaData.variety_id,
          growMethodId: maturityAreaData.grow_method_id
        });
      });
    });
    return uniqueObjectArrayByKey(tabData, 'title');
  }

  private clearBackData() {
    this.backData = [];
  }

  private async getZespriCompensationCurveData(): Promise<ZespriCompensationCurveSeason[]> {
    return this.http.get(INVENTORY_ZCC_ENDPOINT).toPromise() as Promise<ZespriCompensationCurveSeason[]>;
  }

  private updateData() {
    this.data = this.getSeasonData(this.rawData[0]) || [];

    this.updateWeeklyData(this.data, 'percentage_shipped_trays_by_week', true);
    this.updateWeeklyData(this.data, 'adjusted_defects_by_week');
    this.updateWeeklyData(this.data, 'fruit_loss_grower_liability_trays_by_week');
    this.updateWeeklyData(this.data, 'shipped_and_ordered_trays_by_week');

    this.updateInventoryStatusSizeColumns();

    this.clearBackData();
    if (this.data.length) {
      this.updateDataBackSeasonData(1);
      this.updateDataBackSeasonData(2);

      this.backData.forEach((item) => {
        this.updateWeeklyData(item.data, 'percentage_shipped_trays_by_week');
        this.updateWeeklyData(item.data, 'adjusted_defects_by_week');
        this.updateWeeklyData(item.data, 'fruit_loss_grower_liability_trays_by_week');
        this.updateWeeklyData(item.data, 'shipped_and_ordered_trays_by_week');
      });
    }
    this.hasNoData = !this.data.length;
  }

  private updateInventoryStatusSizeColumns() {
    const availableSizes = [16, 18, 22, 25, 27, 30, 33, 36, 39];
    if (!VARIETY_SIZE_16_TO_39_IDS.includes(this.selectedTab.varietyId)) {
      availableSizes.push(42);
      availableSizes.shift();
    }
    const inventoryStatusGridColumn = this.gridColumns.find((item) => item.tabGroup === 'inventory-status');
    inventoryStatusGridColumn.childColumns.forEach((column, i) => {
      const size = availableSizes[i];
      column.label = '' + size;
      column.key = 'trays_by_size_' + size;
    });
  }

  private updateDataBackSeasonData(seasonsBack: number) {
    const backSeasonData = this.getSeasonData(this.rawData[seasonsBack]);
    if (backSeasonData) {
      this.saveBackDataItem(backSeasonData, this.selectedSeason.value - seasonsBack, null);
    }
  }

  private saveBackDataItem(data: BaseInventoryData[], season: number, week: number = null) {
    this.backData.push({ season: season, week: week, data: data });
  }

  private getSeasonData(rawData): BaseInventoryData[] {
    let data = clone(rawData);
    data = this.filterDataAndChildren(data, this.selectedTab.varietyId, this.selectedTab.growMethodId);
    if (data && data.length) {
      data = this.flattenChildren(data);
      return data;
    }
  }

  private flattenChildren(data: BaseInventoryData[], parent = null, flatData = [], level = 0): any[] {
    data.forEach((item) => {
      item.level = level;
      item.parent = parent;
      item.isHidden = level > 0;
      item.groupColumnValue = this.getGroupColumnValue(item, level);

      if (!item.grower_number && parent) {
        item.grower_number = parent.grower_number;
      }

      flatData.push(item);
      if (item.children) {
        this.flattenChildren(item.children, item, flatData, level + 1);
      }
    });
    return flatData;
  }

  private getGroupColumnValue(row, level) {
    switch (level) {
      case 0: return row.grower_number;
      case 1: return row.maturity_area_name;
      default: return null;
    }
  }

  private filterDataAndChildren(data: BaseInventoryData[], varietyId: number, growMethodId: number): any[] {
    return (data || []).filter((item) => {
      item.children = this.filterData(item.children, varietyId, growMethodId);
      return item.children.length > 0;
    });
  }

  private showInventoryStatusColumns() {
    this.hiddenColumns = ['shipped-vs-week', 'scouting-check', 'grower-compensation'];
    this.gridHasColumnSelection = false;
  }

  private showShippedColumns() {
    this.hiddenColumns = ['inventory-status', 'scouting-check', 'grower-compensation'].concat(this.hiddenShippedColumns);
    this.gridHasColumnSelection = true;
  }

  private showScoutingCheckColumns() {
    this.hiddenColumns = ['shipped-vs-week', 'inventory-status', 'grower-compensation'].concat(this.hiddenScoutingCheckColumns);
    this.gridHasColumnSelection = true;
  }

  private showGrowerCompensationColumns() {
    this.hiddenColumns = ['shipped-vs-week', 'inventory-status', 'scouting-check'].concat(this.hiddenGrowerCompensationColumns);
    this.gridHasColumnSelection = true;
  }

  private updateTraysBySizeData(traysBySizeType: string) {
    this.data.forEach((item) => {
      const traysBySize = item['trays_by_size'][traysBySizeType];
      Object.keys(traysBySize || {}).forEach((size: string) => {
        item['trays_by_size_' + size] = traysBySize[size];
      });
    });
  }

  private updateTraysBySizeDataPrecision(traysBySizeType: string) {
    const precision = traysBySizeType === 'fruit_loss_grower_liability' ? 1 : 0;
    if (this.mainGrid) {
      this.mainGrid.updateColumnProperty('inventory-status', 'precision', precision);
    }
  }

  private updateWeeklyData(data: BaseInventoryData[], key: string, propagateLastValue = false) {
    data.forEach((item) => {
      const itemData = item[key];
      let lastWeek: string|number = '1';
      let value = 0;
      Object.keys(itemData || {}).forEach((week: string) => {
        value = itemData[week];
        lastWeek = week;
        item[`${ key }_${ week }`] = value;
      });
      delete(item[key]);

      lastWeek = parseInt(lastWeek, 10);
      if (propagateLastValue && (this.selectedWeek.value > lastWeek)) {
        this.propagateWeeklyValue(item, key, lastWeek + 1, this.selectedWeek.value, value);
      }
    });
  }

  private propagateWeeklyValue(data: any, key: string, from: number, to: number, value: string|number) {
    for (let week = from; week <= to; week++) {
      data[`${ key }_${ week }`] = value;
    }
  }

  private onInventoryStatusTabDropdownChange(item: DropdownItem) {
    this.inventoryDropdown.current = item;
    this.updateTraysBySizeDataPrecision(item.value);
    this.updateTraysBySizeData(this.inventoryDropdown.current.value);
  }

  private onShippedTabPageChange(from: number, to: number, isInit: boolean) {
    this.hiddenShippedColumns = this.getHiddenIsoWeekColumns('percentage_shipped_trays_by_week_', from, to);
    if (!isInit) {
      this.showShippedColumns();
    }
    this.visibleIsoWeekRange = { from: from, to: to };
  }

  private onScoutingCheckTabPageChange(from: number, to: number, isInit: boolean) {
    this.hiddenScoutingCheckColumns = this.getHiddenIsoWeekColumns('adjusted_defects_by_week_', from, to);
    if (!isInit) {
      this.showScoutingCheckColumns();
    }
  }

  private onGrowerCompensationTabPageChange(from: number, to: number, isInit: boolean) {
    this.hiddenGrowerCompensationColumns = this.getHiddenIsoWeekColumns('fruit_loss_grower_liability_trays_by_week_', from, to);
    if (!isInit) {
      this.showGrowerCompensationColumns();
    }
    this.visibleIsoWeekRange = { from: from, to: to };
    if (this.selectedRow) {
      this.setGrowerCompensationChart(this.selectedRow, from, to);
    }
  }

  private getHiddenIsoWeekColumns(prefix: string, from: number, to: number) {
    const hiddenColumns = [];
    for (let i = 1; i < from; i++) {
      hiddenColumns.push(prefix + i);
    }
    for (let i = to + 1; i <= 52; i++) {
      hiddenColumns.push(prefix + i);
    }
    return hiddenColumns;
  }

  private setInventoryDetailsChart(selectedGridRow) {
    // Expected data format: [{ season: 2018, group: 'GA1', component: 'Shipped', value: 90, percent: 6.4 }, ...]
    if (selectedGridRow.level === Level.Ma) {
      this.setBarChartPrimaryGroupLabel(this.inventoryDetailsBarChart, 'MA');
      this.setInventoryDetailsChartData(Level.Ma, 'maturity_area_name', selectedGridRow.grower_number);
    } else if (selectedGridRow.level === Level.Orchard) {
      this.setBarChartPrimaryGroupLabel(this.inventoryDetailsBarChart, 'KPIN');
      this.setInventoryDetailsChartData(Level.Orchard, 'grower_number');
    }
    this.changeDetectorRef.markForCheck();
  }

  private setBarChartPrimaryGroupLabel(chartDefinition: any, value: string) {
    chartDefinition.groups[0].label = value;
  }

  private setInventoryDetailsChartData(level: number, groupKey: string, growerNumber = '') {
    const action = this.getInventoryDetailsBarChartDataItems.bind(this);
    const chartData = this.getBarChartData(this.data, action, level, groupKey, growerNumber);
    if (chartData && chartData.length) {
      this.backData.forEach((backDataItem) => {
        chartData.push(...this.getBarChartData(backDataItem.data, action, level, groupKey, growerNumber));
      });
    }
    this.inventoryDetailsBarChart.data = chartData;
  }

  private getBarChartData(
    data: any[],
    action: (item: any, groupKey: string) => any[],
    level: number,
    groupKey: string,
    growerNumber = ''
  ): any[] {
    const chartData = [];
    data.forEach((item) => {
      if ((level === Level.Orchard && item.level === Level.Orchard) ||
        (level === Level.Ma && item.level === Level.Ma && item.grower_number === growerNumber)
      ) {
        chartData.push(...action(item, groupKey));
      }
    });
    return chartData;
  }

  private getInventoryDetailsBarChartDataItems(item: any, groupKey: string) {
    return [
      this.getInventoryDetailsBarChartDataItem(item, groupKey, 'Fruit Loss', 'fruit_loss_grower_liability'),
      this.getInventoryDetailsBarChartDataItem(item, groupKey, 'Shipped', 'shipped'),
      this.getInventoryDetailsBarChartDataItem(item, groupKey, 'Ordered', 'on_order'),
      this.getInventoryDetailsBarChartDataItem(item, groupKey, 'In Store', 'in_store')
    ];
  }

  private getInventoryDetailsBarChartDataItem(item: any, groupKey: string, component: string, key: string) {
    let value;
    let percent;

    if (key === 'shipped') {
      // Remove fruit loss from shipped values
      value = item.total_shipped - item.total_fruit_loss_grower_liability;
      percent = item.percentage_shipped - item.percentage_fruit_loss_grower_liability;
    } else {
      value = item['total_' + key];
      percent = item['percentage_' + key];
    }

    return {
      season: item.season,
      group: item[groupKey],
      component: component,
      value: value,
      percent: percent
    };
  }

  private setInventoryStatusChart(selectedGridRow) {
    // Expected data format: [{ size: 16, group: 'GA1', component: 'Shipped', value: 90, percent: 6.4 }, ...]
    if (selectedGridRow.level === Level.Ma) {
      this.setInventoryStatusBarChartData(Level.Ma, 'maturity_area_name', selectedGridRow.grower_number);
    } else if (selectedGridRow.level === Level.Orchard) {
      this.setInventoryStatusBarChartData(Level.Orchard, 'grower_number');
    }
  }

  private setInventoryStatusBarChartData(level: number, groupKey: string, growerNumber = '') {
    const action = this.getInventoryStatusBarChartDataItems.bind(this);
    this.inventoryStatusBarChart.data = this.getBarChartData(this.data, action, level, groupKey, growerNumber);
  }

  private getInventoryStatusBarChartDataItems(item: any, groupKey: string) {
    return [
      ...this.getInventoryStatusBarChartDataSizeDataItems(item.trays_by_size.fruit_loss_grower_liability, item, groupKey, 'Fruit Loss'),
      ...this.getInventoryStatusBarChartDataSizeDataItems(item.trays_by_size.shipped, item, groupKey, 'Shipped'),
      ...this.getInventoryStatusBarChartDataSizeDataItems(item.trays_by_size.on_order, item, groupKey, 'Ordered'),
      ...this.getInventoryStatusBarChartDataSizeDataItems(item.trays_by_size.in_store, item, groupKey, 'In Store')
    ];
  }

  private getInventoryStatusBarChartDataSizeDataItems(traysBySize, item: any, groupKey: string, component: string) {
    return Object.keys(traysBySize || {}).map((size: string) => {
      const value = traysBySize[size];
      const percent = 100 * value / item.total_packed;
      return {
        size: size,
        group: item[groupKey],
        component: component,
        value: value,
        percent: percent
      };
    });
  }

  private setShippedChart(selectedGridRow): void {
    // Expected data format: [{ label: 'GA1', cssClass: 'item-1', values: [{ x: '0', y: 0 },...] }, ...]
    if (selectedGridRow.level === Level.Ma) {
      this.setShippedChartData(Level.Ma, selectedGridRow.grower_number);
    } else if (selectedGridRow.level === Level.Orchard) {
      this.setShippedChartData(Level.Orchard);
    }
  }

  private setShippedChartData(level: number, growerNumber = ''): void {
    const lookup = this.getChartLookup(
      level,
      this.shippedWeekRange.from,
      this.shippedWeekRange.to,
      growerNumber,
      'percentage_shipped_trays_by_week_'
    );
    const result = [];
    let index = 0;
    for (const key of lookup.keys()) {
      result.push({ label: key, cssClass: 'item-' + index, values: lookup.get(key).values });
      index++;
    }
    this.shippedLineChartData = result;
  }

  private getChartLookup(level: number, from: number, to: number, growerNumber: string, valueKeyPrefix: string): Map<string, any> {
    const lookup = new Map();
    for (let week = from; week <= to; week++) {
      this.data.forEach((item) => {
        if (level === Level.Orchard && item.level === Level.Orchard) {
          this.addDataToChartLookup(lookup, item, week, 'grower_number', valueKeyPrefix);
        } else if (level === Level.Ma && item.level === Level.Ma && item.grower_number === growerNumber) {
          this.addDataToChartLookup(lookup, item, week, 'maturity_area_name', valueKeyPrefix);
        }
      });
    }
    return lookup;
  }

  // Note: Change will be required to the line chart component.
  // Undefined values are set to 0 because the line chart component does not handle undefined values correctly.
  // This is not the desired behaviour. The line in the chart should stop if no data is undefined for the specific point.
  private addDataToChartLookup(lookup: Map<string, any>, item: Object, week: number, keyName: string, valueKeyPrefix: string) {
    const key = item[keyName];
    if (lookup.has(key)) {
      lookup.get(key).values.push({ x: week, y: item[valueKeyPrefix + week] || 0 });
    } else {
      lookup.set(key, { values: [{ x: week, y: item[valueKeyPrefix + week] || 0 }] });
    }
  }

  private setGrowerCompensationChart(selectedGridRow, from: number, to: number) {
    // Line chart only includes compensation curves
    const lineChartData = this.setGrowerCompensationLineChartData(Level.Ma, from - 1, to + 1);
    const barChartData = this.setGrowerCompensationBarChartData(selectedGridRow.level, from - 1, to + 1, selectedGridRow.grower_number);
    this.growerCompensationChartData = {
      line: lineChartData,
      bar: {
        groups: this.weeklyBarChartGroups,
        data: barChartData
      }
    };
  }

  private setGrowerCompensationLineChartData(level: number, from: number, to: number): LineChartDataItem[] {
    // Expected data format:
    // [{ label: 'GA1', cssClass: 'item-1', onlyTooltip?: boolean, tooltipPosition?: string, values: [{ x: '0', y: 0 },...] }, ...]
    const zccValues = [];
    const zccForSeason = this.zccData.find(obj => obj.season === this.selectedSeason.value);
    const zccWeeks = (zccForSeason?.compensation_curves || []).find(obj => {
      return obj.grow_method_id === this.selectedTab.growMethodId &&
        obj.variety_id === this.selectedTab.varietyId;
    });

    for (let week = from; week <= to; week++) {
      const item = (zccWeeks?.weeks || []).find(zccWeek => zccWeek.week === week);
      if (item) {
        zccValues.push({ x: week, y: roundTo(item.value, this.growerCompensationChartPrecision), isHidden: false });
      } else {
        zccValues.push({ x: week, y: null, isHidden: true });
      }
    }

    return [{
      label: 'ZCC',
      cssClass: 'zcc',
      values: zccValues,
      tooltipPosition: 'footer'
    }];
  }

  private setGrowerCompensationBarChartData(level: number, from: number, to: number, growerNumber = ''): BarChartDataItem[] {
    // Expected data format: [{ week: 0, group: 'GA1', value: 1, z: 111 }, ...]
    const groupKey = level === Level.Ma ? 'maturity_area_name' : 'grower_number';
    const barChartData = [];
    this.data.forEach((item) => {
      if ((level === Level.Orchard && item.level === Level.Orchard) ||
        (level === Level.Ma && item.level === Level.Ma && item.grower_number === growerNumber)
      ) {
        barChartData.push(...this.getGrowerCompensationBarChartDataItems(item, groupKey, from, to));
      }
    });
    return barChartData;
  }

  private getGrowerCompensationBarChartDataItems(item: any, groupKey: string, from: number, to: number): BarChartDataItem[] {
    const chartData = [];
    for (let week = from; week <= to; week++) {
      const fruitLoss = item['fruit_loss_grower_liability_trays_by_week_' + week] || 0;
      // Shipped and ordered already includes FL trays so no need to add it again
      const totalTrays = item['shipped_and_ordered_trays_by_week_' + week] || 0;
      const value = totalTrays ? 100 * fruitLoss / totalTrays : 0; // % fruit loss
      chartData.push({
        week: week,
        group: item[groupKey],
        value: roundTo(value, this.growerCompensationChartPrecision),
        z: roundTo(totalTrays, this.growerCompensationChartPrecision)
      });
    }
    return chartData;
  }
}
