import { Component, Input } from '@angular/core';
import { ResourceBadge, ResourceMetaItem } from 'app/resources/resource.interfaces';

@Component({
  selector: 'grower-portal-resource-tile',
  templateUrl: './resource-tile.component.html',
  styleUrls: ['./resource-tile.component.scss']
})
export class ResourceTileComponent {
  @Input() label: string;
  @Input() icon: string;
  @Input() badge?: ResourceBadge;
  @Input() meta?: ResourceMetaItem[];
}
