<div class="breadcrumbs-panel">
  <app-breadcrumbs></app-breadcrumbs>
  <app-help-button class="help-button" [helpKey]="helpKey"></app-help-button>
</div>
<div class="col-group">
  <div class="col-left">
    <div class="input-panel">
      <label for="orchard-select">Orchard</label>
      <kendo-dropdownlist id="orchard-select"
                          [data]="orchardOptions"
                          textField="grower_number"
                          valueField="variety_grow_methods"
                          [value]="selectedOrchard"
                          (valueChange)="onOrchardSelect($event)">
        <ng-template kendoDropDownListValueTemplate let-dataItem>
          <div class="item-text"
               [class.reduced-width]="orchardOptions.length > 1">
            {{ dataItem?.grower_number | appendIfExists:' - ' }}{{ dataItem?.orchard_name }}
          </div>
          <div *ngIf="orchardOptions.length > 1"
               class="additional-items">
            {{ orchardOptions.length - 1 }} more
          </div>
        </ng-template>
        <ng-template kendoDropDownListItemTemplate let-dataItem>
            {{ dataItem.grower_number }} - {{ dataItem.orchard_name }}
        </ng-template>
        <ng-template kendoDropDownListNoDataTemplate>
          <h5>
            Please ensure you have supplied your full bloom dates to your Trevelyan's grower representative.
          </h5>
        </ng-template>
      </kendo-dropdownlist>
      <div class="sub-text">
        Select orchard here.<br>
        If your orchard is missing then please ensure you have supplied your full bloom dates to your grower rep.
      </div>
    </div>
    <div class="input-panel">
      <label for="variety-select">Variety</label>
      <kendo-dropdownlist id="variety-select"
                          [disabled]="isVarietyDisabled"
                          [data]="varietyOptions"
                          textField="variety_name"
                          valueField="variety_id"
                          [value]="selectedVariety"
                          (valueChange)="onVarietySelect($event)">
        <ng-template kendoDropDownListValueTemplate let-dataItem>
          <div class="item-text">{{ dataItem?.variety_name }}</div>
          <div *ngIf="varietyOptions.length > 1" class="additional-items">{{ varietyOptions.length - 1 }} more</div>
        </ng-template>
      </kendo-dropdownlist>
      <div class="sub-text">Choose your variety.</div>
    </div>
    <div class="input-panel">
      <label for="grow-method-select">Grow Method</label>
      <kendo-dropdownlist id="grow-method-select"
                          [disabled]="isGrowMethodDisabled"
                          [data]="growMethodOptions"
                          textField="grow_method_name"
                          valueField="grow_method_name"
                          [value]="selectedGrowMethod"
                          (valueChange)="onGrowMethodSelect($event)">
        <ng-template kendoDropDownListValueTemplate let-dataItem>
          <div class="item-text">{{ dataItem?.grow_method_name }}</div>
          <div *ngIf="growMethodOptions.length > 1" class="additional-items">{{ growMethodOptions.length - 1 }} more</div>
        </ng-template>
      </kendo-dropdownlist>
      <div class="sub-text">Choose your growing method.</div>
    </div>
    <div class="input-panel">
      <label for="thinning-date">Fruit Thinning Date</label>
      <kendo-datepicker id="thinning-date"
                        [format]="dateFormat"
                        [weekNumber]="true"
                        [min]="minimumThinningDate"
                        [max]="maximumThinningDate"
                        (valueChange)="calculateGrowthSubject.next()"
                        [(value)]="selectedThinningDate">
      </kendo-datepicker>
      <div class="sub-text">Select the proposed thinning date (defaults to today or 65 days from full bloom).</div>
    </div>
    <div class="input-panel">
      <label for="thinning-date">Harvest Date</label>
      <kendo-datepicker id="harvest-date"
                        clearDatePickerButton
                        [format]="dateFormat"
                        [weekNumber]="true"
                        [min]="minimumPickDate"
                        [max]="maximumPickDate"
                        placeholder="Estimated harvest date..."
                        (valueChange)="calculateGrowthSubject.next()"
                        [(value)]="selectedPickDate">
      </kendo-datepicker>
      <div class="sub-text">Choose an estimate harvest date or leave blank for full term.</div>
    </div>
  </div>
  <div class="col-right">
    <table>
      <tr>
        <th>Full Bloom Date</th>
        <td>{{ fullBloomDate | date: dateFormat }}</td>
      </tr>
      <tr>
        <th>Days from Full Bloom</th>
        <td>{{ daysFromFullBloom }}</td>
      </tr>
      <tr>
        <th>Multiplier</th>
        <td>{{ multiplier | number:'1.2-2' }}</td>
      </tr>
    </table>
    <div style="margin-bottom: 20px;"></div>
    <table>
      <tr>
        <th colspan="2" class="center">Minimum thinning weights by size</th>
      </tr>
      <ng-container *ngFor="let size of sizesToPrint">
        <tr>
          <th [class.nss]="isSizeNss(selectedVariety?.variety_code, size)">{{ size }} Count</th>
          <td>{{ minimumFruitWeights[size.toString()] | number:'1.0-0' | appendIfExists:' grams' }}</td>
        </tr>
      </ng-container>
    </table>
  </div>
  <div class="col-help" [innerHTML]="calculatorDescription?.content"></div>
</div>

<loading-indicator [class.visible]="isLoading"
                   [radius]="30"
                   [thickness]="4"
                   message=""
                   [isLight]="true"
                   externalMessage="Loading orchard data..."
                   [isAbsolute]="true">
</loading-indicator>
