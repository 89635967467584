<div class="content">
  <header class="header">
    <div class="column index">
      <div class="breadcrumbs-panel">
        <app-breadcrumbs></app-breadcrumbs>
        <app-help-button class="help-button" helpKey="inventory-details"></app-help-button>
      </div>
      <dropdown *ngIf="data?.length"
                class="header-item"
                [items]="availableSeasons"
                [current]="selectedSeason"
                handle="inventoryDetailsSeasonDropdown"
                [highlightSelected]="true"
                [maxHeight]="maxDropdownHeight"
                (currentChange)="selectSeason($event)">
        <button class="button transparent tiny rounded bordered">
          Season: {{ selectedSeason.label }}
          <i class="fas fa-chevron-right right"></i>
        </button>
      </dropdown>
      <dropdown *ngIf="data?.length && availableWeeks?.length"
                class="header-item"
                [items]="availableWeeks"
                [current]="selectedWeek"
                handle="inventoryDetailsWeekDropdown"
                [highlightSelected]="true"
                [maxHeight]="maxDropdownHeight"
                (currentChange)="selectWeek($event)">
        <button class="button transparent tiny rounded bordered">
          Week: {{ selectedWeek.label }}
          <i class="fas fa-chevron-right right"></i>
        </button>
      </dropdown>
    </div>
    <div *ngIf="tabs?.length" class="column data-category two-line-tabs">
      <div class="download-report-button-group">
        <button class="button transparent tiny rounded bordered"
                (click)="openInventoryReport()">
          <i class="fas fa-download"></i>
          Inventory Report
        </button>
      </div>
      <div class="data-category-content">
        <app-simple-tabs *ngIf="dataCategoryTabs?.length"
                         cssClass="data-category-tabstrip two-line-tabs"
                         [tabs]="dataCategoryTabs"
                         [selected]="selectedDataCategoryTab"
                         [onSelect]="onDataCategoryTabSelect.bind(this)">
        </app-simple-tabs>
      </div>
    </div>
  </header>
  <kendo-tabstrip *ngIf="tabs?.length"
                  class="data-grid tabstrip two-line-tabs"
                  (tabSelect)="onTabSelect($event)">
    <kendo-tabstrip-tab *ngFor="let tab of tabs"
                        [title]="tab.title"
                        [selected]="tab.title === selectedTab.title">
        <ng-template kendoTabContent>
          <app-simple-grid #mainGrid [columns]="gridColumns"
                           [hiddenColumns]="hiddenColumns"
                           [data]="data"
                           [hasColumnSelect]="gridHasColumnSelection"
                           [alternatingColumnBg]="false"
                           rowSelectType="single"
                           [showRowSelect]="false"
                           [rowSelectCallback]="onGridRowSelect.bind(this)"
                           [emptyPlaceholder]="emptyPlaceholder"
                           [selectedRowIndexes]="selectedGridRowIndexes"
                           [toggleRowCallback]="onGridRowToggle.bind(this)"
                           [columnSelectCallback]="onGridColumnSelect.bind(this)">
          </app-simple-grid>
        </ng-template>
    </kendo-tabstrip-tab>
  </kendo-tabstrip>

  <ng-template #pageSubTitle>
    <div class="page-sub-title">
      <span *ngIf="selectedRow?.grower_number" class="group"><span class="label">KPIN:</span><span class="value">{{ selectedRow.grower_number }}</span></span>
      <span *ngIf="selectedRow?.variety" class="group"><span class="label">Variety:</span><span class="value">{{ selectedRow.variety }}</span></span>
      <span *ngIf="selectedRow?.grow_method" class="group"><span class="label">Grow Method:</span><span class="value">{{ selectedRow.grow_method }}</span></span>
      <span *ngIf="selectedSeason?.label" class="group"><span class="label">Season:</span><span class="value">{{ selectedSeason.label }}</span></span>
      <span *ngIf="selectedWeek?.label" class="group"><span class="label">Week:</span><span class="value">{{ selectedWeek.label }}</span></span>
    </div>
  </ng-template>

  <section *ngIf="data?.length" class="detail" [class.pages-visible]="!isUiTransitionInProgress">
    <div class="column index-chart-column">
      <button class="fs-button button transparent tiny fas fa-expand-arrows-alt" (click)="enterFullScreen('Inventory Summary')"></button>
      <div class="page" [class.full-screen]="fullScreenPage === 'Inventory Summary'">
        <button class="fs-button button transparent fas fa-times" (click)="exitFullScreen()"></button>
        <h2 class="page-title">
          Inventory Summary
          <ng-template *ngTemplateOutlet="pageSubTitle"></ng-template>
        </h2>
        <div class="chart-frame">
          <app-grouped-bar-chart class="chart flex-grow one-line-legend-and-group-select"
                                 [groups]="inventoryDetailsBarChart.groups"
                                 [data]="inventoryDetailsBarChart.data"
                                 [yAxis]="inventoryDetailsBarChart.yAxis"
                                 [minValue]="0"
                                 type="stacked"
                                 [precision]="inventoryDetailsBarChart.precision"
                                 legendLocation="bottom"
                                 [showPlotValueSelector]="true"
                                 [plotValues]="inventoryDetailsBarChart.plotValues"
                                 [showGroupTotalForPercent100]="false"
                                 noDataSubMessage=""></app-grouped-bar-chart>
        </div>
      </div>
    </div>
    <div [class]="'column data-category ' + selectedDataCategoryTab.cssClass">
      <button class="fs-button button transparent tiny fas fa-expand-arrows-alt" (click)="enterFullScreen()"></button>
      <div class="pages">
        <div class="page inventory-status" [class.full-screen]="fullScreenPage === 'Inventory Status'">
          <button class="fs-button button transparent fas fa-times" (click)="exitFullScreen()"></button>
          <h2 class="page-title">
            {{ inventoryDropdown?.current?.label || '' }} Trays By Size
            <ng-template *ngTemplateOutlet="pageSubTitle"></ng-template>
          </h2>
          <div class="chart-frame">
            <app-grouped-bar-chart class="chart flex-grow one-line-legend-and-group-select"
                                   [groups]="inventoryStatusBarChart.groups"
                                   [data]="inventoryStatusBarChart.data"
                                   [yAxis]="inventoryStatusBarChart.yAxis"
                                   [minValue]="0"
                                   type="stacked"
                                   [precision]="inventoryStatusBarChart.precision"
                                   legendLocation="bottom"
                                   [showPlotValueSelector]="true"
                                   [showTypeSelector]="true"
                                   [plotValues]="inventoryStatusBarChart.plotValues"
                                   [forceUpdate]="forceChartUpdate"
                                   noDataSubMessage=""></app-grouped-bar-chart>
          </div>
        </div>
        <div class="page shipped" [class.full-screen]="fullScreenPage === 'Shipped'">
          <button class="fs-button button transparent fas fa-times" (click)="exitFullScreen()"></button>
          <h2 class="page-title">
            Cumulative % Trays Shipped by ISO Week
            <ng-template *ngTemplateOutlet="pageSubTitle"></ng-template>
          </h2>
          <div class="chart-frame">
            <app-line-chart class="chart flex-grow"
                            [data]="shippedLineChartData"
                            legendLocation="bottom"
                            [showAverage]="false"
                            [showXGrid]="true"
                            [minYValue]="0"
                            [hasTooltip]="true"
                            [isYValueAPercentage]="true"
                            [tooltipConfig]="tooltipConfigShipped"
                            [tooltipX]="tooltipX"
                            [transitionDuration]="chartTransitionDuration"
                            [margin]="{ top: 10, right: 10, bottom: 30, left: 35 }"
                            [axisSettings]="shippedChart.axis"
                            [forceUpdate]="forceChartUpdate"></app-line-chart>
          </div>
        </div>
        <div *ngIf="hasScoutingCheck" class="page scouting-check" [class.full-screen]="fullScreenPage === 'Scouting Check'">
          <button class="fs-button button transparent fas fa-times" (click)="exitFullScreen()"></button>
          <h2 class="page-title">
            Scouting Check
            <ng-template *ngTemplateOutlet="pageSubTitle"></ng-template>
          </h2>
          <div class="chart-frame">
            <app-grouped-bar-chart class="chart flex-grow"
                                   [groups]="scoutingCheckBarChart.groups"
                                   [data]="scoutingCheckBarChart.data"
                                   [yAxis]="scoutingCheckBarChart.yAxis"
                                   [minValue]="0"
                                   type="side-by-side"
                                   [precision]="scoutingCheckBarChart.precision"
                                   [showLegend]="false"
                                   [showGroupSelector]="false"
                                   [noGroupMargin]="true"
                                   [colorMasterGroups]="true"
                                   [margin]="{ top: 0, right: 0, bottom: 50, left: 0 }"
                                   [forceUpdate]="forceChartUpdate"
                                   noDataSubMessage=""></app-grouped-bar-chart>
          </div>
        </div>
        <div class="page grower-compensation" [class.full-screen]="fullScreenPage === 'Grower Compensation'">
          <button class="fs-button button transparent fas fa-times" (click)="exitFullScreen()"></button>
          <h2 class="page-title">
            Trays Lost, Trays Shipped + Trays Ordered + Trays Lost by ISO Week
            <ng-template *ngTemplateOutlet="pageSubTitle"></ng-template>
          </h2>
          <div class="chart-frame">
            <app-multi-chart class="chart multi-chart flex-grow"
                             [data]="growerCompensationChartData"
                             legendLocation="bottom"
                             [showAverage]="false"
                             [showXGrid]="true"
                             [minYValue]="0"
                             [tickPadding]="{ x: 15, y: 10 }"
                             [hasTooltip]="true"
                             [isExtendedDomain]="true"
                             [isYValueAPercentage]="true"
                             [tooltipConfig]="tooltipConfigGrowerCompensation"
                             [tooltipX]="tooltipX"
                             [smallestMaxY]="100"
                             [transitionDuration]="chartTransitionDuration"
                             [margin]="{ top: 5, right: 10, bottom: 30, left: 35 }"
                             [axisSettings]="growerCompensationChart.axis"
                             [forceUpdate]="forceChartUpdate"></app-multi-chart>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>

<div *ngIf="data?.length && hasSidebar"
     [class]="'sidebar ' + selectedDataCategoryTab.cssClass">
  <div class="sidebar-handle" (click)="toggleSidebar()"></div>
  <div class="sidebar-content">
    <div class="pages">
      <div class="page inventory-status">
        <h4 class="sidebar-header">
          Inventory Status
        </h4>
        <div class="panel explanation-panel">
          <p>Content coming soon.</p>
        </div>
      </div>
      <div class="page shipped">
        <h4 class="sidebar-header">
          Shipped
        </h4>
        <div class="panel explanation-panel">
          <p>Content coming soon.</p>
        </div>
      </div>
      <div *ngIf="hasScoutingCheck" class="page scouting-check">
        <h4 class="sidebar-header">
          Scouting Check Adjusted Defects
        </h4>
        <div class="sidebar-sub-heading"
             [class.hidden-transparent]="!scoutingCheckSidebarSelectedItem">
          Week {{ scoutingCheckSidebarSelectedItem?.week }}
        </div>
        <div class="panel scouting-check-sidebar-chart">
          <app-grouped-bar-chart class="flex-grow"
                                 [groups]="scoutingCheckSidebarChartGroups"
                                 [data]="scoutingCheckSidebarChartData"
                                 [minValue]="0"
                                 type="side-by-side"
                                 [noGroupMargin]="true"
                                 [margin]="{ top: 30, right: 14, bottom: 20, left: 32 }"
                                 [precision]="1"
                                 [showLegend]="false"
                                 noDataSubMessage="Please select a row below"></app-grouped-bar-chart>
        </div>
        <div class="panel flex-column">
          <div class="flex-column scroll-grid rejects-grid">
            <header class="header">
              <table class="grid">
                <thead>
                  <tr>
                    <th class="left">ISO Week</th>
                    <th class="right col-fixed-width"><div class="indicator item-0"></div>Min</th>
                    <th class="right col-fixed-width"><div class="indicator item-1"></div>Maj</th>
                    <th class="right col-fixed-width"><div class="indicator item-2"></div>Other Rot</th>
                    <th class="right col-fixed-width"><div class="indicator item-3"></div>Over Ripe</th>
                    <th class="right col-fixed-width"><div class="indicator item-4"></div>P. Mark</th>
                    <th class="right col-fixed-width"><div class="indicator item-5"></div>Rot</th>
                    <th class="right col-fixed-width"><div class="indicator item-6"></div>SBD</th>
                    <th class="right col-fixed-width"><div class="indicator item-7"></div>Soft</th>
                  </tr>
                </thead>
              </table>
            </header>
            <div class="scroll-panel">
              <div class="scroll-panel-content">
                <table class="grid">
                  <tbody>
                    <tr *ngFor="let item of scoutingCheckSidebarData"
                        [class.selected]="item.selected"
                        (click)="updateScoutingCheckSidebarChart(item)">
                      <td class="left">{{ item.week }}</td>
                      <td class="right col-fixed-width">{{ item.minors }}</td>
                      <td class="right col-fixed-width">{{ item.majors }}</td>
                      <td class="right col-fixed-width">{{ item.over_rots }}</td>
                      <td class="right col-fixed-width">{{ item.over_ripes }}</td>
                      <td class="right col-fixed-width">{{ item.p_marks }}</td>
                      <td class="right col-fixed-width">{{ item.rots }}</td>
                      <td class="right col-fixed-width">{{ item.sbd }}</td>
                      <td class="right col-fixed-width">{{ item.softs }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="page grower-compensation">
        <h4 class="sidebar-header">
          Grower Compensation
        </h4>
        <div class="panel explanation-panel">
          <p>Content coming soon.</p>
        </div>
      </div>
    </div>
    <div class="controls">
      <button *ngIf="selectedRow"
              class="button fill report-button"
              (click)="openInventoryReport()">
        Download Inventory Report
      </button>
    </div>
  </div>
</div>

<loading-indicator [class.visible]="isLoading"
                   [radius]="30"
                   [thickness]="4"
                   message=""
                   [isLight]="true"
                   [externalMessage]="mainLoadingMessage"
                   [isAbsolute]="true">
</loading-indicator>

<div class="no-data-message large" [class.visible]="!isLoading && !availableSeasons?.length">
  <i class="fas fa-exclamation-triangle"></i>
  <div class="message">Inventory data not found.</div>
  <div class="sub-message">No available seasons with inventory data found.</div>
</div>

<div *ngIf="availableSeasons && selectedSeason" class="no-data-message large" [class.visible]="!isLoading && !data?.length">
  <i class="fas fa-exclamation-triangle"></i>
  <div class="message">Inventory data not found.</div>
  <div class="sub-message">Try selecting a different season?</div>
  <div class="controls">
    <dropdown class="large"
              [items]="availableSeasons"
              [current]="selectedSeason"
              handle="ogrDetailsNoDataSeasonDropdown"
              [highlightSelected]="true"
              (currentChange)="selectSeason($event)">
      <button class="button transparent rounded bordered">
        Season: {{ selectedSeason.label }}
        <i class="fas fa-chevron-right right"></i>
      </button>
    </dropdown>
  </div>
</div>
