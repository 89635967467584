import { Component, HostBinding, Input } from '@angular/core';

export type MessageOverlayType = 'ok' | 'info' | 'warning' | 'error' | 'loading' | 'message';

@Component({
  selector: 'grower-portal-message-overlay',
  templateUrl: './message-overlay.component.html',
  styleUrls: ['./message-overlay.component.scss']
})
export class MessageOverlayComponent {
  @Input() @HostBinding('class.with-transition') withTransition = true;
  @Input() @HostBinding('class.visible') visible = false;
  @HostBinding('class') private _type: MessageOverlayType = 'info';
  @Input()
  get type() {
    return this._type;
  }
  set type(value: MessageOverlayType) {
    this._type = value;
    this.icon = this.getIcon(this._type);
  }
  @Input() message: string;
  icon = 'fa-circle-info';

  private getIcon(type: MessageOverlayType): string {
    switch (type) {
      case 'error': return 'fa-exclamation-triangle';
      case 'warning': return 'fa-circle-exclamation';
      case 'ok': return 'fa-circle-check';
      case 'info': return 'fa-circle-info';
      case 'message': return 'fa-circle-info';
      case 'loading': return 'fa-circle-notch fa-spin';
      default: return '';
    }
  }
}
