import { Injectable } from '@angular/core';

const DEFAULT_END_DELAY = 1000; // ms

@Injectable()
export class ResizeService {
  private onStartHandlers: { type: string, callback: Function }[] = [];
  private onEndHandlers: { type: string, callback: Function }[] = [];
  public resizeInProgress: boolean;
  private timeoutHandler;
  private windowWidth: number;
  private windowHeight: number;
  endDelay = DEFAULT_END_DELAY; // End handlers are executed after this many ms

  constructor() {
    this.saveWindowDimensions();
    window.addEventListener('resize', (event) => {
      if (!this.resizeInProgress) {
        this.handleStart();
      }
      this.start();
    });
  }

  setEndDelay(endDelay: number) {
    this.endDelay = endDelay;
    this.start();
  }

  resetEndDelay() {
    this.endDelay = DEFAULT_END_DELAY;
  }

  addOnStartHandler(callback: Function, type: 'both'|'width'|'height' = 'both') {
    this.onStartHandlers.push({ type: type, callback: callback });
  }

  addOnEndHandler(callback: Function, type: 'both'|'width'|'height' = 'both') {
    this.onEndHandlers.push({ type: type, callback: callback });
  }

  private start() {
    clearTimeout(this.timeoutHandler);
    this.timeoutHandler = setTimeout(this.handleEnd.bind(this), this.endDelay);
  }

  private isWindowWidthChanged() {
    return this.windowWidth !== window.innerWidth;
  }

  private isWindowHeightChanged() {
    return this.windowHeight !== window.innerHeight;
  }

  private saveWindowDimensions() {
    this.windowWidth = window.innerWidth;
    this.windowHeight = window.innerHeight;
  }

  private handleStart() {
    this.resizeInProgress = true;
    this.onStartHandlers.forEach((handler) =>  {
      this.executeHandlerCallback(handler, this.isWindowWidthChanged(), this.isWindowHeightChanged());
    });
  }

  private handleEnd() {
    this.onEndHandlers.forEach((handler) =>  {
      this.executeHandlerCallback(handler, this.isWindowWidthChanged(), this.isWindowHeightChanged());
    });
    this.saveWindowDimensions();
    this.resizeInProgress = false;
  }

  private executeHandlerCallback(handler, widthChanged, heightChanged) {
    if ((widthChanged && heightChanged) || (widthChanged && handler.type !== 'height') || (heightChanged && handler.type !== 'width')) {
      handler.callback();
    }
  }
}
