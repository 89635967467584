import { GrowMethodOption, Orchard, VarietyGrowMethod, VarietyOption } from 'app/interfaces/calculator.interface';
import { Help, HelpService } from 'app/shared/services/help.service';

export class CalculatorBase {
  isVarietyDisabled = true;
  isGrowMethodDisabled = true;
  isLoading = true;
  orchardOptions: Orchard[] = [];
  varietyOptions: VarietyOption[] = [];
  growMethodOptions: GrowMethodOption[] = [];
  calculatorDescription: Help;
  selectedOrchard: Orchard;
  selectedVariety: VarietyOption;
  selectedGrowMethod: GrowMethodOption;
  helpKey: string;

  constructor(
    private helpService: HelpService,
  ) {}

  onOrchardSelect(orchard: Orchard) {
    this.selectedOrchard = orchard;
    this.varietyOptions = [];
    this.growMethodOptions = [];
    this.varietyOptions = this.getUniqueVarietyOptions(orchard.variety_grow_methods);
    this.growMethodOptions = this.getUniqueGrowMethodOptions(orchard.variety_grow_methods);
    this.selectedGrowMethod = this.growMethodOptions[0];
    this.onVarietySelect(this.varietyOptions[0]);
    this.isVarietyDisabled = false;
    this.isGrowMethodDisabled = false;
  }

  onVarietySelect(variety: VarietyOption) {
    this.selectedVariety = variety;
  }

  onGrowMethodSelect(growMethod: GrowMethodOption) {
    this.selectedGrowMethod = growMethod;
  }

  protected getUniqueVarietyOptions(varietyGrowMethods: VarietyGrowMethod[]): VarietyOption[] {
    const uniqueVarieties = varietyGrowMethods.reduce((result, item) => {
      result[item.variety_id] = item;
      return result;
    }, {});
    return Object.keys(uniqueVarieties).map(key => {
      const variety = uniqueVarieties[key];
      return {
        variety_id: variety.variety_id,
        variety_name: variety.variety_name,
        variety_code: variety.variety_code
      };
    });
  }

  protected getUniqueGrowMethodOptions(varietyGrowMethods: VarietyGrowMethod[]): GrowMethodOption[] {
    const uniqueGrowMethods = varietyGrowMethods.reduce((result, item) => {
      result[item.grow_method_id] = item;
      return result;
    }, {});
    return Object.keys(uniqueGrowMethods).map(key => {
      const growMethod = uniqueGrowMethods[key];
      return {
        grow_method_id: growMethod.grow_method_id,
        grow_method_name: growMethod.grow_method_name,
      };
    });
  }

  protected async fetchCalculatorDescription() {
    this.calculatorDescription = await this.helpService.getHelpText(this.helpKey);
  }
}
