import { Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { first, skipWhile, Subscription } from 'rxjs';
import { ResourceService } from 'app/resources/resource.service';
import { Resource } from 'app/resources/resource.interfaces';
import { AlternativeMobileBehaviour } from 'app/lib/alternative-mobile-behaviour';
import { ResourceScrollService } from 'app/resources/resource-scroll.service';

@Component({
  template: ''
})
export abstract class ResourceBaseComponent extends AlternativeMobileBehaviour implements OnInit, OnDestroy {

  protected paramsSubscription: Subscription;
  @HostBinding('class.visible') isVisible = false;

  protected constructor(
    public resourceService: ResourceService,
    protected resourceScrollService: ResourceScrollService,
  ) {
    super();
  }

  ngOnInit() {
    this.resourceService.isRemoteDataLoaded.pipe(skipWhile(isDone => !isDone), first()).subscribe(isDone => {
      this.isVisible = isDone;
    });
    this.config();
    this.enableAlternateMobileBehaviour();
    this.resourceScrollService.init();
  }

  ngOnDestroy() {
    this.paramsSubscription?.unsubscribe();
    this.disableAlternateMobileBehaviour();
  }

  protected isResourceDownloading(resource: Resource): boolean {
    return resource.message?.type === 'loading';
  }

  protected config() {}
}
