import { Injectable } from '@angular/core';

@Injectable()
export class OutsideClickService {

  private callbacks = {};

  constructor() {
    document.addEventListener('click', (event) => {
      this.executeAll(event);
    });
    window.addEventListener('blur', (event) => {
      this.executeAll(event);
    });
  }

  add(handle: string, callback: ((event?) => void), scope: Object) {
    this.callbacks[handle] = callback.bind(scope);
  }

  remove(handle: string) {
    delete this.callbacks[handle];
  }

  get(handle: string) {
    return this.callbacks[handle];
  }

  getAll() {
    return this.callbacks;
  }

  executeAll(event) {
    Object.keys(this.callbacks).forEach((handle) => {
      this.callbacks[handle](event);
    });
  }

  executeAllExcept(excludedHandle: string, event: any) {
    Object.keys(this.callbacks).forEach((handle) => {
      if (excludedHandle !== handle) {
        this.callbacks[handle](event);
      }
    });
  }

  execute(handle: string, event: any) {
    const callback = this.callbacks[handle];
    if (callback) {
      callback(event);
    }
  }
}
