import { EventEmitter, Injectable } from '@angular/core';
import { AuthService } from 'app/shared/services/auth.service';
import { getLocalStorageValue, setLocalStorageValue, compare } from 'app/shared/services/utils.service';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpService } from 'app/shared/services/http.service';
import { UserOverride, UserOverrideComponent } from 'app/shared/user-override/user-override.component';

export const USER_PRODUCTS_KEY_PREFIX = 'trevelyan_user_products';
export const USER_SELECTED_PRODUCT_KEY_PREFIX = 'trevelyan_user_selected_product';
export type ProductName = 'Kiwifruit'|'Avocado';

export interface Product {
  product_id: number;
  product_name: ProductName;
}

export const DEFAULT_PRODUCT: Product = {
  product_id: 5,
  product_name: 'Kiwifruit'
};

export enum ProductNameToId {
  Kiwifruit = 5,
  Avocado = 6,
}

const AVAILABLE_PRODUCTS_ENDPOINT = 'growers/available_products/';

@Injectable()
export class UserProductsService {
  selectedProductChange = new EventEmitter();
  productsChange = new EventEmitter();
  private products: Product[];
  private selectedProduct: Product;
  private userProductsKey: string;
  private userSelectedProductKey: string;

  constructor(
    private authService: AuthService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private http: HttpService
  ) {}

  async init(userOverride: UserOverride = null, forceFetch = false, shouldSelectDefaultProduct = true) {
    this.initKeys(userOverride);
    if (forceFetch || userOverride) {
      try {
        await this.fetchAvailableProducts();
      } catch(error) {
        if (userOverride) {
          UserOverrideComponent.clearOverride();
          await this.init(null, forceFetch, shouldSelectDefaultProduct);
        } else {
          return Promise.reject(error);
        }
      }
    } else {
      this.initProducts();
    }
    this.initSelectedProduct(shouldSelectDefaultProduct);
  }

  clearState() {
    this.products = null;
    this.selectedProduct = null;
    this.userProductsKey = null;
    this.userSelectedProductKey = null;
  }

  getProducts(): Product[] {
    return this.products || this.getProductsFromLocalStorage();
  }

  hasKiwifruit(): boolean {
    return this.getProducts()?.some(product => product.product_name === 'Kiwifruit');
  }

  async fetchAvailableProducts() {
    const availableProducts = await this.http.get(AVAILABLE_PRODUCTS_ENDPOINT, {}, false).toPromise() as Product[];
    this.setProducts(availableProducts || [], true);
  }

  setProducts(products: Product[], shouldSaveToLocalStorage = false) {
    const isSame = compare(products, this.products);
    this.products = products;
    if (shouldSaveToLocalStorage && this.userProductsKey) {
      this.saveToLocalStorage(this.userProductsKey, this.products);
    }
    if (!isSame) {
      this.productsChange.next(this.products);
    }
  }

  isProductSelectionRequired(): boolean {
    return this.products?.length > 1 && !this.selectedProduct;
  }

  setSelectedProduct(product: Product) {
    const isSame = compare(product, this.selectedProduct);
    this.selectedProduct = product;
    if (this.userSelectedProductKey) {
      this.saveToLocalStorage(this.userSelectedProductKey, this.selectedProduct);
    }
    if (!isSame) {
      this.selectedProductChange.next(this.selectedProduct);
    }
  }

  setNextProduct() {
    let nextIndex = this.getSelectedProductIndex() + 1;
    if (nextIndex >= this.products.length) {
      nextIndex = 0;
    }
    if (this.products?.length > 1) {
      this.setSelectedProduct(this.products[nextIndex]);
    }
  }

  getSelectedProduct(): Product {
    return this.selectedProduct || this.getSelectedProductFromLocalStorage() || this.products?.[0] || DEFAULT_PRODUCT;
  }

  initKeys(userOverride: UserOverride = null) {
    const user = this.authService.getUser();
    if (user) {
      if (userOverride) {
        this.userProductsKey = `${ USER_PRODUCTS_KEY_PREFIX }_${ userOverride.user_id }`;
        this.userSelectedProductKey = `${ USER_SELECTED_PRODUCT_KEY_PREFIX }_${ user.id }_${ userOverride.user_id }`;
      } else {
        this.userProductsKey = `${ USER_PRODUCTS_KEY_PREFIX }_${ user.id }`;
        this.userSelectedProductKey = `${ USER_SELECTED_PRODUCT_KEY_PREFIX }_${ user.id }`;
      }
    } else {
      this.userProductsKey = null;
      this.userSelectedProductKey = null;
    }
  }

  initSelectedProduct(shouldSelectDefaultProduct = true) {
    if (this.products?.length && this.areKeysSet()) {
      this.selectedProduct = this.getSelectedProductFromLocalStorage();
      this.clearInvalidSelectedProduct();
      if (shouldSelectDefaultProduct && !this.selectedProduct) {
        this.selectedProduct = this.products[0];
      }
    } else {
      this.selectedProduct = null;
    }
    this.selectedProductChange.next(this.selectedProduct);
  }

  redirectUnsupportedProducts(): boolean {
    const permittedProducts = this.getPermittedProductsFromRoute();
    if (permittedProducts && this.selectedProduct && !permittedProducts.includes(this.selectedProduct.product_name)) {
      this.redirectToDashboard();
      return false;
    }
    return true;
  }

  private redirectToDashboard() {
    this.router.navigate(['/dashboard']);
  }

  private getPermittedProductsFromRoute(): string[] {
    let routeItem = this.activatedRoute;
    while (routeItem.firstChild) {
      routeItem = routeItem.firstChild;
    }
    return routeItem?.snapshot?.data?.products as string[];
  }

  private getSelectedProductIndex(): number {
    const selectedIndex = this.findSelectedProductIndex();
    return selectedIndex > 0 ? selectedIndex : 0;
  }

  private findSelectedProductIndex(): number {
    if (this.products && this.selectedProduct) {
      return this.products.findIndex(item => item.product_id === this.selectedProduct.product_id);
    }
    return -1;
  }

  private clearInvalidSelectedProduct() {
    if (this.userSelectedProductKey && this.findSelectedProductIndex() < 0) {
      this.selectedProduct = null;
      this.saveToLocalStorage(this.userSelectedProductKey, null);
    }
  }

  private areKeysSet() {
    return this.userProductsKey && this.userSelectedProductKey;
  }

  private initProducts() {
    if (this.areKeysSet()) {
      this.products = this.getProductsFromLocalStorage();
    } else {
      this.products = null;
    }
    this.productsChange.next(this.products);
  }

  private getProductsFromLocalStorage(): Product[] {
    return this.getFromLocalStorage(this.userProductsKey);
  }

  private getSelectedProductFromLocalStorage(): Product {
    return this.getFromLocalStorage(this.userSelectedProductKey);
  }

  private getFromLocalStorage(key: string): any {
    return JSON.parse(getLocalStorageValue(key, null));
  }

  private saveToLocalStorage(key: string, value: any) {
    setLocalStorageValue(key, JSON.stringify(value));
  }
}
