import { Component, HostBinding, Input } from '@angular/core';

export interface SimpleBarChartColumns {
  label: string;
  valueLabel: string;
}

export interface SimpleBarChartItem {
  label: string;
  value: number;
  percent?: number;
  maxValue?: number;
  cssClass?: string;
  precision?: number;
  prefix?: string;
  suffix?: string;
  errorMessage?: string;
}

@Component({
  selector: 'app-simple-bar-chart',
  templateUrl: './simple-bar-chart.component.html',
  styleUrls: ['./simple-bar-chart.component.scss']
})
export class SimpleBarChartComponent {
  @Input() @HostBinding('class.value-outside-bar') valueOutsideBar = false;
  @Input() @HostBinding('class.value-bg') valueBg = false;
  @Input() @HostBinding('class.alt-theme') altTheme = false;
  @Input() columns: SimpleBarChartColumns = { label: '', valueLabel: '' };
  @Input() precision = 0;
  @Input() placeholder = '-';
  @Input() useLocalItemMax = false;
  @Input()
  set data(data: SimpleBarChartItem[]) {
    data = data || [];
    if (!this.useLocalItemMax) {
      this.setMaxValue(data);
    }
    this.setChartData(data);
    this.setPercentages();
  }
  get data() {
    return this.chartData;
  }
  chartData: SimpleBarChartItem[] = [];
  private maxValue = 0;

  constructor() {}

  private setMaxValue(data: SimpleBarChartItem[]) {
    let maxValue = 0;
    data.forEach((item) => {
      if (item.value > maxValue) {
        maxValue = item.value;
      }
    });
    this.maxValue = maxValue;
  }

  private setPercentages() {
    this.chartData.forEach((item) => {
      const maxValue = this.useLocalItemMax ? item.maxValue : this.maxValue;
      item.percent = maxValue ? 100 * item.value / maxValue : 0;
    });
  }

  private setChartData(data: SimpleBarChartItem[]) {
    if (this.chartData?.length) {
      this.updateChartData(data);
    } else {
      this.createChartData(data);
    }
  }

  private createChartData(data: SimpleBarChartItem[]) {
    this.chartData = data;
  }

  private updateChartData(data: SimpleBarChartItem[]) {
    this.chartData.forEach((item, i) => {
      item.value = data?.[i]?.value;
    });
  }
}
