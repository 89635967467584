import { Pipe, PipeTransform } from '@angular/core';
import { DecimalPipe } from '@angular/common';

@Pipe({
  name: 'formatValue'
})
export class FormatValuePipe implements PipeTransform {
  transform(value: string|number, prefix = '', precision = 0, emptyPlaceholder = '', allowEmpty = false, isPercent = false): any {
    let formattedValue: string|number;
    const isValueNaN = Number.isNaN(value);
    const decimalPipe = new DecimalPipe('en-NZ');
    const valuePrefix = value == null || value === '' || value === '?' || isValueNaN ? '' : prefix;

    if (value == null || isValueNaN) {
      formattedValue = allowEmpty ? '' : emptyPlaceholder;
    } else if (typeof(value) !== 'number') {
      formattedValue = value;
    } else {
      if (isPercent && value >= 99.95 && value < 100.05) {
        formattedValue = 100;
      } else if (precision != null) {
        formattedValue = decimalPipe.transform(value, `1.${precision}-${precision}`);
      } else {
        formattedValue = value;
      }
    }

    return valuePrefix + formattedValue;
  }
}
