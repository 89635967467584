import { Component, ElementRef, HostBinding, Input, OnInit, ViewChild } from '@angular/core';
import { AgreementListItem, AgreementsService, ENDPOINT } from 'app/agreements/agreements.service';
import { FormGroup, Validators } from '@angular/forms';
import { HttpService } from 'app/shared/services/http.service';
import { finalize } from 'rxjs';
import { StateService } from 'app/shared/services/state.service';
import { Router } from '@angular/router';
import { NotificationService, NotificationOptions } from 'app/notification/notification.service';
import { GrowerPortalScrollService } from 'app/grower-portal-layout/grower-portal-scroll.service';
import { DialogCloseResult, DialogResult } from '@progress/kendo-angular-dialog';
import { UserOverrideComponent } from 'app/shared/user-override/user-override.component';
import { HttpErrorResponse } from '@angular/common/http';
import { snakeToTitle } from 'app/shared/services/utils.service';
import {
  ChangeableField, ChangeRequestFormGroup
} from 'app/agreements/agreement-page/agreement-form/changeable-field/changeable-field.component';
import { NumberFormatOptions } from '@progress/kendo-angular-intl';
import { SettingsService } from 'app/shared/services/settings.service';
import { GaService } from 'app/shared/services/ga.service';

export interface OrchardList {
  orchard_id: number;
  association_id: number;
  included: boolean;
  grower_number: string;
  orchard_name: string;
  orchard_address: string;
  biogro_number: string;
  biogro_expiry_date: Date;
  variety_grow_methods: string[];
  avocado_exporter: string;
  avocado_pest_monitor_center: string;
  food_safety_declaration?: any[];
  change_requests?: any[]
}

export interface BaseAgreementData {
  name: string;
  uuid: string;
  entity_name: string;
  authorized_signer_id: number;
  authorized_signer_user_id: number;
  authorized_signer_name: string;
  email_address: string;
  signature_value: string;
  countersigner_signature_image: string;
  countersigner_signature_description: string;
}

export interface BaseCommitmentFormData extends BaseAgreementData {
  postal_address_line_1: ChangeableField<string>;
  postal_address_line_2: ChangeableField<string>;
  postal_address_city: ChangeableField<string>;
  postal_address_country: ChangeableField<string>;
  postal_address_postal_code: ChangeableField<string>;
  physical_address_same_as_postal: boolean;
  physical_address_line_1: ChangeableField<string>;
  physical_address_line_2: ChangeableField<string>;
  physical_address_city: ChangeableField<string>;
  physical_address_country: ChangeableField<string>;
  physical_address_postal_code: ChangeableField<string>;
  phone_number_mobile: ChangeableField<string>;
  entity_bank_account_number: ChangeableField<string>;
  entity_gst_number: ChangeableField<string>;
  orchard_list: OrchardList[];
}

export const LOADING_FORM_MESSAGE = 'Loading form';

@Component({
  template: ''
})
export abstract class AgreementFormComponent<T> implements OnInit {
  @Input() agreement: AgreementListItem;
  @Input() cancelCallback: () => any;
  abstract changeRequestsForm: ChangeRequestFormGroup;
  abstract form: FormGroup;
  abstract endpoint: string;
  abstract data: T;
  abstract successMessage: string;
  signSuffix: 'Below' | 'Here' = 'Below';
  @HostBinding('class.view-only') isViewOnly = true;
  @HostBinding('class.hidden') isFormHidden = true;
  @HostBinding('class.disabled') isDisabled = false;
  @ViewChild('signatureRow', { static: true }) signatureRow: ElementRef;
  private scrollContainer: HTMLElement;
  dateFormat = SettingsService.KENDO_SHORT_EXPANDED_DATE_FORMAT;
  isFormInvalid = true;

  protected postcodeFormatOptions: NumberFormatOptions = {
    useGrouping: false,
    minimumFractionDigits: 0,
  };

  protected postalCodeValidators = [
    Validators.min(1000),
    Validators.max(9999)
  ];

  protected mobileNumberValidators = [
    Validators.pattern(/^02\d{7,9}$/)
  ];

  private successMessageOptions: NotificationOptions = {
    title: 'Thank You',
    type: 'success',
    theme: 'light',
    icon: 'fas fa-handshake',
    duration: 5000
  };

  private errorMessageOptions: NotificationOptions = {
    title: 'Error',
    type: 'error',
    theme: 'light',
    icon: 'fas fa-exclamation-triangle'
  };

  protected constructor(
    private http: HttpService,
    private router: Router,
    private stateService: StateService,
    private notificationService: NotificationService,
    private growerPortalScrollService: GrowerPortalScrollService,
    private agreementsService: AgreementsService,
    protected ga: GaService
  ) {}

  ngOnInit() {
    this.initScrollContainer();
    if (this.agreement) {
      this.getFormData();
    }
    this.growerPortalScrollService.addCallback('agreement-form', this.setSignSuffix.bind(this));

    this.form.statusChanges.subscribe(newStatus => {
      this.isFormInvalid = newStatus !== 'VALID';
    });
  }

  getFormData() {
    this.stateService.setLoading(true, LOADING_FORM_MESSAGE);
    this.http.get(this.getUrl())
      .pipe(finalize(this.onLoad.bind(this)))
      .subscribe((response: T) => {
        this.data = response;
        this.handleFormData();
        this.isDisabled = !!UserOverrideComponent.getOverride();
        this.isViewOnly = !this.data['is_user_signer'];

        setTimeout(() => {
          this.setSignSuffix();
        });
      });
  }

  cancel() {
    if (typeof(this.cancelCallback) !== 'function') {
      return;
    }

    if (this.isViewOnly || this.isDisabled) {
      this.ga.event(
        'button',
        'click',
        `agreement form: ${ this.agreement.name } cancel`,
        this.agreement.entity_name
      );
      this.cancelCallback();
      return;
    }

    this.agreementsService.showConfirmDialog(this.showConfirmDialogCallback.bind(this));
  }

  saveForm() {
    this.form.markAllAsTouched();
    if (!this.form.valid) {
      return;
    }

    this.ga.event(
      'button',
      'click',
      `agreement form: ${ this.agreement.name } save`,
      this.agreement.entity_name
    );

    const formData = this.form.getRawValue();
    this.stateService.loading = true;
    this.http.put(this.getUrl(), formData, {}, false, false)
      .pipe(finalize(() => this.stateService.loading = false))
      .subscribe({
        next: () => {
          this.notificationService.showNotification( this.successMessage, this.successMessageOptions);
          this.returnToLists();
        },
        error: (error: HttpErrorResponse) => {
          const errorMessage = this.getErrorMessage(error);
          this.notificationService.showNotification( errorMessage, this.errorMessageOptions);
        }
      });
  }

  private showConfirmDialogCallback(result: DialogResult) {
    if (!(result instanceof DialogCloseResult) && result['text'] === 'Yes') {
      this.ga.event(
        'button',
        'click',
        `agreement form: ${ this.agreement.name } cancel`,
        this.agreement.entity_name
      );
      this.cancelCallback();
    }
  }

  protected handleFormData() {
    this.form.patchValue(this.data);
  }

  private getErrorMessage(error: HttpErrorResponse): string {
    const errors = Object.keys(error.error).map(field => {
      return `<strong>${ snakeToTitle(field) }</strong>: ${ error.error[field] }`;
    });
    return `Save failed!<br>${ errors.join('<br>') }`;
  }

  private initScrollContainer() {
    this.scrollContainer = this.signatureRow.nativeElement.closest('.main');
  }

  private setSignSuffix(_: MouseEvent = null) {
    const scrollContainerHeight = this.scrollContainer?.['offsetHeight'] || 0;
    const signatureRowY = this.signatureRow.nativeElement.getBoundingClientRect().top;
    this.signSuffix = signatureRowY < scrollContainerHeight ? 'Here' : 'Below';
  }

  private getUrl(): string {
    return `${ ENDPOINT }${ this.agreement.uuid }/form/${this.endpoint}/`;
  }

  private returnToLists() {
    this.router.navigate(['/agreements']);
  }

  private onLoad() {
    this.isFormHidden = false;
    this.stateService.setLoading(false);
  }
}
