import { Component, OnInit, OnDestroy, HostBinding } from '@angular/core';
import {
  NotificationService,
  NotificationState,
  NotificationType,
  NotificationLocation,
  DEFAULT_NOTIFICATION_TYPE,
  DEFAULT_NOTIFICATION_THEME,
  DEFAULT_NOTIFICATION_LOCATION
} from 'app/notification/notification.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent implements OnInit, OnDestroy {

  type: NotificationType;
  title: string;
  icon: string;
  message: string;
  showCloseButton = false;
  @HostBinding('class') location: NotificationLocation;
  @HostBinding('class.dark') isDark = true;
  @HostBinding('class.light') isLight = false;
  @HostBinding('class.success') isSuccess = false;
  @HostBinding('class.error') isError = false;
  @HostBinding('class.visible') isVisible = false;
  private notificationChangeSubscription: Subscription;
  private timeoutHandle: any;

  constructor(
    private notificationService: NotificationService
  ) {}

  ngOnInit(): void {
    this.notificationChangeSubscription = this.notificationService.onChange.subscribe((state: NotificationState) => {
      this.configure(state);
    });
  }

  ngOnDestroy() {
    this.notificationChangeSubscription?.unsubscribe();
  }

  hide() {
    this.clearTimeout();
    this.isVisible = false;
  }

  private clearTimeout() {
    if (this.timeoutHandle) {
      clearTimeout(this.timeoutHandle);
    }
  }

  private configure(state: NotificationState) {
    const options = state.options || {};
    this.location = options.location || DEFAULT_NOTIFICATION_LOCATION;
    this.icon = options.icon || this.getDefaultIcon(options.type || DEFAULT_NOTIFICATION_TYPE);
    this.title = options.title || null;
    this.message = state.message || '';
    this.isError = options.type === 'error';
    this.isSuccess = options.type === 'success';
    this.isVisible = state.visible;

    const theme = options.theme || DEFAULT_NOTIFICATION_THEME;
    this.isDark = theme === 'dark';
    this.isLight = theme === 'light';
    this.showCloseButton = !options.duration;

    this.clearTimeout();

    if (options.duration) {
      this.timeoutHandle = setTimeout(() => {
        this.isVisible = false;
      }, options.duration);
    }
  }

  private getDefaultIcon(type: NotificationType) {
    switch (type) {
      case 'error': return 'fas fa-exclamation-triangle';
      case 'success': return 'fas fa-check-circle';
      default: return 'fas fa-bullhorn';
    }
  }
}
