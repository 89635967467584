import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EnvIndicatorService {
  constructor() {}

  setIndicator() {
    const envIndicatorText = this.getEnvIndicatorText();
    if (envIndicatorText && !document.getElementsByClassName('env-indicator').length) {
      const indicatorElement = document.createElement('div');
      indicatorElement.classList.add('env-indicator');
      indicatorElement.setAttribute('env-indicator-text', envIndicatorText);
      document.body.appendChild(indicatorElement);
    }
  }

  private getEnvIndicatorText() {
    return environment['ENV_INDICATOR_TEXT'];
  }
}
