import { Component, ElementRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ResourceService } from 'app/resources/resource.service';
import { Resource } from 'app/resources/resource.interfaces';
import { ContainerRefDirective } from 'app/directives/container-ref.directive';
import { ResourceBaseComponent } from 'app/resources/resource-base/resource-base.component';
import { first } from 'rxjs';
import { ResourceScrollService } from 'app/resources/resource-scroll.service';

const RESOURCE_MESSAGE_FLASH_DURATION = 2000;

@Component({
  selector: 'grower-portal-resource-group-page',
  templateUrl: './resource-group-page.component.html',
  styleUrls: ['./resource-group-page.component.scss']
})
export class ResourceGroupPageComponent extends ResourceBaseComponent {
  @ViewChild(ContainerRefDirective, { static: true }) resourceComponentTarget!: ContainerRefDirective;
  @ViewChild('content', { static: true }) content: ElementRef;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public resourceService: ResourceService,
    protected resourceScrollService: ResourceScrollService,
  ) {
    super(resourceService, resourceScrollService);
  }

  ngOnInit() {
    super.ngOnInit();
    this.resourceScrollService.groupPageScrollElement = this.content.nativeElement;
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  onResourceClick(resource: Resource) {
    if (this.isResourceDownloading(resource)) {
      return;
    }

    if (resource.item) {
      this.resourceLoading(resource);
      this.resourceService.downloadResource(resource).pipe(first()).subscribe({
        next: () => {
          this.resourceDownloadSuccess(resource);
        },
        error: () => {
          this.resourceDownloadError(resource);
        }
      });
    } else if (resource.component) {
      return this.router.navigate([resource.id], {
        relativeTo: this.activatedRoute
      });
    }
  }

  private resourceLoading(resource: Resource) {
    resource.message = { type: 'loading', text: 'Download in progress' };
  }

  private resourceDownloadSuccess(resource: Resource) {
    this.flashResourceSuccess(resource);
  }

  private resourceDownloadError(resource: Resource) {
    this.flashResourceError(resource);
  }

  private flashResourceSuccess(resource: Resource) {
    resource.message = { type: 'ok', text: 'Download successful' };
    setTimeout(() => {
      resource.message = null;
    }, RESOURCE_MESSAGE_FLASH_DURATION);
  }

  private flashResourceError(resource: Resource) {
    resource.message = { type: 'error', text: 'Download failed' };
    setTimeout(() => {
      resource.message = null;
    }, RESOURCE_MESSAGE_FLASH_DURATION);
  }

  protected config() {
    this.paramsSubscription = this.activatedRoute.params.subscribe(params => {
      this.resourceService.setCurrent(params.category);
      if (this.resourceService.currentResourceGroup.component) {
        this.renderComponent();
      } else {
        this.clearComponentTarget();
      }
      this.configureBreadcrumbs();
    });
  }

  private clearComponentTarget() {
    this.resourceComponentTarget.viewContainerRef.clear();
  }

  private configureBreadcrumbs() {
    this.activatedRoute.snapshot.routeConfig.data.title = this.resourceService.currentResourceGroup.group_name;
  }

  private renderComponent() {
    const viewContainerRef = this.resourceComponentTarget.viewContainerRef;
    const componentClass = this.resourceService.currentResourceGroup.component.componentClass;
    const componentParams = this.resourceService.currentResourceGroup.component.params;
    viewContainerRef.clear();
    const componentRef = viewContainerRef.createComponent<any>(componentClass);
    (componentParams || []).forEach(param => {
      componentRef.instance[param.key] = param.value;
    });
  }
}
