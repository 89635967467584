import { of as observableOf, Observable, throwError } from 'rxjs';
import { Injectable, NgZone } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthService } from './auth.service';
import { environment } from 'environments/environment';
import { DialogRef, DialogService, DialogSettings } from '@progress/kendo-angular-dialog';
import { catchError, finalize, share } from 'rxjs/operators';
import { Router } from '@angular/router';
import { UserOverrideComponent } from '../user-override/user-override.component';
import { GaService } from 'app/shared/services/ga.service';

const HANDLED_ERROR_CODES = [401, 503];

export interface PaginatedResponse {
  count: number;
  results: any[];
}

@Injectable()
export class HttpService {
  private openDialog: DialogRef;

  constructor(
    private authService: AuthService,
    private dialogService: DialogService,
    private http: HttpClient,
    private router: Router,
    private ngZone: NgZone,
    private ga: GaService
  ) {
    this.router = router;
  }

  head<T = Object>(url: string, options = {}, catchErrors = true, reThrowErrors = false, ignoreCodes: number[] = [],
       closeMessageOnSuccess = false): Observable<Object> {
    return this.sendRequest<T>('head', url, null, options, catchErrors, reThrowErrors, ignoreCodes, closeMessageOnSuccess);
  }

  get<T = Object>(url: string, options = {}, catchErrors = true, reThrowErrors = false, ignoreCodes: number[] = [],
      closeMessageOnSuccess = false): Observable<T> {
    return this.sendRequest<T>('get', url, null, options, catchErrors, reThrowErrors, ignoreCodes, closeMessageOnSuccess);
  }

  delete<T = Object>(url: string, options = {}, catchErrors = true, reThrowErrors = false, ignoreCodes: number[] = [],
         closeMessageOnSuccess = false): Observable<T> {
    return this.sendRequest<T>('delete', url, null, options, catchErrors, reThrowErrors, ignoreCodes, closeMessageOnSuccess);
  }

  post<T = Object>(url: string, data: {}, options = {}, catchErrors = true, reThrowErrors = false, ignoreCodes: number[] = [],
       closeMessageOnSuccess = false): Observable<T> {
    return this.sendRequest<T>('post', url, data, options, catchErrors, reThrowErrors, ignoreCodes, closeMessageOnSuccess);
  }

  put<T = Object>(url: string, data: {}, options = {}, catchErrors = true, reThrowErrors = false, ignoreCodes: number[] = [],
      closeMessageOnSuccess = false): Observable<T> {
    return this.sendRequest<T>('put', url, data, options, catchErrors, reThrowErrors, ignoreCodes, closeMessageOnSuccess);
  }

  patch<T = Object>(url: string, data: {}, options = {}, catchErrors = true, reThrowErrors = false, ignoreCodes: number[] = [],
        closeMessageOnSuccess = false): Observable<T> {
    return this.sendRequest<T>('patch', url, data, options, catchErrors, reThrowErrors, ignoreCodes, closeMessageOnSuccess);
  }

  private getApiBaseUrl(): string {
    return environment.API_BASE_URL;
  }

  private sendRequest<T>(type: string, url: string, data: {} = null, options = {},
                      catchErrors = true, reThrowErrors = false, ignoreCodes: number[] = [],
                      closeMessageOnSuccess = false): Observable<T> {
    let request: Observable<any>;
    const _url = options['noPrefix'] ? url : this.getApiBaseUrl() + url;
    const reqOptions = this.getRequestOptions(options);
    let isError: boolean;
    const requestStartDate = Date.now();
    if (data) {
      request = this.http[type](_url, data, reqOptions);
    } else {
      request = this.http[type](_url, reqOptions);
    }

    if (catchErrors) {
      return request.pipe(
        catchError(err => {
          if (!(ignoreCodes.concat(HANDLED_ERROR_CODES)).includes(err.status)) {
            let message = 'An unknown error has occurred, please try again or contact an administrator.';
            if (err.status === 403) {
              message = 'You do not have permission to perform this action.';
            }
            if (err.error?.['human_error']) {
              message = err.error.human_error;

              // Impersonation failed so logout the user
              if (/impersonate/.test(err.error.human_error)) {
                this.authService.clear();
                this.ngZone.run(() => {
                  this.router.navigateByUrl('/login');
                });
              }
            }
            if (this.openDialog) {
              this.openDialog.close();
            }
            this.openDialog = this.dialogService.open(<DialogSettings>{
              title: 'Error',
              content: message,
              actions: [{ text: 'OK' }],
              width: 400
            });
          }

          isError = err.status;

          if (reThrowErrors) {
            return throwError(err);
          } else {
            return observableOf([]);
          }
        }),
        finalize(() => {
          if (closeMessageOnSuccess && !isError && this.openDialog) {
            this.openDialog.close();
          }
          this.ga.timingEvent(_url, Date.now() - requestStartDate, `HTTP ${ type } request`);
        }),
        share()
      );
    }

    return request.pipe(
      finalize(() => {
        this.ga.timingEvent(_url, Date.now() - requestStartDate, `HTTP ${ type } request`);
      }),
      share()
    );
  }

  private getRequestOptions(options: any) {
    const requestOptions: any = {};
    requestOptions.params = options.params;
    if (options.isUnauthenticated !== true) {
      let headers = this.getAuthHeaders();
      headers = this.addUserOverrideHeaders(headers);
      headers = this.addExtraHeaders(options, headers);
      requestOptions.headers = headers;
    }
    if (options.responseType) {
      requestOptions.responseType = options.responseType;
    }
    if (options.observe) {
      requestOptions.observe = options.observe;
    }
    return requestOptions;
  }

  private addExtraHeaders(options, headers: HttpHeaders) {
    return Object.keys(options.headers || {}).reduce((result, key) => {
      return result.set(key, options.headers[key]);
    }, headers);
  }

  private getAuthHeaders(): HttpHeaders {
    let headers = new HttpHeaders();
    if (this.authService.getToken()) {
      headers = headers.set('Authorization', 'Bearer ' + this.authService.getToken());
    }
    return headers;
  }

  private addUserOverrideHeaders(headers: HttpHeaders): HttpHeaders {
    const override = UserOverrideComponent.getOverride();
    if (override) {
      headers = headers.set('User-Override-Id', String(override.user_id));
    }
    return headers;
  }
}
