<div class="logo"></div>
<div class="overlay"></div>

<div class="content" [class.busy]="isBusy">
  <div *ngIf="!isResetPasswordFormVisible" class="login-form">
    <h2>Login</h2>
    <div class="message error-message" [class.visible]="loginErrorMessage">
      <i class="fas fa-exclamation-triangle"></i>
      {{ loginErrorMessage }}
    </div>
    <div class="login-content">
      <div *ngIf="!showMFA" class="input-panel">
        <p class="top-message">
          Enter your <strong>{{ loginParamName }}</strong> and <strong>password</strong>:
        </p>
        <div class="input-row username-input-row" [class.error]="isUsernameError">
          <input #usernameInputField
                 type="text"
                 autofocus
                 autocorrect="off"
                 autocapitalize="none"
                 class="username"
                 autocomplete="username"
                 [placeholder]="'Your ' + loginParamName"
                 [(ngModel)]="username"
                 (keyup)="keyUpUsername($event)">
          <i class="icon fas fa-user"></i>
          <i class="clear fas fa-times-circle" [class.visible]="isUsernameClearVisible" (click)="clearUsername()"></i>
        </div>
        <div class="input-row" [class.error]="isPasswordError">
          <input type="password"
                 class="password"
                 placeholder="Password"
                 autocomplete="current-password"
                 [(ngModel)]="password"
                 (keyup)="keyUpPassword($event)">
          <i class="icon fas fa-lock"></i>
          <i class="clear fas fa-times-circle" [class.visible]="isPasswordClearVisible" (click)="clearPassword()"></i>
        </div>
      </div>
      <div *ngIf="showMFA" class="input-panel">
        <p class="top-message">
          A verification code has been sent to
          <strong *ngIf="maskedEmail; else defaultEmailMessage">{{ maskedEmail }}</strong>
          <ng-template #defaultEmailMessage>
            your email address.
          </ng-template>
        </p>
        <p>
          Please enter it here:
        </p>
        <div class="input-row" [class.error]="isPasswordError">
          <input #mfaTokenInputField
                 type="text"
                 inputmode="numeric"
                 pattern="[0-9]*"
                 maxlength="6"
                 autocomplete="one-time-code"
                 placeholder="Verification Code"
                 [(ngModel)]="mfaToken"
                 (keyup)="keyUpMFA($event)">
          <i class="icon fas fa-shield-halved"></i>
          <i class="clear fas fa-times-circle" [class.visible]="isMFAClearVisible" (click)="clearMFA()"></i>
        </div>
      </div>
      <button class="button login" (click)="showMFA ? loginMFA() : login()">
        {{ showMFA ? 'Confirm Verification Code' : 'Login' }}
      </button>
      <a *ngIf="showMFA" href="#" class="resend-mfa" (click)="resendMFA()">Resend Verification Code</a>
    </div>
    <footer *ngIf="!showMFA">
      <a href="#reset-password" class="button" (click)="showResetPasswordForm()">Reset your password</a>
    </footer>
  </div>

  <div *ngIf="isResetPasswordFormVisible" class="reset-password-form">
    <div class="done-message-panel" [class.visible]="isResetPasswordDone">
      <i class="large-icon fas fa-check"></i>
      <div class="panel-content">
        <p>
          We have sent you instructions with more information on how to reset your password.
        </p>
        <p>
          <i class="fas fa-exclamation-triangle error"></i>
          If you don't receive an email from us in the next few minutes,
          please contact <strong>{{ loginHelpName }}</strong> for further assistance.
        </p>
      </div>
      <button #backToLoginPageButton class="button ok" (click)="showLoginForm()">
        <i class="fas fa-chevron-left"></i>
        Return to the Login Form
      </button>
    </div>

    <div class="error-message-panel" [class.visible]="isResetPasswordError">
      <i class="large-icon fas fa-exclamation-triangle"></i>
      <div class="panel-content">
        Sorry, but your password <strong>could not</strong> be reset at this time.
      </div>
      <button #backToResetPasswordPageButton class="button" (click)="showResetPasswordForm()">
        <i class="fas fa-chevron-left"></i>
        Click here to try again
      </button>
    </div>

    <h2>Reset Password</h2>
    <div class="login-content">
      <div class="input-panel">
        <p class="top-message">
          Enter your <strong>{{ loginParamName }}</strong> and click <strong>Reset Password</strong> to get further instructions:
        </p>
        <div class="input-row username-input-row" [class.error]="isPasswordResetUsernameError">
          <input #resetPasswordUsernameInputField type="text" class="username" [placeholder]="'Your ' + loginParamName" [(ngModel)]="passwordResetUsername" (keyup)="keyUpPasswordResetUsername($event)">
          <i class="icon fas fa-user"></i>
          <i class="clear fas fa-times-circle" [class.visible]="isPasswordResetUsernameClearVisible" (click)="clearPasswordResetUsername()"></i>
        </div>
      </div>
      <button class="button reset" (click)="resetPassword()">
        Reset Password
      </button>
    </div>
    <footer>
      <a href="#reset-password" class="button" (click)="showLoginForm()">
        <i class="fas fa-chevron-left"></i>
        Return to the Login Form
      </a>
    </footer>
  </div>
  <grow-product-selector *ngIf="isGrowerPortal" [class.visible]="isProductSelectorVisible"
                         [onProductSelect]="navigateNext.bind(this)"></grow-product-selector>
</div>
