import { Component, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'main-search',
  templateUrl: './main-search.component.html',
  styleUrls: ['./main-search.component.scss']
})
export class MainSearchComponent {
  @Output() onFocus = new EventEmitter<boolean>();

  constructor() {}

  showGlobalSearch() {
    // Trigger "/" keydown which is bound to show the global search component.
    document.dispatchEvent(new KeyboardEvent('keydown', { key: '/' }));
    this.onFocus.emit(true);
  }
}
