import { Component, ElementRef, Input, NgZone, ViewChild } from '@angular/core';
import { ProductName, ResourceBadge, ResourceMessage, ResourceMetaItem } from 'app/resources/resource.interfaces';

@Component({
  selector: 'grower-portal-resource-image-tile',
  templateUrl: './resource-image-tile.component.html',
  styleUrls: ['./resource-image-tile.component.scss']
})
export class ResourceImageTileComponent {
  @Input() label: string;
  @Input() placeholder: string;
  @Input() icon: string;
  @Input() text: string;
  @Input() product: ProductName;
  @Input() badge?: ResourceBadge;
  @Input() meta?: ResourceMetaItem[];
  @Input() image: string;
  @Input() message: ResourceMessage;

  @ViewChild('tileHeader', { static: true }) tileHeaderEl: ElementRef;
  constructor(private ngZone: NgZone) {}
}
