import { Component, HostBinding, Input, OnDestroy, OnInit } from '@angular/core';
import { forkJoin, Subject, Subscription } from 'rxjs';
import { debounceTime, finalize, map } from 'rxjs/operators';
import { parseDate } from 'app/shared/services/utils.service';
import { HttpService } from 'app/shared/services/http.service';
import { SettingsService } from 'app/shared/services/settings.service';
import { HelpService } from 'app/shared/services/help.service';
import { Orchard, VarietyOption, GrowMethodOption, VarietyGrowMethod } from 'app/interfaces/calculator.interface';
import { CalculatorBase } from 'app/calculators/calculator-base';
import { FormulaVariables, WeightBand, MinimumFruitWeights, ResponseGroup } from 'app/interfaces/thinning-calculator.interface';
import { TutorialService } from 'app/shared/services/tutorial.service';

const MS_PER_DAY = 1000 * 60 * 60 * 24;
const MINIMUM_DAYS_FROM_FULL_BLOOM = 65;
const FORMULA_VARIABLES_ENDPOINT = 'growers/kiwifruit/calculators/fruit_growth/formula/';
const ORCHARDS_LIST_ENDPOINT = 'growers/kiwifruit/calculators/fruit_growth/orchards/';

@Component({
  selector: 'thinning-calculator',
  templateUrl: './thinning-calculator.component.html',
  styleUrls: ['./thinning-calculator.component.scss']
})
export class ThinningCalculatorComponent extends CalculatorBase implements OnInit, OnDestroy {
  @Input() @HostBinding('class.embedded') embedded = false;
  varietyFormulas: FormulaVariables[];
  weightBands: WeightBand[];
  minimumFruitWeights: MinimumFruitWeights = {};
  readonly sizesToPrint = [42, 39, 36, 33, 30, 27, 25];
  readonly helpKey = 'thinning-calculator';
  selectedThinningDate: Date;
  minimumThinningDate: Date;
  maximumThinningDate: Date; // End of March each year
  minimumPickDate: Date; // First of March each year
  maximumPickDate: Date; // End of June each year
  selectedPickDate: Date;
  dateFormat = SettingsService.KENDO_SHORT_EXPANDED_DATE_FORMAT;
  fullBloomDate: Date;
  daysFromFullBloom: number; // fullBloomDate - selectedThinningDate
  multiplier: number;

  calculateGrowthSubject = new Subject<void>();
  private calculateGrowthSubscription: Subscription;
  private fetchDataSubscription: Subscription;

  constructor(
    private _helpService: HelpService,
    private http: HttpService,
    private tutorialService: TutorialService
  ) {
    super(_helpService);
  }

  ngOnInit() {
    this.tutorialService.hideTutorialFooterButton();
    this.initDates();
    this.fetchCalculatorDescription();
    this.fetchData();
    this.calculateGrowthSubscription = this.calculateGrowthSubject.pipe(
      debounceTime(100)
    ).subscribe(this.calculateGrowth.bind(this));
  }

  ngOnDestroy() {
    this.calculateGrowthSubscription?.unsubscribe();
    this.fetchDataSubscription?.unsubscribe();
  }

  onOrchardSelect(orchard: Orchard) {
    this.clearPickDate();
    super.onOrchardSelect(orchard);
    this.calculateGrowthSubject.next();
  }

  onVarietySelect(variety: VarietyOption) {
    this.clearPickDate();
    super.onVarietySelect(variety);
    this.calculateGrowthSubject.next();
  }

  onGrowMethodSelect(growMethod: GrowMethodOption) {
    this.clearPickDate();
    super.onGrowMethodSelect(growMethod);
    this.calculateGrowthSubject.next();
  }

  isSizeNss(varietyCode: string, size: number) {
    return varietyCode === 'GA' && size >= 39;
  }

  private initDates() {
    const today = new Date();
    const currentYear = today.getFullYear();
    this.selectedThinningDate = today;
    this.maximumThinningDate = new Date(currentYear, 2, 31); // End of March each year
    this.minimumPickDate = new Date(currentYear, 2, 1); // First of March each year
    this.maximumPickDate = new Date(currentYear, 5, 30); // End of June each year
  }

  private fetchData() {
    this.isLoading = true;

    this.fetchDataSubscription = forkJoin({
      varietyFormulas: this.http.get(FORMULA_VARIABLES_ENDPOINT),
      orchardOptions: this.http.get(ORCHARDS_LIST_ENDPOINT)
    }).pipe(
      map((data: ResponseGroup) => {
        for (const orchard of data.orchardOptions) {
          for (const varietyGrowMethod of orchard.variety_grow_methods) {
            varietyGrowMethod.earliest_full_bloom_date = parseDate(varietyGrowMethod.earliest_full_bloom_date as any);
          }
        }
        return data;
      }),
      finalize(() => {
        this.isLoading = false;
      })
    ).subscribe((data: ResponseGroup) => {
      this.varietyFormulas = data.varietyFormulas.formula_variables;
      this.weightBands = data.varietyFormulas.weight_bands;

      this.orchardOptions = data.orchardOptions;
      if (this.orchardOptions.length > 0) {
        this.onOrchardSelect(this.orchardOptions[0]);
      }
    });
  }

  private calculateGrowth() {
    let varietyGrowMethod: VarietyGrowMethod;
    let formula: FormulaVariables;

    this.multiplier = null;
    this.daysFromFullBloom = null;
    this.minimumFruitWeights = {};

    // Find the variety/grow method object on the selected orchard
    if (this.selectedOrchard != null) {
      varietyGrowMethod = this.selectedOrchard['variety_grow_methods'].find((_varietyGrowMethod) => {
        return _varietyGrowMethod.variety_id === this.selectedVariety.variety_id &&
          _varietyGrowMethod.grow_method_id === this.selectedGrowMethod.grow_method_id;
      });
    }

    if (!varietyGrowMethod) {
      return;
    }

    this.fullBloomDate = varietyGrowMethod.earliest_full_bloom_date;
    if (!this.fullBloomDate) {
      return;
    }

    this.minimumThinningDate = this.addDaysToDate(this.fullBloomDate, MINIMUM_DAYS_FROM_FULL_BLOOM);
    if (!this.selectedThinningDate || this.selectedThinningDate < this.minimumThinningDate) {
      this.selectedThinningDate = this.minimumThinningDate;
    } else if (this.selectedThinningDate > this.maximumThinningDate) {
      this.selectedThinningDate = this.maximumThinningDate;
    }

    formula = this.varietyFormulas.find((varietyFormula) => {
      return varietyFormula.variety === varietyGrowMethod.variety_id;
    });

    this.daysFromFullBloom = this.calculateDaysFromFullBloom(this.selectedThinningDate);

    this.multiplier = this.getMultiplier(this.daysFromFullBloom, formula);

    this.minimumFruitWeights = this.calculateMinimumFruitWeights(this.multiplier, varietyGrowMethod.variety_id);

    if (this.selectedPickDate) {
      const daysFromFullBloomToPickDate = this.calculateDaysFromFullBloom(this.selectedPickDate);
      const pickDateMultiplier = this.getMultiplier(daysFromFullBloomToPickDate, formula);

      this.minimumFruitWeights = this.adjustMinimumFruitWeightsForPicking(
        pickDateMultiplier, this.minimumFruitWeights, varietyGrowMethod.variety_id
      );
    }
  }

  private addDaysToDate(date: Date, daysToAdd: number): Date {
    const dateCopy = new Date(date);
    dateCopy.setDate(date.getDate() + daysToAdd);
    return dateCopy;
  }

  private getMultiplier(daysFromFullBloom: number, formulaVariables: FormulaVariables): number {
    if (daysFromFullBloom < formulaVariables.days_to_full_maturity) {
      // multiplier = order_4 * dffb^4 + order_3 * dffb^3 + order_2 * dffb^2 + order_1 * dffb + constant
      return (formulaVariables.order_4 * Math.pow(daysFromFullBloom, 4))
             + (formulaVariables.order_3 * Math.pow(daysFromFullBloom, 3))
             + (formulaVariables.order_2 * Math.pow(daysFromFullBloom, 2))
             + (formulaVariables.order_1 * Math.pow(daysFromFullBloom, 1))
             + formulaVariables.constant;
    } else {
      return 1;
    }
  }

  private calculateDaysFromFullBloom(date: Date): number {
    return Math.floor((date.getTime() - this.fullBloomDate.getTime()) / MS_PER_DAY);
  }

  private calculateMinimumFruitWeights(multiplier: number, varietyId: number): MinimumFruitWeights {
    const fruitWeights: MinimumFruitWeights = {};
    for (const weightBand of this.weightBands) {
      if (weightBand.variety === varietyId) {
        fruitWeights[weightBand.size.toString()] = weightBand.minimum_weight / multiplier;
      }
    }
    return fruitWeights;
  }

  private adjustMinimumFruitWeightsForPicking(pickDateMultiplier: number,
                                              minimumFruitWeights: MinimumFruitWeights,
                                              varietyId: number): MinimumFruitWeights {
    const fruitWeightDifferenceToFullMaturity: MinimumFruitWeights = {};
    for (const weightBand of this.weightBands) {
      if (weightBand.variety === varietyId) {
        const predictedWeightAtTimeOfPick = (weightBand.minimum_weight / pickDateMultiplier);
        const weightDifference = weightBand.minimum_weight - predictedWeightAtTimeOfPick;
        fruitWeightDifferenceToFullMaturity[weightBand.size.toString()] = weightDifference;
      }
    }
    for (const weightBand of Object.keys(minimumFruitWeights)) {
      minimumFruitWeights[weightBand] += fruitWeightDifferenceToFullMaturity[weightBand];
    }
    return minimumFruitWeights;
  }

  private clearPickDate() {
    this.selectedPickDate = null;
  }
}
