import { Component, Input, OnDestroy } from '@angular/core';
import { Product, UserProductsService } from 'app/shared/services/user-products.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'grow-product-selector',
  templateUrl: './product-selector.component.html',
  styleUrls: ['./product-selector.component.scss']
})
export class ProductSelectorComponent implements OnDestroy {
  @Input() onProductSelect: () => any;
  products: Product[];
  private readonly productsChangeSubscription: Subscription;

  constructor(
    private userProductsService: UserProductsService
  ) {
    this.productsChangeSubscription = this.userProductsService.productsChange.subscribe((newProducts: Product[]) => {
      this.products = newProducts;
    });
  }

  ngOnDestroy() {
    this.productsChangeSubscription?.unsubscribe();
  }

  selectProduct(product: Product) {
    this.userProductsService.setSelectedProduct(product);
    if (typeof(this.onProductSelect) === 'function') {
      this.onProductSelect();
    }
  }
}
