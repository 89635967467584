<div class="chart-panel">
  <div class="chart-inner-panel">
    <svg class="chart" [attr.width]="width" [attr.height]="height">
      <g #currentChart class="chart-content current" [attr.transform]="'translate(' + width / 2 + ', ' + height / 2 + ')'"></g>
      <g #previousChart class="chart-content previous" [attr.transform]="'translate(' + width / 2 + ', ' + height / 2 + ')'"></g>
    </svg>
    <div *ngIf="hasCenterValue" [style.width]="width + 'px'" [style.height]="height + 'px'" class="center-value">
      <div class="label">
        {{ value || centerValue || 0 }}{{ valuePostfix }}
      </div>
      <div class="sub-label" [innerHTML]="subLabel"></div>
    </div>
    <div *ngIf="currentLabel"
          class="chart-label current"
          [style.top]="currentLabelPosition.top + 'px'"
          [style.left]="currentLabelPosition.left + 'px'">
      {{ currentLabel }}
      <div class="arrow"></div>
    </div>
    <div *ngIf="previousLabel"
          class="chart-label previous"
          [style.top]="previousLabelPosition.top + 'px'"
          [style.left]="previousLabelPosition.left + 'px'">
      {{ previousLabel }}
      <div class="arrow"></div>
    </div>
  </div>
</div>
<div *ngIf="showLegend" class="legend" [style.marginTop]="legendTop + 'px'">
  <div *ngFor="let legendItem of legendItems"
      class="legend-item"
      (mouseover)="onLegendItemMouseOver(legendItem)"
      (mouseout)="onLegendItemMouseOut()">
    <div [class]="'indicator ' + legendItem.cssClass"></div>
    <div class="label">
      {{ legendItem.label }}
      <span *ngIf="showValuesInLegend" class="value">
        {{ legendItem.value === '0' ? '<1' : legendItem.value }}{{ valuePostfix }}
      </span>
    </div>
  </div>
</div>
