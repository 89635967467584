<div class="alternate-toggle" #ref [class.hidden]="ref.children.length == 0" (click)="toggle($event)">
  <ng-content></ng-content>
</div>
<button *ngIf="ref.children.length == 0" type="button" class="button toggle" (click)="toggle($event)">
  <i class="fas fa-caret-down caret"></i>
  {{ currentItem?.label }}
</button>
<div class="dropdown-menu"
     [class.is-top-menu]="isTopMenu"
     [class.align-left]="alignLeft"
     [style.maxHeight]="maxHeight ? maxHeight + 'px' : 'auto'">
  <ng-template ngFor let-item [ngForOf]="items">
    <a *ngIf="!item.divider && !item.header"
       class="dropdown-item"
       [class.read-only]="item.readOnly"
       [class.selected]="highlightSelected && current?.label === item.label"
       [href]="item.href || '#' + item.value"
       (click)="selectItem(item, $event)">{{ item.label }}</a>
    <div *ngIf="item.divider" class="dropdown-divider" (click)="stopPropagation($event)"></div>
    <h6 *ngIf="item.header" class="dropdown-header" (click)="stopPropagation($event)">{{ item.label }}</h6>
  </ng-template>
</div>
