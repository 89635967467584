import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { finalize, map, take } from 'rxjs/operators';
import { HttpService } from 'app/shared/services/http.service';
import { ActivatedRoute, Params } from '@angular/router';
import { Subscription } from 'rxjs';
import { SettingsService } from 'app/shared/services/settings.service';

export interface NewsItem {
  id: number;
  author: number;
  author_name: string;
  title: string;
  content: string;
  category: number;
  category_name: string;
  start_date: Date;
  end_date: Date;
  product: number;
  target_grower: boolean;
  target_staff: boolean;
}

@Component({
  selector: 'grower-portal-news-viewer',
  templateUrl: './news-viewer.component.html',
  styleUrls: ['./news-viewer.component.scss']
})
export class NewsViewerComponent implements OnInit, OnDestroy {
  @Input() titleField;
  items: NewsItem[] = [];
  currentItem: NewsItem;
  isIndexOpen = false;
  isLoading = false;
  pageSize = 20;
  page = 1;
  dateFormat = SettingsService.KENDO_SHORT_DATE_FORMAT;

  private currentItemId: number;
  private queryParamsSubscription: Subscription;

  constructor(
    private http: HttpService,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit() {
    this.bindQueryParams();
    this.load();
  }

  ngOnDestroy() {
    this.queryParamsSubscription?.unsubscribe();
  }

  toggleIndex() {
    this.isIndexOpen = !this.isIndexOpen;
  }

  closeIndex() {
    this.isIndexOpen = false;
  }

  stopPropagation(event: Event) {
    event.stopPropagation();
  }

  private load() {
    this.showLoading();
    this.http.get('news/', { params: { target_grower: 1, pageSize: this.pageSize, page: this.page }}).pipe(
      map(response => response['results']),
      take(1),
      finalize(this.hideLoading.bind(this))
    ).subscribe({
      next: (items: NewsItem[]) => {
        this.items = items;
        this.currentItemId ||= this.items[0]?.id;
        if (this.currentItemId) {
          this.setCurrentItem();
        }
      }
    });
  }

  private showLoading() {
    this.isLoading = true;
  }

  private hideLoading() {
    this.isLoading = false;
  }

  private setCurrentItem() {
    this.currentItem = this.items.find(item => item.id === this.currentItemId);
  }

  private bindQueryParams() {
    this.queryParamsSubscription = this.activatedRoute.queryParams.subscribe(this.onQueryParamsChange.bind(this));
  }

  private onQueryParamsChange(params: Params) {
    if (params.id) {
      this.currentItemId = parseInt(params.id, 10);
      if (this.items?.length) {
        this.setCurrentItem();
        this.closeIndex();
      }
    }
  }
}
