import {
  Component,
  EventEmitter,
  ElementRef,
  HostListener,
  Input,
  OnDestroy,
  Output,
  ViewChild,
  HostBinding
} from '@angular/core';
declare let kendo: any;

const windowContainerSelector = 'window-container';
const ESCAPE_CODE = 'Escape';

interface WindowContainerButton {
  label: string;
  action: () => void;
  primary: boolean;
  keyboardCode?: string;
}

@Component({
  selector: windowContainerSelector,
  templateUrl: './window-container.component.html',
  styleUrls: ['./window-container.component.scss']
})
export class WindowContainerComponent implements OnDestroy {
  defaultButtons: WindowContainerButton[] = [
    {
      label: 'Close',
      action: () => {
        this.closeWindow();
      },
      primary: true
    }
  ];
  buttonsList: WindowContainerButton[] = [];

  @ViewChild('windowContainer', { static: false }) windowContainer: any;
  @HostBinding('class.blocking') isBlocking = false;
  isWindowVisible = false;
  isWindowMaximized = false;
  @Output() isVisibleChange = new EventEmitter<boolean>();
  @Input() isLoading = false;
  @Input() windowHeight: number = null;
  @Input() windowWidth: number = window.innerWidth * 0.9;
  @Input() minWindowHeight = 400;
  @Input() minWindowWidth = 300;
  @Input() windowTop = 100;
  @Input() resizable = true;
  @Input() draggable = true;
  @Input() blocking = false;
  @Input() htmlTitle: string;
  @Input() windowTitle: string;
  @Input() windowTitleClass: string;
  @Input() maximizeOnHeaderDoubleClick = true;
  @Input() showMaximizeButton = false;
  @Input() closeHandler = () => {};
  @Input() resizeEndHandler = () => {};
  @Input() resizeStartHandler = () => {};
  @Input() closeWindow = () => {
    setTimeout(() => {
      this.isVisible = false;
    });
  }

  @Input()
  set buttons(buttonsList: WindowContainerButton[]) {
    this.buttonsList = buttonsList || this.defaultButtons;
  }

  @Input()
  get isVisible() {
    return this.isWindowVisible;
  }
  set isVisible(visible: boolean) {
    this.isWindowVisible = visible;
    this.isVisibleChange.emit(this.isWindowVisible);
    if (this.isWindowVisible) {
      document.body.classList.add('window-container-visible');
      this.isBlocking = this.blocking;
      const pageContentHeight = document.querySelector<HTMLElement>('.page-content')?.offsetHeight;
      // If the requested window height is less than the page content height, use the page content height
      if (pageContentHeight < ((+this.minWindowHeight) + (+this.windowTop))) {
        this.minWindowHeight = pageContentHeight;
        this.windowTop = 60;  // 60px is the height of the header
      }
      setTimeout(() =>  {
        this.windowContainer?.focus();
      });
    } else {
      document.body.classList.remove('window-container-visible');
      this.isBlocking = false;
    }
  }

  @HostListener('keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    const target = event.target as Element;
    if (event.code === ESCAPE_CODE && target.classList.contains(windowContainerSelector)) {
      this.closeWindow();
    }
    this.buttonsList.forEach((button: WindowContainerButton) => {
      if (event.code === button.keyboardCode && typeof(button.action) === 'function') {
        const localName = event.target['localName'];
        if (localName !== 'textarea' || (event.ctrlKey)) {
          event.preventDefault();
          button.action();
        }
      }
    });
  }

  constructor(private elementRef: ElementRef) { }

  ngOnDestroy(): void {
    if (typeof(kendo) !== 'undefined') {
      kendo.destroy(this.elementRef.nativeElement);
    }
  }

  onHeaderDoubleClick(event: MouseEvent) {
    if (!this.maximizeOnHeaderDoubleClick) {
      event.stopImmediatePropagation();
    }
  }

  maximizeWindow() {
    this.isWindowMaximized = !this.isWindowMaximized;
  }
}
