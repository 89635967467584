<div class="breadcrumbs-panel">
  <app-breadcrumbs></app-breadcrumbs>
</div>
<section class="main">
  <nav class="side-nav">
    <div class="side-nav-inner">
      <a [routerLink]="['/resources']">
        <div class="nav-item-content">
          <i class="fas fa-chevron-left"></i>
          <div class="text-wrap">
            <div class="text">Back</div>
          </div>
        </div>
      </a>
      <ng-container *ngFor="let category of resourceService.categories">
        <div class="label">{{ category.label }}</div>
        <ng-container *ngFor="let resourceGroup of category.resourceGroups">
          <a *ngIf="resourceGroup.component || resourceGroup.items.length"
             [routerLink]="['/resources', resourceGroup.id]"
             [class.current]="resourceGroup.id === resourceService.currentResourceGroup.id">
            <div class="nav-item-content">
              <i [class]="'fas ' + resourceGroup.icon"></i>
              <div class="text-wrap">
                <div class="text">{{ resourceGroup.group_name }}</div>
              </div>
            </div>
          </a>
        </ng-container>
      </ng-container>
    </div>
  </nav>
  <section #content class="content" [class.scroll-disabled]="resourceService.currentResourceGroup.contentScrollDisabled">
    <div *ngIf="!resourceService.currentResourceGroup.component" class="index">
      <grower-portal-resource-image-tile *ngFor="let resource of resourceService.currentResourceGroup.items || []"
                                         class="resource-image-tile"
                                         [icon]="resourceService.currentResourceGroup.icon"
                                         [image]="resource.thumbnail.src"
                                         [placeholder]="resourceService.placeholderImage"
                                         [label]="resource.name"
                                         [text]="resource.description"
                                         [product]="resource.product_name"
                                         [badge]="resource.badge"
                                         [meta]="resource.meta"
                                         [message]="resource.message"
                                         (click)="onResourceClick(resource)"></grower-portal-resource-image-tile>
      <div *ngIf="!resourceService.currentResourceGroup.items.length"
           class="no-data-message visible">
        <i class="fas fa-exclamation-triangle"></i>
        <div class="message">{{ resourceService.currentResourceGroup.group_name }} Not Found</div>
      </div>
    </div>
    <ng-template containerRef></ng-template>
  </section>
</section>
