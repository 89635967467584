import { Component, forwardRef, Input } from '@angular/core';
import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  AbstractControl
} from '@angular/forms';
import { Subscription } from 'rxjs';

export interface Option {
  value: any;
  label: string;
}

export const DEFAULT_OPTIONS: Option[] = [
  { value: true, label: 'Yes' },
  { value: false, label: 'No' }
];

/*
  This component is used to select a value from a list of options.
  The default value is null, and there is no way to set it back to null once changed.
  Renders the inputted options list as a series of selectable buttons.
 */
@Component({
  selector: 'nullable-value-selector',
  templateUrl: './nullable-value-selector.component.html',
  styleUrls: ['./nullable-value-selector.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: NullableValueSelectorComponent
    }
  ]
})
export class NullableValueSelectorComponent implements ControlValueAccessor {
  @Input() options: Option[] = DEFAULT_OPTIONS;
  selectedValue: any = null;

  onTouched: Function = () => {};
  onChange: Function = () => {};

  registerOnChange(onChange: any): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: Function) {
    this.onTouched = onTouched;
  }

  writeValue(obj: any): void {
    this.selectedValue = obj;
  }

  setValue(value: any) {
    this.selectedValue = value;
    this.onChange(value);
  }
}
