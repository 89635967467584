<p *ngIf="topMessage" class="top-message">
  {{ topMessage }}
</p>
<section class="message-panel">
  <textarea #feedbackMessageTextArea class="feedback-message" [(ngModel)]="feedbackMessage" rows="4"></textarea>
</section>
<section class="survey-panel" *ngIf="showSurvey">
  <div *ngFor="let question of survey" class="survey-question">
    <div class="question">{{ question.text }}</div>
    <rank [(current)]="question.result"></rank>
  </div>
</section>
<div class="save-user-panel" *ngIf="showSaveUser">
  <checkbox [label]="'Save user information along with your feedback?'" [(isChecked)]="saveUser"></checkbox>
</div>
<section class="controls">
  <button class="button transparent clear" (click)="clear()">Clear</button>
  <button class="button submit" (click)="submit()">Submit</button>
</section>

<div class="thank-you-panel" [class.visible]="isThankYouVisible && thankYouMessage != null">
  <i class="fas fa-check"></i>
  <p>{{ thankYouMessage }}</p>
</div>

<div class="error-panel" [class.visible]="isErrorVisible">
  <i class="fas fa-exclamation-triangle"></i>
  <p>Sorry, but something went wrong and we <strong>could not</strong> save your feedback at this time.</p>
  <button #pleaseTryAgainButton class="button ok" (click)="hideError()">
    Please Try Again
  </button>
</div>
