import { Component, OnInit, ElementRef, ViewChild, HostBinding, Input, OnDestroy } from '@angular/core';
import { HttpService } from '../services/http.service';
import { Subscription } from 'rxjs';

const FEEDBACK_FLASH_TIME = 2500;

@Component({
  selector: 'feedback-form',
  templateUrl: './feedback-form.component.html',
  styleUrls: ['./feedback-form.component.scss', './feedback-form-media-queries.component.scss']
})
export class FeedbackFormComponent implements OnInit, OnDestroy {
  feedbackMessage = '';
  survey = [
    { text: 'How happy are you with the changes to the portal?', result: 3 }
  ];
  isThankYouVisible = false;
  isErrorVisible = false;
  private defaultTopMessage = 'Do you have any suggestions or changes you would like to see implemented in the portal?';
  private defaultThankYouMessage = 'Thank you, your feedback is greatly appreciated.';
  private saveUser = true;
  private resetResponseValue = 3;
  private sendFeedbackSubscription: Subscription;
  @Input() topMessage: string = null;
  @Input() thankYouMessage: string = null;
  @Input() messageType = 'feedback';
  @Input() showSurvey = true;
  @Input() showSaveUser = true;
  @Input() sendFeedbackNow = false;
  @Input() additionalData = {};
  @ViewChild('pleaseTryAgainButton', { static: true }) pleaseTryAgainButton: ElementRef;
  @ViewChild('feedbackMessageTextArea', { static: true }) feedbackMessageTextArea: ElementRef;
  @HostBinding('class.busy') isBusy = false;

  constructor(
    private http: HttpService
  ) {}

  ngOnInit() {
    if (this.topMessage == null) {
      this.topMessage = this.defaultTopMessage;
    }
    if (this.thankYouMessage == null) {
      this.thankYouMessage = this.defaultThankYouMessage;
    }
  }

  ngOnDestroy() {
    this.sendFeedbackSubscription?.unsubscribe();
  }

  clear(focusOnMessage = true) {
    this.feedbackMessage = '';
    this.survey.forEach((question) => {
      question.result = this.resetResponseValue;
    });

    if (focusOnMessage) {
      this.feedbackMessageTextArea.nativeElement.focus();
    }
  }

  submit() {
    const message = (this.feedbackMessage || '').trim();
    if (this.isValid(message, this.survey)) {
      this.lock();

      const data = {
        message: message,
        message_type: this.messageType,
        save_user: this.saveUser,
        send_feedback_now: this.sendFeedbackNow,
        additional_data: this.additionalData
      };

      if (this.showSurvey) {
        data['survey_result'] = JSON.stringify(this.survey);
      }

      this.sendFeedbackSubscription = this.http.post('feedback/', data, {}, false).subscribe(
        this.flashThankYou.bind(this),
        this.showError.bind(this)
      );
    } else {
      // The feedback was not valid
      // Thank the user anyway, but not post the result
      this.flashThankYou();
    }
  }

  // The form is valid if the user:
  // Entered a message
  // Changed the survey result
  private isValid(message: string, survey) {
    return message || (!message && this.showSurvey && survey[0].result !== this.resetResponseValue);
  }

  private lock() {
    this.isBusy = true;
  }

  private unlock() {
    this.isBusy = false;
  }

  private flashThankYou() {
    this.unlock();
    this.clear(false);
    this.isThankYouVisible = true;
    setTimeout(() => {
      this.isThankYouVisible = false;
    }, FEEDBACK_FLASH_TIME);
  }

  private showError() {
    this.unlock();
    this.isErrorVisible = true;
  }

  hideError() {
    this.feedbackMessageTextArea.nativeElement.focus();
    this.isErrorVisible = false;
  }
}
