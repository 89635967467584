import { Component, ElementRef, ViewChild } from '@angular/core';
import { ResourceService } from 'app/resources/resource.service';
import { Resource } from 'app/resources/resource.interfaces';
import { ActivatedRoute, Router } from '@angular/router';
import { ContainerRefDirective } from 'app/directives/container-ref.directive';
import { ResourceBaseComponent } from 'app/resources/resource-base/resource-base.component';
import { first } from 'rxjs';
import { ResourceScrollService } from 'app/resources/resource-scroll.service';

@Component({
  selector: 'grower-portal-resource-page',
  templateUrl: './resource-page.component.html',
  styleUrls: ['./resource-page.component.scss']
})
export class ResourcePageComponent extends ResourceBaseComponent {
  @ViewChild(ContainerRefDirective, { static: true }) resourceComponentTarget!: ContainerRefDirective;
  @ViewChild('content', { static: true }) content: ElementRef;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public resourceService: ResourceService,
    protected resourceScrollService: ResourceScrollService,
  ) {
    super(resourceService, resourceScrollService);
  }

  ngOnInit() {
    super.ngOnInit();
    this.resourceScrollService.resourcePageScrollElement = this.content.nativeElement;
  }

  onResourceClick(resource: Resource) {
    if (this.isResourceDownloading(resource)) {
      return;
    }

    if (resource.item) {
      this.resourceDownloadStart(resource);
      this.resourceService.downloadResource(resource).pipe(first()).subscribe({
        next: () => {
          this.resourceDownloadDone(resource);
        },
        error: () => {
          this.resourceDownloadDone(resource);
        }
      });
    } else if (resource.component) {
      const commands = ['/resources', this.resourceService.currentResourceGroup.id, resource.id];
      return this.router.navigate(commands);
    }
  }

  private resourceDownloadStart(resource: Resource) {
    resource.message = { type: 'loading' };
  }

  private resourceDownloadDone(resource: Resource) {
    resource.message = null;
  }

  protected config() {
    this.paramsSubscription = this.activatedRoute.params.subscribe(params => {
      this.resourceService.setCurrent(params.category, params.group);
      if (this.resourceService.currentResource.component) {
        this.renderComponent();
      }
      this.configureBreadcrumbs();
    });
  }

  private configureBreadcrumbs() {
    this.activatedRoute.snapshot.parent.routeConfig.children[0].data.title = this.resourceService.currentResourceGroup.group_name;
    this.activatedRoute.snapshot.data.title = this.resourceService.currentResource.name;
  }

  private renderComponent() {
    const viewContainerRef = this.resourceComponentTarget.viewContainerRef;
    const componentClass = this.resourceService.currentResource.component.componentClass;
    const componentParams = this.resourceService.currentResource.component.params;
    viewContainerRef.clear();
    const componentRef = viewContainerRef.createComponent<any>(componentClass);
    (componentParams || []).forEach(param => {
      componentRef.instance[param.key] = param.value;
    });
  }
}
