<div #chartLegend *ngIf="showLegend"
     class="legend"
     [class.has-y-axis]="yAxis"
     [class.visible]="data?.length && chartLegendItems?.length">
  <div *ngFor="let legendItem of chartLegendItems"
       class="legend-item"
       [class.selected]="selectedBarIndex === legendItem.index"
       (mouseover)="onLegendItemMouseOver(legendItem)"
       (mouseout)="onLegendItemMouseOut()">
    <div [class]="'indicator item-' + legendItem.index + ' ' + legendItem.cssClass"></div>
    <div class="label">{{ legendItem.label }}</div>
  </div>
  <div *ngIf="showAverageInLegend" class="legend-item">
    <div class="indicator item-average"></div>
    <div class="label">Average</div>
  </div>
  <ng-container *ngFor="let chartAverage of chartAverages">
    <div *ngIf="chartAverage.addToLegend" class="legend-item">
      <div [class]="'indicator item-average ' + chartAverage.cssClass"></div>
      <div class="label">{{ chartAverage.legendLabel || 'Average' }}</div>
    </div>
  </ng-container>
</div>
<div class="chart-outer" [class.visible]="data?.length">
  <div *ngIf="yAxis" class="axis y">
    <div class="label">
      {{ (showPlotValueSelector && selectedPlotValue) ? selectedPlotValue.label : yAxis.label }}
      <span *ngIf="yAxis.unit" class="unit" [innerHTML]="yAxis.unit"></span>
    </div>
  </div>
  <div class="chart-inner">
    <div class="chart"
         [class.stacked]="type === 'stacked'"
         [style.marginTop]="margin.top + 'px'"
         [style.marginRight]="margin.right + 'px'"
         [style.marginBottom]="margin.bottom + 'px'"
         [style.marginLeft]="margin.left + 'px'">
      <div *ngIf="showGrid" class="grid">
        <div *ngFor="let gridItem of grid"
             class="grid-item"
             [class.zero]="gridItem.isZero"
             [style.bottom]="gridItem.y + '%'">
          <div class="label">
            {{ gridItem.value }}
          </div>
        </div>
      </div>
      <div *ngFor="let chartAverage of internalChartAverages; index as i"
           [class]="'average average-' + i + ' ' + (chartAverage.cssClass || '')"
           [class.selected]="selectedInternalChartAverageIndex === i"
           [style.bottom]="chartAverage.y + '%'"
           (mouseover)="onInternalChartAverageMouseOver(i)"
           (mouseout)="onInternalChartAverageMouseOut()">
        <div class="average-label">{{ chartAverage.label }}</div>
      </div>
      <div class="groups">
        <div *ngFor="let chartGroup of chartGroups; let i = index" [class]="'master-group master-group-' + i">
          <div *ngFor="let chart of chartGroup.charts; let i = index"
               class="group"
               [style.width]="groupWidth ? groupWidth + 'px' : null"
               [style.marginLeft]="i === 0 && barLeftOffset ? barLeftOffset + 'px' : null"
               (click)="onGroupClick(chart)">
            <div *ngIf="chart.average"
                 class="average computed"
                 [style.bottom]="chart.average.y + '%'"
                 (mouseover)="onGroupChartAverageMouseOver(chart)"
                 (mouseout)="onGroupChartAverageMouseOut()">
              <div class="average-label">
                {{ groupAveragePrefix }}{{ chart.average.value | number: '1.' + (precision || 0) + '-' + (precision || 0) }}
              </div>
            </div>
            <div *ngFor="let bar of chart.bars; first as isFirst"
                 [class]="'bar item-' + bar.index + ' ' + bar.cssClass || ''"
                 [class.selected]="selectedLegendIndex === bar.index"
                 [class.show-value-on-hover]="onlyShowValueOnHover"
                 [style.top]="bar.topOffset + '%'"
                 [style.height]="bar.height + '%'"
                 (mouseover)="onBarMouseOver(bar)"
                 (mouseout)="onBarMouseOut()">
              <div *ngIf="(showValue || onlyShowValueOnHover) && !bar.exclude" class="value">
                {{ bar.value | number: '1.' + (precision || 0) + '-' + (precision || 0) }}{{ postfix }}
              </div>
              <div *ngIf="bar.exclude"
                   class="value"
                   (mouseenter)="showTooltip(bar)"
                   (mouseleave)="hideTooltip(bar)">
                <i class="fas fa-exclamation-triangle"></i>
                <app-tooltip class="bar-tooltip" direction="south" [visible]="bar.isTooltipVisible">
                  {{ bar.excludeMessage }}
                </app-tooltip>
              </div>
              <div *ngIf="isFirst && type === 'stacked' && (!selectedPlotValue?.isPercent || showGroupTotalForPercent100 || chart.total !== 100)"
                   class="group-total">
                {{ chart.total | formatValue: '': (precision || 0): '': false: selectedPlotValue?.isPercent }}{{ postfix }}
              </div>
            </div>
            <div *ngIf="!xAxis?.hideValue" class="label">{{ chart.label }}</div>
          </div>
          <div *ngIf="!xAxis?.hideValue && hasMasterGroup" class="label">{{ chartGroup.label }}</div>
        </div>
      </div>
    </div>
    <div *ngIf="xAxis" class="axis x">
      <div class="label">
        {{ xAxis.label }}
        <span *ngIf="xAxis.unit" class="unit">{{ xAxis.unit }}</span>
      </div>
    </div>
    <div #chartControls class="controls">
      <div *ngIf="showTypeSelector" class="type-switch">
        <span class="label">Plot Type:</span>
        <button class="button small rounded no-outline"
                [class.selected]="type === 'stacked'"
                (click)="switchType('stacked')">
          Stacked
        </button>
        <button class="button small rounded no-outline"
                [class.selected]="type === 'side-by-side'"
                (click)="switchType('side-by-side')">
          Side by Side
        </button>
      </div>

      <div *ngIf="showPlotValueSelector && plotValues?.length > 1" class="plot-value-switch">
        <span class="label">Plot Value:</span>
        <button *ngFor="let plotValue of plotValues"
                class="button small rounded no-outline"
                [class.selected]="plotValue === selectedPlotValue"
                (click)="switchPlotValue(plotValue)">
          {{ plotValue.label }}
        </button>
      </div>

      <div *ngIf="showGroupSelector && groups?.length > 1" class="group-switch">
        <span class="label">Group By:</span>
        <button *ngFor="let group of groups"
                class="button small rounded no-outline"
                [class.selected]="group.primary === selectedGroup.primary"
                (click)="switchGroupBy(group.primary, group.secondary, group.master, group.secondarySortOrder)">
          {{ group.label }}
        </button>
      </div>
    </div>
  </div>
</div>
<div class="no-data-message" [class.visible]="!data?.length">
  <i class="fas fa-exclamation-triangle"></i>
  <div class="message">{{ noDataMessage }}</div>
  <div class="sub-message">{{ noDataSubMessage }}</div>
</div>
