import { Injectable } from '@angular/core';

const DEFAULT_LOADING_MESSAGE = 'Loading...';

@Injectable()
export class StateService {
  layoutCssClass: string;
  data: any = {};
  loadingMessage = DEFAULT_LOADING_MESSAGE;
  private setLoadingTimeoutHandle: NodeJS.Timeout;
  private loadingTimeoutHandle: NodeJS.Timeout;

  private _loading = false;
  set loading(loading: boolean) {
    clearTimeout(this.loadingTimeoutHandle);
    this.loadingTimeoutHandle = setTimeout(() => {
      this._loading = loading;
    });
  }
  get loading(): boolean {
    return this._loading;
  }

  setLoading(state: boolean, message = DEFAULT_LOADING_MESSAGE) {
    clearTimeout(this.setLoadingTimeoutHandle);
    this.loading = state;

    // Prevents the message changing before the loading indicator is hidden.
    this.setLoadingTimeoutHandle = setTimeout(() => {
      this.loadingMessage = message;
    }, state ? 0 : 500);
  }
}
