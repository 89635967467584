<app-widget [updatedAt]="updatedAt"
            [dropdownItems]="availableSeasons"
            [selectedDropdownItem]="selectedSeason"
            [onDropdownItemChange]="selectSeason.bind(this)"
            [isLoading]="isLoading"
            [hasNoData]="!tabs?.length"
            [headerButtons]="headerButtons"
            loadingMessage="Loading production data..."
            noDataMessage="No production data available."
            dropdownHandle="avocadoProductionWidgetSeasonDropdown">
  <kendo-tabstrip *ngIf="tabs?.length" class="tabstrip"
                  (tabSelect)="onTabSelect($event)">
    <kendo-tabstrip-tab *ngFor="let tab of tabs"
                        [title]="tab.title"
                        [selected]="tab.selected">
        <ng-template kendoTabContent>
          <app-simple-grid #avocadoProductionWidgetGrid
                           [columns]="gridColumns"
                           [data]="gridDataFiltered"
                           [showRowSelect]="false"
                           [rowSelectType]="'single'"
                           [rowSelectCallback]="onGridRowSelect.bind(this)"
                           [emptyPlaceholder]="emptyPlaceholder"
                           [selectedRowIndexes]="[0]">
          </app-simple-grid>
        </ng-template>
    </kendo-tabstrip-tab>
  </kendo-tabstrip>

  <div class="details" [class.visible]="areDetailsVisible">
    <div class="chart">
      <header>
        Harvest Progress
      </header>
      <app-pie-chart hasCenterValue="true"
                     valuePostfix="%"
                     [subLabel]="pieChartSubLabel"
                     [maxValue]="100"
                     [width]="pieChartSize"
                     [height]="pieChartSize"
                     [value]="tippedPercent">
      </app-pie-chart>
    </div>

    <div class="comparison-panel">
      <header>
        How do I compare?
      </header>
      <table>
        <thead>
          <tr>
            <th rowspan="2" class="row-label"></th>
            <th colspan="2" class="center alt">Class 1</th>
            <th colspan="2" class="center">Class 2</th>
          </tr>
          <tr>
            <th class="alt">Trays Per Bin</th>
            <th class="alt">Average Size</th>
            <th>Trays Per Bin</th>
            <th>Average Size</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th class="row-label">
              Your Value
            </th>
            <td class="alt"
                [class.not-available]="!selectedRow?.class_1_trays_per_bin"
                [class.better-than-average]="selectedRow?.class_1_trays_per_bin > trevelyansAverageData?.class_1_trays_per_bin"
                [class.worse-than-average]="selectedRow?.class_1_trays_per_bin < trevelyansAverageData?.class_1_trays_per_bin">
              {{ selectedRow?.class_1_trays_per_bin|formatNumber:1:emptyPlaceholder }}
            </td>
            <td class="alt"
                [class.not-available]="!selectedRow?.class_1_average_size"
                [class.better-than-average]="selectedRow?.class_1_average_size < trevelyansAverageData?.class_1_average_size"
                [class.worse-than-average]="selectedRow?.class_1_average_size > trevelyansAverageData?.class_1_average_size">
              {{ selectedRow?.class_1_average_size|formatNumber:1:emptyPlaceholder }}
            </td>
            <td [class.not-available]="!selectedRow?.class_2_trays_per_bin"
                [class.better-than-average]="selectedRow?.class_2_trays_per_bin > trevelyansAverageData?.class_2_trays_per_bin"
                [class.worse-than-average]="selectedRow?.class_2_trays_per_bin < trevelyansAverageData?.class_2_trays_per_bin">
              {{ selectedRow?.class_2_trays_per_bin|formatNumber:1:emptyPlaceholder }}
            </td>
            <td [class.not-available]="!selectedRow?.class_2_average_size"
                [class.better-than-average]="selectedRow?.class_2_average_size < trevelyansAverageData?.class_2_average_size"
                [class.worse-than-average]="selectedRow?.class_2_average_size > trevelyansAverageData?.class_2_average_size">
              {{ selectedRow?.class_2_average_size|formatNumber:1:emptyPlaceholder }}
            </td>
          </tr>
          <tr>
            <th class="row-label">
              Trevelyan's Average
            </th>
            <td class="alt"
                [class.not-available]="!trevelyansAverageData?.class_1_trays_per_bin">
              {{ trevelyansAverageData?.class_1_trays_per_bin|formatNumber:1:emptyPlaceholder }}
            </td>
            <td class="alt"
                [class.not-available]="!trevelyansAverageData?.class_1_average_size">
              {{ trevelyansAverageData?.class_1_average_size|formatNumber:1:emptyPlaceholder }}
            </td>
            <td [class.not-available]="!trevelyansAverageData?.class_2_trays_per_bin">
              {{ trevelyansAverageData?.class_2_trays_per_bin|formatNumber:1:emptyPlaceholder }}
            </td>
            <td [class.not-available]="!trevelyansAverageData?.class_2_average_size">
              {{ trevelyansAverageData?.class_2_average_size|formatNumber:1:emptyPlaceholder }}
            </td>
          </tr>
          <tr>
            <th class="row-label">
              Region Average
              <div *ngIf="regionAverageData?.area_name" class="sub-label">({{ regionAverageData.area_name }})</div>
            </th>
            <td class="alt"
                [class.not-available]="!regionAverageData?.class_1_trays_per_bin">
              {{ regionAverageData?.class_1_trays_per_bin|formatNumber:1:emptyPlaceholder }}
            </td>
            <td class="alt"
                [class.not-available]="!regionAverageData?.class_1_average_size">
              {{ regionAverageData?.class_1_average_size|formatNumber:1:emptyPlaceholder }}
            </td>
            <td [class.not-available]="!regionAverageData?.class_2_trays_per_bin">
              {{ regionAverageData?.class_2_trays_per_bin|formatNumber:1:emptyPlaceholder }}
            </td>
            <td [class.not-available]="!regionAverageData?.class_2_average_size">
              {{ regionAverageData?.class_2_average_size|formatNumber:1:emptyPlaceholder }}
            </td>
          </tr>
        </tbody>
      </table>

      <loading-indicator [class.visible]="isLoadingAverages"
                   [radius]="30"
                   [thickness]="4"
                   [isLight]="true"
                   [isAbsolute]="true"
                   message=""
                   [externalMessage]="loadingAveragesMessage">
      </loading-indicator>
    </div>
  </div>
</app-widget>
