export const BROWSER_VERSION_LIMITS = {
  edge: 90,
  opera: 80,
  chrome: 85,
  msie: 10000,
  firefox: 90,
  safari: 15.3
};

export class Browser {
  name: string;
  version: string;
  isOutdated: boolean;

  constructor() {
      this.setNameAndVersion();
      this.setOutdated();
  }

  private setNameAndVersion() {
    const userAgent = navigator.userAgent;
    const DEFAULT_VERSION = -1;
    const matchTest = userAgent.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*([\d.]+)/i) || [];
    let temp;
    let result: any = DEFAULT_VERSION;

    if (/trident/i.test(matchTest[1])) {
      temp = /\brv[ :]+(\d+)/g.exec(userAgent) || [];
      matchTest[1] = 'msie';
      result = temp[1];
    } else if (matchTest[1] === 'Chrome') {
      temp = userAgent.match(/\b(OPR|Edge|Edg)\/([\d.]+)/);
      if (temp != null) {
        matchTest[1] = temp[1] !== 'OPR' ? 'edge' : 'opera';
        result = temp[2];
      }
    }

    if (!result || result === DEFAULT_VERSION) {
      result = matchTest[2] || navigator['appVersion'];
      temp = userAgent.match(/version\/([\d.]+)/i);
      if (temp != null) {
        result = temp[1];
      }
    }

    this.name = matchTest[1] ? matchTest[1].toLowerCase() : null;
    this.version = result || null;
  }

  private setOutdated() {
    this.isOutdated = BROWSER_VERSION_LIMITS[this.name] > parseFloat(this.version);
  }
}
