import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  HostBinding,
  ChangeDetectorRef, ChangeDetectionStrategy
} from '@angular/core';
import { OutsideClickService } from '../services/outside-click.service';

export interface DropdownItem {
  label: any;
  value: any;
}

@Component({
  selector: 'dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DropdownComponent implements OnInit {
  currentItem: DropdownItem = {} as DropdownItem;
  @Input() isTopMenu = false;
  @Input() highlightSelected = false;
  @Input() items: {}[];
  @Input() maxHeight: number;
  @Input() handle: string;
  @Input() alignLeft = false;
  @Input()
  get current() {
    return this.currentItem;
  }
  set current(item) {
    if (this.currentItem !== item) {
      this.currentChange.emit(item);
    }
    this.currentItem = item;
  }

  @Output() currentChange = new EventEmitter();
  @HostBinding('class.open') isOpen = false;

  constructor(
    private outsideClickService: OutsideClickService,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.outsideClickService.add(this.handle, this.close, this);
  }

  selectItem(item, event) {
    if (item.readOnly) {
      event.stopPropagation();
    } else {
      this.current = item;
      this.close();
    }
    return false;
  }

  stopPropagation(event) {
    event.stopPropagation();
    return false;
  }

  close() {
    this.isOpen = false;
    this.changeDetectorRef.markForCheck();
  }

  toggle(event) {
    this.outsideClickService.executeAllExcept(this.handle, event);
    this.isOpen = !this.isOpen;
    event.stopPropagation();
    this.changeDetectorRef.markForCheck();
    return false;
  }
}
