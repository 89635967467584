<app-widget [updatedAt]="updatedAt"
            [dropdownItems]="availableSeasons"
            [selectedDropdownItem]="selectedSeason"
            [onDropdownItemChange]="selectSeason.bind(this)"
            [isLoading]="isLoading"
            [hasNoData]="!tabs?.length"
            [headerButtons]="headerButtons"
            loadingMessage="Loading production data..."
            noDataMessage="No production data available."
            dropdownHandle="kiwifruitProductionWidgetSeasonDropdown">
  <kendo-tabstrip *ngIf="tabs?.length" class="tabstrip"
                  (tabSelect)="onTabSelect($event)">
    <kendo-tabstrip-tab *ngFor="let tab of tabs"
                        [title]="tab.title"
                        [selected]="tab.selected">
        <ng-template kendoTabContent>
          <app-simple-grid [columns]="gridColumns"
                           [data]="gridDataFiltered"
                           [rowSelectType]="'single'"
                           [rowSelectCallback]="onGridRowSelect.bind(this)"
                           [emptyPlaceholder]="emptyPlaceholder"
                           [selectedRowIndexes]="[0]">
          </app-simple-grid>
        </ng-template>
    </kendo-tabstrip-tab>
  </kendo-tabstrip>

  <div class="details" [class.visible]="areDetailsVisible">
    <div class="column chart">
      <app-pie-chart class="packed-chart"
                     hasCenterValue="true"
                     showLegend="true"
                     legendPosition="top"
                     valuePostfix="%"
                     includeZeroValues="true"
                     [centerValue]="packedChartCenterValue"
                     [subLabel]="packedChartSubLabel"
                     [maxValue]="100"
                     [width]="packedChartSize.width"
                     [height]="packedChartSize.height"
                     [data]="packedChartData">
      </app-pie-chart>
    </div>

    <div class="comparison-panel">
      <div class="heading">
        How do I compare?
      </div>
      <div class="column-group">
        <loading-indicator [class.visible]="isLoadingAltitudeAverages || isLoadingTrevelyansAverages"
                           [radius]="30"
                           [thickness]="4"
                           message=""
                           [isLight]="true"
                           externalMessage="Loading averages..."
                           [isAbsolute]="true">
        </loading-indicator>
        <div class="column avg-labels">
          <div class="additional-data">
            <div class="panel"></div>
            <div class="panel">
              <div class="label light">My Value</div>
            </div>
            <div class="panel">
              <div class="label light">Altitude Avg<br>({{ altitudeBoundsString }})</div>
            </div>
            <div class="panel">
              <div class="label light">Trevelyan's Average</div>
            </div>
          </div>
        </div>
        <div *ngFor="let column of additionalDataColumns" [class]="'column ' + column.cssClass">
          <div *ngIf="selectedRow" class="additional-data">
            <div class="panel">
              <div class="label light">{{ column.label }}</div>
            </div>
            <div class="panel">
              <div class="value bold"
                   [class.not-available]="!selectedRow[column.key]"
                   [class.better-than-average]="selectedRow[column.key] && (column.betterIsGreaterThanAverage ? selectedRow[column.key] > trevelyansData[column.key] : selectedRow[column.key] < trevelyansData[column.key])"
                   [class.worse-than-average]="selectedRow[column.key] && (column.betterIsGreaterThanAverage ? selectedRow[column.key] < trevelyansData[column.key] : selectedRow[column.key] > trevelyansData[column.key])">
                {{ selectedRow[column.key] == null ? emptyPlaceholder : ( column.precision ? (selectedRow[column.key] | number:'1.' + (column.precision || 0) + '-' + (column.precision || 0)) || '&nbsp;' : selectedRow[column.key] || '&nbsp;' ) }}
              </div>
            </div>
            <div class="panel">
              <div class="value bold">
                {{ altitudeData[column.key] == null ? emptyPlaceholder : ( column.precision ? (altitudeData[column.key] | number:'1.' + (column.precision || 0) + '-' + (column.precision || 0)) || '&nbsp;' : altitudeData[column.key] || '&nbsp;' ) }}
              </div>
            </div>
            <div class="panel">
              <div class="value bold">
                {{ trevelyansData[column.key] == null ? emptyPlaceholder : ( column.precision ? (trevelyansData[column.key] | number:'1.' + (column.precision || 0) + '-' + (column.precision || 0)) || '&nbsp;' : trevelyansData[column.key] || '&nbsp;' ) }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</app-widget>
