<window-container class="window-container single-line-title flex-window-container"
                  [isVisible]="isVisible"
                  [maximizeOnHeaderDoubleClick]="false"
                  minWindowHeight="760"
                  windowWidth="620"
                  [resizable]="false"
                  [draggable]="false"
                  [blocking]="true"
                  [windowTitle]="windowHeader"
                  [closeWindow]="closeWindow.bind(this)"
                  [buttons]="buttons">
  <form [formGroup]="form">
    <div class="card">
      <h4>Pest and Disease Control</h4>

      <kendo-formfield class="form-field">
        <kendo-label class="form-field-label">
          Have any sprays been applied on your orchard?
        </kendo-label>
        <nullable-value-selector formControlName="sprays_applied"
                                 (click)="toggleSpraysApplied()">
        </nullable-value-selector>
        <kendo-formerror>
          Please select an option
        </kendo-formerror>
      </kendo-formfield>

      <div class="openable-container" [class.open]="form.get('sprays_applied').value">
        <kendo-formfield class="form-field">
          <kendo-label class="form-field-label">
            Have all sprays been applied by someone with a valid <em>GROWSAFE</em> certificate?
          </kendo-label>
          <nullable-value-selector formControlName="spray_applicator_growsafe_certified"></nullable-value-selector>
        </kendo-formfield>

        <kendo-formfield class="form-field">
          <kendo-label class="form-field-label">
            Approved chemical storage/disposal?
          </kendo-label>
          <nullable-value-selector formControlName="approved_chemical_storage"></nullable-value-selector>
          <kendo-formerror>
            Please select an option
          </kendo-formerror>
        </kendo-formfield>

        <kendo-formfield class="form-field">
          <kendo-label class="form-field-label">
            Only approved chemicals applied?
          </kendo-label>
          <nullable-value-selector formControlName="approved_chemicals_applied"></nullable-value-selector>
          <kendo-formerror>
            Please select an option
          </kendo-formerror>
        </kendo-formfield>

        <kendo-formfield class="form-field">
          <kendo-label class="form-field-label">
            Spray diary kept current?
          </kendo-label>
          <nullable-value-selector formControlName="spray_diary_current"></nullable-value-selector>
          <kendo-formerror>
            Please select an option
          </kendo-formerror>
        </kendo-formfield>
      </div>
    </div>

    <div class="card">
      <h4>Fertilisers</h4>

      <kendo-formfield class="form-field">
        <kendo-label class="form-field-label">
          Has any fertiliser been applied to your orchard?
        </kendo-label>
        <nullable-value-selector formControlName="fertilisers_applied"
                                 (click)="toggleFertilisersApplied()">
        </nullable-value-selector>
        <kendo-formerror>
          Please select an option
        </kendo-formerror>
      </kendo-formfield>

      <kendo-formfield class="form-field openable-container" [class.open]="form.get('fertilisers_applied').value">
        <kendo-label class="form-field-label">
          Have you maintained a fertiliser diary?
        </kendo-label>
        <nullable-value-selector formControlName="fertiliser_diary_current"></nullable-value-selector>
        <kendo-formerror>
          Please select an option
        </kendo-formerror>
      </kendo-formfield>
    </div>

    <div class="card">
      <h4>Orchard materials and services</h4>

      <kendo-formfield class="form-field">
        <kendo-label class="form-field-label">
          Records of receivals maintained (e.g. GST records)?
        </kendo-label>
        <nullable-value-selector formControlName="receivals_records_maintained"></nullable-value-selector>
        <kendo-formerror>
          Please select an option
        </kendo-formerror>
      </kendo-formfield>
    </div>

    <div class="card">
      <h4>Hygiene</h4>

      <kendo-formfield class="form-field">
        <kendo-label class="form-field-label">
          Adequate toilet and wash facilities?
        </kendo-label>
        <nullable-value-selector formControlName="adequate_toilet_facilities"></nullable-value-selector>
        <kendo-formerror>
          Please select an option
        </kendo-formerror>
      </kendo-formfield>
    </div>
  </form>
</window-container>
