<h2>Select Your Product</h2>
<div class="top-message">
  Select the product you wish to view.
  <em>You can change this later in the top right corner of any page.</em>
</div>
<div class="products no-select">
  <div *ngFor="let product of products" [class]="'product ' + product.product_name"
       (click)="selectProduct(product)">
    <div class="picture"></div>
    <div class="label">{{ product.product_name }}</div>
  </div>
</div>
