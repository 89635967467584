import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'summary'
})
export class SummaryPipe implements PipeTransform {
  // Strip all tags
  // Cleanup spaces
  // Add a period at the end of all block type tags (h1+, p, div)
  // Return the specified number of words (or all words if maxWords is unspecified)
  transform(value: string, maxWords = 1000000): any {
    const clean = value.replace(/\s+|&nbsp;/g, ' ').replace(/\.?<\/(?:h\d|p|div)>/g, '.').replace(/<[^>]+>/g, '');
    const words = clean.split(' ');
    const suffix = words.length > maxWords ? '...' : '';
    return words.slice(0, maxWords).join(' ') + suffix;
  }
}
