import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import { SettingsService } from '../shared/services/settings.service';
import { fixDateStringFormat } from '../shared/services/utils.service';

@Pipe({
  name: 'strToFormattedDate'
})
export class StrToFormattedDatePipe implements PipeTransform {
  transform(value: any, format = SettingsService.PUBLIC_DATE_TIME_FORMAT): any {
    const datePipe = new DatePipe('en-NZ');
    return datePipe.transform(new Date(fixDateStringFormat(value)), format);
  }
}
