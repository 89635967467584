import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, NavigationEnd, Router, Params } from '@angular/router';
import { SettingsService } from '../services/settings.service';
import { Subscription } from 'rxjs';

const PATH_SEPERATOR = '/';

export interface Breadcrumb {
  url: string;
  title: string;
  query: Params;
}

@Component({
  selector: 'app-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss']
})
export class BreadcrumbsComponent implements OnInit, OnDestroy {
  dateFormat = SettingsService.PUBLIC_DATE_TIME_FORMAT;
  @Input() updatedAt: Date = null;
  @Input() confirmAction: () => Promise<boolean>;
  @Input() additionalBreadcrumbs: Breadcrumb[] = [];
  breadcrumbs: Breadcrumb[] = [];
  private routeEventSubscription: Subscription;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit() {
    this.routeEventSubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.initBreadcrumbs();
      }
    });
    this.initBreadcrumbs();
  }

  ngOnDestroy() {
    this.routeEventSubscription?.unsubscribe();
  }

  async confirm(event: MouseEvent, breadcrumb: Breadcrumb) {
    if (typeof(this.confirmAction) === 'function') {
      event.preventDefault();
      event.stopPropagation();

      if (await this.confirmAction()) {
        this.router.navigate([breadcrumb.url], { queryParams: breadcrumb.query });
      }
    }
  }

  private initBreadcrumbs() {
    this.breadcrumbs = [];
    this.makeBreadcrumbs(this.activatedRoute.snapshot.root);
    this.breadcrumbs.splice(-1, 0, ...this.additionalBreadcrumbs);
  }

  private makeBreadcrumbs(activatedRouteSnapshot: ActivatedRouteSnapshot, routeBase: string = '') {
    if (!activatedRouteSnapshot) {
      return;
    }
    const pathParts = (activatedRouteSnapshot.url || []).map(item => item.path);
    let url = routeBase;
    if (pathParts.length) {
      url = [url, ...pathParts].join(PATH_SEPERATOR);
      const title = this.getSegmentTitle(activatedRouteSnapshot);
      if (title) {
        this.breadcrumbs.push({
          url,
          title,
          query: activatedRouteSnapshot.queryParams
        });
      }
    }

    if (activatedRouteSnapshot.firstChild) {
      this.makeBreadcrumbs(activatedRouteSnapshot.firstChild, url);
    }
  }

  private getSegmentTitle(activatedRouteSnapshot: ActivatedRouteSnapshot): string {
    if (activatedRouteSnapshot.component && activatedRouteSnapshot.data) {
      return activatedRouteSnapshot.data.title || '';
    } else {
      let emptyPathChild: any;
      if (activatedRouteSnapshot.routeConfig?.children) {
        emptyPathChild = activatedRouteSnapshot.routeConfig?.children.find(item => item.path === '');
      } else if (activatedRouteSnapshot.children) {
        emptyPathChild = activatedRouteSnapshot.children.find(item => item.routeConfig.path === '');
      }
      return emptyPathChild ? emptyPathChild.data.title : '';
    }
  }
}
