import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService } from 'app/shared/services/http.service';

interface GeometryFeature {
  geometry: { coordinates: any[], type: string },
}

interface BlockGeometryFeature extends GeometryFeature {
  properties: {
    block: number,
    block_name: string,
    maturity_area_id: number,
    maturity_area_name: string,
    producing_ha: number,
    variety_code: string
  };
}

interface MonitorBayGeometryFeature extends GeometryFeature {
  properties: {
    monitor_bay: number,
    bay_number: number,
    row_number: number
  };
}

interface OrchardObjectGeometryFeature extends GeometryFeature {
  properties: {
    orchard: number,
    object_type: string,
    comment: string
  };
}

export interface OrchardGeometryLayers {
  blocks: { features: BlockGeometryFeature[] };
  monitor_bays: { features: MonitorBayGeometryFeature[] };
  object_lines: { features: OrchardObjectGeometryFeature[] };
  object_points: { features: OrchardObjectGeometryFeature[] };
  object_polygons: { features: OrchardObjectGeometryFeature[] };
}

export interface OrchardGeometry {
  currently_editing_geometry: boolean;
  has_geometry: boolean;
  layers: OrchardGeometryLayers;
}

@Injectable()
export class OrchardGeometryService extends Observable<OrchardGeometry> {
  private ENDPOINT = 'orchards/geometry/';

  constructor(
    private http: HttpService
  ) {
    super(null);
  }

  public fetch(orchardId: number): Observable<OrchardGeometry> {
    const url = `${this.ENDPOINT}${orchardId}/`;
    return this.http.get(url, {}, false) as any;
  }
}
