<div class="main-menu-content" [class.vera]="!isGrowerPortal">
  <div class="logo">
    <a class="home-link" routerLink="/">
      <div class="logo-image"></div>
      <i class="home-icon fas fa-home"></i>
    </a>
  </div>

  <nav *ngIf="menuCategories"
       class="menu"
       [class.open]="isMainMenuOpen"
       (click)="toggleMainMenu($event)">

    <a href="#menu-toggle" class="button menu-toggle">
      <div class="burger">
        <div class="line top"></div>
        <div class="line mid"></div>
        <div class="line bottom"></div>
      </div>
      Menu
    </a>

    <div class="menu-panel container" (click)="stopMenuClose($event)">

      <div class="loading-indicator" [class.visible]="isLoading">
        <i class="fas fa-cog fa-spin"></i>
        Loading menu content, please wait...
      </div>

      <div class="menu-content"
           [class.single-category]="menuCategories?.length === 1">
        <div class="menu-category" routerLink="/">
          <h2 class="clickable">
            Home
          </h2>
        </div>

        <ng-container *ngFor="let category of menuCategories">
          <div *ngIf="category.items?.length"
               class="menu-category">
            <h2 *ngIf="category.label">{{ category.label }}</h2>
            <a *ngFor="let item of category.items"
                class="menu-item"
                [class.desktop]="item.desktopOnly"
                [routerLink]="item.url | toFrontendUrl"
                [queryParams]="item.queryParams"
                (click)="menuLinkClick($event)">
              <i *ngIf="showIcons && item.icon" [class]="'fas ' + item.icon">
                <i *ngIf="showIcons && item.iconFg" [class]="'fg fas ' + item.iconFg"></i>
              </i>
              {{ item.label }}
            </a>
          </div>
        </ng-container>
      </div>
    </div>
  </nav>

  <nav class="quick-menu">
    <a *ngFor="let item of quickMenuItems" class="button" [class.current]="item.url === currentUrl" [routerLink]="item.url" >
      <i [class]="'fas ' + item.icon"></i>
      {{item.label}}
    </a>
  </nav>

  <dropdown [items]="userDropdownItems" [isTopMenu]="true" [handle]="'userDropdown'" (currentChange)="userMenuAction($event)" >
    <button class="button account tablet">
      <i class="fas fa-user"></i>
      User
    </button>
  </dropdown>

  <button *ngIf="hasSearch" class="button mobile" (click)="toggleSearchPanel()">
    <i class="fas fa-search"></i>
    Search
  </button>

  <div *ngIf="hasSearch" class="search-panel" [class.open]="isSearchPanelOpen" (click)="closeOwnDropdowns($event)">
    <main-search (onFocus)="closeMainMenu()"></main-search>
  </div>

  <div class="menu-spacer"></div>

  <grow-product-menu-item *ngIf="isGrowerPortal" class="grow-product-menu-item"></grow-product-menu-item>

  <div class="user">
    <div class="name">{{ currentUserName }}</div>
    <a *ngIf="hasAccountLink" [routerLink]="'/ip/user/' + currentUser.id" class="button account">
      <i class="fas fa-cog account"></i>
      Account
    </a>
    <button class="button logout" (click)="logout()">
      <i class="fas fa-sign-out-alt logout"></i>
      logout
    </button>
  </div>
</div>
