import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { VarietyGrowMethodTab } from '../interfaces/tab.interface';
import { TabbedVarietyGrowMethod } from '../lib/tabbed-variety-grow-method';
import { HttpService } from 'app/shared/services/http.service';
import { SeasonDropdownItem } from 'app/grower-portal-dashboard/grower-portal-dashboard.component';
import { WidgetHeaderButton } from '../widget/widget.component';
import { PRODUCTION_SEASONS_ENDPOINT } from 'app/packout-details/packout-details.component';
import { Subscription } from 'rxjs';
import { PieChart } from 'app/pie-chart/pie-chart.component';
import { GaService } from 'app/shared/services/ga.service';

const PACK_RUN_ENDPOINT = 'growers/kiwifruit/production/summary/orchards/';
const AVERAGES_ENDPOINT = 'growers/kiwifruit/production/summary/averages/';
const ALTITUDE_BOUNDS = 10;

@Component({
  selector: 'app-kiwifruit-production-widget',
  templateUrl: './kiwifruit-production-widget.component.html',
  styleUrls: ['./kiwifruit-production-widget.component.scss']
})
export class KiwifruitProductionWidgetComponent extends TabbedVarietyGrowMethod implements OnInit, OnDestroy {
  @Input() updatedAt: Date = null;
  @Input() emptyPlaceholder = 'NA';
  tabs: VarietyGrowMethodTab[] = [];
  gridColumns = [
    { label: 'KPIN', rowspan: 2, align: 'left', key: 'growernumber' },
    {
      label: 'Bins', align: 'center', childColumns: [
        { label: 'Est.', key: 'bins_planned', precision: 0 },
        { label: 'Rec.', key: 'bins_receipted', precision: 0 },
        { label: 'BS', key: 'bins_in_buffer_store', precision: 0 },
        { label: 'Tipped', key: 'bins_tipped', precision: 0 }
      ]
    },
    {
      label: 'Class 1 Trays', align: 'center', childColumns: [
        { label: 'Total', key: 'trays_total', cssClass: 'c1-trays-total', precision: 1 },
        { label: 'Per Bin', key: 'trays_per_bin', cssClass: 'c1-trays-per-bin', precision: 1 }
      ]
    },
    { label: 'Size', childColumns: [{ label: 'Avg', key: 'average_size', cssClass: 'size', precision: 1 }] },
    {
      label: 'Chargeable Rejects', childColumns: [
        { label: '%', key: 'rejects_percentage', cssClass: 'rejects-percentage', precision: 2 }
      ]
    }
  ];
  isLoading = true;
  isLoadingAltitudeAverages = false;
  isLoadingTrevelyansAverages = false;
  gridData: any = [];
  gridDataFiltered: any = [];
  altitudeBoundsString = '';
  packedChartSubLabel = '';
  packedChartSize = { width: 265, height: 200 };
  packedChartData: PieChart = null;
  packedChartCenterValue = 0;
  selectedRow;
  areDetailsVisible = false;
  additionalDataColumns = [
    {
      label: 'Class 1 Trays Per Bin',
      key: 'trays_per_bin',
      cssClass: 'c1-trays-per-bin' ,
      precision: 1,
      betterIsGreaterThanAverage: true
    },
    {
      label: 'Average Size',
      key: 'average_size',
      cssClass: 'size odd',
      precision: 1,
      betterIsGreaterThanAverage: false
    },
    {
      label: 'Chargeable Rejects',
      key: 'rejects_percentage',
      cssClass: 'rejects-percentage',
      precision: 2,
      betterIsGreaterThanAverage: false
    }
  ];
  altitudeData: any = [];
  trevelyansData: any = [];

  availableSeasons: SeasonDropdownItem[] = [];
  selectedSeason: SeasonDropdownItem = null;
  headerButtons: WidgetHeaderButton[] = [
    {
      text: 'Packout Details',
      routerLink: '/packout-details',
      isDesktopOnly: true,
      cssClass: 'packout-details-button',
      getQueryParams: () => ({
        season: this.selectedSeason?.value,
        growernumber: this.selectedRow?.growernumber,
        variety: this.selectedRow?.variety,
        growMethod: this.selectedRow?.grow_method
      })
    }
  ];
  private fetchAltitudeDataSubscription: Subscription;
  private fetchTrevelyansDataSubscription: Subscription;

  constructor(
    private http: HttpService,
    private ga: GaService
  ) {
    super();
  }

  async ngOnInit() {
    this.isLoading = true;
    this.availableSeasons = await this.getSeasons();
    if (this.availableSeasons?.length) {
      this.selectedSeason = this.availableSeasons[0];
      this.fetchGridData();
    } else {
      this.isLoading = false;
    }
  }

  ngOnDestroy() {
    this.fetchAltitudeDataSubscription?.unsubscribe();
    this.fetchTrevelyansDataSubscription?.unsubscribe();
  }

  onTabSelect(event: any) {
    this.ga.event('tab', 'change', 'production widget variety gm tab', event.title);
    this.selectedTab = this.tabs[event.index];
    this.filterGridData(this.selectedTab);
    this.fetchTrevelyansData();
  }

  onGridRowSelect(row) {
    this.ga.event('grid', 'change', 'production widget grid row', row.id);
    this.selectRow(row);
    setTimeout(() => {
      this.fetchAltitudeData(row);
    });
  }

  selectSeason(season: SeasonDropdownItem) {
    if (this.selectedSeason && this.selectedSeason.label !== season.label) {
      this.selectedSeason = season;
      this.fetchGridData();
    }
  }

  private showDetails() {
    setTimeout(() => {
      this.areDetailsVisible = true;
    }, 500);
  }

  private selectRow(row) {
    this.selectedRow = row;
    let percent_bins_in_buffer_store = 0;
    let percent_bins_tipped = 0;
    let percent_bins_remaining = 0;

    if (row.bins_receipted) {
      percent_bins_in_buffer_store = Math.round((row.bins_in_buffer_store || 0) / row.bins_receipted * 100);
      percent_bins_tipped = Math.round((row.bins_tipped || 0) / row.bins_receipted * 100);
      percent_bins_remaining = 100 - percent_bins_in_buffer_store - percent_bins_tipped;
      this.packedChartSubLabel = `(${(row.bins_in_buffer_store || 0)} + ${(row.bins_tipped || 0)}) / ${row.bins_receipted}<br>Bins Packed`;
    } else {
      this.packedChartSubLabel = '0 Bins Receipted';
      percent_bins_remaining = 100;
    }

    this.packedChartData = {
      current: {
        data: [
          { label: 'Bin Store', value: percent_bins_in_buffer_store, cssClass: 'item-2' },
          { label: 'Tipped', value: percent_bins_tipped, cssClass: 'item-0' },
          // Note: The label intentionally does not reflect the value.
          // The value completes the pie chart and appears as the track (pie chart background).
          // Receipted bins = 100% of the pie chart, ie. the entire track.
          { label: 'Receipted', value: percent_bins_remaining, cssClass: 'track' }
        ]
      }
    };

    this.packedChartCenterValue = 100 - percent_bins_remaining;
  }

  private async fetchGridData() {
    this.isLoading = true;
    const params: any = { season: this.selectedSeason.value };

    this.gridData = await this.http.get(PACK_RUN_ENDPOINT, { params: params }).toPromise();

    if (this.gridData) {
      this.setTabs(this.gridData, this.tabReducer.bind(this));
      this.sortTabs();
      this.setBufferStoreBins();

      if (this.tabs && this.tabs.length) {
        this.initSelectedTab();

        this.filterGridData(this.selectedTab);
        if (this.gridDataFiltered && this.gridDataFiltered.length) {
          this.selectRow(this.gridDataFiltered[0]);
          this.showDetails();
          this.fetchTrevelyansData();
        }
      }
      this.updatedAt = new Date(); // This will come from the server
    }
    this.isLoading = false;
  }

  private setBufferStoreBins() {
    this.gridData.forEach((item) => {
      item.bins_in_buffer_store = this.getBufferStoreBins(item);
    });
  }

  private getBufferStoreBins(item: any): number {
    return item.buffer_store_bins_receipted - item.buffer_store_bins_tipped;
  }

  protected tabReducer(result: VarietyGrowMethodTab[], obj: any): VarietyGrowMethodTab[] {
    result.push({
      title: obj.variety + obj.grow_method,
      variety_code: obj.variety,
      variety_id: obj.variety_id,
      grow_method_code: obj.grow_method,
      grow_method_id: obj.grow_method_id,
      selected: false
    });
    return result;
  }

  private filterGridData(tab: VarietyGrowMethodTab) {
    this.gridDataFiltered = this.cleanGridData(
      this.gridData.filter(obj => {
        return obj.variety === tab.variety_code && obj.grow_method === tab.grow_method_code;
      })
    );
  }

  // If no bins were tipped other values should not be defined
  // If bins were tipped but the other values are not,
  // force them to be an empty string to avoid showing the NA placeholder
  private cleanGridData(data: any[]): any[] {
    data.forEach((item) => {
      if (!item.bins_tipped) {
        item.trays_total = null;
        item.trays_per_bin = null;
        item.average_size = null;
        item.rejects_percentage = null;
      } else {
        item.trays_total = item.trays_total || '';
        item.trays_per_bin = item.trays_per_bin || '';
        item.average_size = item.average_size || '';
        item.rejects_percentage = item.rejects_percentage == null ? '' : item.rejects_percentage;
      }
    });
    return data;
  }

  private fetchAltitudeData(orchard: any) {
    const altitudeLowerBand = Math.max(orchard.altitude - ALTITUDE_BOUNDS, 0);
    const altitudeUpperBand = orchard.altitude + ALTITUDE_BOUNDS;

    this.isLoadingAltitudeAverages = true;
    this.altitudeBoundsString = altitudeLowerBand + '-' + altitudeUpperBand + 'm';

    const params: any = {
      season: this.selectedSeason.value,
      grow_method: this.selectedTab.grow_method_id,
      variety: this.selectedTab.variety_id,
      altitude_min: altitudeLowerBand,
      altitude_max: altitudeUpperBand
    };

    this.fetchAltitudeDataSubscription = this.http.get(AVERAGES_ENDPOINT, { params: params }).subscribe(data => {
      this.altitudeData = data;
      this.isLoadingAltitudeAverages = false;
    });
  }

  private fetchTrevelyansData() {
    this.isLoadingTrevelyansAverages = true;

    const params: any = {
      season: this.selectedSeason.value,
      grow_method: this.selectedTab.grow_method_id,
      variety: this.selectedTab.variety_id
    };

    this.fetchTrevelyansDataSubscription = this.http.get(AVERAGES_ENDPOINT, { params: params }).subscribe(data => {
      this.trevelyansData = data;
      this.isLoadingTrevelyansAverages = false;
    });
  }

  private async getSeasons(): Promise<SeasonDropdownItem[]> {
    const data = await this.fetchSeasons();

    return data.map((item: { season: number }) => {
      return { label: item.season, value: item.season };
    });
  }

  private fetchSeasons(): Promise<any> {
    return this.http.get(PRODUCTION_SEASONS_ENDPOINT).toPromise();
  }
}
