import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';

export function getGridPagerPageRange(from: number, itemCount: number, pageSize: number, currentPage = 1): [number, number] {
  const maxTo = from + itemCount - 1;
  let currentTo = from + currentPage * pageSize - 1;
  currentTo = currentTo > maxTo ? maxTo : currentTo;
  const currentFrom = currentTo - pageSize + 1;
  return [currentFrom, currentTo];
}

export function getItemCount(from: number, to: number): number {
  if (from != null && to != null && to > from) {
    return to - from + 1;
  }
  return 0;
}

@Component({
  selector: 'app-grid-pager',
  templateUrl: './grid-pager.component.html',
  styleUrls: ['./grid-pager.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GridPagerComponent implements OnInit {
  @Input() label = '';
  @Input() separator = '-';

  private _pageSize: number;
  @Input()
  get pageSize() {
    return this._pageSize;
  }
  set pageSize(value: number) {
    this._pageSize = value;
    this.setPageCount();
  }

  private _from: number;
  @Input()
  get from() {
    return this._from;
  }
  set from(value: number) {
    this._from = value;
    this.initItemCount();
    this.setPageCount();
  }

  private _to: number;
  @Input()
  get to() {
    return this._to;
  }
  set to(value: number) {
    this._to = value;
    this.initItemCount();
    this.setPageCount();
  }

  private _currentPage: number;
  @Input()
  get currentPage() {
    return this._currentPage;
  }
  set currentPage(value: number) {
    this._currentPage = value;
    this.setCurrent(value, true);
  }

  @Input() onChange: (from?: number, to?: number, isInit?: boolean) => {};
  pageCount: number;
  currentFrom: number;
  currentTo: number;
  private itemCount: number;

  constructor(
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.setCurrent(this.currentPage, true);
  }

  previous() {
    this.setCurrent(this.currentPage - 1);
  }

  next() {
    this.setCurrent(this.currentPage + 1);
  }

  private initItemCount() {
    if (this.from != null && this.to != null && this.to > this.from) {
      this.itemCount = getItemCount(this.from, this.to);
    }
  }

  private setPageCount() {
    if (this.itemCount > 0 && this.pageSize > 0) {
      this.pageCount = Math.ceil(this.itemCount / this.pageSize);
    }
  }

  private setCurrent(page: number, isInit = false) {
    if (page < 1) {
      this._currentPage = 1;
    } else if (page > this.pageCount) {
      this._currentPage = this.pageCount;
    } else {
      this._currentPage = page;
    }

    [this.currentFrom, this.currentTo] = getGridPagerPageRange(this.from, this.itemCount, this.pageSize, this.currentPage);

    if (typeof(this.onChange) === 'function') {
      this.onChange(this.currentFrom, this.currentTo, isInit);
    }

    this.changeDetectorRef.markForCheck();
  }
}
