<div class="rank-items">
  <div class="zero-rank-item" (click)="select(0)" (mouseenter)="setHighlightIndex(0)"></div>
  <div *ngFor="let rankItem of rankItems; let i = index"
    class="rank-item"
    [class.selected]="i < highlightValue"
    (click)="select(i + 1)"
    (mouseenter)="setHighlightIndex(i + 1)"
    (mouseleave)="onMouseLeave()"></div>
</div>
<div class="legend">
  <div class="good-label">{{ legend.goodLabel }}</div>
  <div class="bad-label">{{ legend.badLabel }}</div>
</div>
