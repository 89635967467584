<div class="mobile-menu">
  <button class="toggle-index-button button rounded vertical-fill" (click)="toggleIndex()">
    <i class="fas fa-bars left"></i>
    {{ isIndexOpen ? 'Hide' : 'Show' }}<span class="desktop-only">Index</span>
  </button>
</div>
<kendo-splitter orientation="horizontal" [class.index-open]="isIndexOpen" (click)="closeIndex()">
  <kendo-splitter-pane size="30%" min="25%" max="60%" class="index-pane">
    <div class="index" (click)="stopPropagation($event)">
      <a *ngFor="let item of items" [routerLink]="[]" [queryParams]="{ id: item.id }" class="item" [class.current]="item.id === currentItem?.id">
        <div *ngIf="titleField" class="title">{{ item[titleField] }}</div>
        <div class="summary">{{ item.content | summary: 15 }}</div>
        <div class="date">
          <i class="fas fa-calendar-alt"></i>
          {{ item.start_date | strToFormattedDate: dateFormat }}
        </div>
      </a>
    </div>
  </kendo-splitter-pane>
  <kendo-splitter-pane class="content-pane">
    <main class="content news-item" [innerHtml]="currentItem?.content"></main>
  </kendo-splitter-pane>
</kendo-splitter>

<loading-indicator [class.visible]="isLoading"
                   message="Loading..."
                   [isGrowerPortal]="true"
                   [radius]="75"></loading-indicator>
