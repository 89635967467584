<header class="header">
  <div class="tabs">
    <div *ngFor="let tab of tabs; let i = index"
         class="tab"
         [id]="tab.id"
         [class.current]="tab.title === current.title"
         (click)="selectTab(i, tab)">
      {{ tab.title }}
      <app-help-button *ngIf="tab.helpKey && tab.title === current.title" [helpKey]="tab.helpKey"></app-help-button>
    </div>
  </div>
</header>
<div class="content">
  <ng-template #content></ng-template>
</div>