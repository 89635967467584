import { VarietyGrowMethodTab } from 'app/interfaces/tab.interface';
import { Tabbed } from './tabbed';

export abstract class TabbedVarietyGrowMethod extends Tabbed {
  declare tabs: VarietyGrowMethodTab[];
  declare protected selectedTab: VarietyGrowMethodTab;

  protected initSelectedTab() {
    if (this.tabs && this.selectedTab) {
      for (const tab of this.tabs) {
        if (tab.variety_code === this.selectedTab.variety_code && tab.grow_method_code === this.selectedTab.grow_method_code) {
          this.selectTab(tab);
          return;
        }
      }
    }
    this.selectFirstTab();
  }
}
