import { Component, OnDestroy } from '@angular/core';
import { MessageService } from 'app/shared/services/message.service';
import { SettingsService } from 'app/shared/services/settings.service';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { GaService } from 'app/shared/services/ga.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-unauthenticated-layout',
  templateUrl: './unauthenticated-layout.component.html',
  styleUrls: ['./unauthenticated-layout.component.scss']
})
export class UnauthenticatedLayoutComponent implements OnDestroy {
  document: Document = null;
  showFooter = true;
  private readonly messageSubscription: Subscription;
  private readonly routeEventSubscription: Subscription;

  constructor(
    private messageService: MessageService,
    private route: ActivatedRoute,
    private router: Router,
    private ga: GaService
    ) {
    this.flagBodyAsUnauthenticated();
    this.messageSubscription = this.messageService.read().subscribe(this.onMessage.bind(this));

    this.routeEventSubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        const data = this.route.snapshot.firstChild.data;
        if ('showFooter' in data) {
          this.showFooter = data['showFooter'];
        }
      }
    });
    this.ga.updateUseAnalytics(true);
  }

  ngOnDestroy() {
    this.messageSubscription?.unsubscribe();
    this.routeEventSubscription?.unsubscribe();
  }

  private flagBodyAsUnauthenticated() {
    document.body.classList.remove('authenticated');
  }

  closeDocumentViewer() {
    this.document = null;
  }

  onMessage(message) {
    if (message.text === SettingsService.SHOW_DOCUMENT_MESSAGE) {
      this.document = message.data;
    }
  }
}
