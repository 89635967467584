<div class="previous button fas fa-chevron-left"
     [class.disabled]="currentPage <= 1"
     (click)="previous()">
</div>
<div class="label">{{ label }}</div>
<div class="from">{{ currentFrom }}</div>
<div class="separator">{{ separator }}</div>
<div class="to">{{ currentTo }}</div>
<div class="next button fas fa-chevron-right"
     [class.disabled]="currentPage >= pageCount"
     (click)="next()">
</div>
