import { TabbedVarietyGrowMethod } from 'app/lib/tabbed-variety-grow-method';
import {
  compare,
  formatDateShort,
  getDropdownItems,
  openGrowerReport
} from 'app/shared/services/utils.service';
import { SeasonDropdownItem } from 'app/grower-portal-dashboard/grower-portal-dashboard.component';
import { DropdownItem } from 'app/shared/dropdown/dropdown.component';
import { ChangeDetectorRef } from '@angular/core';
import { HttpService } from 'app/shared/services/http.service';
import { GaService } from 'app/shared/services/ga.service';

export interface SeasonAndOrchardItem {
  season: number;
  grower_number: string;
  orchard_id: number;
}

export interface SampleInformationItem {
  grower_number: string;
  maturity_area: string;
  maturity_area_id: number;
  variety: string;
  variety_id: number;
  grow_method: string;
  grow_method_id: number;
  type?: string;
  collect_date: string;
  clearance_date: string;
  booked_date: string;
  dry_matter_average: number;
  ctp_complete: boolean;
  fruit_in_sample: number;
  fresh_weight_average: number;
  sample_number: string;
  sample_status: string;
  sample_type: string;
  sample_type_code: string;
  comments: string;
}

export abstract class SampleInformationWidget<T extends SampleInformationItem> extends TabbedVarietyGrowMethod {
  isLoading = true;
  gridData: T[] = [];
  gridDataFiltered: T[] = [];
  selectedRow: T;
  availableSeasons: SeasonDropdownItem[] = [];
  selectedSeason: SeasonDropdownItem = null;
  availableGrowerNumbers: DropdownItem[] = [];
  selectedGrowerNumber: DropdownItem = null;
  availableMaturityAreas: DropdownItem[] = [];
  selectedMaturityArea: DropdownItem = null;
  areDetailsVisible = false;
  protected gridDataForSelectedGrowerNumber: T[];
  protected gridDataForSelectedVarietyAndGrowMethod: T[];
  protected availableSeasonsAndOrchards: any[];
  private dataUrl: string;
  private seasonsAndOrchardsUrl: string;

  constructor(
    protected http: HttpService,
    protected changeDetectorRef: ChangeDetectorRef,
    protected ga: GaService
  ) {
    super();
  }

  async selectSeason(season: SeasonDropdownItem) {
    if (this.selectedSeason?.label !== season.label) {
      this.selectedSeason = season;
      this.initAvailableGrowerNumbers();
      await this.execWithLoading(this.loadGridData.bind(this));
    }
  }

  onTabSelect(event: any) {
    const newTab = this.tabs[event.index];
    if (newTab.variety_id !== this.selectedTab.variety_id || newTab.grow_method_id !== this.selectedTab.grow_method_id) {
      this.ga.event('tab', 'change', 'sample information widget variety gm tab', event.title);
      this.selectedTab = newTab;
      this.filterGridData();
    }
  }

  onGridRowSelect(row) {
    if (!compare(row, this.selectedRow)) {
      this.ga.event('grid', 'change', 'sample information widget grid row', row.sample_number);
      this.selectedRow = row;
      this.changeDetectorRef.markForCheck();
      this.updateDetails();
    }
  }

  async selectGrowerNumber(dropdownItem: DropdownItem) {
    if (this.selectedGrowerNumber?.label !== dropdownItem.label) {
      this.selectedGrowerNumber = dropdownItem;
      await this.execWithLoading(this.loadGridData.bind(this));
    }
  }

  selectMaturityArea(maturityArea: DropdownItem) {
    if (maturityArea && this.selectedMaturityArea?.label !== maturityArea.label) {
      this.selectedMaturityArea = maturityArea;
      this.setGridDataForSelectedMaturityArea();
    }
  }

  // TODO: Update details when they are finalized
  // Overwrite in child class
  protected updateDetails() {}

  // Overwrite in child class
  protected updateFieldVisibility() {}

  protected async init(dataUrl: string, seasonsAndOrchardsUrl: string): Promise<void> {
    this.dataUrl = dataUrl;
    this.seasonsAndOrchardsUrl = seasonsAndOrchardsUrl;
    this.availableSeasonsAndOrchards = await this.fetchSeasonsAndOrchards();
    if (this.availableSeasonsAndOrchards?.length) {
      this.availableSeasons = getDropdownItems(this.availableSeasonsAndOrchards, 'season', 'season');
      this.selectedSeason = this.availableSeasons[0];
      this.initAvailableGrowerNumbers();
      return this.loadGridData();
    }
  }

  protected async execWithLoading(callback: () => Promise<any>) {
    this.isLoading = true;
    await callback();
    this.isLoading = false;
    this.changeDetectorRef.markForCheck();
  }

  protected tabReducer(result: any[], obj: any): any[] {
    result.push({
      title: obj.variety + obj.grow_method,
      variety_code: obj.variety,
      variety_id: obj.variety_id,
      grow_method_code: obj.grow_method,
      grow_method_id: obj.grow_method_id,
      selected: false
    });
    return result;
  }

  protected onDownloadSampleResultsClick(urlTemplate: string, item) {
    const link = urlTemplate.replace('{sampleNumber}', item.sample_number);
    this.ga.event('button', 'click', 'sample information widget report download', link);
    openGrowerReport(link);
  }

  protected getCtpReportUrl(baseUrl: string) {
    return baseUrl + this.selectedMaturityArea.value;
  }

  private fetchSeasonsAndOrchards(): Promise<any> {
    return this.http.get(this.seasonsAndOrchardsUrl).toPromise();
  }

  private async loadGridData(): Promise<void> {
    const params: any = {
      orchard_id: this.selectedGrowerNumber.value
    };
    const data = await this.http.get(this.dataUrl, { params: params }).toPromise() as T[];
    if (data) {
      return this.initLoadedData(data);
    } else {
      this.gridDataFiltered = [];
    }
  }

  private initLoadedData(data) {
    this.gridData = data;
    this.setAdditionalFields();
    this.setGridDataForSelectedGrowerNumber();
    this.setTabs(this.gridDataForSelectedGrowerNumber, this.tabReducer.bind(this));
    this.sortTabs();
    if (this.tabs?.length) {
      this.initSelectedTab();
      this.filterGridData();
      if (this.gridDataFiltered && this.gridDataFiltered.length) {
        this.selectRow(this.gridDataFiltered[0]);
        this.updateDetails();
      }
      this.changeDetectorRef.markForCheck();
    }
  }

  private filterGridData() {
    this.updateFieldVisibility();
    this.setGridDataForSelectedVarietyAndGrowMethod();
    this.initAvailableMaturityAreas();
    this.setGridDataForSelectedMaturityArea();
  }

  private setAdditionalFields() {
    this.gridData.forEach((item: T) => {
      if (item.sample_type_code) {
        let type = item.sample_type_code;
        if (item.fruit_in_sample) {
          type += `(${item.fruit_in_sample})`;
        }
        item.type = type;
      }

      if (item.collect_date) {
        item.collect_date = formatDateShort(item.collect_date);
      }

      if (item.clearance_date) {
        item.clearance_date = formatDateShort(item.clearance_date);
      }

      if (item.booked_date) {
        item.booked_date = formatDateShort(item.booked_date);
      }
    });
  }

  private setGridDataForSelectedGrowerNumber(): void {
    this.gridDataForSelectedGrowerNumber = this.gridData.filter((obj: T) => {
      return obj.grower_number === this.selectedGrowerNumber.label;
    });
  }

  private setGridDataForSelectedVarietyAndGrowMethod(): void {
    this.gridDataForSelectedVarietyAndGrowMethod = (this.gridDataForSelectedGrowerNumber || []).filter((obj: T) => {
      return (obj.variety_id === this.selectedTab.variety_id)
        && (obj.grow_method_id === this.selectedTab.grow_method_id);
    });
  }

  private setGridDataForSelectedMaturityArea(): void {
    if (this.selectedMaturityArea) {
      this.gridDataFiltered = (this.gridDataForSelectedVarietyAndGrowMethod || []).filter((obj: T) => {
        return obj.maturity_area_id === this.selectedMaturityArea.value;
      });
    } else {
      this.gridDataFiltered = [];
    }
  }

  protected selectRow(row: T) {
    this.ga.event('grid', 'change', 'sample information widget grid row', row.sample_number);
    this.selectedRow = row;
    this.changeDetectorRef.markForCheck();
  }

  private getAvailableGrowerNumbers(): DropdownItem[] {
    return this.availableSeasonsAndOrchards.reduce((result: DropdownItem[], item: SeasonAndOrchardItem) => {
      if (this.selectedSeason.value === item.season) {
        result.push({ label: item.grower_number, value: item.orchard_id });
      }
      return result;
    }, []);
  }

  private initAvailableGrowerNumbers() {
    this.availableGrowerNumbers = this.getAvailableGrowerNumbers();
    this.initSelectedGrowerNumber();
  }

  private initSelectedGrowerNumber() {
    if (this.availableGrowerNumbers?.length) {
      if (!this.selectedGrowerNumber) {
        this.selectFirstAvailableGrowerNumber();
      } else {
        const growerNumberWithSameLabelAsSelectedGrowerNumber = this.getGrowerNumberWithSameLabelAsSelectedGrowerNumber();
        if (growerNumberWithSameLabelAsSelectedGrowerNumber) {
          this.selectedGrowerNumber = growerNumberWithSameLabelAsSelectedGrowerNumber;
        } else {
          this.selectFirstAvailableGrowerNumber();
        }
      }
    }
  }

  private selectFirstAvailableGrowerNumber() {
    this.selectedGrowerNumber = this.availableGrowerNumbers[0];
  }

  private getGrowerNumberWithSameLabelAsSelectedGrowerNumber(): DropdownItem {
    for (const availableGrowerNumber of this.availableGrowerNumbers) {
      if (availableGrowerNumber.label === this.selectedGrowerNumber.label) {
        return availableGrowerNumber;
      }
    }
  }

  private initAvailableMaturityAreas() {
    if (this.gridDataForSelectedVarietyAndGrowMethod?.length) {
      this.availableMaturityAreas = getDropdownItems(
        this.gridDataForSelectedVarietyAndGrowMethod,
        'maturity_area',
        'maturity_area_id',
        true
      );
      this.selectedMaturityArea = this.availableMaturityAreas[0];
    } else {
      this.availableMaturityAreas = [];
    }
  }
}
