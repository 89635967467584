import { Component, OnInit, HostBinding, HostListener, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss']
})
export class CheckboxComponent implements OnInit {
  @Input() label = '';
  @Input() disabled = false;
  @Input() small = false;
  @Output() isCheckedChange = new EventEmitter();
  currentValue = false;

  @HostListener('click') toggle() {
    if (!this.disabled) {
      this.isChecked = !this.isChecked;
    }
  }

  @Input()
  @HostBinding('class.checked')
  get isChecked() {
    return this.currentValue;
  }
  set isChecked(currentValue) {
    this.currentValue = currentValue;
    this.isCheckedChange.emit(this.currentValue);
  }

  constructor() { }

  ngOnInit() {}
}
