import { Component, Input, HostListener, HostBinding, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-toggle',
  templateUrl: './toggle.component.html',
  styleUrls: ['./toggle.component.scss']
})
export class ToggleComponent {
  @Input() stateValue = true;
  @Input() onLabel = 'On';
  @Input() offLabel = 'Off';
  @Output() stateChange = new EventEmitter<boolean>();

  @Input()
  @HostBinding('class.on')
  get state(): boolean {
    return this.stateValue;
  }

  set state(stateValue: boolean) {
    this.stateValue = stateValue;
    this.stateChange.emit(this.stateValue);
  }

  @HostListener('click') toggle() {
    this.state = !this.state;
  }
}
