import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { UserProductsService } from 'app/shared/services/user-products.service';

@Injectable()
export class ProductGuard implements CanActivate {
  constructor(
    private userProductsService: UserProductsService
  ) {}

  canActivate() {
    return this.userProductsService.redirectUnsupportedProducts();
  }
}
