import { TutorialStep, AttachDirection } from 'app/shared/services/tutorial.service';

class FruitCountsWidgetStep extends TutorialStep {
  constructor(step) {
    super(step);
    this.id = 'fruit-counts-widget';
    this.title = 'Fruit Counts Widget';
    this.arrow = true;
  }
}

export function getFruitCountsWidgetSteps(tutorialService): FruitCountsWidgetStep[] {
  return [
    new FruitCountsWidgetStep ({
      beforeShowPromise: () => {
        return new Promise<void>((resolve) => {
          tutorialService.clickElement('fruit-counts');
          resolve();
        });
      },
      attachTo: {
        element: 'div:nth-child(1) > app-tabbed-content > div.content',
        on: AttachDirection.right
      },
      text: 'The Fruit Count area provides a high-level summary of the fruit counts Trevelyan’s performs on your orchard. Approximately 3 bays per hectare are counted. The actual data is available once counting has been completed on your orchard. The crop estimate itself, and assumptions used, are available once assumptions for your orchard have been checked and signed off as giving a realistic crop estimate.'
    }),
    new FruitCountsWidgetStep ({
      attachTo: {
        element: 'app-widget.fruit app-simple-grid > table > tbody > tr > td.row-select.ng-star-inserted',
        on: AttachDirection.right
      },
      text: 'If you are associated with multiple KPINs, use the radio buttons on the left side of the table to switch between them. '
    }),
    new FruitCountsWidgetStep ({
      attachTo: {
        element: 'app-widget.fruit app-simple-grid > table > thead > tr:nth-child(1) > th:nth-child(3)',
        on: AttachDirection.bottom
      },
      text: 'The Fruit column shows the number average number of flower buds counted per m2. This value is used as the basis for the other data in the row.'
    }),
    new FruitCountsWidgetStep ({
      attachTo: {
        element: 'app-widget.fruit app-simple-grid > table > tbody > tr > td:nth-child(9)',
        on: AttachDirection.bottom
      },
      text: 'Click on the download buttons here to generate Crop Estimate and Scorecard reports in PDF form.'
    }),
    new FruitCountsWidgetStep ({
      attachTo: {
        element: 'app-widget.fruit .details',
        on: AttachDirection.top
      },
      text: 'The graph below shows flower bud count results for your orchard(s) up to 3 seasons back from the currently selected season. The X axis displays the season, while the Y axis displays the selected value. Clicking on the bold headings in the table above changes what is displayed on the Y axis.'
    }),
    new FruitCountsWidgetStep ({
      beforeShowPromise: () => {
        return new Promise<void>((resolve) => {
          tutorialService.clickElement('fruit-counts');
          resolve();
        });
      },
      attachTo: {
        element: 'app-widget.fruit > header > div > dropdown',
        on: AttachDirection.bottom
      },
      text: 'Use the Season selector to change which season’s data is displayed.'
    }),
  ];
}
