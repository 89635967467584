import { Component, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-maintenance',
  templateUrl: './maintenance.component.html',
  styleUrls: ['./maintenance.component.scss']
})
export class MaintenanceComponent {

  constructor(private meta: Meta) {
    this.setRefreshMetaTag();
  }

  setRefreshMetaTag() {
    this.meta.addTag({ 'http-equiv': 'refresh', content: '10' });
  }
}
