import { NgModule } from '@angular/core';
import { SimpleBarChartComponent } from 'app/shared/simple-bar-chart/simple-bar-chart.component';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [
    SimpleBarChartComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
    SimpleBarChartComponent
  ]
})
export class SimpleBarChartModule {}
