import { Component, Input } from '@angular/core';

export interface Document {
  title: string;
  content: string;
  messageType?: string;
  isAdaptive?: boolean;
  showSurvey?: boolean;
  showSaveUser?: boolean;
  sendFeedbackNow?: boolean;
  topMessage?: string;
  thankYouMessage?: string;
  additionalData?: any;
}

@Component({
  selector: 'app-document-viewer',
  templateUrl: './document-viewer.component.html',
  styleUrls: ['./document-viewer.component.scss']
})
export class DocumentViewerComponent {
  private currentDocument: Document = null;
  @Input() closeWindowHandler;
  @Input() minWindowHeight = 400;
  @Input()
  get document(): Document {
    return this.currentDocument;
  }
  set document(document: Document) {
    this.currentDocument = document;
  }

  constructor() {}

  outsideClick() {
    this.closeWindow();
  }

  closeWindow() {
    if (typeof(this.closeWindowHandler) === 'function') {
      this.closeWindowHandler();
    }
  }
}
