<div class="layers">
  <app-grouped-bar-chart class="layer grouped-bar-chart"
                         [class.transparent]="isHidden"
                         [isEmbedded]="true"
                         [groups]="data?.bar?.groups"
                         [groupWidth]="barChartGroupWidth"
                         [barLeftOffset]="barLeftOffset"
                         [data]="data?.bar?.data"
                         [minValue]="minYValue"
                         [maxValue]="maxYValue"
                         [xAxis]="{ label: '&nbsp;', hideValue: true }"
                         [precision]="0"
                         [margin]="barChartMargin"
                         [showValue]="showBarValue"
                         [showGrid]="false"
                         [showLegend]="false"
                         [selectedLegendIndex]="selectedLegendItem?.index"
                         [showGroupSelector]="false"></app-grouped-bar-chart>

  <app-line-chart class="layer line-chart"
                  [class.transparent]="isHidden"
                  [data]="data?.line"
                  [showLegend]="false"
                  [tickPadding]="tickPadding"
                  [showAverage]="false"
                  [showXGrid]="true"
                  [minYValue]="minYValue"
                  [maxYValue]="maxYValue"
                  [hasTooltip]="true"
                  [isYValueAPercentage]="isYValueAPercentage"
                  [tooltipConfig]="tooltipConfig"
                  [tooltipX]="tooltipX"
                  [margin]="margin"
                  [axisSettings]="axisSettings"
                  [hasNoDataMessage]="false"
                  [selectedLine]="selectedLegendItem"
                  [forceUpdate]="forceUpdate"
                  [transitionDuration]="transitionDuration"
                  (xStepChange)="onLineChartXStepChange($event)"></app-line-chart>
</div>

<div class="legend bottom">
  <div *ngFor="let legendItem of legendItems"
       class="legend-item"
        (mouseover)="onLegendItemMouseOver(legendItem)"
        (mouseout)="onLegendItemMouseOut()">
    <div [class]="'indicator ' + legendItem.cssClass"></div>
    <div class="label">{{ legendItem.label }}</div>
  </div>
</div>
