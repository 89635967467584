import { Component, OnInit, OnDestroy } from '@angular/core';
import { Product, UserProductsService } from 'app/shared/services/user-products.service';
import { Subscription } from 'rxjs';
import { compare } from 'app/shared/services/utils.service';

@Component({
  selector: 'grow-product-menu-item',
  templateUrl: './product-menu-item.component.html',
  styleUrls: ['./product-menu-item.component.scss']
})
export class ProductMenuItemComponent implements OnInit, OnDestroy {
  currentProduct: Product;
  products: Product[];
  private readonly selectedProductChangeSubscription: Subscription;

  constructor(
    private userProductsService: UserProductsService
  ) {
    this.selectedProductChangeSubscription = this.userProductsService.selectedProductChange.subscribe((product: Product) => {
      this.currentProduct = product;
    });
    this.selectedProductChangeSubscription = this.userProductsService.productsChange.subscribe((products: Product[]) => {
      if (!compare(this.products, products)) {
        this.products = products;
      }
    });
  }

  ngOnInit(): void {
    this.currentProduct = this.userProductsService.getSelectedProduct();
    this.products = this.userProductsService.getProducts();
  }

  ngOnDestroy() {
    this.selectedProductChangeSubscription?.unsubscribe();
  }

  switchToNextProduct() {
    this.userProductsService.setNextProduct();
  }
}
