import { Injectable, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { MessageService } from './message.service';
import { SettingsService } from './settings.service';

@Injectable()
export class ClearSearchService implements OnDestroy {
  clearSearchSubscription: Subscription;

  constructor(
    private messageService: MessageService,
    private router: Router
  ) {}

  ngOnDestroy() {
    this.clearSearchSubscription?.unsubscribe();
  }

  subscribeToClearOnRouteChange() {
    if (!this.clearSearchSubscription) {
      this.clearSearchSubscription = this.router.events.subscribe(() => {
        this.clearSearch();
      });
    }
  }

  clearSearch() {
    this.messageService.send(SettingsService.CLEAR_SEARCH_MESSAGE);
  }
}
