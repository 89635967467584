import { TutorialStep, AttachDirection } from 'app/shared/services/tutorial.service';
import { getCalendarSteps } from 'app/orchard-calendar/orchard-calendar-tutorial';
import { getMapWidgetSteps } from 'app/orchard-map-widget/orchard-map-widget-tutorial';
import { getAvocadoProductionWidgetSteps } from 'app/avocado-production-widget/avocado-production-widget-tutorial';
import { getKiwifruitProductionWidgetSteps } from 'app/kiwifruit-production-widget/kiwifruit-production-widget-tutorial';
import { getFlowerBudCountsWidgetSteps } from 'app/kiwifruit-counts-widget/flower-bud-counts-widget-tutorial';
import { getFruitCountsWidgetSteps } from 'app/kiwifruit-counts-widget/fruit-counts-widget-tutorial';
import { getKiwifruitSampleInformationWidgetSteps } from 'app/kiwifruit-sample-information-widget/kiwifruit-sample-information-widget-tutorial';
import { getAvocadoSampleInformationWidgetSteps } from 'app/avocado-sample-information-widget/avocado-sample-information-widget-tutorial';
import { getOgrInventoryWidgetSteps } from 'app/ogr-inventory-widget/ogr-inventory-widget-tutorial';
import { getLatestNewsSteps } from 'app/latest-news/latest-news-tutorial';

export function getGrowerDashboardSteps(tutorialService, options: any) {
  const currentProduct = options?.product.toLowerCase() || 'kiwifruit';
  let mainStepButtons = [
    {
      classes: 'shepherd-button-primary',
      text: 'Map & Weather',
      action: () => tutorialService.show('map-weather-widget')
    }
  ];

  if (currentProduct === 'kiwifruit') {
    mainStepButtons = mainStepButtons.concat([
      {
        classes: 'shepherd-button-primary',
        text: 'Production',
        action: () => tutorialService.show('kiwifruit-production-widget')
      },
      {
        classes: 'shepherd-button-primary',
        text: 'Flower Bud Counts',
        action: () => tutorialService.show('flower-bud-counts-widget')
      },
      {
        classes: 'shepherd-button-primary',
        text: 'Fruit Counts',
        action: () => tutorialService.show('fruit-counts-widget')
      },
      {
        classes: 'shepherd-button-primary',
        text: 'Sample Information',
        action: () => tutorialService.show('kiwifruit-sample-information-widget')
      },
      {
        classes: 'shepherd-button-primary',
        text: 'OGR & Inventory',
        action: () => tutorialService.show('ogr-inventory-widget')
      }
    ]);
  } else {
    mainStepButtons = mainStepButtons.concat([
      {
        classes: 'shepherd-button-primary',
        text: 'Production',
        action: () => tutorialService.show('avocado-production-widget')
      },
      {
        classes: 'shepherd-button-primary',
        text: 'Sample Information',
        action: () => tutorialService.show('avocado-sample-information-widget')
      }
    ]);
  }

  mainStepButtons = mainStepButtons.concat([
    {
      classes: 'shepherd-button-secondary',
      text: 'I\'m Not Interested',
      action: () => {
        return tutorialService.cancel();
      }
    }
  ]);

  let steps: TutorialStep[] = [
    new TutorialStep ({
      id: 'intro',
      title: 'Welcome to the Trevelyan\'s Grower Portal',
      text: `If this is your first time using the Trevelyan’s Grower Portal, we’ve created this brief tutorial so you can make the most of this valuable tool.`,
      beforeShowPromise: () => {
        return new Promise<void>((resolve) => {
          setTimeout(() => {
            window.scrollTo(0, 0);
            resolve();
          }, 500);
        });
      },
      buttons: mainStepButtons,
      classes: 'tutorial',
      highlightClass: 'highlight'
    })
  ];

  steps = steps.concat(getCalendarSteps());
  steps = steps.concat(getMapWidgetSteps(tutorialService));

  if (currentProduct === 'kiwifruit') {
    steps = steps.concat(getKiwifruitProductionWidgetSteps(tutorialService));
    steps = steps.concat(getFlowerBudCountsWidgetSteps(tutorialService));
    steps = steps.concat(getFruitCountsWidgetSteps(tutorialService));
    steps = steps.concat(getOgrInventoryWidgetSteps(tutorialService));
    steps = steps.concat(getKiwifruitSampleInformationWidgetSteps(tutorialService));
  } else {
    steps = steps.concat(getAvocadoProductionWidgetSteps(tutorialService));
    steps = steps.concat(getAvocadoSampleInformationWidgetSteps(tutorialService));
  }

  steps = steps.concat(getLatestNewsSteps());

  steps = steps.concat(
    new TutorialStep({
      id: 'help-buttons',
      attachTo: {
        element: 'app-orchard-calendar-component > header > div.title > app-help-button',
        on: AttachDirection.bottom
      },
      scrollTo: true,
      title: 'Help',
      text: `If you'd like more detailed information on any of these tools, simply click the "?" buttons next to each heading.`
    }),
    new TutorialStep ({
      id: 'feedback',
      buttons: [
        {
          classes: 'shepherd-button-primary',
          text: 'End Tutorial',
          action() {
            return tutorialService.cancel();
          }
        }
      ],
      scrollTo: true,
      title: 'All done!',
      text: `Thanks for taking the time to learn more about the Trevelyan's Grower Portal. We hope you find it helpful and easy to use. If you have any suggestions, critiques or even kind words you'd like to share with us, you can use the "Feedback" button in the bottom right corner of any page.`
    })
  );
  return steps;
}
