import { Component, HostBinding } from '@angular/core';
import { randomBgCssClass } from 'app/shared/services/utils.service';

const NUM_BG_IMAGES = 7;

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss']
})
export class PageNotFoundComponent {
  @HostBinding('class') class = randomBgCssClass(NUM_BG_IMAGES);
  constructor() { }
}
