import { BaseFilterCellComponent, FilterService } from '@progress/kendo-angular-grid';
import { Input, Component } from '@angular/core';
import { process, State } from '@progress/kendo-data-query';
import { CompositeFilterDescriptor } from '@progress/kendo-data-query';

@Component({
  selector: 'combobox-filter',
  template: `
    <kendo-combobox [data]="filteredData"
                    (valueChange)="onValueChange($event)"
                    (filterChange)="onFilterChange($event)"
                    [filterable]="true"
                    [value]="selectedValue"
                    [valuePrimitive]="true"
                    [textField]="textField"
                    [valueField]="valueField">
    </kendo-combobox>
  `,
  styleUrls: ['./combobox-filter.component.scss']
})

export class ComboBoxFilterComponent extends BaseFilterCellComponent {

  get selectedValue(): any {
    const filter = this.filterByField(this.valueField);
    return filter ? filter.value : null;
  }

  private _data: any[];
  filteredData: any[];
  @Input() declare filter: CompositeFilterDescriptor;
  @Input() textField: string;
  @Input() valueField: string;
  @Input()
  get data(): any[] {
    return this._data;
  }
  set data(data: any[]) {
    this._data = data;
    this.filteredData = data;
  }

  constructor(
    filterService: FilterService
  ) {
    super(filterService);
  }

  onValueChange(value: any): void {
    this.applyFilter(
      !value ? this.removeFilter(this.valueField) : this.updateFilter({
        field: this.valueField,
        operator: 'eq',
        value: value
      })
    );
  }

  onFilterChange(value: any) {
    const state = {
      filter: {
        logic: 'and',
        filters: [{ field: this.textField, operator: 'contains', value: value }]
      }
    };
    this.filteredData = process(this.data, state as State).data;
  }
}
