<app-widget [class]="countType"
            [updatedAt]="updatedAt"
            [dropdownItems]="availableSeasons"
            [selectedDropdownItem]="selectedSeason"
            [onDropdownItemChange]="selectSeason.bind(this)"
            [isLoading]="isLoading"
            [hasNoData]="!tabs?.length"
            loadingMessage="Loading {{ countType }} counts data..."
            noDataMessage="No {{ countType }} counts data available."
            dropdownHandle="kiwifruitCountWidgetSeasonDropdown">
  <kendo-tabstrip *ngIf="tabs?.length" class="tabstrip"
                  (tabSelect)="onTabSelect($event)">
    <kendo-tabstrip-tab *ngFor="let tab of tabs"
                        [title]="tab.title"
                        [selected]="tab.selected">
        <ng-template kendoTabContent>
          <app-simple-grid [columns]="gridColumns"
                           [data]="gridDataFiltered"
                           [selectedColumnKey]="selectedColumnKey"
                           [selectedRowIndexes]="[0]"
                           rowSelectType="single"
                           [rowSelectCallback]="onGridRowSelect.bind(this)"
                           [hasColumnSelect]="true"
                           [columnSelectCallback]="onGridColumnSelect.bind(this)"
                           [rowErrors]="rowErrors">
          </app-simple-grid>
        </ng-template>
    </kendo-tabstrip-tab>
  </kendo-tabstrip>

  <div class="details" [class.visible]="areDetailsVisible">
    <app-grouped-bar-chart [data]="detailData.data"
                           [groups]="detailData.groups"
                           [yAxis]="detailsChartYAxis"
                           [precision]="detailPrecision"
                           [minValue]="0"
                           [showLegend]="false">
    </app-grouped-bar-chart>
  </div>
</app-widget>
