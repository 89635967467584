<header class="header">
  <div class="title">
    <h3>{{ title }}</h3>
    <app-help-button
      [helpKey]="'calendar'">
    </app-help-button>
  </div>

  <div class="calendar-menu">
    <div *ngIf="!calendarIsCollapsed"
         class="group flex-row">
      <dropdown *ngIf="varietiesAndGrowMethods && varietiesAndGrowMethods.length"
                class="variety-and-grow-method-dropdown"
                [items]="varietiesAndGrowMethods"
                [current]="varietiesAndGrowMethods[0]"
                [handle]="'varietyAndGrowMethodDropdown'"
                [highlightSelected]="true"
                (currentChange)="selectVarietyAndGrowMethod($event)">
        <button class="button transparent tiny rounded bordered">
          {{ selectedVarietyAndGrowMethod.label }}
          <i class="fas fa-chevron-right right"></i>
        </button>
      </dropdown>

      <button *ngIf="showExpandAllButton" class="button transparent tiny rounded bordered" (click)="toggleExpandAll()">
        {{ isExpanded ? 'Collapse All' : 'Expand All' }}
        <i [class]="'fas ' + (isExpanded ? 'fa-chevron-down' : 'fa-chevron-right')"></i>
      </button>

      <button class="today-button button transparent tiny rounded bordered" (click)="goToToday()">
        Today
        <i class="far fa-calendar-check right"></i>
      </button>
    </div>

    <button *ngIf="showHideCalendarButton" class="button transparent tiny rounded bordered toggle-calendar-button" (click)="toggleCalendar()">
      <span *ngIf="calendarIsCollapsed; then showCalendarButton else hideCalendarButton"></span>
      <ng-template #showCalendarButton>
        Show Calendar
        <i class="far fa-window-maximize right"></i>
      </ng-template>
      <ng-template #hideCalendarButton>
        Hide Calendar
        <i class="fas fa-times right"></i>
      </ng-template>
    </button>
  </div>
</header>

<div class="orchard-calendar-content"
     (mouseleave)="onMouseLeave($event)">
  <div class="fade"></div>

  <div class="previous"
       [style.width]="buttonWidth + 'px'"
       (click)="previousWeek()">
    <i class="fas fa-chevron-left"></i>
  </div>

  <div class="main no-select"
       (touchstart)="onTouchStart($event)"
       (mousedown)="onMouseDown($event)"
       (touchmove)="onTouchMove($event)"
       (mousemove)="onMouseMove($event)"
       (touchend)="onTouchEnd($event)"
       (mouseup)="onMouseUp($event)"
       [class.dragging]="dragStartX">

    <div class="loading-indicator"
        [class.is-visible]="isDataLoading">
      <i class="fas fa-circle-notch fa-spin"></i>
      Loading Data...
    </div>

    <div class="error-indicator"
        [class.is-visible]="isError">
      <i class="fas fa-exclamation-triangle"></i>
      Failed to load data.
    </div>

    <div class="data-panel">
      <div #categories
           [style.transform]="'translateX(' + xTranslation + 'px)'"
           class="categories">
        <div *ngFor="let category of data"
             class="category"
             [class.dark]="category.isDark"
             [class.hidden]="category.isEmpty || !category.inView">

          <div class="category-panel"
               [class.grouped]="category.grouped"
               [style.left]="category.left + 'px'"
               [style.width]="category.width + 'px'"
               [style.backgroundColor]="category.grouped ? '#' + category.colour : 'transparent'">

            <div class="category-header"
                 [class.visible]="category.grouped"
                 (click)="toggleCategory(category)">
              <div class="category-header-content"
                   [style.marginLeft]="category.headerLeft + 'px'">
                <i [class]="category.isOpen ? 'fas fa-chevron-down' : 'fas fa-chevron-right'"></i>
                <span class="category-name">{{ category.name }}</span>
                <span class="title">{{ category.title }}</span>
              </div>
              <div class="category-header-content dark"
                   [style.marginLeft]="category.headerLeft + 'px'">
                <i [class]="category.isOpen ? 'fas fa-chevron-down' : 'fas fa-chevron-right'"></i>
                <span class="category-name">{{ category.name }}</span>
                <span class="title">{{ category.title }}</span>
              </div>
            </div>

            <div class="category-items"
                 [style.height]="category.height + 'px'"
                 [class.visible]="category.isOpen || !category.grouped">
              <div *ngFor="let item of category.items"
                   class="category-item"
                   [class.hidden]="!item.inView"
                   [style.left]="item.left + 'px'"
                   [style.top]="item.top + 'px'"
                   [style.width]="item.width + 'px'"
                   (mouseenter)="onItemMouseEnter(item, $event)"
                   (mouseleave)="onItemMouseLeave()">
                <div class="color-panel"
                     [style.backgroundColor]="'#' + category.colour"
                     (click)="showItemContent(category.name, item)">
                  <div class="title"
                       [style.width]="item.maxTitleWidth + 'px' || 'auto'"
                       [style.paddingLeft]="(item.left < category.headerLeft ? category.headerLeft - item.left + 5 : 5) + 'px'"
                       [style.overflow]="item.maxTitleWidth ? 'hidden' : 'visible'">
                    {{ item.content && item.title !== item.content ? item.title + ': ' + item.cleanContent : item.cleanContent || item.title }}
                  </div>
                </div>
                <div class="title dark"
                     [style.width]="item.maxTitleWidth + 'px' || 'auto'"
                     [style.paddingLeft]="(item.left < category.headerLeft ? category.headerLeft - item.left + 5 : 5) + 'px'"
                     [style.overflow]="item.maxTitleWidth ? 'hidden' : 'visible'">
                  {{ item.content && item.title !== item.content ? item.title + ': ' + item.cleanContent : item.cleanContent || item.title }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div #grid
         class="grid"
         [style.transform]="'translateX(' + xTranslation + 'px)'">
      <div class="current-date-indicator"
           [style.width]="nowWidth + 'px'"
           [style.left]="nowLeft + 'px'">
        <div class="line"></div>
        <div class="label">Today, {{ now | date:humanDateFormat }}</div>
      </div>
      <div *ngFor="let gridItem of gridItems"
           class="grid-item"
           [class.odd]="gridItem.isOdd"
           [style.width]="gridItem.width + 'px'"
           [style.left]="gridItem.left + 'px'">
        <div class="day">
          {{ gridItem.showMonth ? (gridItem.date | date:'MMM d') : (gridItem.date | date:'d')}} &nbsp;
          <div class="week">WK {{(gridItem.date | date:'ww')}} &nbsp;</div>
        </div>
      </div>
    </div>

    <app-tooltip class="orchard-calendar-tooltip"
                 [title]="tooltipData.title"
                 [hasFooter]="!!tooltipData.footer"
                 [footer]="tooltipData.footer"
                 [top]=tooltipPosition.top
                 [left]=tooltipPosition.left
                 [width]=tooltipWidth
                 [visible]=isTooltipVisible
                 [hasOpacityTransition]="true">
      {{ tooltipData.content }}
    </app-tooltip>
  </div>

  <div class="next"
       [style.width]="buttonWidth + 'px'"
       (click)="nextWeek()">
    <i class="fas fa-chevron-right"></i>
  </div>
</div>
